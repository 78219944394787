import React from "react";
import { withRouter } from "react-router-dom";

//utils
import { handleRoundOneDecimal } from "../../utils/roundDecimal";
import {
  IMAGE_RESTAURANT_THUMB_SIZE,
  IMAGE_URL,
  getImageBase,
  RESTAURANT_FOLDER,
} from "../../constants/imageConstants";
import ImageWithLoading from "../ImageWithLoading";

const SearchedItemsDropdown = ({
  dropdownOpen,
  searchedItems,
  onMenuItemClick,
  filters,
  onSearchAllRestaurants,
  loading,
  isSearchBoxEmpty,
  searchedItemLength,
  children,
}) => {
  const searchedItemKeys = searchedItems
    ? ["cuisines", "restaurants", "tags"]
    : null;

  return (
    <div
      className={`search_dropDown location_search_dropdown ${
        dropdownOpen === "filters" ? "active" : ""
      }`}
    >
      {children || null}
      {filters && filters.locationName && !searchedItemLength ? (
        <div className="search_padd">
          <div className="search_body">
            <ul>
              <li
                className="m-0"
                onClick={() => onSearchAllRestaurants("allRestaurants")}
                style={{ cursor: "pointer" }}
              >
                <div className="link_red d-flex-align">
                  <img src="/images/icon_send.png" alt="" />
                  <span>All {filters.locationName} restaurants</span>
                </div>
              </li>
              <li
                onClick={() => onSearchAllRestaurants("topTen")}
                style={{ cursor: "pointer" }}
              >
                <div className="link_red d-flex-align">
                  <img src="/images/icon_send.png" alt="" />
                  <span>Top 10 in {filters.locationName}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
      {searchedItems &&
      Object.values(searchedItems).some((el) => el.length) &&
      searchedItemLength ? (
        <React.Fragment>
          {loading ? (
            <div className="search_location search_padd">
              <div className="search_title d-flex-align">
                <h5 className="padd-left-9">Coming right up...</h5>
              </div>
            </div>
          ) : null}
          {searchedItemKeys.map((key, index) => {
            if (searchedItems[key].length && !isSearchBoxEmpty) {
              if (key === "restaurants") {
                return (
                  <div className="search_location search_padd" key={index}>
                    <div className="search_title d-flex-align">
                      <h5>Restaurants</h5>
                    </div>
                    <div className="restonserach">
                      <ul>
                        {searchedItems[key].map((item) => {
                          let restaurantImage = `/images/user_join_tablet_bg.png`;
                          if (item.photoList && item.photoList.length) {
                            //restaurantImage = `${IMAGE_URL}/${IMAGE_RESTAURANT_THUMB_SIZE}/${item.photoList[0].fileName}`
                            restaurantImage = `${IMAGE_URL}/${getImageBase(
                              item.photoList[0].fileName,
                              RESTAURANT_FOLDER,
                              IMAGE_RESTAURANT_THUMB_SIZE.width,
                              IMAGE_RESTAURANT_THUMB_SIZE.height
                            )}`;
                          }
                          return (
                            <li
                              key={item._id}
                              onClick={() =>
                                onMenuItemClick(key, item.slug, item.title)
                              }
                              className="d-flex-align"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="leftrestimg">
                                <ImageWithLoading
                                  src={restaurantImage}
                                  alt="restaurant-image"
                                  type="restaurantThumb"
                                />
                              </div>
                              <div className="rightrestconts">
                                <h4>
                                  {item.title.length > 18
                                    ? `${item.title.substring(0, 16)}..`
                                    : item.title}
                                  <sub>
                                    <img src="/images/icon_star.png" alt="" />
                                    {handleRoundOneDecimal(item.rating)}
                                  </sub>
                                </h4>
                                <p className="locrest">
                                  <i className="fa fa-map-marker" />
                                  {(item.place && item.place.vicinity) || ""}
                                </p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="search_location search_padd" key={index}>
                    <div className="search_title d-flex-align">
                      <h5 className="padd-left-9">
                        {key === "tags" ? "Filters" : key}
                      </h5>
                    </div>
                    <div className="search_body">
                      <ul className="search_name_list search_lo_list">
                        {searchedItems[key].map((item) => {
                          return (
                            <li
                              key={item._id}
                              onClick={() =>
                                onMenuItemClick(key, item._id, "", item)
                              }
                            >
                              <div className="d-flex-align">
                                {key === "cuisines" ? (
                                  <img
                                    src={`/images/cuisine/${item.slug}.png`}
                                    srcset={`/images/cuisine/${item.slug}@2x.png 2x`}
                                    onError={(event) => {
                                      event.target.src =
                                        "/images/cuisine/default.png";
                                      event.target.srcset =
                                        "/images/cuisine/default@2x.png 2x";
                                    }}
                                    alt="cuisine"
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.category &&
                                      item.category === "Cuisine"
                                        ? `/images/cuisine/${item.slug}.png`
                                        : `/images/tag/${item.slug}.png`
                                    }
                                    srcset={
                                      item.category &&
                                      item.category === "Cuisine"
                                        ? `/images/cuisine/${item.slug}@2x.png 2x`
                                        : `/images/tag/${item.slug}@2x.png 2x`
                                    }
                                    onError={(event) => {
                                      event.target.src =
                                        "/images/tag/default.png";
                                      event.target.srcset =
                                        "/images/tag/default@2x.png 2x";
                                    }}
                                    alt="tag"
                                  />
                                )}
                                <span>
                                  {item.title.length > 32
                                    ? `${item.title.substring(0, 30)}..`
                                    : item.title}
                                </span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              }
            }
          })}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!loading && !isSearchBoxEmpty && searchedItemLength ? (
            <div className="search_location search_padd">
              <div className="search_title d-flex-align">
                <h5 className="padd-left-9">No results found</h5>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="search_location search_padd">
              <div className="search_title d-flex-align">
                <h5 className="padd-left-9">Coming right up...</h5>
              </div>
            </div>
          ) : null}
          <div className="search_location search_padd">
            <div className="search_title d-flex-align">
              <h5 className="padd-left-9">Cuisines</h5>
            </div>
            <div className="search_body">
              <ul className="search_name_list search_lo_list">
                <li
                  onClick={() =>
                    onMenuItemClick(
                      "cuisines",
                      "5e457cf4c3b9e62c71617f42",
                      "British"
                    )
                  }
                >
                  <div className="d-flex-align">
                    <img src="/images/britain.png" alt="" />
                    <span>British</span>
                  </div>
                </li>
                <li
                  onClick={() =>
                    onMenuItemClick(
                      "cuisines",
                      "5e457cf4c3b9e62c71617f4e",
                      "Mediterranean"
                    )
                  }
                >
                  <div className="d-flex-align">
                    <img src="/images/cuisine/mediterranean.png" alt="" />
                    <span>Mediterranean</span>
                  </div>
                </li>
                <li
                  onClick={() =>
                    onMenuItemClick(
                      "cuisines",
                      "5e457cf4c3b9e62c71617f43",
                      "Indian"
                    )
                  }
                >
                  <div className="d-flex-align">
                    <img src="/images/cuisine/indian.png" alt="" />
                    <span>Indian</span>
                  </div>
                </li>
                <li className="clearrecent" onClick={onSearchAllRestaurants}>
                  <div>View all cuisines</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="search_location search_padd">
            <div className="search_title d-flex-align">
              <h5 className="padd-left-9">Top filters</h5>
            </div>
            <div className="search_body">
              <ul className="search_name_list search_lo_list">
                <li
                  onClick={() =>
                    onMenuItemClick("tags", "5e457cd0c3b9e62c71617f0a", "Vegan")
                  }
                >
                  <div className="d-flex-align">
                    <img src="/images/vegan.png" alt="" />
                    <span>Vegan</span>
                  </div>
                </li>
                <li
                  onClick={() =>
                    onMenuItemClick("tags", "5e457cd0c3b9e62c71617f12", "Halal")
                  }
                >
                  <div className="d-flex-align">
                    <img src="/images/halal.png" alt="" />
                    <span>Halal</span>
                  </div>
                </li>
                <li
                  onClick={() =>
                    onMenuItemClick(
                      "tags",
                      "5e457cd0c3b9e62c71617f35",
                      "Healthy"
                    )
                  }
                >
                  <div className="d-flex-align">
                    <img src="/images/healthy.png" alt="" />
                    <span>Healthy</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(SearchedItemsDropdown);
