import {FETCH_TOP3_BANNER_RESTAURANTS,
    FETCH_TOP3_BANNER_RESTAURANTS_SUCCESS,
    FETCH_TOP3_BANNER_RESTAURANTS_FAILED,
    FETCH_TOP_BANNER_CUISINES,
    FETCH_TOP_BANNER_CUISINES_SUCCESS,
    FETCH_TOP_BANNER_CUISINES_FAILED,
    FETCH_INSPIRATION_TAGS,
    FETCH_INSPIRATION_TAGS_SUCCESS,
    FETCH_INSPIRATION_TAGS_FAILED
} from '../../constants/actionTypes'

const initialState = {
    top3bannerRestaurants : {
        "bannerData": {
            "_id": "5eff2d5475e2ad2e1c60c55a",
            "__v": 0,
            "createDate": "2020-07-03T13:06:28.383Z",
            "fillInfo": true,
            "guideType": "best restaurants",
            "isOnline": true,
            "modifiedDate": "2020-08-06T13:26:27.261Z",
            "onlineDate": "2021-03-09T07:54:08.233Z",
            "postText": "in these locations",
            "preText": "View the",
            "slug": "best-restaurants-1",
            "status": "active"
        },
        "restaurants": [
            {
                "_id": "5e457d20c3b9e62c71618515",
                "place": {
                    "location": {
                        "type": "Point",
                        "coordinates": [
                            -1.5461808,
                            53.7999744
                        ]
                    },
                    "placeId": "ChIJNQ1dWzb9DDkRS8WIby85g7U",
                    "vicinity": "4 The Light, The Headrow, Leeds"
                },
                "title": "Nando's",
                "slug": "nando's-3526",
                "keywords": [
                    "nando's"
                ],
                "description": "",
                "ordering": 639,
                "region": null,
                "county": null,
                "city": null,
                "cuisine": [
                    "5e457cf4c3b9e62c71617f56",
                    "5e457cf4c3b9e62c71617f67",
                    "5e457cf4c3b9e62c71617f80"
                ],
                "phones": [
                    {
                        "restaurantTel": "0113 242 8908"
                    }
                ],
                "email": "",
                "website": "https://www.nandos.co.uk/restaurants/leeds-light",
                "pageContent": "",
                "labels": [],
                "tags": [
                    "5e457cd0c3b9e62c71617f00",
                    "5e457cd0c3b9e62c71617f04",
                    "5e457cd0c3b9e62c71617f07",
                    "5e457cd0c3b9e62c71617f08",
                    "5e457cd0c3b9e62c71617f12",
                    "5e457cd0c3b9e62c71617f1f",
                    "5e457cd0c3b9e62c71617f20",
                    "5e457cd0c3b9e62c71617f21",
                    "5e457cd0c3b9e62c71617f23",
                    "5e457cd0c3b9e62c71617f26",
                    "5e457cd0c3b9e62c71617f2b",
                    "5e457cd0c3b9e62c71617f2c",
                    "5e457cd0c3b9e62c71617f35",
                    "5e457cd0c3b9e62c71617f40"
                ],
                "avgPrice": [
                    {
                        "Drink": "5.00"
                    },
                    {
                        "Starter": 0
                    },
                    {
                        "Main": "7.71"
                    },
                    {
                        "Side": "2.60"
                    },
                    {
                        "restaurantPrice": "15.31"
                    }
                ],
                "hygieneInspection": {
                    "isAvailable": false
                },
                "ssoReviews": [],
                "openingTimes": [
                    {
                        "close": {
                            "day": 0,
                            "time": "2330"
                        },
                        "open": {
                            "day": 0,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 1,
                            "time": "2330"
                        },
                        "open": {
                            "day": 1,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 2,
                            "time": "2330"
                        },
                        "open": {
                            "day": 2,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 3,
                            "time": "2330"
                        },
                        "open": {
                            "day": 3,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 4,
                            "time": "2330"
                        },
                        "open": {
                            "day": 4,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 5,
                            "time": "2330"
                        },
                        "open": {
                            "day": 5,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 6,
                            "time": "2330"
                        },
                        "open": {
                            "day": 6,
                            "time": "1130"
                        }
                    }
                ],
                "photos": [
                    {
                        "category": "featured_image",
                        "media": "5e731eff4dedb527e8823218",
                        "viewOrder": 0
                    },
                    {
                        "category": "promotion",
                        "media": "5e731f1c4dedb527e88239af",
                        "viewOrder": 0
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f5b4dedb527e88245a3",
                        "viewOrder": 4
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f5b4dedb527e88245a2",
                        "viewOrder": 2
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f5b4dedb527e88245a4",
                        "viewOrder": 3
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f5b4dedb527e88245a5",
                        "viewOrder": 6
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f5b4dedb527e88245a6",
                        "viewOrder": 5
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f5b4dedb527e88245a1",
                        "viewOrder": 1
                    }
                ],
                "status": "active",
                "restaurantType": "listed",
                "listedDate": null,
                "listedBy": null,
                "inspected": false,
                "oldDBId": "3526",
                "plateformRatings": [
                    {
                        "_id": "5e4fd93001410f5b4eaa6567",
                        "plateform": "tablefy",
                        "rating": 0,
                        "user_ratings_total": 0
                    },
                    {
                        "_id": "5e5e1355005c42375458a6d7",
                        "plateform": "google",
                        "rating": 4.1,
                        "user_ratings_total": 5847
                    }
                ],
                "createDate": "2020-02-13T16:45:20.338Z",
                "modifiedDate": "2020-02-13T16:45:20.338Z",
                "inspectionHistory": [],
                "__v": 0,
                "virtualTourLink": null,
                "priceCategory": "A la carte",
                "restaurantId": "5e457d20c3b9e62c71618515",
                "rating": 2.05,
                "totalReviews": 5847
            },
            {
                "_id": "5e457d20c3b9e62c716186d1",
                "place": {
                    "location": {
                        "type": "Point",
                        "coordinates": [
                            -1.152565706,
                            52.95354531
                        ]
                    },
                    "placeId": "ChIJNQ1dWzb9DDkRS8WIby85g7U",
                    "vicinity": "12 Angel Row, Nottingham"
                },
                "title": "Nando's",
                "slug": "nando's-3217",
                "keywords": [
                    "nando's"
                ],
                "description": "",
                "ordering": 7654,
                "region": null,
                "county": null,
                "city": null,
                "cuisine": [
                    "5e457cf4c3b9e62c71617f56",
                    "5e457cf4c3b9e62c71617f67",
                    "5e457cf4c3b9e62c71617f80"
                ],
                "phones": [
                    {
                        "restaurantTel": "0115 985 9641"
                    }
                ],
                "email": "",
                "website": "https://www.nandos.co.uk/restaurants/nottingham-market-square",
                "pageContent": "<h3>Flame-grilled chicken and fiery Afro-Portuguese flavour.</h3><p>Nando&rsquo;s is renowned worldwide for its lip-smacking grilled chicken which brings peri-peri flavour all the way from South Africa. </p><p>Marinades range from the \"mild\" lemon and herb to extra hot, with chicken ordered by the whole or half, in a burger or pitta, with rice or chips.&nbsp;</p><p>The menu goes on to provide choices to be enjoyed by the whole family and the atmosphere at Nando's is cool, rustic and welcoming.</p><p>South African artwork, a stripped-back wood and brick finish and impressive bamboo huts housing some of the tables make this a fun destination for any laidback get-together.</p><p>Find this branch of the ever-popular peri-peri chain looking out onto Nottingham's Old Market Square from Angel Row.&nbsp;<br /></p>",
                "labels": [],
                "tags": [
                    "5e457cd0c3b9e62c71617f02",
                    "5e457cd0c3b9e62c71617f04",
                    "5e457cd0c3b9e62c71617f07",
                    "5e457cd0c3b9e62c71617f13",
                    "5e457cd0c3b9e62c71617f1e",
                    "5e457cd0c3b9e62c71617f1f",
                    "5e457cd0c3b9e62c71617f20",
                    "5e457cd0c3b9e62c71617f21",
                    "5e457cd0c3b9e62c71617f23",
                    "5e457cd0c3b9e62c71617f26",
                    "5e457cd0c3b9e62c71617f29",
                    "5e457cd0c3b9e62c71617f2b",
                    "5e457cd0c3b9e62c71617f2c",
                    "5e457cd0c3b9e62c71617f2e",
                    "5e457cd0c3b9e62c71617f2f",
                    "5e457cd0c3b9e62c71617f33",
                    "5e457cd0c3b9e62c71617f35",
                    "5e457cd0c3b9e62c71617f40"
                ],
                "avgPrice": [
                    {
                        "Drink": "5.00"
                    },
                    {
                        "Starter": 0
                    },
                    {
                        "Main": "7.88"
                    },
                    {
                        "Side": 0
                    },
                    {
                        "restaurantPrice": "12.88"
                    }
                ],
                "hygieneInspection": {
                    "isAvailable": false
                },
                "ssoReviews": [],
                "openingTimes": [
                    {
                        "close": {
                            "day": 0,
                            "time": "2330"
                        },
                        "open": {
                            "day": 0,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 1,
                            "time": "2330"
                        },
                        "open": {
                            "day": 1,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 2,
                            "time": "2330"
                        },
                        "open": {
                            "day": 2,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 3,
                            "time": "2330"
                        },
                        "open": {
                            "day": 3,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 4,
                            "time": "2330"
                        },
                        "open": {
                            "day": 4,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 5,
                            "time": "2330"
                        },
                        "open": {
                            "day": 5,
                            "time": "1130"
                        }
                    },
                    {
                        "close": {
                            "day": 6,
                            "time": "2330"
                        },
                        "open": {
                            "day": 6,
                            "time": "1130"
                        }
                    }
                ],
                "photos": [
                    {
                        "category": "featured_image",
                        "media": "5e731efd4dedb527e88231b4",
                        "viewOrder": 0
                    },
                    {
                        "category": "promotion",
                        "media": "5e731f1b4dedb527e8823919",
                        "viewOrder": 0
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f534dedb527e8824383",
                        "viewOrder": 3
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f534dedb527e8824384",
                        "viewOrder": 2
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f534dedb527e8824385",
                        "viewOrder": 6
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f534dedb527e8824386",
                        "viewOrder": 1
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f534dedb527e8824388",
                        "viewOrder": 4
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f534dedb527e882438a",
                        "viewOrder": 5
                    }
                ],
                "status": "active",
                "restaurantType": "listed",
                "listedDate": null,
                "listedBy": null,
                "inspected": false,
                "oldDBId": "3217",
                "plateformRatings": [
                    {
                        "_id": "5e4fd93301410f5b4eaa686a",
                        "plateform": "tablefy",
                        "rating": 0,
                        "user_ratings_total": 0
                    },
                    {
                        "_id": "5e54af8377890c1d6fd67767",
                        "plateform": "google",
                        "rating": 4.1,
                        "user_ratings_total": 5779
                    }
                ],
                "createDate": "2020-02-13T16:45:20.606Z",
                "modifiedDate": "2020-02-13T16:45:20.606Z",
                "inspectionHistory": [],
                "__v": 0,
                "virtualTourLink": null,
                "priceCategory": "A la carte",
                "coins": 50,
                "restaurantId": "5e457d20c3b9e62c716186d1",
                "rating": 2.05,
                "totalReviews": 5779
            },
            {
                "_id": "5e457d20c3b9e62c716188a0",
                "place": {
                    "location": {
                        "type": "Point",
                        "coordinates": [
                            -1.155144,
                            52.951684
                        ]
                    },
                    "placeId": "ChIJPcr-g-UadkgRHjOPc3ITZdo",
                    "vicinity": "Royal Standard Place, Nottingham"
                },
                "title": "The Round House",
                "slug": "the-round-house-3078",
                "keywords": [
                    "the round house"
                ],
                "description": "",
                "ordering": 486,
                "region": null,
                "county": null,
                "city": null,
                "cuisine": [
                    "5e457cf4c3b9e62c71617f42",
                    "5e457cf4c3b9e62c71617f4e",
                    "5e457cf4c3b9e62c71617f7e"
                ],
                "phones": [
                    {
                        "restaurantTel": "0115 924 0120"
                    }
                ],
                "email": "",
                "website": "https://www.roundhousenottingham.com/",
                "pageContent": "<h3>Friendly bar and restaurant in unusual venue.</h3><p>The Round House brings an array of quality British food for those feeling peckish, with offerings of Welsh Rarebit for starters and wonderfully juicy pan-roasted loin of Lamb and grilled Scottish salmon fillet for the main event.</p><p>Their burgers add creativity to the menu with the likes of the Lentil and Spinach Burger and The Lamb Mint Burger with Greek tzatziki with battered onion rings, hand-cut chips or salad as standard.</p><p>The building&rsquo;s period features and Renaissance-style ceiling frescos make for a relaxing environment, with leather armchairs to cosy in on a chilly day and an outdoor terrace ideal for a glass of Pimm's during warmer weather.</p><p>The Round House also offers a convenient lunch menu full of snacks, light bites and main meals that lead up to the grand end-of-the-week Sunday full of seasonal veg, potatoes, puddings and meat with all the trimmings (or a butternut squash and pine nut roast for the vegetarians!)</p><p>For your leisurely catch-up, find The Round House just up the hill from the Castle in Nottingham City Centre.<br /></p>",
                "labels": [],
                "tags": [
                    "5e457cd0c3b9e62c71617f00",
                    "5e457cd0c3b9e62c71617f04",
                    "5e457cd0c3b9e62c71617f06",
                    "5e457cd0c3b9e62c71617f07",
                    "5e457cd0c3b9e62c71617f08",
                    "5e457cd0c3b9e62c71617f0a",
                    "5e457cd0c3b9e62c71617f11",
                    "5e457cd0c3b9e62c71617f13",
                    "5e457cd0c3b9e62c71617f15",
                    "5e457cd0c3b9e62c71617f18",
                    "5e457cd0c3b9e62c71617f19",
                    "5e457cd0c3b9e62c71617f1b",
                    "5e457cd0c3b9e62c71617f1d",
                    "5e457cd0c3b9e62c71617f1f",
                    "5e457cd0c3b9e62c71617f20",
                    "5e457cd0c3b9e62c71617f21",
                    "5e457cd0c3b9e62c71617f23",
                    "5e457cd0c3b9e62c71617f2c",
                    "5e457cd0c3b9e62c71617f2e",
                    "5e457cd0c3b9e62c71617f2f",
                    "5e457cd0c3b9e62c71617f31",
                    "5e457cd0c3b9e62c71617f32",
                    "5e457cd0c3b9e62c71617f3a",
                    "5e457cd0c3b9e62c71617f3b",
                    "5e457cd0c3b9e62c71617f40"
                ],
                "avgPrice": [
                    {
                        "Drink": "5.00"
                    },
                    {
                        "Starter": "8.50"
                    },
                    {
                        "Main": "17.38"
                    },
                    {
                        "Side": 0
                    },
                    {
                        "restaurantPrice": "30.88"
                    }
                ],
                "hygieneInspection": {
                    "isAvailable": false
                },
                "ssoReviews": [],
                "openingTimes": [
                    {
                        "close": {
                            "day": 0,
                            "time": "1700"
                        },
                        "open": {
                            "day": 0,
                            "time": "0930"
                        }
                    },
                    {
                        "close": {
                            "day": 1,
                            "time": "1700"
                        },
                        "open": {
                            "day": 1,
                            "time": "0930"
                        }
                    },
                    {
                        "close": {
                            "day": 2,
                            "time": "2100"
                        },
                        "open": {
                            "day": 2,
                            "time": "0930"
                        }
                    },
                    {
                        "close": {
                            "day": 3,
                            "time": "2100"
                        },
                        "open": {
                            "day": 3,
                            "time": "0930"
                        }
                    },
                    {
                        "close": {
                            "day": 4,
                            "time": "2100"
                        },
                        "open": {
                            "day": 4,
                            "time": "0930"
                        }
                    },
                    {
                        "close": {
                            "day": 5,
                            "time": "2100"
                        },
                        "open": {
                            "day": 5,
                            "time": "0930"
                        }
                    },
                    {
                        "close": {
                            "day": 6,
                            "time": "1700"
                        },
                        "open": {
                            "day": 6,
                            "time": "0930"
                        }
                    }
                ],
                "photos": [
                    {
                        "category": "featured_image",
                        "media": "5e731efd4dedb527e882318d",
                        "viewOrder": 0
                    },
                    {
                        "category": "promotion",
                        "media": "5e731f1b4dedb527e88238f0",
                        "viewOrder": 0
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f4e4dedb527e8824248",
                        "viewOrder": 1
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f4e4dedb527e8824249",
                        "viewOrder": 4
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f4e4dedb527e882424a",
                        "viewOrder": 6
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f4e4dedb527e882424b",
                        "viewOrder": 3
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f4e4dedb527e882424c",
                        "viewOrder": 2
                    },
                    {
                        "category": "gallery",
                        "media": "5e731f4e4dedb527e882424d",
                        "viewOrder": 5
                    }
                ],
                "status": "active",
                "restaurantType": "listed",
                "listedDate": null,
                "listedBy": null,
                "inspected": false,
                "oldDBId": "3078",
                "plateformRatings": [
                    {
                        "_id": "5e4fd93001410f5b4eaa6379",
                        "plateform": "tablefy",
                        "rating": 0,
                        "user_ratings_total": 0
                    },
                    {
                        "_id": "5e5e12fb005c42375458a63e",
                        "plateform": "google",
                        "rating": 4.6,
                        "user_ratings_total": 5295
                    }
                ],
                "createDate": "2020-02-13T16:45:20.887Z",
                "modifiedDate": "2020-02-13T16:45:20.887Z",
                "inspectionHistory": [],
                "__v": 0,
                "virtualTourLink": null,
                "priceCategory": "A la carte",
                "restaurantId": "5e457d20c3b9e62c716188a0",
                "rating": 2.3,
                "totalReviews": 5295
            }
        ]
    },
    topBannerCuisines : {},
    inspirationTags : [],
    error : null
}

const reducer = (state = initialState, action) => {
   
    switch(action.type){
        case FETCH_TOP3_BANNER_RESTAURANTS: 
                return { ...state, error: null};
        case FETCH_TOP3_BANNER_RESTAURANTS_SUCCESS:
                const data = action.payload;
                return {
                  ...state,
                  error: null,
                  top3bannerRestaurants: data
                };
        case FETCH_TOP3_BANNER_RESTAURANTS_FAILED:
                return { ...state, error: action.payload };
        case FETCH_TOP_BANNER_CUISINES: 
                return { ...state, error: null};
        case FETCH_TOP_BANNER_CUISINES_SUCCESS:
                const cuisinedata = action.payload;
                return {
                  ...state,
                  error: null,
                  topBannerCuisines: cuisinedata
                };
        case FETCH_TOP_BANNER_CUISINES_FAILED:
                return { ...state, error: action.payload };
        case FETCH_INSPIRATION_TAGS:
                return { ...state, error: null};
        case FETCH_INSPIRATION_TAGS_SUCCESS:
                const inspiration = action.payload;
                return {
                  ...state,
                  error: null,
                  inspirationTags: inspiration
                };
        case FETCH_INSPIRATION_TAGS_FAILED : 
                return { ...state, error: action.payload };
        default:
                return state
    }
}

export default reducer