import {
  ADD_CONTACT_START,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILED,
  ADD_ENQUIRY_START,
  ADD_ENQUIRY_SUCCESS,
  ADD_ENQUIRY_FAILED
} from '../../constants/actionTypes';

const initialState = {
  error: null,
  isLoading: false,
  payload: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTACT_START: {
      return { ...state, error: null, isLoading: true };
    }
    case ADD_CONTACT_SUCCESS: {
      return { ...state, error: null, payload: action.payload, isLoading: false };
    }
    case ADD_CONTACT_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    case ADD_ENQUIRY_START: {
      return { ...state, error: null, isLoading: true };
    }
    case ADD_ENQUIRY_SUCCESS: {
      return { ...state, error: null, payload: action.payload, isLoading: false };
    }
    case ADD_ENQUIRY_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
