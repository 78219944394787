import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  REGISTRATION_START,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILED,
  CLEAR_REGISTRATION_SUCCESS,
  FORGOT_PASSWORD_OTP_START,
  FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_OTP_FAILED,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_OTP_CLEAN,
  UPDATE_USER_DATA,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  UPDATE_USER_COINS,
} from "../../constants/actionTypes";
import { loginService } from "../../service/login-service";
import { showBuzzScreens } from "../actions";
import { FIRST_COINS } from "../../constants/buzzConstants";

export const tryLogin = (username, password, otherData) => {

  const failureMessage = 'Something wrong happened';
  let inviteCode = '';
  if (otherData) {
    inviteCode = otherData.inviteCode;
  }

  return (dispatch) => {
    dispatch(authStart());
    loginService
      .login(username, password, { inviteCode })
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.user) {
          const userData = jwtDecode(response.accessToken);
          localStorage.setItem("userAccessToken", response.accessToken);
          localStorage.setItem("loginData", JSON.stringify(userData));
          dispatch(authSuccess(userData));
          dispatch(fetchProfile());
        } else {
          toast.error(response.message);
          dispatch(authFailed(response.message));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(authFailed(failureMessage));
      });
  };
};

export const trySocialLogin = (token, type, otherData) => {
  const failureMessage = "Something wrong happened";
  let loginFunc;
  if (type === "facebook") {
    loginFunc = loginService.facebookLogin;
  }
  if (type === "google") {
    loginFunc = loginService.googleLogin;
  }

  let inviteCode = "";
  if (otherData) {
    inviteCode = otherData.inviteCode;
  }

  return (dispatch) => {
    dispatch(authStart());
    loginFunc(token, { inviteCode })
      .then((rawData) => {
        return rawData.json();
      })
      .then(response => {
        if (response.user) {
          const userData = jwtDecode(response.accessToken);
          localStorage.setItem("userAccessToken", response.accessToken);
          localStorage.setItem("loginData", JSON.stringify(userData));
          dispatch(authSuccess(userData));
          dispatch(fetchProfile());
        } else {
          toast.error(response.message);
          dispatch(authFailed(response.message));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(authFailed(failureMessage));
      });
  };
};

export const tryRegister = (registrationData) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(registrationStart());
    loginService
      .registration(registrationData)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(registrationSuccess(response.data));
          // call login
          let inviteCode = '';
          dispatch(authStart());
          loginService.login(registrationData.email, registrationData.password, { inviteCode })
            .then(rawData => {
              return rawData.json();
            })
            .then(response => {
              if (response.user) {
                const userData = jwtDecode(response.accessToken);
                localStorage.setItem('userAccessToken', response.accessToken);
                localStorage.setItem('loginData', JSON.stringify(userData));
                dispatch(authSuccess(userData));
                dispatch(fetchProfile());
                dispatch(showBuzzScreens(FIRST_COINS));
              } else {
                toast.error(response.message);
                dispatch(authFailed(response.message));
              }
            })
            .catch(err => {
              console.log(err);
              dispatch(authFailed(failureMessage));
            });
          // dispatch(showBuzzScreens(FIRST_COINS));
        } else {
          toast.error(
            Array.isArray(response.message)
              ? response.message[0]
              : response.message
          );
          dispatch(registrationFailed(response.message));
        }
      })
      .catch(() => {
        dispatch(registrationFailed(failureMessage));
      });
  };
};

export const tryRegisterLogin = (registrationData) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(authStart());
    loginService
      .registration(registrationData)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.user) {
          const userData = jwtDecode(response.accessToken);
          localStorage.setItem("userAccessToken", response.accessToken);
          localStorage.setItem("loginData", JSON.stringify(userData));
          dispatch(authSuccess(userData));
          dispatch(fetchProfile());
        } else {
          toast.error(
            Array.isArray(response.message)
              ? response.message[0]
              : response.message
          );
          dispatch(authFailed(response.message));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(authFailed(failureMessage));
      });
  };
};

export const tryLogout = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(logoutStart());
    loginService
      .logout()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.message === "Authentication object disabled") {
          dispatch(logoutSuccess());
          localStorage.removeItem("userAccessToken");
          localStorage.removeItem("loginData");
          //window.location = "/home";
          window.location.reload();
        } else {
          toast.error(
            Array.isArray(response.message)
              ? response.message[0]
              : response.message
          );
          dispatch(logoutFailed(failureMessage));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(logoutFailed(failureMessage));
      });
  };
};

export const checkAuth = () => {
  return (dispatch) => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (
      loginData &&
      loginData.exp &&
      Date.now().valueOf() / 1000 < Number(loginData.exp)
    ) {
      dispatch(authSuccess(loginData));
      dispatch(fetchProfile());
    } else {
      dispatch(logoutSuccess());
      localStorage.removeItem("userAccessToken");
      localStorage.removeItem("loginData");
    }
  };
};

export const fetchProfile = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchProfileStart());
    loginService
      .getProfile()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          const responseData = { ...response.data[0] };
          const loginData = JSON.parse(localStorage.getItem("loginData"));
          const newLoginData = {
            ...loginData,
            ...responseData,
          };
          localStorage.setItem("loginData", JSON.stringify(newLoginData));
          dispatch(fetchProfileSuccess(newLoginData));
        } else {
          dispatch(fetchProfileFailed(failureMessage));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchProfileFailed(failureMessage));
      });
  };
};

export const tryResetPasswordOtp = (email) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(forgotPasswordOtpStart());
    loginService
      .getForgotPasswordOtp(email)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(forgotPasswordOtpSuccess(email));
        } else {
          dispatch(forgotPasswordOtpFailed(response.message[0]));
        }
      })
      .catch(() => {
        dispatch(forgotPasswordOtpFailed(failureMessage));
      });
  };
};

export const tryResetPassword = (email, resetToken, password) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(forgotPasswordStart());
    loginService
      .setNewPassword({ email, resetToken, password })
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(forgotPasswordSuccess(response.message));
        } else {
          dispatch(forgotPasswordFailed(response.message[0]));
        }
      })
      .catch(() => {
        dispatch(forgotPasswordFailed(failureMessage));
      });
  };
};

export const tryChangePassword = (resetData) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(changePasswordStart());
    loginService
      .changePassword(resetData)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(changePasswordSuccess(response.message));
        } else {
          toast.error(response.message[0]);
          dispatch(changePasswordFailed(response.message[0]));
        }
      })
      .catch(() => {
        dispatch(changePasswordFailed(failureMessage));
      });
  };
};

const authStart = () => {
  return {
    type: LOGIN_START,
  };
};

const authSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

const authFailed = (data) => {
  return {
    type: LOGIN_FAILED,
    payload: data,
  };
};

const registrationStart = () => {
  return {
    type: REGISTRATION_START,
  };
};

const registrationSuccess = (data) => {
  return {
    type: REGISTRATION_SUCCESS,
    payload: data,
  };
};

const registrationFailed = (data) => {
  return {
    type: REGISTRATION_FAILED,
    payload: data,
  };
};

export const clearRegistrationSuccess = () => {
  return {
    type: CLEAR_REGISTRATION_SUCCESS,
  };
};

const logoutStart = () => {
  return {
    type: LOGOUT_START,
  };
};

const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutFailed = (data) => {
  return {
    type: LOGOUT_FAILED,
    payload: data,
  };
};

const forgotPasswordOtpStart = () => {
  return {
    type: FORGOT_PASSWORD_OTP_START,
  };
};

const forgotPasswordOtpSuccess = (email) => {
  return {
    type: FORGOT_PASSWORD_OTP_SUCCESS,
    payload: email,
  };
};

const forgotPasswordOtpFailed = (data) => {
  return {
    type: FORGOT_PASSWORD_OTP_FAILED,
    payload: data,
  };
};

export const forgotPasswordOtpClean = () => {
  return {
    type: FORGOT_PASSWORD_OTP_CLEAN,
  };
};

const forgotPasswordStart = () => {
  return {
    type: FORGOT_PASSWORD_START,
  };
};

const forgotPasswordSuccess = (success) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: success,
  };
};

const forgotPasswordFailed = (data) => {
  return {
    type: FORGOT_PASSWORD_FAILED,
    payload: data,
  };
};

const fetchProfileStart = () => {
  return {
    type: FETCH_PROFILE,
  };
};

const fetchProfileSuccess = (success) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: success,
  };
};

const fetchProfileFailed = (data) => {
  return {
    type: FETCH_PROFILE_FAILURE,
    payload: data,
  };
};

const changePasswordStart = () => {
  return {
    type: CHANGE_PASSWORD_START,
  };
};

const changePasswordSuccess = (success) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: success,
  };
};

const changePasswordFailed = (data) => {
  return {
    type: CHANGE_PASSWORD_FAILED,
    payload: data,
  };
};

export const updateUserCoins = (coinValue) => {
  return {
    type: UPDATE_USER_COINS,
    payload: coinValue,
  };
};

export const updateUserData = (userData) => {
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const newLoginData = {
    ...loginData,
    ...userData,
  };
  localStorage.setItem("loginData", JSON.stringify(newLoginData));
  return {
    type: UPDATE_USER_DATA,
    payload: newLoginData,
  };
};
