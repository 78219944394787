import {
  SAVE_BOOKING_DATA,
  UPDATE_BOOKING_DATA,
  UPDATE_BOOKING_DATA_FAILED,
  UPDATE_BOOKING_DATA_SUCCESS,
  GET_BOOKING_DATA,
  GET_BOOKING_DATA_SUCCESS,
  GET_BOOKING_DATA_FAILED,
  CLEAR_CONFIRMED_BOOKING_DATA,
  SET_CONFIRMED_BOOKING_DATA,
  RESET_PAGING,
  UPDATE_BOOKING_REVIEW_DATA,
  CHANGE_BOOKING_STAGE,
  CHANGE_BOOKING_STAGE_SUCCESS,
  CHANGE_BOOKING_STAGE_FAILED,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILED
} from "../../constants/actionTypes";
import { cloneDeep } from 'lodash';

const initialSettings = {
  bookingData: null,
  error: null,
  isLoading: false,
  payload: null,
  pendingBookingLoading: false,
  confirmedBookingLoading: false,
  cancelledBookingLoading: false,
  historyBookingLoading: false,
  pendingBookingData: null,
  confirmedBookingData: null,
  cancelledBookingData: null,
  historyBookingData: null,
  bookingHistoryPaging: {
    currentPage: 0,
    pageSize: null,
    totalItem: null,
    totalPage: 1,
  },
  stageUpdating: false,
  stagePayload: null,
  bookingStatus:false
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case UPDATE_BOOKING_DATA: {
      return { ...state, error: null, isLoading: true };
    }
    case UPDATE_BOOKING_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false,
        bookingStatus:true
      };
    }
    case UPDATE_BOOKING_DATA_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    case GET_BOOKING_DATA: {
      if (action.bookingType === 'pending') {
        return { ...state, error: null, pendingBookingLoading: true };
      } else if (action.bookingType === 'confirmed') {
        return { ...state, error: null, confirmedBookingLoading: true };
      } else if (action.bookingType === 'history') {
        return { ...state, error: null, historyBookingLoading: true };
      } else {
        return { ...state };
      }
    }
    case GET_BOOKING_DATA_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;
      if (action.bookingType === 'pending') {
        return {
          ...state,
          error: null,
          pendingBookingData: data,
          pendingBookingLoading: false
        };
      } else if (action.bookingType === 'confirmed') {
        return {
          ...state,
          error: null,
          confirmedBookingData: data,
          confirmedBookingLoading: false
        };
      }
      else if (action.bookingType === 'cancelled') {
        return {
          ...state,
          error: null,
          cancelledBookingData: data,
          cancelledBookingLoading: false
        };
      } else if (action.bookingType === 'history') {
        return {
          ...state,
          error: null,
          historyBookingData: action.appendData ? [...state.historyBookingData, ...data] : data,
          historyBookingLoading: false,
          bookingHistoryPaging: {
            ...state.bookingHistoryPaging,
            currentPage,
            pageSize,
            totalItem,
            totalPage
          }
        };
      } else {
        return { ...state };
      }
    }
    case UPDATE_BOOKING_REVIEW_DATA: {
      const newBookingHistory = cloneDeep(state.historyBookingData)
      const updateBookingIndex = newBookingHistory.findIndex(booking => booking._id === action.payload.booking);
      if (updateBookingIndex !== -1) {
        newBookingHistory[updateBookingIndex].reviewData = { ...action.payload }
      }

      return {
        ...state,
        historyBookingData: newBookingHistory
      }
    }
    case GET_BOOKING_DATA_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    case SAVE_BOOKING_DATA:
      return {
        ...state,
        bookingData: action.payload
      };
    case CLEAR_CONFIRMED_BOOKING_DATA: {
      return {
        ...state,
        payload: null
      }
    }
    case SET_CONFIRMED_BOOKING_DATA: {
      return {
        ...state,
        payload: action.payload
      }
    }
    case RESET_PAGING: {
      if (action.payload === 'history') {
        return {
          ...state,
          bookingHistoryPaging: {
            currentPage: 0,
            pageSize: null,
            totalItem: null,
            totalPage: 1,
          }
        }
      } else {
        return { ...state }
      }
    }
    case CHANGE_BOOKING_STAGE: {
      return { ...state, stageUpdating: true, error: null };
    }
    case CHANGE_BOOKING_STAGE_SUCCESS: {
      return { ...state, stageUpdating: false, stagePayload: action.payload, error: null };
    }
    case CHANGE_BOOKING_STAGE_FAILED: {
      return { ...state, stageUpdating: false, error: action.payload };
    }
    case CANCEL_BOOKING: {
      return { ...state, stageUpdating: true, error: null };
    }
    case CANCEL_BOOKING_SUCCESS: {
      return { ...state, stageUpdating: false, stagePayload: action.payload, error: null };
    }
    case CANCEL_BOOKING_FAILED: {
      return { ...state, stageUpdating: false, error: action.payload };
    }
    default:
      return state;
  }
};

export default settings;
