
import { toast } from "react-toastify";
import {
  ADD_CONTACT_START,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILED,
  ADD_ENQUIRY_START,
  ADD_ENQUIRY_SUCCESS,
  ADD_ENQUIRY_FAILED
} from '../../constants/actionTypes';
import { contactService } from '../../service/contact-service';

export const addContact = (data) => {
  const failureMessage = 'Something wrong happened';

  return dispatch => {
    dispatch(addContactStart());
    contactService.addContact(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          toast.success('Contact information added successfully!');
          dispatch(addContactSuccess(response));
        } else {
          toast.error('Failed to submit contact information!');
          dispatch(addContactFailed(response));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(addContactFailed(failureMessage));
      });
  }
};

export const addEnquiry = (data) => {
  const failureMessage = 'Something wrong happened';

  return dispatch => {
    dispatch(addEnquiryStart());
    contactService.addEnquiry(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          toast.success('Enquiry information added successfully!');
          dispatch(addEnquirySuccess(response));
        } else {
          toast.error('Failed to submit enquiry information!');
          dispatch(addEnquiryFailed(response));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(addEnquiryFailed(failureMessage));
      });
  }
};

const addContactStart = () => {
  return {
    type: ADD_CONTACT_START
  }
};

const addContactSuccess = data => {
  return {
    type: ADD_CONTACT_SUCCESS,
    payload: data
  }
};

const addContactFailed = data => {
  return {
    type: ADD_CONTACT_FAILED,
    payload: data
  }
};

const addEnquiryStart = () => {
  return {
    type: ADD_ENQUIRY_START
  }
};

const addEnquirySuccess = data => {
  return {
    type: ADD_ENQUIRY_SUCCESS,
    payload: data
  }
};

const addEnquiryFailed = data => {
  return {
    type: ADD_ENQUIRY_FAILED,
    payload: data
  }
};