import * as Yup from "yup";

export const completeSignUp = Yup.object({
  firstName: Yup.string()
    .max(40, 'Please enter no more than 40 characters')
    .required('We require first name'),
  lastName: Yup.string()
    .max(40, 'Please enter no more than 40 characters')
    .required('We require last name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('We require email address'),
  mobile: Yup.string()
    .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Invalid phone number')
});