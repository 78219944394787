import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const dashboardService = {
  getDashboardData
};

function getDashboardData() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.DASHBOARD}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}
