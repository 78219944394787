import {
  FETCH_GUIDES_START,
  FETCH_GUIDES_SUCCESS,
  FETCH_GUIDES_FAILED,
  FETCH_GUIDE_DETAILS_START,
  FETCH_GUIDE_DETAILS_SUCCESS,
  FETCH_GUIDE_DETAILS_FAILED,
  FETCH_GUIDE_RESTAURANTS_START,
  FETCH_GUIDE_RESTAURANTS_SUCCESS,
  FETCH_GUIDE_RESTAURANTS_FAILED,
  FETCH_GUIDE_MAP_RESTAURANTS_START,
  FETCH_GUIDE_MAP_RESTAURANTS_SUCCESS,
  FETCH_GUIDE_MAP_RESTAURANTS_FAILED,
  GUIDE_PAGING_RESET,
  GUIDE_DETAILS_RESET,
  FETCH_GUIDE_RESTAURANTS_MAP_POINTER_START,
  FETCH_GUIDE_RESTAURANTS_MAP_POINTER_SUCCESS,
  FETCH_GUIDE_RESTAURANTS_MAP_POINTER_FAILED
} from "../../constants/actionTypes";

const initialState = {
  controller: new AbortController(),
  error: null,
  guideListLoading: false,
  isLoading: false,
  guideListPayload: [],
  payload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
  detailsPayload: [],
  detailsMapPointerPayload: [],
  guideMapList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GUIDES_START: {
      return { ...state, error: null, guideListLoading: true };
    }
    case FETCH_GUIDES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        guideListPayload: data,
        guideListLoading: false
      };
    }
    case FETCH_GUIDES_FAILED: {
      return { ...state, guideListLoading: false, error: action.payload };
    }
    case FETCH_GUIDE_RESTAURANTS_START: {
      return { ...state, error: null, isLoading: true, controller: action.controller };
    }
    case FETCH_GUIDE_RESTAURANTS_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;
      if (currentPage !== state.currentPage) {
        return {
          ...state,
          error: null,
          payload: state.payload.concat(data),
          currentPage,
          pageSize,
          totalItem,
          totalPage,
          isLoading: false
        };
      } else {
        return { ...state, isLoading: false };
      }
    }
    case FETCH_GUIDE_RESTAURANTS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case FETCH_GUIDE_MAP_RESTAURANTS_START: {
      return { ...state, error: null, isLoading: true, controller: action.controller};
    }
    case FETCH_GUIDE_MAP_RESTAURANTS_SUCCESS: {
      const { data } = action.payload;
      // if (currentPage !== state.currentPage) {
        return {
          ...state,
          error: null,
          isLoading: false,
          guideMapList: data
        };
      // } else {
      //   return { ...state, isLoading: false };
      // }
    }
    case FETCH_GUIDE_MAP_RESTAURANTS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case FETCH_GUIDE_DETAILS_START: {
      return { ...state, error: null, guideListLoading: true };
    }
    case FETCH_GUIDE_DETAILS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        detailsPayload: [{ ...data }],
        guideListLoading: false
      };
    }
    case FETCH_GUIDE_DETAILS_FAILED: {
      return { ...state, guideListLoading: false, error: action.payload };
    }
    case GUIDE_PAGING_RESET: {
      return {
        ...state,
        payload: [],
        currentPage: 0,
        pageSize: null,
        totalItem: null,
        totalPage: null
      }
    }
    case GUIDE_DETAILS_RESET: {
      return { ...state, detailsPayload: [] }
    }
    case FETCH_GUIDE_RESTAURANTS_MAP_POINTER_START: {
      return { ...state, error: null, guideListLoading: true, controller: action.controller };
    }
    case FETCH_GUIDE_RESTAURANTS_MAP_POINTER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        detailsMapPointerPayload: data,
        guideListLoading: false
      };
    }
    case FETCH_GUIDE_RESTAURANTS_MAP_POINTER_FAILED: {
      return { ...state, guideListLoading: false, error: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
