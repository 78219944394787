import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';
import {
  RESTAURANT_ADMIN_PANEL,
} from "./Apiconfig";

export const restaurantAdminService = {
  claimRequest,
  getRestaurantAffiliated,
  checkRestaurantAdmin,
  updateRestaurantAdminDetails,
  getCoinBundles,
  getModerationList,
  updateModerationStatus,
  getUsersBooking,
  getAllUsersBooking,
  rejectBooking,
  acceptBooking,
  confirmBooking,
  getCoinsRewardsSetting,
  getPageLevel,
  getNewsList,
  updateNewsStatus,
  getNotificationList,
  updateNotificationStatus,
  getInboxCount,
  getRestaurantAdminUsers,
  updateUserAccessType,
  inviteRestaurantUser,
  deleteRestaurantUser
}

function claimRequest(details) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${apiURL.RESTAURANT_ADMIN}/`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getRestaurantAffiliated(search) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  let searchText = '';
  if (search) {
    searchText = search;
  }

  return secureFetch(`${apiURL.RESTAURANT_ADMIN}?search=${searchText}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function checkRestaurantAdmin(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.RESTAURANT_ADMIN}/${id}/validate`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateRestaurantAdminDetails(restaurantId, details) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${apiURL.RESTAURANT_ADMIN_PANEL}/${restaurantId}/restaurant`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getCoinBundles(type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const newType = type || 'both';

  return secureFetch(`${apiURL.COIN_BUNDLES}?bundleFor=${newType}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getModerationList({ currentPage, pageSize }, signal, restaurantId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    signal
  };

  const newPageSize = pageSize ? pageSize : 10;
  // pageSize = 10 * currentPage;

  return secureFetch(`${apiURL.USER_SUGGESTIONS}?pageSize=${newPageSize}&currentPage=${currentPage}&restaurant=${restaurantId}`, requestOptions)

    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getUsersBooking(details) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.BOOKING}?pageSize=10&currentPage=${details.currentPage}&orderBy=createDate&search=&bookingStage=&userRole=&orderDir=desc&fromDate=${details.fromDate}&toDate=${details.toDate}&mapCenter=&restaurant=${details.restaurantId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getAllUsersBooking(details) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.BOOKING}?pageSize=10&currentPage=${details.currentPage}&orderBy=createDate&search=&bookingStage=&userRole=&orderDir=desc&fromDate=${details.fromDate}&toDate=${details.toDate}&mapCenter=&restaurant=${details.restaurantId}&showAll=true`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function rejectBooking(id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.BOOKING}/${id}/cancel`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function acceptBooking(id, params) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(params)
  };

  return secureFetch(`${apiURL.BOOKING}/${id}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateModerationStatus(moderationId, details) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${apiURL.USER_SUGGESTIONS}/${moderationId}/change-status`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function confirmBooking(id, params) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(params)
  };

  return secureFetch(`${apiURL.BOOKING}/${id}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getCoinsRewardsSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.RESTAURANT_COINS_SETTINGS}/coin-rewards?category=misc&rewardFor=restaurant`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getPageLevel(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.PAGE_LEVEL}/?id=${id}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getNewsList({ currentPage, pageSize, id }, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    signal
  };
  const newPageSize = pageSize ? pageSize : 10;
  // pageSize = 10 * currentPage;

  return secureFetch(`${apiURL.NEWS}?pageSize=${newPageSize}&currentPage=${currentPage}&orderBy=createDate&restaurant=${id}`, requestOptions)

    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateNewsStatus(newsId, details) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${apiURL.NEWS}/${newsId}/status`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getNotificationList({ currentPage, pageSize, id }, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    signal
  };
  // pageSize = 10 * currentPage;
  const newPageSize = pageSize ? pageSize : 10;
  return secureFetch(`${apiURL.NOTIFICATIONS}/${id}?pageSize=${newPageSize}&currentPage=${currentPage}&orderBy=createDate`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateNotificationStatus(id, details) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${apiURL.NOTIFICATIONS}/${id}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getInboxCount(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),

  };

  return secureFetch(`${RESTAURANT_ADMIN_PANEL}/inbox-count?restaurant=${id}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getRestaurantAdminUsers(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),

  };

  return secureFetch(`${RESTAURANT_ADMIN_PANEL}/${id}/users`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateUserAccessType(details) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${RESTAURANT_ADMIN_PANEL}/user-access`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function inviteRestaurantUser(details) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${apiURL.RESTAURANT_ADMIN_PANEL}/invite`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function deleteRestaurantUser(userId, restaurantId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANT_ADMIN_PANEL}/${userId}/${restaurantId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}