const SECTION_LOGIN_MODAL = "Login Modal";
const SECTION_SEARCH_INPUT = "Search Input Box";
const SECTION_BEST_RESTAURANTS_BY_LOCATION = "Best Restaurants by location";
const SECTION_TOP_RATED = "Top Rated";
const SECTION_BANNERS = "Banners";
const SECTION_TEXT_CONTEXT_BLOCK = "Text Content Block";
const SECTION_FOOTER = "Footer";
const SECTION_RESTAURANT_CARD = "Restaurant Card";
const SECTION_MAP_VIEW = "Map view";
const SECTION_FILTERS = "Filter selection";
const SECTION_HEADER = "header";
const SECTION_SHARE = "Share";
const SECTION_GALLERY = "Gallery";
const SECTION_VIRTUAL_TOUR = "Virtual Tour";
const SECTION_UNCLAIMED = "Unclaimed";
const SECTION_BOOKING_MODAL = "Booking modal";
const SECTION_LOCATION_CONTACT = "Location & contact";
const SECTION_WEBSITE = "Website";
const SECTION_REPORT_THIS_PAGE = "Report this page";
const SECTION_YOU_MAY_ALSO_LIKE = "You may also like";
const TOP_10_IN = "Top 10 in ";
const SECTION_EDIT_MY_PROFILE = "Edit my profile";
const SECTION_VIEW_MY_ACHIEVMENTS = "View my achievements";
const SECTION_LEARN_MORE_AND_DONATE = "Learn more and donate";
const SECTION_AMEND_OR_QUERY = "Amend or query";
const SECTION_OPEN_CHEST = "Open chest";
const SECTION_CREATE_NEW_LIST = "Create new list";
const SECTION_SAVED_LIST_SHARE = "Saved list ";
const SECTION_SAVED_LIST_EDIT = "Edit list";
const SECTION_WHAT_ARE_COINS = "What are coins";
const SECTION_TRANSFER_TO_RESTAURANT = "Transfer to restaurant";
const SECTION_REVIEW_THIS_VISIT = "Review this visit ";
const SECTION_WATCH_ADS = "Watch ads now";
const SECTION_SAVE_WITH_BUNDLES = "Save with bundles";
const SECTION_SEND_INVITE_REQUEST = "Send invite Request";
const SECTION_VIEW_RESTAURANT = "View restaurant";
const SECTION_DATE_OF_BIRTH = "Date of birth";
const SECTION_PROFILE_PICTURE = "Profile picture";
const SECTION_GENDER = "Gender";
const SECTION_FIRST_NAME = "Firstname";
const SECTION_LAST_NAME = "Lastname";
const SECTION_EMAIL = "Email";
const SECTION_MOBILE_PHONE_NUMBER = "Mobile phone number";
const SECTION_WORK_POSTCODE = "Work postcode";
const SECTION_HOME_POSTCODE = "Home postcode";
const SECTION_CHANGE_PASSWORD = "Change password";
const SECTION_SAVE_CHANGES = "Save changes";
const SECTION_SEARCH_MY_PREFERENCES = "My search preferences";
const SECTION_DEFAULT_LOCATION = "My default locataion";
const SECTION_DIETARY = "Dietary";
const SECTION_OCCASION = "Occasion";
const SECTION_DINNING_STYLE = "Dining style";
const SECTION_OFFERS = "Offers";
const SECTION_AMENITY_NEEDS = "Amenity needs";
const SECTION_CRAVINGS = "Cravings";
const SECTION_CUISINE = "cuisine";
const SECTION_MENU_TYPES = "Menu types";
const SECTION_SEARCH_BAR = "Search bar";
const SECTION_SURVEY_CARD = "survey card";
const SECTION_VIEW_MY_BOOKINGS = "View my bookings";
const SECTION_VIEW_MY_LIST = "View my lists";
const SECTION_VIEW_MY_BONUSES = "View my bonuses";
const SECTION_GUIDE = "Guide";
const SECTION_INSPIRATION_BLOCK = "Inspiration block";

export const sectionName = {
  SECTION_LOGIN_MODAL,
  SECTION_SEARCH_INPUT,
  SECTION_BEST_RESTAURANTS_BY_LOCATION,
  SECTION_TOP_RATED,
  SECTION_BANNERS,
  SECTION_TEXT_CONTEXT_BLOCK,
  SECTION_FOOTER,
  SECTION_RESTAURANT_CARD,
  SECTION_MAP_VIEW,
  SECTION_FILTERS,
  SECTION_HEADER,
  SECTION_SHARE,
  SECTION_GALLERY,
  SECTION_VIRTUAL_TOUR,
  SECTION_UNCLAIMED,
  SECTION_BOOKING_MODAL,
  SECTION_LOCATION_CONTACT,
  SECTION_WEBSITE,
  SECTION_REPORT_THIS_PAGE,
  SECTION_YOU_MAY_ALSO_LIKE,
  TOP_10_IN,
  SECTION_EDIT_MY_PROFILE,
  SECTION_VIEW_MY_ACHIEVMENTS,
  SECTION_LEARN_MORE_AND_DONATE,
  SECTION_AMEND_OR_QUERY,
  SECTION_OPEN_CHEST,
  SECTION_CREATE_NEW_LIST,
  SECTION_SAVED_LIST_SHARE,
  SECTION_SAVED_LIST_EDIT,
  SECTION_WHAT_ARE_COINS,
  SECTION_TRANSFER_TO_RESTAURANT,
  SECTION_REVIEW_THIS_VISIT,
  SECTION_WATCH_ADS,
  SECTION_SAVE_WITH_BUNDLES,
  SECTION_SEND_INVITE_REQUEST,
  SECTION_VIEW_RESTAURANT,
  SECTION_DATE_OF_BIRTH,
  SECTION_PROFILE_PICTURE,
  SECTION_GENDER,
  SECTION_FIRST_NAME,
  SECTION_LAST_NAME,
  SECTION_EMAIL,
  SECTION_MOBILE_PHONE_NUMBER,
  SECTION_WORK_POSTCODE,
  SECTION_HOME_POSTCODE,
  SECTION_CHANGE_PASSWORD,
  SECTION_SAVE_CHANGES,
  SECTION_SEARCH_MY_PREFERENCES,
  SECTION_DEFAULT_LOCATION,
  SECTION_DIETARY,
  SECTION_OCCASION,
  SECTION_DINNING_STYLE,
  SECTION_OFFERS,
  SECTION_AMENITY_NEEDS,
  SECTION_CRAVINGS,
  SECTION_CUISINE,
  SECTION_MENU_TYPES,
  SECTION_SEARCH_BAR,
  SECTION_SURVEY_CARD,
  SECTION_VIEW_MY_BOOKINGS,
  SECTION_VIEW_MY_LIST,
  SECTION_VIEW_MY_BONUSES,
  SECTION_GUIDE,
  SECTION_INSPIRATION_BLOCK,
};

const logUserNameInput = (userName) => {
  return `Username input ${userName}`;
};

const logSelection = (value, message) => {
  return `${message !== undefined ? message : `Selection`}  ${value}`;
};

const logInput = (value) => {
  return `${value}`;
};

const logClick = (value) => {
  return `Click ${value}`;
};

const shareSelecton = (value) => {
  return `${value}`;
};

//functions
const LOG_SIGN_IN = "Sign in";
const LOG_FORGOTTEN_PASSWORD = "Forgotten Password";
const LOG_SIGN_IN_FACEBOOK = "Sign in w/ Facebook";
const LOG_SIGN_IN_GOOGLE = "Sign in w/ Google";
const LOG_SIGN_OUT = "Sign out";
const LOG_DOWNLOAD_MOBILE_APP = "Download mobile app";
const LOG_LIKE_A_RESTAURANT = "Like a restaurant";
const LOG_SCROLL_IMAGE = "Scroll Images";
const LOG_SEE_ALL_WINNERS = "See all the Winners";
const SHEAR_VIA_FACEBOOK = "Facebook";
const LOG_CUISINE_BOX = "Cuisine Box Banner";

//page visits
const LOG_CONTACT_US = "Contact Us";
const LOG_BENEFITS = "Benefits";
const LOG_LIST_A_RESTAURANT = "List a restaurant";
const LOG_SITEMAP = "Sitemap";
const LOG_PRIVACY = "Privacy";
const LOG_TANDC = "T&Cs";
const LOG_JOIN_FOR_FREE = "Join For Free";
const HEADER_OFFER = "offer";
const CLICK_GALLERY = "Gallery click";
const VIRTUAL_TOUR = "Virtaul tour";
const UNCLAIMED = "Claim Restaurant";
const GET_CODE_BY_EMAIL = "Get code by email";
const EDIT_MY_PROFILE = "Edit profile";
const VIEW_MY_ACHIEVEMENTS = "My achievements";
const LEARN_MORE_AND_DONATE = "";
const SAVED_LIST_EDIT = "Saved list edit";
const LOG_WHAT_ARE_COINS = "what are coins";
const LOG_UPLOAD_YOUR_PHOTO = "Upload your photo";
const LOG_WATCH_ADS = "";
const LOG_PROFILE_PICTURE = "Upload";
const LOG_CHANGE_PASSWORD = "Password change";
const LOG_SAVE_CHANGES = "Save all changes";

export const logActions = {
  logUserNameInput,
  logSelection,
  logInput,
  logClick,
  shareSelecton,

  LOG_SIGN_IN,
  LOG_FORGOTTEN_PASSWORD,
  LOG_SIGN_IN_FACEBOOK,
  LOG_SIGN_IN_GOOGLE,
  LOG_SIGN_OUT,
  LOG_DOWNLOAD_MOBILE_APP,
  LOG_LIKE_A_RESTAURANT,
  LOG_SCROLL_IMAGE,
  LOG_SEE_ALL_WINNERS,
  LOG_CUISINE_BOX,

  LOG_CONTACT_US,
  LOG_BENEFITS,
  LOG_LIST_A_RESTAURANT,
  LOG_SITEMAP,
  LOG_PRIVACY,
  LOG_TANDC,
  LOG_JOIN_FOR_FREE,
  HEADER_OFFER,
  SHEAR_VIA_FACEBOOK,
  CLICK_GALLERY,
  VIRTUAL_TOUR,
  UNCLAIMED,
  GET_CODE_BY_EMAIL,
  EDIT_MY_PROFILE,
  VIEW_MY_ACHIEVEMENTS,
  LEARN_MORE_AND_DONATE,
  SAVED_LIST_EDIT,
  LOG_WHAT_ARE_COINS,
  LOG_UPLOAD_YOUR_PHOTO,
  LOG_WATCH_ADS,
  LOG_PROFILE_PICTURE,
  LOG_CHANGE_PASSWORD,
  LOG_SAVE_CHANGES,
};
