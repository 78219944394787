import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { throttle } from "lodash";

//assets and images
import closeIcon from "../../assets/images/close.png";
import henryNormalIcon from "../../assets/images/henrynormal1.png";
import currencyIcon from "../../assets/images/icon_currency.png";
import { guideService } from "../../service/guide-service";
//stylesheets
import './Sidebar.css';

//constants
import { IMAGE_PROFILE_SIZE, IMAGE_URL, getImageBase, USER_FOLER } from "../../constants/imageConstants";
import {
  REGISTRATION_BENEFITS,
  MOBILE_SIGN_IN,
  MY_DASHBOARD,
  MOBILE_REGISTER,
  RESTAURANT_DETAILS,
  LOADING_SEARCH_RESULTS,
  CONTACT_US,
  SITEMAP,
  LIST_A_RESTAURANT,
  PRIVACY_POLICY,
  TERMS_CONDITIONS
} from "../../constants/routes";
import {
  fetchTopLocations,
  fetchRecentLocations,
  fetchSearchedItems,
  saveInteractionLog,
  setQueryString,
  fetchTopTenRestaurants,
  setTopRestaurantMode,
  fetchAllRestaurants
} from "../../store/actions";
import { createLoggerData } from "../../utils/loggerData";
import { logActions, sectionName } from "../../constants/loggerConstants";

//custom components
import SearchDropdown from "../SearchDropdown";
import SearchedItemsDropdown from "../../components/SearchedItemsDropdown";

const { SECTION_SEARCH_INPUT } = sectionName;
const { logInput } = logActions;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearchLocation = throttle(this.handleWithSearchString, 700);
    this.handleSearchItems = throttle(this.handleSearchedItem, 700);
    this.handleSearchForGuide = throttle(this.handleSearchStringForGuide, 700);
    this.state = {
      dropdownVisible: false,
      fetchedOptions: [],
      fetchedOptionsGuide: [],
      autoCompleteSession: '',
      mapsSet: false,
      dropdownOpen: false,
      searchString: '',
      searchItemsString: '',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.mapsSet && window.google && window.google.maps) {
      let sessionToken = new window.google.maps.places.AutocompleteSessionToken();
      this.setState({ autoCompleteSession: sessionToken, mapsSet: true });
    }
  }

  handleWithSearchString = text => {
    // Pass the token to the autocomplete service.
    let autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({
      input: text,
    },
      (searchResults, status) => {
        if (status === 'OK') {
          this.setState({
            fetchedOptions: searchResults.map(result => ({
              placeName: result.description || result.structured_formatting.main_text,
              placeId: result.place_id
            }))
          })
        }
      });
    this.props.fetchTopLocations(text);
    this.props.fetchRecentLocations(text);
  };

  handleLocationInputClicked = () => {
    this.setState({ dropdownVisible: true });
    this.props.fetchRecentLocations();
  };

  handleDropdownClose = () => {
    this.setState({ dropdownVisible: false });
  };

  handleInputFocusChange = visibility => {
    this.setState({ dropdownOpen: visibility });
    if (visibility === 'location') {
      this.props.fetchRecentLocations();
    }
  };

  handleChangeSearchString = ({ target }, type) => {

    this.setState({ [type]: target.value });
    if (type === 'searchString') {
      this.handleSearchLocation(target.value);
      this.handleSearchForGuide(target.value)
    } else {
      this.handleSearchItems(target.value);
    }
  };

  handleSearchStringForGuide = text => {
    guideService.getGuidesSearch(text)
      .then(rawData => {
        return rawData.json()
      })
      .then(response => {
        if(response.status && response.data && response.data.length > 0){
          // console.log("guides reas ==", response)
          this.setState({fetchedOptionsGuide: response.data})
        }else{
          this.setState({fetchedOptionsGuide: []})
          //this.handleWithSearchString(text)
        }
      })
      .catch(() => {
        console.log("error in getch guide search")
      })
  }

  handleSearchedItem = text => {
    if (text.length > 1) {
      this.props.saveInteractionLog(
        createLoggerData(SECTION_SEARCH_INPUT, logInput(text), this.props.location.pathname)
      );
      this.props.fetchSearchedItems(text);
    }
  };

  handleSearchItemsClose = () => {
    this.setState({ dropdownOpen: false, dropdownVisible: false });
    this.props.onCloseClick();
  }

  handleSearchItemsLocationBoxClicked = () => {
    this.setState({ dropdownOpen: false, dropdownVisible: true });
  }

  handleItemClick = (type, identifier) => {
    if (type === 'restaurants') {
      this.handleSearchItemsClose();
      return this.props.history.push(`${RESTAURANT_DETAILS}/${identifier}`)
    } else {
      let newLocation = this.props.restaurantFilters && this.props.restaurantFilters.location
        ? this.props.restaurantFilters.location
        : {
          latitude: 52.9547831,
          longitude: -1.1581086
        };
      if (type === 'menuTypes') {
        this.props.setQueryString({
          menuType: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || ''
        });
      }
      if (type === 'menus') {
        this.props.setQueryString({
          menu: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || ''
        });
      }
      if (type === 'tags') {
        this.props.setQueryString({
          tag: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || ''
        });
      }
      if (type === 'cuisines') {
        this.props.setQueryString({
          cuisine: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || ''
        });
      }
      if (type === 'topTen') {
        this.props.fetchTopTenRestaurants();
        this.props.setTopRestaurantMode();
        this.handleSearchItemsClose();
        this.props.history.push(LOADING_SEARCH_RESULTS);
        return;
      }
      if (type === 'allRestaurants') {
        this.props.setQueryString({
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || ''
        });
        this.props.fetchAllRestaurants();
        this.handleSearchItemsClose();
        this.props.history.push(LOADING_SEARCH_RESULTS);
        return;
      }
      if (type === 'onEnterKeyPressed') {
        this.props.setQueryString({
          queryString: this.state.searchItemsString,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || ''
        });
      }
      this.props.setTopRestaurantMode();
      this.handleSearchItemsClose();
      this.props.fetchAllRestaurants();
      this.props.history.push(LOADING_SEARCH_RESULTS);
    }
  };

  handleOnSearchedItemsKeyPressed = event => {
    if (event.key === "Enter") {
      this.handleItemClick('onEnterKeyPressed');
    }
  };

  handleSearchRestaurantsClick = type => {
    this.handleItemClick(type);
  };

  render() {
    const {
      visible,
      onCloseClick,
      authUser,
      tryLogout,
      restaurantFilters,
      userData,
      searchedItems,
      searchedItemsLoading,
    } = this.props;

    const {
      dropdownVisible,
      fetchedOptions,
      fetchedOptionsGuide,
      dropdownOpen,
      searchItemsString,
    } = this.state;

    return (
      <div className={visible ? "slide_menu v1website_header active" : "slide_menu v1website_header"}>
        <div className="slide_menu_inner">
          <div className="close_slide_menu" onClick={onCloseClick}>
            <img src={closeIcon} alt="header" />
          </div>
          {authUser
            ? (<div className="slider_user d-flex-align">
              <div className="slider_user_img">
                <a href="/">
                  <img src={userData.profilePictureDetails ? `${IMAGE_URL}/${getImageBase(userData.profilePictureDetails.fileName, USER_FOLER, IMAGE_PROFILE_SIZE.width, IMAGE_PROFILE_SIZE.height)}` : henryNormalIcon} alt="" />
                </a>
              </div>
              <div className="slider_user_de">
                <a className="username" href="/">{userData.fullName} <span className="slider_user_round">2</span></a>
                <div className="slider_user_info">
                  <a href="/">
                    <img src="/images/newcoin.png" srcset="/images/newcoin@2x.png 2x" alt="header" />
                    <span>{Math.floor(userData.coins) || 0}</span>
                  </a>
                </div>
              </div>
            </div>)
            : null}
          <div className="slider_search">
            <div className="d-flex-align header_top_location_outer1">
              <div className="header_top_location1">
                <i
                  className="fa fa-map-marker"
                  style={{ color: restaurantFilters && restaurantFilters.locationName ? '#ff4a5e' : null }}
                />
                <input
                  type="text"
                  className="form-control location_input"
                  placeholder="Enter town or postcode"
                  onClick={this.handleLocationInputClicked}
                  value={(restaurantFilters && restaurantFilters.locationName) || ''}
                  readOnly
                />
                <button
                  className="btn"
                  style={{ color: restaurantFilters && restaurantFilters.locationName ? '#ff4a5e' : null }}
                  onClick={this.handleLocationInputClicked}
                >
                  <i className="fa fa-send" />
                </button>
                {
                  dropdownVisible
                    ? <SearchDropdown
                      visible={dropdownVisible}
                      fetchedOptions={fetchedOptions}
                      fetchedOptionsGuide={fetchedOptionsGuide}
                      onInputThrottled={e => this.handleChangeSearchString(e, 'searchString')}
                      onMobileCloseClicked={this.handleDropdownClose}
                      locationName={(restaurantFilters && restaurantFilters.locationName) || ''}
                    />
                    : null
                }
              </div>
              <div className="header_top_sea1">
                <input
                  type="text" className="form-control location_se_input"
                  placeholder="Search by cuisine, name, dish etc."
                  onClick={() => this.handleInputFocusChange('filters')}
                />
                <button className="btn"><i className="fa fa-search" /></button>
                <SearchedItemsDropdown
                  dropdownOpen={dropdownOpen}
                  searchedItems={searchedItems}
                  onMenuItemClick={this.handleItemClick}
                  filters={restaurantFilters}
                  loading={searchedItemsLoading}
                  isSearchBoxEmpty={!searchItemsString}
                  searchedItemLength={searchItemsString.length > 1}
                  onSearchAllRestaurants={this.handleSearchRestaurantsClick}
                >
                  <div className="search_padd">
                    <div className="search_body">
                      <ul>
                        <li className="m-0">
                          <div className="drop_input d-flex-align">
                            <i className="fa fa-map-marker" style={{ color: '#ff4a5e' }} />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter location"
                              value={(restaurantFilters && restaurantFilters.locationName) || ''}
                              style={{ color: '#495057' }}
                              readOnly
                              onClick={this.handleSearchItemsLocationBoxClicked}
                            />
                            <p className="cancel" onClick={this.handleSearchItemsClose}>Cancel</p>
                          </div>
                        </li>
                        <li className="m-0">
                          <div className="drop_input d-flex-align">
                            <i className="fa fa-search" style={{ color: '#ff4a5e' }} />
                            <input
                              type="text"
                              className="form-control"
                              onChange={e => this.handleChangeSearchString(e, 'searchItemsString')}
                              value={searchItemsString}
                              placeholder="Resaturant, cuisine or dish"
                              disabled={!restaurantFilters.locationName}
                              style={{ backgroundColor: searchItemsString ? '#ffffff' : null, width: '100%' }}
                              onKeyDown={this.handleOnSearchedItemsKeyPressed}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </SearchedItemsDropdown>
              </div>
            </div>

          </div>
          {authUser
            ? (<div className="user_menu">
              <ul>
                <li><Link to={MY_DASHBOARD}>My account</Link></li>
              </ul>
            </div>)
            : (<div className="user_menu">
              <ul>
                <li><Link to={MOBILE_SIGN_IN}>Log in</Link></li>
                <li><Link to={MOBILE_REGISTER}>Join for free</Link></li>
              </ul>
            </div>)
          }
          <div className="page_menu">
            <ul>
              <li><Link to={REGISTRATION_BENEFITS}>Member benefits</Link></li>
              <li><Link to={LIST_A_RESTAURANT}>List your restaurant</Link></li>
              <li><Link to={CONTACT_US}>Contact us</Link></li>
              {authUser
                ? <li><a onClick={tryLogout}>Sign out</a></li>
                : null
              }
            </ul>
          </div>
          <div className="slidebar_bottom_menu">
            <ul>
              <li><Link to={SITEMAP}>Sitemap</Link></li>
              <li><Link to={PRIVACY_POLICY}>Privacy</Link></li>
              <li><Link to={TERMS_CONDITIONS}>T&amp;Cs</Link></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ restaurant, auth, user }) => {
  return {
    restaurantFilters: restaurant.filters,
    userData: auth.payload,
    searchedItems: user.searchedItemsPayload,
    searchedItemsLoading: user.searchedItemsLoading,
    authUser: !!auth.payload,
    authLoading: auth.isLoading,
  }
};

export default withRouter(connect(mapStateToProps, {
  fetchRecentLocations,
  fetchTopLocations,
  fetchSearchedItems,
  saveInteractionLog,
  setQueryString,
  fetchTopTenRestaurants,
  setTopRestaurantMode,
  fetchAllRestaurants
})(Sidebar));