import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//constants
import {
  fetchAllRestaurants,
  fetchGuideRestaurants,
  fetchGuideMapRestaurants,
  resetGuidePagination,
  setQueryString,
  clearRecentLocations,
  saveInteractionLog,
} from "../../store/actions";
import { LOADING_SEARCH_RESULTS } from "../../constants/routes";
import { createLoggerData } from "../../utils/loggerData";
import { logActions, sectionName } from "../../constants/loggerConstants";

const { logSelection } = logActions;
const { SECTION_SEARCH_INPUT, SECTION_SEARCH_BAR, SECTION_GUIDE } = sectionName;

class SearchDropdown extends React.Component {
  state = {
    searchString: "",
    pageUrl: this.props.location.pathname.split("/")[1],
  };

  componentDidMount() {
    if (this.props.locationName) {
      this.setState({ searchString: this.props.locationName });
    }
  }

  handleSearchStringSubmit = (location, name, placeId) => {
    this.props.setQueryString({
      location: {
        latitude: location[1],
        longitude: location[0],
      },
      locationName: name,
      mapRadius: 10,
      placeId: placeId,
      saveRecent: true,
    });

    //console.log("handle serch string");
    this.props.saveInteractionLog(
      createLoggerData(
        this.state.pageUrl === "dashboard"
          ? SECTION_SEARCH_BAR
          : SECTION_SEARCH_INPUT,
        logSelection(name, "top location |"),
        this.props.location.pathname
      )
    );
    if (this.props.handleSecondSearchBoxTextClear) {
      this.props.handleSecondSearchBoxTextClear("");
    }
    this.props.fetchAllRestaurants();
    this.props.history.push(LOADING_SEARCH_RESULTS);
    this.props.onMobileCloseClicked && this.props.onMobileCloseClicked();
  };

  handleUserItemSelect = (place) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: place.placeId }, (results, status) => {
      if (status === "OK") {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        this.handleSearchStringSubmit(
          [lng, lat],
          place.placeName,
          place.placeId
        );
      }
    });
    this.props.onMobileCloseClicked && this.props.onMobileCloseClicked();
  };

  handleGuideItemSelect = (data) => {
    this.props.setQueryString({
      locationName: data.areaName,
    });
    //("handle guide select");
    this.props.saveInteractionLog(
      createLoggerData(
        this.state.pageUrl === "dashboard" ? SECTION_SEARCH_BAR : SECTION_GUIDE,
        //SECTION_SEARCH_INPUT,
        logSelection("", data.areaName),
        this.props.location.pathname
      )
    );
    this.setState({ searchString: data.areaName });
    this.props.resetGuidePagination();
    //for all map pointers
    this.props.fetchGuideMapRestaurants({
      mapPointer: true,
      guide: data.guide.slug,
      areaName:
        data.parent == null || !data.parent ? data.slug : data.parentSlug,
      title: data.areaName,
      subAreaName: data.parent == null || !data.parent ? "" : data.slug,
    });

    this.props.fetchGuideRestaurants({
      guide: data.guide.slug,
      areaName:
        data.parent == null || !data.parent ? data.slug : data.parentSlug,
      saveRecent: true,
      title: data.areaName,
      subAreaName: data.parent == null || !data.parent ? "" : data.slug,
    });
    if (data.parent == null || !data.parent) {
      this.props.history.push(
        `${LOADING_SEARCH_RESULTS}/guide?type=${data.guide.slug}&location=${data.slug}`
      );
    } else {
      this.props.history.push(
        `${LOADING_SEARCH_RESULTS}/guide?type=${data.guide.slug}&location=${data.parentSlug}&sublocation=${data.slug}`
      );
    }
    this.props.onMobileCloseClicked && this.props.onMobileCloseClicked();
    this.props.handleSecondSearchBoxTextClear("");
  };

  // handleCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const { latitude, longitude } = position.coords;
  //       const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB51WtUTg4mQihUVx_Ja1dpYC-qLQ_LPmU`;
  //       fetch(url)
  //         .then(rawData => rawData.json())
  //         .then(response => {
  //           let city = 'Unnamed Location';
  //           if (response.results.length > 0) {
  //             city = response.results[5].address_components[0].long_name;
  //           }
  //           this.props.saveInteractionLog(
  //             createLoggerData(
  //               SECTION_SEARCH_INPUT,
  //               logSelection("User Current Location"),
  //               this.props.location.pathname
  //             )
  //           );
  //           this.props.setQueryString({
  //             location: {
  //               latitude: position.coords.latitude,
  //               longitude: position.coords.longitude,
  //             },
  //             locationName: city
  //           });
  //           this.props.fetchAllRestaurants();
  //           this.props.history.push(LOADING_SEARCH_RESULTS);
  //         })
  //         .catch(() => {
  //         });
  //     });
  //   }
  //   this.props.onMobileCloseClicked && this.props.onMobileCloseClicked();
  // };

  handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB51WtUTg4mQihUVx_Ja1dpYC-qLQ_LPmU`;
        fetch(url)
          .then((rawData) => rawData.json())
          .then((response) => {
            let postal_town = "";
            let city = "Unnamed Location";
            let place_id = "";
            if (response.results.length > 0) {
              const townIndex =
                response.results[0].address_components.findIndex((x) =>
                  x.types.includes("postal_town")
                );
              if (townIndex !== -1) {
                postal_town =
                  response.results[0].address_components[townIndex].long_name;
              }
              // else {
              const areaLevel2Index =
                response.results[0].address_components.findIndex((x) =>
                  x.types.includes("administrative_area_level_2")
                );
              if (areaLevel2Index !== -1) {
                city =
                  response.results[0].address_components[areaLevel2Index]
                    .long_name;
              }
              // }
              place_id = response.results[0].place_id;
            }
            //("handl current item selecte");
            this.props.saveInteractionLog(
              createLoggerData(
                this.state.pageUrl === "dashboard"
                  ? SECTION_SEARCH_BAR
                  : SECTION_SEARCH_INPUT,
                logSelection("", "User Current Location"),
                this.props.location.pathname
              )
            );
            let finalLocName = "";
            if (postal_town.length < 1 || postal_town === city) {
              finalLocName = city;
            } else {
              finalLocName = `${postal_town}, ${city}`;
            }
            this.props.setQueryString({
              location: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              placeId: place_id,
              saveRecent: true,
              locationName: finalLocName, //city
            });
            this.props.fetchAllRestaurants();
            this.props.history.push(LOADING_SEARCH_RESULTS);
          })
          .catch(() => {});
      });
    }
    this.props.onMobileCloseClicked && this.props.onMobileCloseClicked();
    this.props.handleSecondSearchBoxTextClear("");
  };

  handleChangeSearchString = (e) => {
    this.setState({ searchString: e.target.value });
    this.props.onInputThrottled(e);
  };

  handleRecentSearch = (data) => {
    if (data.type === "guide") {
      this.props.setQueryString({
        locationName: data.place.name,
      });

      this.setState({ searchString: data.place.name });
      this.props.resetGuidePagination();
      //for map ponters
      this.props.fetchGuideMapRestaurants({
        mapPointer: true,
        guide: data.guide,
        areaName: data.areaName,
        title: data.place.name,
        subAreaName: data.subAreaName ? data.subAreaName : "",
      });
      this.props.fetchGuideRestaurants({
        guide: data.guide,
        areaName: data.areaName,
        saveRecent: true,
        title: data.place.name,
        subAreaName: data.subAreaName ? data.subAreaName : "",
      });
      //this.props.history.push(`${LOADING_SEARCH_RESULTS}/guide?type=${data.guide}&location=${data.areaName}`);
      if (!data.subAreaName) {
        this.props.history.push(
          `${LOADING_SEARCH_RESULTS}/guide?type=${data.guide}&location=${data.areaName}`
        );
      } else {
        this.props.history.push(
          `${LOADING_SEARCH_RESULTS}/guide?type=${data.guide}&location=${data.areaName}&sublocation=${data.subAreaName}`
        );
      }
      this.props.onMobileCloseClicked && this.props.onMobileCloseClicked();
    } else {
      this.handleSearchStringSubmit(
        data.place.location.coordinates,
        data.place.name,
        data.place.placeId
      );
    }
    if (this.props.handleSecondSearchBoxTextClear) {
      this.props.handleSecondSearchBoxTextClear("");
    }
    this.props.saveInteractionLog(
      createLoggerData(
        this.state.pageUrl === "dashboard"
          ? SECTION_SEARCH_BAR
          : SECTION_SEARCH_INPUT,
        logSelection(data.place.name, "User Recent Location |"),
        this.props.location.pathname
      )
    );
  };

  render() {
    const {
      visible,
      topLocations,
      recentLocations,
      fetchedOptions,
      fetchedOptionsGuide,
      clearRecentLocations,
      onMobileCloseClicked,
    } = this.props;
    const { searchString } = this.state;

    return (
      <div
        className={`search_dropDown search_banner_dropdowwn ${
          visible ? "active" : ""
        }`}
      >
        <div className="search_padd">
          <div className="search_body">
            <ul>
              <li className="m-0">
                <div className="drop_input d-flex-align">
                  <i
                    className="fa fa-map-marker"
                    style={{ color: "#ff4a5e" }}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter location"
                    onChange={this.handleChangeSearchString}
                    value={searchString}
                    style={{ color: "#495057" }}
                  />
                  <p className="cancel" onClick={onMobileCloseClicked}>
                    Cancel
                  </p>
                </div>
              </li>
              <li className="m-0">
                <div
                  className="v1_link_ylw d-flex-align"
                  style={{ cursor: "pointer" }}
                  onClick={this.handleCurrentLocation}
                >
                  <img
                    src="/images/homepage/locate_icon_ylw.png"
                    alt="locate"
                  />
                  <span>Use current location</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {fetchedOptionsGuide && fetchedOptionsGuide.length > 0 ? (
          <div className="search_location search_padd">
            <div className="search_body">
              <ul className="search_name_list">
                {fetchedOptionsGuide.map((location, index) => {
                  const { areaName } = location;
                  return index <= 2 ? (
                    <li
                      key={location._id}
                      onClick={() => this.handleGuideItemSelect(location)}
                    >
                      <div>
                        {areaName}{" "}
                        <label style={{ fontSize: 12, color: "#b6c6ce" }}>
                          {location.guide.guideType}
                        </label>
                      </div>
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          </div>
        ) : null}
        {fetchedOptionsGuide.length < 1 &&
        fetchedOptions &&
        fetchedOptions.length > 0 ? (
          <div className="search_location search_padd">
            <div className="search_body">
              <ul className="search_name_list">
                {fetchedOptions.map((location, index) => {
                  const { placeName, placeId } = location;

                  return index <= 2 ? (
                    <li
                      key={placeId}
                      onClick={() => this.handleUserItemSelect(location)}
                    >
                      <div>{placeName}</div>
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          </div>
        ) : null}
        {recentLocations && recentLocations.length > 0 ? (
          <div className="search_location search_padd">
            <div className="search_title d-flex-align">
              <img
                src="/images/homepage/history_icon_16.png"
                alt="recent locations"
              />
              <h5>Recent locations</h5>
            </div>
            <div className="search_body">
              <ul className="search_name_list">
                {recentLocations.map((location) => {
                  const { place, _id } = location;

                  return (
                    <li
                      key={_id}
                      onClick={() => this.handleRecentSearch(location)}
                    >
                      <div>{place.name}</div>
                    </li>
                  );
                })}
                <li className="clearrecent" onClick={clearRecentLocations}>
                  <div>Clear recent</div>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        {topLocations && topLocations.length > 0 ? (
          <div className="search_location search_padd">
            <div className="search_title d-flex-align">
              <img
                src="/images/homepage/star_grey_16.png"
                alt="top locations"
              />
              <h5>Top locations</h5>
            </div>
            <div className="search_body">
              <ul className="search_name_list">
                {topLocations.map((location) => {
                  const { place, _id } = location;
                  return (
                    <li
                      key={_id}
                      onClick={() =>
                        this.handleSearchStringSubmit(
                          place.location.coordinates,
                          location.title,
                          place.placeId
                        )
                      }
                    >
                      <div>{location.title}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    topLocations: user.payload,
    recentLocations: user.recentLocationsPayload,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchAllRestaurants,
    fetchGuideRestaurants,
    fetchGuideMapRestaurants,
    resetGuidePagination,
    setQueryString,
    clearRecentLocations,
    saveInteractionLog,
  })(SearchDropdown)
);
