import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
//custom components
import asyncComponent from "../../utils/asyncComponent";
import RestaurantAdminHeader from "../../components/RestaurantAdminHeader";
import { checkRestaurantAccess } from '../../store/actions';
import LoaderPage from "../../components/LoaderPage";
import { HOME } from "../../constants/routes";
import MetaTags from "../../containers/MetaTags";
import TCButton from "./TCButton";

export const userRoles = {
  admin: [
    { path: 'page', name: 'Page', component: asyncComponent(() => import('./Page/index')) },
    // { path: 'ads', name: 'Ads', component: asyncComponent(() => import('./Ads/index')) },
    { path: 'bookings', name: 'Bookings', component: asyncComponent(() => import('./Bookings/index')) },
    // {path: 'insights', name: 'Insights', component: asyncComponent(() => import('./Insights/index')) },
    { path: 'coins', name: 'Coins', component: asyncComponent(() => import('./Coins/index')) },
    {
      path: 'inbox',
      name: 'Inbox',
      component: asyncComponent(() => import('./Inbox/index')),
      childRoutes: [
        { path: 'moderation', name: 'Moderation' },
        { path: 'news', name: 'News' },
        { path: 'notifications', name: 'Notifications' },
      ]
    },
    { path: 'account', name: 'Account', component: asyncComponent(() => import('./Account/index')) },
    { path: 'help', name: 'Help', component: asyncComponent(() => import('./UserHelp/index')) },
  ],
  editor: [
    { path: 'page', name: 'Page', component: asyncComponent(() => import('./Page/index')) },
    {
      path: 'inbox',
      name: 'Inbox',
      component: asyncComponent(() => import('./Inbox/index')),
      childRoutes: [
        { path: 'moderation', name: 'Moderation' },
        { path: 'news', name: 'News' },
        { path: 'notifications', name: 'Notifications' },
      ]
    },
    { path: 'help', name: 'Help', component: asyncComponent(() => import('./UserHelp/index')) },
  ],
  advertiser: [
    { path: 'page', name: 'Page', component: asyncComponent(() => import('./Page/index')) },
    // {path: 'ads', name: 'Ads', component: asyncComponent(() => import('./Ads/index')) },
    // {path: 'insights', name: 'Insights', component: asyncComponent(() => import('./Page/index')) },
    { path: 'help', name: 'Help', component: asyncComponent(() => import('./UserHelp/index')) },
  ]
}

const Dashboard = ({ match, accessChecking, isValidRestaurant, checkRestaurantAccess, ...props }) => {

  const [redirection, setRedirection] = useState(false);
  const [validate, setValidate] = useState(true);
  const [restaurantId, setRestaurantId] = useState('');

  useEffect(() => {
    if (!props.authUser) {
      setRedirection(true);
    }
  }, [props.authUser]);

  useEffect(() => {
    let pathname = props.location.pathname.split('/');
    setRestaurantId(pathname[2])
    checkRestaurantAccess(pathname[2]);
    setValidate(false);
  }, [])

  if (accessChecking || validate) {
    return <LoaderPage />
  }

  if (redirection || !isValidRestaurant || (Array.isArray(isValidRestaurant) && !isValidRestaurant.length)) {
    return (
      <div>
        <Redirect to={HOME} />
      </div>)
  } else {
    const { accessType } = isValidRestaurant;

    return (
      <div className="my-app mt_70 new_for_index_tablet">
        <RestaurantAdminHeader roleHeaders={userRoles[accessType]} />
        <Switch>
          <Redirect exact from={`${match.url}/`} to={HOME} />
          {
            userRoles[accessType].map(roleData => {
              const { path, component } = roleData;
              return <Route path={`${match.url}/:id/${path}`} exact component={component} key={path} />
            })
          }
          <Redirect to={`${match.url}/${restaurantId}/page`} />
        </Switch>
        <TCButton />
        <MetaTags data={restaurantId} isDashboard={false} />
      </div>
    )
  }
};

const mapStateToProps = ({ auth, restaurantAdmin }) => {
  return {
    authUser: !!auth.payload,
    isValidRestaurant: restaurantAdmin.payload,
    accessChecking: restaurantAdmin.isLoading
  }
};

export default connect(mapStateToProps, { checkRestaurantAccess })(Dashboard);
