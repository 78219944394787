import React from 'react';

class ImageWithLoading extends React.Component {
  state = {
    loaded: false
  };

  handleLoaded = () => {
    this.setState({loaded: true});
  };

  render() {
    const {alt, src, className, style, type} = this.props;
    const {loaded} = this.state;

    let placeHolderImage = <img src="/images/restaurant-loading.png" style={{maxHeight: 220}} alt="placeholder"/>;

    if (type === 'restaurant') {
      placeHolderImage = <img
        src="/images/restaurant-loading.png"
        style={{objectFit: 'cover', maxHeight: 210}}
        alt="placeholder"
        className={className}
      />;
    }
    if (type === 'banner') {
      placeHolderImage = <img src="/images/banner-loading.png" style={{maxHeight: 401}} alt="placeholder"/>;
    }
    if (type === 'review-thumb') {
      placeHolderImage = <img src="/images/restaurant-loading.png" style={{maxHeight: 230}} alt="placeholder"/>;
    }
    if (type === 'galleryEdit') {
      placeHolderImage = <img
        src="/images/restaurant-loading.png"
        style={{maxHeight: 241, objectFit: "cover"}}
        alt="placeholder"
      />;
    }
    if (type === 'galleryEditFull') {
      placeHolderImage = <img
        src="/images/restaurant-loading-full.png"
        style={{maxHeight: 241, objectFit: "cover"}}
        alt="placeholder"
      />;
    }
    if (type === 'gallery') {
      placeHolderImage = <div style={{width: 1180, height: 537, backgroundColor: '#eee'}} className="placeholder"/>;
    }
    if (type === 'booking-review') {
      placeHolderImage = <img
        src="/images/booking-review-loading.png"
        style={{...style, maxHeight: 158, objectFit: "cover"}}
        alt="placeholder"
      />;
    }
    if (type === 'strip') {
      placeHolderImage = <img
        src="/images/strip-loading.png"
        style={{maxHeight: 180, objectFit: "cover"}}
        alt="placeholder"
        className="nearby_box_img"
      />;
    }
    if (type === 'searchPage') {
      placeHolderImage = <img
        src="/images/img1.png"
        style={{maxHeight: 60, objectFit: "cover"}}
        alt="placeholder"
        className="nearby_box_img"
      />;
    }
    if (type === 'restaurantThumb') {
      placeHolderImage = <img
        src="/images/img1.png"
        style={{maxHeight: 43, objectFit: "cover"}}
        alt="placeholder"
        className="nearby_box_img"
      />;
    }

    return (
      <React.Fragment>
        {!loaded ? (
          placeHolderImage
        ) : null}
        <img
          style={!loaded ? {display: 'none'} : style ? {...style} : {}}
          onLoad={this.handleLoaded}
          alt={alt || ''}
          className={className || ''}
          src={src}
        />
      </React.Fragment>
    )
  }
}

export default ImageWithLoading;