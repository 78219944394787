import {
  FETCH_TAGS_START,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILED,
  FETCH_TAGS_CATEGORY_START,
  FETCH_TAGS_CATEGORY_SUCCESS,
  FETCH_TAGS_CATEGORY_FAILED,
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  categoryPayload: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAGS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_TAGS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false
      };
    }
    case FETCH_TAGS_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    case FETCH_TAGS_CATEGORY_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_TAGS_CATEGORY_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        categoryPayload: data,
        isLoading: false
      };
    }
    case FETCH_TAGS_CATEGORY_FAILED: {
      return { ...state, categoryPayload: null, isLoading: false, error: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
