import {
  ADD_REVIEW_START,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_FAILED,
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REVIEW_START: {
      return {...state, error: null, isLoading: true};
    }
    case ADD_REVIEW_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false
      };
    }
    case ADD_REVIEW_FAILED: {
      return {...state, payload: null, isLoading: false, error: action.payload};
    }
    default:
      return state
  }
};

export default reducer;
