import {
  LOG_INTERACTION,
  LOG_INTERACTION_SUCCESS,
  LOG_INTERACTION_FAILURE,
} from '../../constants/actionTypes';
import { loggingService } from "../../service/logging-service";

export const saveInteractionLog = data => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(saveInteractionLogStart());
    loggingService.saveLog(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(saveInteractionLogSuccess(response));
        } else {
          dispatch(saveInteractionLogFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(saveInteractionLogFailed(failureMessage));
      });
  }
};

const saveInteractionLogStart = () => {
  return {
    type: LOG_INTERACTION
  }
};

const saveInteractionLogSuccess = data => {
  return {
    type: LOG_INTERACTION_SUCCESS,
    payload: data
  }
};

const saveInteractionLogFailed = data => {
  return {
    type: LOG_INTERACTION_FAILURE,
    payload: data
  }
};
