import {
  FETCH_RESTAURANT_REWARDS_START,
  FETCH_RESTAURANT_REWARDS_SUCCESS,
  FETCH_RESTAURANT_REWARDS_FAILED,
  FETCH_RESTAURANT_COINS_START,
  FETCH_RESTAURANT_COINS_SUCCESS,
  FETCH_RESTAURANT_COINS_FAILED,
  FETCH_RESTAURANT_COINS_SETTING_START,
  FETCH_RESTAURANT_COINS_SETTING_SUCCESS,
  FETCH_RESTAURANT_COINS_SETTING_FAILED,
  PAY_REWARDS_START,
  PAY_REWARDS_SUCCESS,
  PAY_REWARDS_FAILED,
  PAY_RESTAURANT_REWARDS_START,
  PAY_RESTAURANT_REWARDS_SUCCESS,
  PAY_RESTAURANT_REWARDS_FAILED,
  FETCH_COIN_REWARDS_START,
  FETCH_COIN_REWARDS_SUCCESS,
  FETCH_COIN_REWARDS_FAILED,
  FETCH_REWARDS_START,
  FETCH_REWARDS_SUCCESS,
  FETCH_REWARDS_FAILED
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  payloadObj: {},
  restaurantCoins: null,
  payRewards: null,
  payRestaurantRewards: null,
  rewardCoins: null,
  rewardsPayload: [],
  rewardsError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESTAURANT_REWARDS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_RESTAURANT_REWARDS_SUCCESS: {
      const { data: rewardsList } = action.payload;
      let rewardsData = {}
      if (rewardsList.length) {
        rewardsList.map(rewardItem => {
          rewardsData = { ...rewardsData, ...rewardItem }
        })
      }
      return {
        ...state,
        error: null,
        payload: rewardsList,
        payloadObj: rewardsData,
        isLoading: false
      };
    }
    case FETCH_RESTAURANT_REWARDS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case FETCH_RESTAURANT_COINS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_RESTAURANT_COINS_SUCCESS: {
      return {
        ...state,
        error: null,
        restaurantCoins: action.payload,
        isLoading: false
      };
    }
    case FETCH_RESTAURANT_COINS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case FETCH_RESTAURANT_COINS_SETTING_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_RESTAURANT_COINS_SETTING_SUCCESS: {

      const { data: rewardsList } = action.payload;
      let rewardsData = {}
      if (action.payload.length) {
        action.payload.map(rewardItem => {
          let newObj = {};
          newObj[rewardItem.sectionName] = rewardItem.firstPayout !== 0 ? rewardItem.firstPayout : rewardItem.totalPayout;
          rewardsData = { ...rewardsData, ...newObj }
        })
      }

      return {
        ...state,
        error: null,
        payload: action.payload,
        payloadObj: rewardsData,
        isLoading: false
      };
    }
    case FETCH_RESTAURANT_COINS_SETTING_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case PAY_REWARDS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case PAY_REWARDS_SUCCESS: {
      return {
        ...state,
        error: null,
        payRewards: action.payload,
        isLoading: false
      };
    }
    case PAY_REWARDS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case PAY_RESTAURANT_REWARDS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case PAY_RESTAURANT_REWARDS_SUCCESS: {
      return {
        ...state,
        error: null,
        payRestaurantRewards: action.payload,
        isLoading: false
      };
    }
    case PAY_RESTAURANT_REWARDS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case FETCH_COIN_REWARDS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_COIN_REWARDS_SUCCESS: {
      return {
        ...state,
        error: null,
        rewardCoins: action.payload,
        isLoading: false
      };
    }
    case FETCH_COIN_REWARDS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case FETCH_REWARDS_START: {
      return { ...state, rewardsError: null };
    }
    case FETCH_REWARDS_SUCCESS: {
      return { ...state, rewardsError: null, rewardsPayload: action.payload };
    }
    case FETCH_REWARDS_FAILED: {
      return { ...state, rewardsPayload: [], rewardsError: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
