import {
  FETCH_CUISINES_START,
  FETCH_CUISINES_SUCCESS,
  FETCH_FILTERED_CUISINES_SUCCESS,
  FETCH_CUISINES_FAILED,
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  filteredPayload: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUISINES_START: {
      return {...state, error: null, isLoading: true};
    }
    case FETCH_CUISINES_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false
      };
    }
    case FETCH_FILTERED_CUISINES_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        error: null,
        filteredPayload: data,
        isLoading: false
      };
    }
    case FETCH_CUISINES_FAILED: {
      return {...state, payload: null, isLoading: false, error: action.payload};
    }
    default:
      return state
  }
};

export default reducer;
