import {
  ADD_REPORT_ISSUE_START,
  ADD_REPORT_ISSUE_SUCCESS,
  ADD_REPORT_ISSUE_FAILED
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: null,
  reportIssueLoading: false,
  reportIssuePayload: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case ADD_REPORT_ISSUE_START: {
      return { ...state, error: null, reportIssueLoading: true };
    }
    case ADD_REPORT_ISSUE_SUCCESS: {
      return { ...state, error: null, reportIssuePayload: action.payload, reportIssueLoading: false };
    }
    case ADD_REPORT_ISSUE_FAILED: {
      return { ...state, reportIssuePayload: null, reportIssueLoading: false, error: action.payload };
    }

    default:
      return state
  }
};

export default reducer;
