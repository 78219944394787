import { toast } from "react-toastify";

import {
  CLAIM_RESTAURANT_START,
  CLAIM_RESTAURANT_SUCCESS,
  CLAIM_RESTAURANT_FAILED,
  FETCH_LISTS_RESTAURANTS_AFFILIATED_START,
  FETCH_LISTS_RESTAURANTS_AFFILIATED_SUCCESS,
  FETCH_LISTS_RESTAURANTS_AFFILIATED_FAILED,
  CHECK_RESTAURANT_ADMIN_START,
  CHECK_RESTAURANT_ADMIN_SUCCESS,
  CHECK_RESTAURANT_ADMIN_FAILED,
  UPDATE_RESTAURANT_ADMIN_START,
  UPDATE_RESTAURANT_ADMIN_SUCCESS,
  UPDATE_RESTAURANT_ADMIN_FAILED,
  SET_UPDATE_RESTAURANT_ADMIN_STATUS,
  REMOVE_UPDATE_RESTAURANT_ADMIN_STATUS,
  LIST_RESTAURANT_ADMIN_BOOKING_START,
  LIST_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  LIST_RESTAURANT_ADMIN_BOOKING_FAILED,
  LIST_RESTAURANT_ADMIN_ALL_BOOKING_START,
  LIST_RESTAURANT_ADMIN_ALL_BOOKING_SUCCESS,
  LIST_RESTAURANT_ADMIN_ALL_BOOKING_FAILED,
  REJECT_RESTAURANT_ADMIN_BOOKING_START,
  REJECT_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  REJECT_RESTAURANT_ADMIN_BOOKING_FAILED,
  ACCEPT_RESTAURANT_ADMIN_BOOKING_START,
  ACCEPT_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  ACCEPT_RESTAURANT_ADMIN_BOOKING_FAILED,
  CONFIRM_RESTAURANT_ADMIN_BOOKING_START,
  CONFIRM_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  CONFIRM_RESTAURANT_ADMIN_BOOKING_FAILED,
  FETCH_RESTAURANT_COIN_BUNDLE_START,
  FETCH_RESTAURANT_COIN_BUNDLE_SUCCESS,
  FETCH_RESTAURANT_COIN_BUNDLE_FAILED,
  FETCH_RESTAURANT_COIN_REWARDS_SETTING_START,
  FETCH_RESTAURANT_COIN_REWARDS_SETTING_SUCCESS,
  FETCH_RESTAURANT_COIN_REWARDS_SETTING_FAILED,
  FETCH_MODERATION_LIST_START,
  FETCH_MODERATION_LIST_SUCCESS,
  FETCH_MODERATION_LIST_FAILED,
  UPDATE_MODERATION_STATUS_START,
  UPDATE_MODERATION_STATUS_SUCCESS,
  UPDATE_MODERATION_STATUS_FAILED,
  FETCH_PAGE_LEVEL_START,
  FETCH_PAGE_LEVEL_SUCCESS,
  FETCH_PAGE_LEVEL_FAILED,
  FETCH_NEWS_LIST_START,
  FETCH_NEWS_LIST_SUCCESS,
  FETCH_NEWS_LIST_FAILED,
  UPDATE_NEWS_STATUS_START,
  UPDATE_NEWS_STATUS_SUCCESS,
  UPDATE_NEWS_STATUS_FAILED,
  FETCH_NOTIFICATION_START,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILED,
  UPDATE_READ_NOTIFICATION_START,
  UPDATE_READ_NOTIFICATION_SUCCESS,
  UPDATE_READ_NOTIFICATION_FAILED,
  FETCH_INBOX_COUNT_START,
  FETCH_INBOX_COUNT_SUCCESS,
  FETCH_INBOX_COUNT_FAILED
} from '../../constants/actionTypes';
import { restaurantAdminService } from '../../service/restaurant-admin-service';
import { updateRestaurantData } from './Restaurant';
import { bookingService } from "../../service/booking-service";

export const claimRequest = (details) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(claimRequestStart());
    restaurantAdminService.claimRequest(details)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(claimRequestSuccess(response.data));
        } else {
          dispatch(claimRequestFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(claimRequestFailed(failureMessage));
      });
  }
};

export const fetchMyPages = searchText => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(restaurantAffiliatedStart());
    restaurantAdminService.getRestaurantAffiliated(searchText)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(restaurantAffiliatedSuccess(response.data));
        } else {
          dispatch(restaurantAffiliatedFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(restaurantAffiliatedFailed(failureMessage));
      });
  }
};

export const checkRestaurantAccess = (id) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(checkRestaurantAdminStart());
    restaurantAdminService.checkRestaurantAdmin(id)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(checkRestaurantAdminSuccess(response));
        } else {
          dispatch(checkRestaurantAdminFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(checkRestaurantAdminFailed(failureMessage));
      });
  }
};

export const updateRestaurantAdminDetails = (id, details) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateRestaurantAdminDetailsStart());
    restaurantAdminService.updateRestaurantAdminDetails(id, details)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(updateRestaurantAdminDetailsSuccess(response.data));
          dispatch(setUpdateRestaurantAdminStatus());
          dispatch(updateRestaurantData(response.data));
        } else {
          dispatch(updateRestaurantAdminDetailsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateRestaurantAdminDetailsFailed(failureMessage));
      });
  }
};

export const fetchMyBooking = (details) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(restaurantAdminBookingStart());
    restaurantAdminService.getUsersBooking(details)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(restaurantAdminBookingSuccess(response));
        } else {
          dispatch(restaurantAdminBookingFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(restaurantAdminBookingFailed(failureMessage));
      });
  }
};

export const fetchMyAllBooking = (details) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(restaurantAdminAllBookingStart());
    restaurantAdminService.getAllUsersBooking(details)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(restaurantAdminAllBookingSuccess(response));
        } else {
          dispatch(restaurantAdminAllBookingFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(restaurantAdminAllBookingFailed(failureMessage));
      });
  }
};

export const fetchRestaurantCoinBundles = () => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(fetchRestaurantCoinBundlesStart());
    restaurantAdminService.getCoinBundles('restaurant')
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantCoinBundlesSuccess(response));
        } else {
          dispatch(fetchRestaurantCoinBundlesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchRestaurantCoinBundlesFailed(failureMessage));
      });
  }
};

export const fetchModerationList = (restaurantId) => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const { currentPage, totalPage, controller } = getState().restaurantAdmin;
    const nextPage = currentPage + 1;
    if (nextPage !== 1 && (nextPage > totalPage)) return;
    controller.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
      pageSize: 10
    };

    const signal = newController.signal;
    dispatch(moderationListStart(newController));
    restaurantAdminService.getModerationList(params, signal, restaurantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(moderationListSuccess(response));
        } else {
          dispatch(moderationListFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(moderationListFailed(failureMessage));
      });
  }
};

export const rejectBooking = (data) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(restaurantAdminRejectBookingStart());
    bookingService.cancelBooking({ ...data })
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          toast.success("Booking rejected successfully!");
          dispatch(restaurantAdminRejectBookingSuccess(response));
        } else {
          dispatch(restaurantAdminRejectBookingFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(restaurantAdminRejectBookingFailed(failureMessage));
      });
  }
};

export const acceptBooking = (id, params) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(restaurantAdminAcceptBookingStart());
    bookingService.changeBookingStage({ bookingID: id, bookingStage: params.bookingStage })
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          toast.success("Booking accepted successfully!");
          dispatch(restaurantAdminAcceptBookingSuccess(response));
        } else {
          dispatch(restaurantAdminAcceptBookingFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(restaurantAdminAcceptBookingFailed(failureMessage));
      });
  }
};

export const reportBooking = (id, params) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(restaurantAdminAcceptBookingStart());
    restaurantAdminService.acceptBooking(id, params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(restaurantAdminAcceptBookingSuccess(response));
        } else {
          toast.error(failureMessage);
          dispatch(restaurantAdminAcceptBookingFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(restaurantAdminAcceptBookingFailed(failureMessage));
      });
  }
}

export const amendBooking = (id, params, type) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(restaurantAdminAmendBookingStart());
    restaurantAdminService.confirmBooking(id, params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          toast.success(`Booking ${type} successfully!`);
          dispatch(restaurantAdminAmendBookingSuccess(response));
        } else {
          dispatch(restaurantAdminAmendBookingFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(restaurantAdminAmendBookingFailed(failureMessage));
      });
  }
}

export const fetchRestaurantRewardSetting = () => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(fetchRestaurantRewardSettingStart());
    restaurantAdminService.getCoinsRewardsSetting()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantRewardSettingSuccess(response));
        } else {
          dispatch(fetchRestaurantRewardSettingFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchRestaurantRewardSettingFailed(failureMessage));
      });
  }
};

export const updateModerationStatus = (id, details) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateModerationStatusStart());
    restaurantAdminService.updateModerationStatus(id, details)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          toast.success(`Moderation ${details.status} successfully!`);
          dispatch(updateModerationStatusSuccess(response));
        } else {
          toast.error(response.message);
          dispatch(updateModerationStatusFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateModerationStatusFailed(failureMessage));
      });
  }
};

export const fetchPageLevel = (id) => {
  const failureMessage = 'Something wrong happened';

  return (dispatch) => {
    dispatch(fetchPageLevelStart());
    restaurantAdminService.getPageLevel(id)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchPageLevelSuccess(response.data));
        } else {
          const error = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(error);
          dispatch(fetchPageLevelFailed(error));
        }
      })
      .catch(() => {
        dispatch(fetchPageLevelFailed(failureMessage));
      });
  }
};

export const fetchNewsList = (id, reload = false) => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const { newsCurrentPage, controller, newsTotalPage } = getState().restaurantAdmin;

    let nextPage = newsCurrentPage;
    if (!reload) {
      nextPage = newsCurrentPage + 1;
      if ((nextPage !== 1 && (nextPage > newsTotalPage))) return;
    }

    controller.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
      pageSize: 10,
      id
    };

    const signal = newController.signal;
    dispatch(newsListStart(newController));
    restaurantAdminService.getNewsList(params, signal)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(newsListSuccess(response));
        } else {
          dispatch(newsListFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(newsListFailed(failureMessage));
      });
  }
};

export const updateNewsStatus = (id, details, type) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateNewsStatusStart());
    restaurantAdminService.updateNewsStatus(id, details)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          if (type === "delete") {
            toast.success(`News ${type} successfully!`);
          }
          // console.log(response);
          dispatch(updateNewsStatusSuccess(response));
        } else {
          toast.error(response.message);
          dispatch(updateNewsStatusFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateNewsStatusFailed(failureMessage));
      });
  }
};

export const fetchNotificationList = (id, reload = false) => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const { notificationCurrentPage, controller, notificationTotalPage } = getState().restaurantAdmin;

    let nextPage = notificationCurrentPage;
    if (!reload) {
      nextPage = notificationCurrentPage + 1;
      if ((nextPage !== 1 && (nextPage > notificationTotalPage))) return;
    }
    controller.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
      pageSize: 10,
      id: id
    };

    const signal = newController.signal;
    dispatch(fetchNotificationListStart(newController));
    restaurantAdminService.getNotificationList(params, signal)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchNotificationListSuccess(response));
        } else {
          toast.error(response.message);
          dispatch(fetchNotificationListFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchNotificationListFailed(failureMessage));
      });
  }
};

export const updateNotificationStatus = (id, data, type) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateReadNotificationStatusStart());
    restaurantAdminService.updateNotificationStatus(id, data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          if (type === "delete") {
            toast.success(`Notification ${type} successfully!`);
          }
          dispatch(updateReadNotificationStatusSuccess(response));
        } else {
          toast.error(response.message);
          dispatch(updateReadNotificationStatusFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateReadNotificationStatusFailed(failureMessage));
      });
  }
};

export const fetchInboxCount = (restaurantId) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(inboxCountStart());
    restaurantAdminService.getInboxCount(restaurantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(inboxCountSuccess(response));
        } else {
          dispatch(inboxCountFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(inboxCountFailed(failureMessage));
      });
  }
};

const claimRequestStart = controller => {
  return {
    type: CLAIM_RESTAURANT_START,
    controller
  }
};

const claimRequestSuccess = data => {
  return {
    type: CLAIM_RESTAURANT_SUCCESS,
    payload: data
  }
};

const claimRequestFailed = data => {
  return {
    type: CLAIM_RESTAURANT_FAILED,
    payload: data
  }
};

const restaurantAffiliatedStart = controller => {
  return {
    type: FETCH_LISTS_RESTAURANTS_AFFILIATED_START,
    controller
  }
};

const restaurantAffiliatedSuccess = data => {
  return {
    type: FETCH_LISTS_RESTAURANTS_AFFILIATED_SUCCESS,
    payload: data
  }
};

const restaurantAffiliatedFailed = data => {
  return {
    type: FETCH_LISTS_RESTAURANTS_AFFILIATED_FAILED,
    payload: data
  }
};

const checkRestaurantAdminStart = () => {
  return {
    type: CHECK_RESTAURANT_ADMIN_START,
  }
};

const checkRestaurantAdminSuccess = response => {
  return {
    type: CHECK_RESTAURANT_ADMIN_SUCCESS,
    payload: response.data
  }
};

const checkRestaurantAdminFailed = data => {
  return {
    type: CHECK_RESTAURANT_ADMIN_FAILED,
    payload: data
  }
};

const updateRestaurantAdminDetailsStart = controller => {
  return {
    type: UPDATE_RESTAURANT_ADMIN_START,
    controller
  }
};

const updateRestaurantAdminDetailsSuccess = response => {
  return {
    type: UPDATE_RESTAURANT_ADMIN_SUCCESS,
    payload: response.data
  }
};

const updateRestaurantAdminDetailsFailed = data => {
  return {
    type: UPDATE_RESTAURANT_ADMIN_FAILED,
    payload: data
  }
};

export const setUpdateRestaurantAdminStatus = () => {
  return {
    type: SET_UPDATE_RESTAURANT_ADMIN_STATUS,
  }
};

export const removeUpdateRestaurantAdminStatus = () => {
  return {
    type: REMOVE_UPDATE_RESTAURANT_ADMIN_STATUS,
  }
};

const restaurantAdminBookingStart = controller => {
  return {
    type: LIST_RESTAURANT_ADMIN_BOOKING_START,
    controller
  }
};

const restaurantAdminBookingSuccess = response => {
  return {
    type: LIST_RESTAURANT_ADMIN_BOOKING_SUCCESS,
    payload: response.data,
    data: response
  }
};

const restaurantAdminBookingFailed = data => {
  return {
    type: LIST_RESTAURANT_ADMIN_BOOKING_FAILED,
    payload: data
  }
};

const restaurantAdminAllBookingStart = controller => {
  return {
    type: LIST_RESTAURANT_ADMIN_ALL_BOOKING_START,
    controller
  }
};

const restaurantAdminAllBookingSuccess = response => {
  return {
    type: LIST_RESTAURANT_ADMIN_ALL_BOOKING_SUCCESS,
    payload: response.data,
    data: response
  }
};

const restaurantAdminAllBookingFailed = data => {
  return {
    type: LIST_RESTAURANT_ADMIN_ALL_BOOKING_FAILED,
    payload: data
  }
};


const restaurantAdminRejectBookingStart = controller => {
  return {
    type: REJECT_RESTAURANT_ADMIN_BOOKING_START,
    controller
  }
};

const restaurantAdminRejectBookingSuccess = response => {
  return {
    type: REJECT_RESTAURANT_ADMIN_BOOKING_SUCCESS,
    payload: response.data,
    data: response
  }
};

const restaurantAdminRejectBookingFailed = data => {
  return {
    type: REJECT_RESTAURANT_ADMIN_BOOKING_FAILED,
    payload: data
  }
};

const restaurantAdminAcceptBookingStart = controller => {
  return {
    type: ACCEPT_RESTAURANT_ADMIN_BOOKING_START,
    controller
  }
};

const restaurantAdminAcceptBookingSuccess = response => {
  return {
    type: ACCEPT_RESTAURANT_ADMIN_BOOKING_SUCCESS,
    payload: response.data
  }
};

const restaurantAdminAcceptBookingFailed = data => {
  return {
    type: ACCEPT_RESTAURANT_ADMIN_BOOKING_FAILED,
    payload: data
  }
};

const restaurantAdminAmendBookingStart = controller => {
  return {
    type: CONFIRM_RESTAURANT_ADMIN_BOOKING_START,
    controller
  }
};

const restaurantAdminAmendBookingSuccess = response => {
  return {
    type: CONFIRM_RESTAURANT_ADMIN_BOOKING_SUCCESS,
    payload: response.data,
    data: response
  }
};

const restaurantAdminAmendBookingFailed = data => {
  return {
    type: CONFIRM_RESTAURANT_ADMIN_BOOKING_FAILED,
    payload: data
  }
};

export const fetchRestaurantCoinBundlesStart = () => {
  return {
    type: FETCH_RESTAURANT_COIN_BUNDLE_START
  }
}

export const fetchRestaurantCoinBundlesSuccess = response => {
  return {
    type: FETCH_RESTAURANT_COIN_BUNDLE_SUCCESS,
    payload: response.data
  }
}

export const fetchRestaurantCoinBundlesFailed = data => {
  return {
    type: FETCH_RESTAURANT_COIN_BUNDLE_FAILED,
    payload: data
  }
}

const moderationListStart = controller => {
  return {
    type: FETCH_MODERATION_LIST_START,
    controller
  }
};

const moderationListSuccess = data => {
  return {
    type: FETCH_MODERATION_LIST_SUCCESS,
    payload: data
  }
};

const moderationListFailed = data => {
  return {
    type: FETCH_MODERATION_LIST_FAILED,
    payload: data
  }
};

const updateModerationStatusStart = controller => {
  return {
    type: UPDATE_MODERATION_STATUS_START,
    controller
  }
};

const updateModerationStatusSuccess = response => {
  return {
    type: UPDATE_MODERATION_STATUS_SUCCESS,
    payload: response.data
  }
};

const updateModerationStatusFailed = data => {
  return {
    type: UPDATE_MODERATION_STATUS_FAILED,
    payload: data
  }
};

const fetchRestaurantRewardSettingStart = controller => {
  return {
    type: FETCH_RESTAURANT_COIN_REWARDS_SETTING_START,
    controller
  }
};

const fetchRestaurantRewardSettingSuccess = response => {
  return {
    type: FETCH_RESTAURANT_COIN_REWARDS_SETTING_SUCCESS,
    payload: response.data
  }
};

const fetchRestaurantRewardSettingFailed = data => {
  return {
    type: FETCH_RESTAURANT_COIN_REWARDS_SETTING_FAILED,
    payload: data
  }
};

const fetchPageLevelStart = () => {
  return {
    type: FETCH_PAGE_LEVEL_START,
  }
}

const fetchPageLevelSuccess = data => {
  return {
    type: FETCH_PAGE_LEVEL_SUCCESS,
    payload: data
  }
}

const fetchPageLevelFailed = error => {
  return {
    type: FETCH_PAGE_LEVEL_FAILED,
    payload: error
  }
}

const newsListStart = controller => {
  return {
    type: FETCH_NEWS_LIST_START,
    controller
  }
};

const newsListSuccess = data => {
  return {
    type: FETCH_NEWS_LIST_SUCCESS,
    payload: data
  }
};

const newsListFailed = data => {
  return {
    type: FETCH_NEWS_LIST_FAILED,
    payload: data
  }
};

const updateNewsStatusStart = controller => {
  return {
    type: UPDATE_NEWS_STATUS_START,
    controller
  }
};

const updateNewsStatusSuccess = response => {
  return {
    type: UPDATE_NEWS_STATUS_SUCCESS,
    payload: response
  }
};

const updateNewsStatusFailed = data => {
  return {
    type: UPDATE_NEWS_STATUS_FAILED,
    payload: data
  }
};

const fetchNotificationListStart = (controller) => {
  return {
    type: FETCH_NOTIFICATION_START,
    controller
  }
};

const fetchNotificationListSuccess = response => {
  // console.log(response);
  return {
    type: FETCH_NOTIFICATION_SUCCESS,
    payload: response
  }
};

const fetchNotificationListFailed = data => {
  return {
    type: FETCH_NOTIFICATION_FAILED,
    payload: data
  }
};

const updateReadNotificationStatusStart = controller => {
  return {
    type: UPDATE_READ_NOTIFICATION_START,
    controller
  }
};

const updateReadNotificationStatusSuccess = response => {
  return {
    type: UPDATE_READ_NOTIFICATION_SUCCESS,
    payload: response
  }
};

const updateReadNotificationStatusFailed = data => {
  return {
    type: UPDATE_READ_NOTIFICATION_FAILED,
    payload: data
  }
};

const inboxCountStart = () => {
  return {
    type: FETCH_INBOX_COUNT_START
  };
}


const inboxCountSuccess = response => {
  return {
    type: FETCH_INBOX_COUNT_SUCCESS,
    payload: response.data
  }
};

const inboxCountFailed = data => {
  return {
    type: FETCH_INBOX_COUNT_FAILED,
    payload: data
  }
};