import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const editMenuService = {
  editMenu,
  addMenu,
  fetchMenuType,
  toggleRemoveMenu,
  getMenuList,
  changeMenuOrder
};

function editMenu(menuId, menuData) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(menuData)
  };

  return secureFetch(`${apiURL.UPDATE_MENU}/${menuId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function addMenu(menuId, menuData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(menuData)
  };

  return secureFetch(apiURL.UPDATE_MENU, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function toggleRemoveMenu(menuId, menuData) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(menuData)
  };

  return secureFetch(`${apiURL.UPDATE_MENU}/${menuId}/toggle-archive`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function changeMenuOrder(menuId, menuData) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(menuData)
  };

  return secureFetch(`${apiURL.UPDATE_MENU}/${menuId}/change-order`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getMenuList({currentPage, restaurantId}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.UPDATE_MENU}?restaurant=${restaurantId}&isActive=false&pageSize=10&currentPage=${currentPage}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function fetchMenuType(searchQuery) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.FETCH_MENU_TYPE}?pageSize=10&currentPage=1&orderBy=fullName&search=${searchQuery || ''}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}


/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
