import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const guideService = {
  getGuides,
  getGuideRestaurants,
  getGuideDetails,
  getMapPointer,
  getGuidesSearch
};

function getGuides() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.GUIDES}/?pageSize=10&currentPage=1&orderBy=guideType&status=active&isOnline=true`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getGuidesSearch(search) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return secureFetch(`${apiURL.GUIDES}/search?search=${search}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getGuideRestaurants({ currentPage, guide, areaName, subAreaName, cuisine, tag, menu, menuType, orderBy, saveRecent, title, pageSize, mapPointer }, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    signal
  };
  const newPageSize = pageSize ? pageSize : 22;
  return secureFetch(`${apiURL.GUIDES}/restaurants?pageSize=${newPageSize}&currentPage=${currentPage}&guide=${guide}&areaName=${areaName}&subAreaName=${subAreaName}&orderDir=desc&orderBy=${orderBy || 'rating'}&cuisine=${cuisine}&type=listed&menu=${menu}&menuType=${menuType}&tag=${tag}&saveRecent=${saveRecent}&title=${title}&mapPointer=${mapPointer}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getGuideDetails({ slug, parent }) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.GUIDES}/guide-details/${slug}?parentSlug=${parent}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getMapPointer({ cuisine, location, tag, menu, menuType, orderBy, mapRadius }) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.RESTAURANTS}/map-pointers?mapCenter=${location}&&orderDir=desc&orderBy=${orderBy || 'rating'}&cuisine=${cuisine}&type=listed&menu=${menu}&menuType=${menuType}&tag=${tag}&mapRadius=${mapRadius}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
