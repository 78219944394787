import {
  FETCH_ADMIN_USERS,
  FETCH_ADMIN_USERS_SUCCESS,
  FETCH_ADMIN_USERS_FAILED,
  UPDATE_USER_ACCESS_TYPE,
  UPDATE_USER_ACCESS_TYPE_SUCCESS,
  UPDATE_USER_ACCESS_TYPE_FAILED,
  REMOVE_RESTAURANT_USER,
  REMOVE_RESTAURANT_USER_SUCCESS,
  REMOVE_RESTAURANT_USER_FAILED
} from "../../constants/actionTypes";
import { cloneDeep } from 'lodash';

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  accessUpdating: '',
  deleting: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_USERS: {
      return { ...state, isLoading: true, error: null }
    }
    case FETCH_ADMIN_USERS_SUCCESS: {
      return { ...state, isLoading: false, payload: cloneDeep(action.payload) }
    }
    case FETCH_ADMIN_USERS_FAILED: {
      return { ...state, isLoading: false, error: action.payload }
    }
    case UPDATE_USER_ACCESS_TYPE: {
      return { ...state, accessUpdating: action.payload, error: null }
    }
    case UPDATE_USER_ACCESS_TYPE_SUCCESS: {
      const oldPayload = cloneDeep(state.payload);
      const index = oldPayload.findIndex(data => data._id === action.payload._id);
      oldPayload[index].accessType = action.payload.accessType;
      return { ...state, accessUpdating: '', payload: [...oldPayload] }
    }
    case UPDATE_USER_ACCESS_TYPE_FAILED: {
      return { ...state, accessUpdating: '', error: action.payload }
    }
    case REMOVE_RESTAURANT_USER: {
      return { ...state, deleting: action.payload, error: null }
    }
    case REMOVE_RESTAURANT_USER_SUCCESS: {
      const oldPayload = cloneDeep(state.payload);
      const newPayload = oldPayload.filter(data => data._id !== action.payload);
      return { ...state, deleting: '', payload: [...newPayload] }
    }
    case REMOVE_RESTAURANT_USER_FAILED: {
      return { ...state, deleting: '', error: action.payload }
    }
    default:
      return state;
  }
}

export default reducer;