import {
  FETCH_LISTS_START,
  FETCH_LISTS_SUCCESS,
  FETCH_LISTS_FAILED,
  MANIPULATE_LISTS_START,
  MANIPULATE_LISTS_SUCCESS,
  MANIPULATE_LISTS_FAILED,
  FETCH_LISTS_RESTAURANTS_START,
  FETCH_LISTS_RESTAURANTS_SUCCESS,
  FETCH_LISTS_RESTAURANTS_FAILED,
  FETCH_SHAREDLIST_START,
  FETCH_SHAREDLIST_SUCCESS,
  FETCH_SHAREDLIST_FAILURE,
  FETCH_SHARED_TOKEN_START,
  FETCH_SHARED_TOKEN_SUCCESS,
  FETCH_SHARED_TOKEN_FAILURE,
  FETCH_ACHIEVEMENTS_FAILED, 
} from "../../constants/actionTypes";

const initialState = {
  controller: new AbortController(),
  controllerRestaurant: new AbortController(),
  isRestaurant: false,
  error: null,
  isLoading: false,
  errorRestaurant: null, 
  isLoadingRestaurant: false,
  payload: [],
  listsPayload: [],
  listsRestaurantPayload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
  currentPageRestaurant: 1,
  pageSizeRestaurant: null,
  totalItemRestaurant: null,
  totalPageRestaurant: null,
  filters: {},
  listsItemsPayload: [],
  listsItemsLoading: false,
  errorlistsItems: null,
  listItemAdded: [],
  listsRestaurantData: [],
  guestSharedList: [],
//   guestSharedList: [
//     {
//         "_id": "60e29c443f55145b91765fc2",
//         "title": "Test2",
//         "description": null,
//         "createDate": "2021-07-05T05:44:36.976Z",
//         "restaurants": [
//             {
//                 "addedDate": "2021-07-05T05:45:21.914Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c7161883c",
//                     "place": {
//                         "placeId": "ChIJwzIfpeFgd0gR0lfUW_tejAo",
//                         "vicinity": "Highcross Shopping Centre, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.1387152,
//                                 52.6367533
//                             ]
//                         },
//                         "formatted_address": "Highcross Shopping Centre, Leicester LE1 4AN, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 4AN"
//                     },
//                     "title": "Frankie and Benny's",
//                     "slug": "frankie-and-benny's-1381",
//                     "ordering": 1024,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f5b"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 261 9134"
//                         }
//                     ],
//                     "email": "",
//                     "website": "https://www.frankieandbennys.com/restaurants/east-midlands/leicester/leicester-highcross-shopping-centre",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f09",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f0c",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f13",
//                         "5e457cd0c3b9e62c71617f1f",
//                         "5e457cd0c3b9e62c71617f20",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f29",
//                         "5e457cd0c3b9e62c71617f2b",
//                         "5e457cd0c3b9e62c71617f2c",
//                         "5e457cd0c3b9e62c71617f2f",
//                         "5e457cd0c3b9e62c71617f37",
//                         "5e457cd0c3b9e62c71617f39",
//                         "5e457cd0c3b9e62c71617f3b",
//                         "5e457cd0c3b9e62c71617f40"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "5.59"
//                         },
//                         {
//                             "Main": "11.19"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "21.78"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93001410f5b4eaa6301",
//                             "plateform": "tablefy",
//                             "rating": 0,
//                             "user_ratings_total": 0
//                         },
//                         {
//                             "_id": "5e5e1a9520cc343db4b2b9c7",
//                             "plateform": "google",
//                             "rating": 4,
//                             "user_ratings_total": 991
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.843Z",
//                     "modifiedDate": "2020-02-13T16:45:20.843Z",
//                     "__v": 0,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "restaurantView": 1,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef84dedb527e88230dd",
//                             "fileName": "910f3e48-3127-59e2-a8a5-6c115e5dd1c4.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:52.115Z",
//                             "modifiedDate": "2020-03-19T07:27:52.115Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f134dedb527e88236e3",
//                             "fileName": "0aa3f630-31b6-5586-8d70-33cbd97394c1.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:19.959Z",
//                             "modifiedDate": "2020-03-19T07:28:19.959Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f134dedb527e88236e4",
//                             "fileName": "f15d838c-a59d-5adf-b48d-89aaafde9c3e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:19.963Z",
//                             "modifiedDate": "2020-03-19T07:28:19.963Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f144dedb527e88236e5",
//                             "fileName": "4e2db2ba-7d51-50d3-be30-e4eff4c81b1a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:20.067Z",
//                             "modifiedDate": "2020-03-19T07:28:20.067Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 9,
//                             "_id": "5e731f144dedb527e88236e6",
//                             "fileName": "3620c6e5-b241-50ae-96c0-84d7634937d2.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:20.068Z",
//                             "modifiedDate": "2020-03-19T07:28:20.068Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f144dedb527e88236e7",
//                             "fileName": "2c8c39c6-4bba-5748-92c4-9bd910440981.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:20.069Z",
//                             "modifiedDate": "2020-03-19T07:28:20.069Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f144dedb527e88236e8",
//                             "fileName": "e6d90104-5e20-5886-933d-93dc0cc5c150.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:20.070Z",
//                             "modifiedDate": "2020-03-19T07:28:20.070Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f144dedb527e88236e9",
//                             "fileName": "55812313-c21f-5cc8-a151-17d50fd3ce3c.JPG",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:20.219Z",
//                             "modifiedDate": "2020-03-19T07:28:20.219Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f144dedb527e88236ea",
//                             "fileName": "2ae198a3-53d7-59d7-84e9-673263e733b6.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:20.224Z",
//                             "modifiedDate": "2020-03-19T07:28:20.224Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f144dedb527e88236ef",
//                             "fileName": "ee4f0eb7-9763-5d02-8be8-af97a817e743.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:20.240Z",
//                             "modifiedDate": "2020-03-19T07:28:20.240Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 2,
//                     "totalReviews": 991,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f20",
//                                     "title": "Baby-changing",
//                                     "slug": "baby-changing"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1f",
//                                     "title": "Disabled access",
//                                     "slug": "disabled-access"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2f",
//                                     "title": "Burgers",
//                                     "slug": "burgers"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f37",
//                                     "title": "Pasta",
//                                     "slug": "pasta"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f39",
//                                     "title": "Pizza",
//                                     "slug": "pizza"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3b",
//                                     "title": "Steak",
//                                     "slug": "steak"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f13",
//                                     "title": "Plenty for veggies",
//                                     "slug": "plenty-for-veggies"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f29",
//                                     "title": "Family friendly",
//                                     "slug": "family-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2b",
//                                     "title": "Armed forces discount",
//                                     "slug": "armed-forces-discount"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f09",
//                                     "title": "Breakfast",
//                                     "slug": "breakfast"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0c",
//                                     "title": "Discount",
//                                     "slug": "discount"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2c",
//                                     "title": "NHS",
//                                     "slug": "nhs"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f5b",
//                             "title": "American",
//                             "slug": "american"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-07-05T05:44:54.558Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c71618589",
//                     "place": {
//                         "placeId": "ChIJH3a-NyBhd0gRAnact_W8zMA",
//                         "vicinity": "9 Welford Place, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.1338889,
//                                 52.63194439999999
//                             ]
//                         },
//                         "formatted_address": "9 Welford Pl, Leicester LE1 6ZH, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 6ZH"
//                     },
//                     "title": "Peter Pizzeria",
//                     "slug": "peter-pizzeria-2948",
//                     "ordering": 451,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5f43b6b47e8d025842c554c8"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 254 5333"
//                         }
//                     ],
//                     "email": "",
//                     "website": "http://www.peterpizzeria.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f00",
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f13",
//                         "5e457cd0c3b9e62c71617f15",
//                         "5e457cd0c3b9e62c71617f17",
//                         "5e457cd0c3b9e62c71617f1b",
//                         "5e457cd0c3b9e62c71617f1e",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f29",
//                         "5e457cd0c3b9e62c71617f31",
//                         "5e457cd0c3b9e62c71617f39",
//                         "5e457cd0c3b9e62c71617f3c",
//                         "5e457cd0c3b9e62c71617f40"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "4.25"
//                         },
//                         {
//                             "Main": "10.13"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "19.38"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93301410f5b4eaa683b",
//                             "plateform": "tablefy",
//                             "rating": 0,
//                             "user_ratings_total": 0
//                         },
//                         {
//                             "_id": "5e5e12e7005c42375458a61b",
//                             "plateform": "google",
//                             "rating": 4.5,
//                             "user_ratings_total": 742
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.437Z",
//                     "modifiedDate": "2021-04-26T08:13:45.638Z",
//                     "__v": 0,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 100,
//                     "restaurantView": 2,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731efa4dedb527e882315f",
//                             "fileName": "7d234b9f-a5b8-51d4-986c-070fcbbb5630.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:54.434Z",
//                             "modifiedDate": "2020-03-19T07:27:54.434Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f1a4dedb527e88238b2",
//                             "fileName": "5d71661f-172e-54cd-a808-95928850eae6.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:26.220Z",
//                             "modifiedDate": "2020-03-19T07:28:26.220Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f484dedb527e88240da",
//                             "fileName": "35f43ba6-91c1-5352-81b4-dff10411af3c.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:12.830Z",
//                             "modifiedDate": "2020-03-19T07:29:12.830Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f484dedb527e88240db",
//                             "fileName": "60907928-182f-5cdf-928f-23b970a33fc1.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:12.831Z",
//                             "modifiedDate": "2020-03-19T07:29:12.831Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f484dedb527e88240dc",
//                             "fileName": "6d82e693-d640-52d4-90c6-a2d7a4026700.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:12.848Z",
//                             "modifiedDate": "2020-03-19T07:29:12.848Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f484dedb527e88240de",
//                             "fileName": "a6dfe259-3f95-5a9a-add4-7a2df23bebe0.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:12.863Z",
//                             "modifiedDate": "2020-03-19T07:29:12.863Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f484dedb527e88240df",
//                             "fileName": "26b71351-a3dd-5915-aed4-8d3bb23cbd0b.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:12.864Z",
//                             "modifiedDate": "2020-03-19T07:29:12.864Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f484dedb527e88240e0",
//                             "fileName": "86ca4eeb-e2fb-5940-8d4c-bb1faa2e2a79.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:12.865Z",
//                             "modifiedDate": "2020-03-19T07:29:12.865Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 2.25,
//                     "totalReviews": 742,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f31",
//                                     "title": "Dessert",
//                                     "slug": "dessert"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f39",
//                                     "title": "Pizza",
//                                     "slug": "pizza"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3c",
//                                     "title": "Street food",
//                                     "slug": "street-food"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f13",
//                                     "title": "Plenty for veggies",
//                                     "slug": "plenty-for-veggies"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f17",
//                                     "title": "Communal seating",
//                                     "slug": "communal-seating"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1b",
//                                     "title": "Fast casual",
//                                     "slug": "fast-casual"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f15",
//                                     "title": "Private dining",
//                                     "slug": "private-dining"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1e",
//                                     "title": "Sharing plates",
//                                     "slug": "sharing-plates"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f29",
//                                     "title": "Family friendly",
//                                     "slug": "family-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f00",
//                                     "title": "Pre-theatre / Early bird",
//                                     "slug": "pre-theatre-early-bird"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5f43b6b47e8d025842c554c8",
//                             "title": " Italian",
//                             "slug": "italian"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-07-05T05:44:41.556Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c716185ca",
//                     "place": {
//                         "placeId": "ChIJ5WoY4SFhd0gRO7HWbdStC7Q",
//                         "vicinity": "13-15 Belvoir Street, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.1308589,
//                                 52.63319489999999
//                             ]
//                         },
//                         "formatted_address": "13-15 Belvoir St, Leicester LE1 6SL, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 6SL"
//                     },
//                     "title": "Las Iguanas",
//                     "slug": "las-iguanas-338",
//                     "ordering": 993,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f49",
//                         "5e457cf4c3b9e62c71617f54",
//                         "5e457cf4c3b9e62c71617f56"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 285 9180"
//                         }
//                     ],
//                     "email": "leicester@iguanas.co.uk",
//                     "website": "http://www.iguanas.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f02",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f0c",
//                         "5e457cd0c3b9e62c71617f12",
//                         "5e457cd0c3b9e62c71617f2b",
//                         "5e457cd0c3b9e62c71617f40",
//                         "5e457cd0c3b9e62c71617f41"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": 0
//                         },
//                         {
//                             "Main": "13.16"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "18.16"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93201410f5b4eaa672d",
//                             "plateform": "tablefy",
//                             "rating": 6,
//                             "user_ratings_total": 2
//                         },
//                         {
//                             "_id": "5e5e1a8120cc343db4b2b9a9",
//                             "plateform": "google",
//                             "rating": 4.1,
//                             "user_ratings_total": 726
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.462Z",
//                     "modifiedDate": "2020-02-13T16:45:20.462Z",
//                     "__v": 0,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "restaurantView": 2,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef54dedb527e882302b",
//                             "fileName": "6753bad1-255d-55a9-bb67-d4aec643562c.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:49.577Z",
//                             "modifiedDate": "2020-03-19T07:27:49.577Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f044dedb527e882332d",
//                             "fileName": "b7a8c6e1-88ec-52d2-b54c-13be31ee614b.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:04.475Z",
//                             "modifiedDate": "2020-03-19T07:28:04.475Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f044dedb527e882332f",
//                             "fileName": "9c8de1b1-e5cc-5e92-a6d2-47055334e759.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:04.524Z",
//                             "modifiedDate": "2020-03-19T07:28:04.524Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f044dedb527e8823331",
//                             "fileName": "f5652b7f-318d-58a1-a567-8bb6a67e55a2.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:04.643Z",
//                             "modifiedDate": "2020-03-19T07:28:04.643Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f044dedb527e8823333",
//                             "fileName": "69f9b078-8fb5-5430-adf3-e46f54ed75e8.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:04.691Z",
//                             "modifiedDate": "2020-03-19T07:28:04.691Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f044dedb527e8823332",
//                             "fileName": "d94819c9-84be-5679-856d-d14e3505d17b.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:04.644Z",
//                             "modifiedDate": "2020-03-19T07:28:04.644Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f044dedb527e8823330",
//                             "fileName": "99ea4b9c-dc28-5cb9-8390-60636e0a0489.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:04.642Z",
//                             "modifiedDate": "2020-03-19T07:28:04.642Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 3.55,
//                     "totalReviews": 728,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f02",
//                                     "title": "Child-friendly",
//                                     "slug": "child-friendly"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f41",
//                                     "title": "Tapas / Meze",
//                                     "slug": "tapas-meze"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f12",
//                                     "title": "Halal options",
//                                     "slug": "halal-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2b",
//                                     "title": "Armed forces discount",
//                                     "slug": "armed-forces-discount"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0c",
//                                     "title": "Discount",
//                                     "slug": "discount"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f49",
//                             "title": "Spanish",
//                             "slug": "spanish"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f54",
//                             "title": "Mexican",
//                             "slug": "mexican"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f56",
//                             "title": "South American",
//                             "slug": "south-american"
//                         }
//                     ]
//                 }
//             }
//         ]
//     },
//     {
//         "_id": "60e297f33f55145b91765f6e",
//         "title": "Testing1",
//         "description": null,
//         "createDate": "2021-07-05T05:26:11.178Z",
//         "restaurants": [
//             {
//                 "addedDate": "2021-07-05T05:27:35.642Z",
//                 "restaurant": {
//                     "_id": "5e457d1fc3b9e62c716180fe",
//                     "place": {
//                         "placeId": "ChIJm2voNCNhd0gRCL1jZ4PkrqY",
//                         "vicinity": "1 De Montfort Street, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.123743,
//                                 52.629532
//                             ]
//                         },
//                         "formatted_address": "1 De Montfort St, Leicester LE1 7GE, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 7GE"
//                     },
//                     "title": "Mumbai Inn",
//                     "slug": "mumbai-inn-1820",
//                     "ordering": 404,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f43",
//                         "5e457cf4c3b9e62c71617f6b"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 247 0420"
//                         }
//                     ],
//                     "email": "info@mumbaiinn.co.uk",
//                     "website": "http://www.mumbaiinn.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f13",
//                         "5e457cd0c3b9e62c71617f2e",
//                         "5e457cd0c3b9e62c71617f30",
//                         "5e457cd0c3b9e62c71617f40"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "4.27"
//                         },
//                         {
//                             "Main": "10.50"
//                         },
//                         {
//                             "Side": "3.39"
//                         },
//                         {
//                             "restaurantPrice": "23.16"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd92f01410f5b4eaa5d79",
//                             "plateform": "tablefy",
//                             "rating": 8.99072245345451,
//                             "user_ratings_total": 32
//                         },
//                         {
//                             "_id": "5e5e12ce005c42375458a5ec",
//                             "plateform": "google",
//                             "rating": 4.1,
//                             "user_ratings_total": 217
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:19.639Z",
//                     "modifiedDate": "2020-02-13T16:45:19.639Z",
//                     "__v": 0,
//                     "callCount": 186,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef94dedb527e8823133",
//                             "fileName": "19c19e92-ee42-5f2b-a45a-2429a9ccbc1a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:53.828Z",
//                             "modifiedDate": "2020-03-19T07:27:53.828Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f194dedb527e8823815",
//                             "fileName": "abfa6271-484b-5410-bc21-fda672ca6dfe.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:25.123Z",
//                             "modifiedDate": "2020-03-19T07:28:25.123Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 10,
//                             "_id": "5e731f3e4dedb527e8823e53",
//                             "fileName": "97dc44b3-a349-52cb-9673-69c16092f06a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.115Z",
//                             "modifiedDate": "2020-03-19T07:29:02.115Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f3e4dedb527e8823e54",
//                             "fileName": "1a543263-a671-540f-9a26-a0c5d3563579.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.117Z",
//                             "modifiedDate": "2020-03-19T07:29:02.117Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f3e4dedb527e8823e55",
//                             "fileName": "9ecc4793-9c73-53ab-b96e-c813d2e84310.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.126Z",
//                             "modifiedDate": "2020-03-19T07:29:02.126Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 9,
//                             "_id": "5e731f3e4dedb527e8823e56",
//                             "fileName": "80040082-f205-5525-ac72-e2f95ef870aa.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.134Z",
//                             "modifiedDate": "2020-03-19T07:29:02.134Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f3e4dedb527e8823e57",
//                             "fileName": "fc8ac633-f474-5dfd-b744-8bbeaee71c08.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.152Z",
//                             "modifiedDate": "2020-03-19T07:29:02.152Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f3e4dedb527e8823e58",
//                             "fileName": "51d3c2e4-cc32-54d4-a96c-44f855728bff.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.157Z",
//                             "modifiedDate": "2020-03-19T07:29:02.157Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f3e4dedb527e8823e5a",
//                             "fileName": "deb957be-a6e8-5639-a25e-207a681416fa.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.160Z",
//                             "modifiedDate": "2020-03-19T07:29:02.160Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 11,
//                             "_id": "5e731f3e4dedb527e8823e59",
//                             "fileName": "1b392ed4-b121-5423-8e81-a1c908cdbeac.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.159Z",
//                             "modifiedDate": "2020-03-19T07:29:02.159Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f3e4dedb527e8823e5b",
//                             "fileName": "75474737-0b0b-555f-b992-d596cbd1176d.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.163Z",
//                             "modifiedDate": "2020-03-19T07:29:02.163Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f3e4dedb527e8823e5c",
//                             "fileName": "ad674d2a-7d66-5f9a-9d90-e4c214c36990.JPG",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.166Z",
//                             "modifiedDate": "2020-03-19T07:29:02.166Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f3e4dedb527e8823e5d",
//                             "fileName": "f7838102-cc4e-5a25-8198-0ed891f5f6dd.JPG",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:02.172Z",
//                             "modifiedDate": "2020-03-19T07:29:02.172Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.297680613363627,
//                     "totalReviews": 249,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2e",
//                                     "title": "BBQ / Grill",
//                                     "slug": "bbq-grill"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f30",
//                                     "title": "Curry",
//                                     "slug": "curry"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f13",
//                                     "title": "Plenty for veggies",
//                                     "slug": "plenty-for-veggies"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f43",
//                             "title": "Indian",
//                             "slug": "indian"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f6b",
//                             "title": "Nepalese",
//                             "slug": "nepalese"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-07-05T05:27:24.684Z",
//                 "restaurant": {
//                     "_id": "5e457d1fc3b9e62c7161805c",
//                     "place": {
//                         "placeId": "ChIJY_3VTyNhd0gRNO-yBIcCHOQ",
//                         "vicinity": "20 De Montfort Street, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.1243728,
//                                 52.6285178
//                             ]
//                         },
//                         "formatted_address": "20 De Montfort St, Leicester LE1 7GR, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 7GR"
//                     },
//                     "title": "The Belmont",
//                     "slug": "the-belmont-652",
//                     "ordering": 1009,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f42",
//                         "5e457cf4c3b9e62c71617f48",
//                         "5e457cf4c3b9e62c71617f7e"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 254 4773"
//                         },
//                         {
//                             "bookNowTel": "0116 426 0773"
//                         }
//                     ],
//                     "email": "info@belmonthotel.co.uk, reservations@belmonthotel.co.uk",
//                     "website": "https://www.belmonthotel.co.uk/dining/jamie-s-bar",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f00",
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f05",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f15",
//                         "5e457cd0c3b9e62c71617f19",
//                         "5e457cd0c3b9e62c71617f1e",
//                         "5e457cd0c3b9e62c71617f1f",
//                         "5e457cd0c3b9e62c71617f20",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f22",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f24",
//                         "5e457cd0c3b9e62c71617f3b",
//                         "5e5a2ce2a6d2e9387d4aa418",
//                         "5e5a2ce2a6d2e9387d4aa41d"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "8.00"
//                         },
//                         {
//                             "Main": "16.46"
//                         },
//                         {
//                             "Side": "8.91"
//                         },
//                         {
//                             "restaurantPrice": "38.37"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd92f01410f5b4eaa5cb9",
//                             "plateform": "tablefy",
//                             "rating": 9.09375,
//                             "user_ratings_total": 6
//                         },
//                         {
//                             "_id": "5e5e1a8b20cc343db4b2b9b8",
//                             "plateform": "google",
//                             "rating": 4.2,
//                             "user_ratings_total": 666
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:19.544Z",
//                     "modifiedDate": "2020-02-13T16:45:19.544Z",
//                     "__v": 0,
//                     "callCount": 138,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef64dedb527e8823075",
//                             "fileName": "372c3ef0-948e-5c1d-a374-5c88da64a947.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:50.538Z",
//                             "modifiedDate": "2020-03-19T07:27:50.538Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f094dedb527e882346c",
//                             "fileName": "bfb678cd-6a7f-5762-8957-5033e8c4c36e.JPG",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:09.347Z",
//                             "modifiedDate": "2020-03-19T07:28:09.347Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 13,
//                             "_id": "5e731f294dedb527e8823d56",
//                             "fileName": "f318391d-0ef2-5129-906a-2bc07eda0a3d.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.544Z",
//                             "modifiedDate": "2020-03-19T07:28:41.544Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 10,
//                             "_id": "5e731f294dedb527e8823d57",
//                             "fileName": "a367cdd7-de54-59a3-a80a-97c47a396e41.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.544Z",
//                             "modifiedDate": "2020-03-19T07:28:41.544Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f294dedb527e8823d58",
//                             "fileName": "e8d102ea-a0c4-5cad-9cb4-4c87e1fd3046.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.545Z",
//                             "modifiedDate": "2020-03-19T07:28:41.545Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 9,
//                             "_id": "5e731f294dedb527e8823d59",
//                             "fileName": "c4d3be35-e482-545d-99b4-404e671bc76b.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.545Z",
//                             "modifiedDate": "2020-03-19T07:28:41.545Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f294dedb527e8823d5a",
//                             "fileName": "50ab528b-6936-5b43-8bee-6f1142eb5014.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.546Z",
//                             "modifiedDate": "2020-03-19T07:28:41.546Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 11,
//                             "_id": "5e731f294dedb527e8823d5b",
//                             "fileName": "b1e2cd81-ea70-5739-9f03-15b4a0dcdc92.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.547Z",
//                             "modifiedDate": "2020-03-19T07:28:41.547Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 12,
//                             "_id": "5e731f294dedb527e8823d5d",
//                             "fileName": "307e7709-302b-513c-92d5-9ad1850e7aea.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.548Z",
//                             "modifiedDate": "2020-03-19T07:28:41.548Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f294dedb527e8823d5f",
//                             "fileName": "50f018dc-f706-51b9-93f5-cd4dc6082df4.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.549Z",
//                             "modifiedDate": "2020-03-19T07:28:41.549Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f294dedb527e8823d5e",
//                             "fileName": "93d7e92e-f1e7-549d-a427-3286345231f1.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.548Z",
//                             "modifiedDate": "2020-03-19T07:28:41.548Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f294dedb527e8823d60",
//                             "fileName": "fa6be5ec-a61e-5647-bdd8-dfcee7e6f389.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.549Z",
//                             "modifiedDate": "2020-03-19T07:28:41.549Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f294dedb527e8823d61",
//                             "fileName": "b92fb751-649d-5353-83b7-acdd4192bb9a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.550Z",
//                             "modifiedDate": "2020-03-19T07:28:41.550Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f294dedb527e8823d62",
//                             "fileName": "7e4161dc-9692-5fb2-a19e-4e85c4887f69.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.550Z",
//                             "modifiedDate": "2020-03-19T07:28:41.550Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.3734375,
//                     "totalReviews": 672,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa41d",
//                                     "title": "Air conditioning",
//                                     "slug": "air-conditioning"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f20",
//                                     "title": "Baby-changing",
//                                     "slug": "baby-changing"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1f",
//                                     "title": "Disabled access",
//                                     "slug": "disabled-access"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f22",
//                                     "title": "Free parking",
//                                     "slug": "free-parking"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa418",
//                                     "title": "In-house bar",
//                                     "slug": "in-house-bar"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f24",
//                                     "title": "Round tables",
//                                     "slug": "round-tables"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3b",
//                                     "title": "Steak",
//                                     "slug": "steak"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f19",
//                                     "title": "Bar dining",
//                                     "slug": "bar-dining"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f15",
//                                     "title": "Private dining",
//                                     "slug": "private-dining"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1e",
//                                     "title": "Sharing plates",
//                                     "slug": "sharing-plates"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f00",
//                                     "title": "Pre-theatre / Early bird",
//                                     "slug": "pre-theatre-early-bird"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f05",
//                                     "title": "Afternoon tea",
//                                     "slug": "afternoon-tea"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f42",
//                             "title": "British",
//                             "slug": "british"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f48",
//                             "title": "Pan European",
//                             "slug": "pan-european"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f7e",
//                             "title": "Western European",
//                             "slug": "western-european"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-07-05T05:27:11.603Z",
//                 "restaurant": {
//                     "_id": "5e457d1fc3b9e62c7161823d",
//                     "place": {
//                         "placeId": "ChIJbZkMsCFhd0gRkQM_wTVVQLE",
//                         "vicinity": "14-16 King Street, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.1321061,
//                                 52.63134609999999
//                             ]
//                         },
//                         "formatted_address": "14-16 King St, Leicester LE1 6RJ, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 6RJ"
//                     },
//                     "title": "The White Peacock",
//                     "slug": "the-white-peacock-919",
//                     "ordering": 239,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f42",
//                         "5e457cf4c3b9e62c71617f7e"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 254 7663"
//                         },
//                         {
//                             "bookNowTel": "0116 254 7663"
//                         }
//                     ],
//                     "email": "enquiries@the-white-peacock.co.uk",
//                     "website": "http://www.the-white-peacock.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f12",
//                         "5e457cd0c3b9e62c71617f13",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f15",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f05",
//                         "5e457cd0c3b9e62c71617f40",
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f04"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Dish": 0
//                         },
//                         {
//                             "Dessert": 0
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "5.00"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd92f01410f5b4eaa60b5",
//                             "plateform": "tablefy",
//                             "rating": 9.958984375,
//                             "user_ratings_total": 11
//                         },
//                         {
//                             "_id": "5e5e1265005c42375458a547",
//                             "plateform": "google",
//                             "rating": 4.4,
//                             "user_ratings_total": 187
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:19.917Z",
//                     "modifiedDate": "2020-02-13T16:45:19.917Z",
//                     "__v": 0,
//                     "callCount": 846,
//                     "virtualTourLink": "&lt;iframe src=\"https://www.google.com/maps/embed?pb=!4v1585648054976!6m8!1m7!1skAuT7MdJ6eObR_7tM9fYHw!2m2!1d52.63125652012103!2d-1.132148423730256!3f20.86947435146213!4f0.3848877156012662!5f0.5286296169213472\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0;\" allowfullscreen=\"\" aria-hidden=\"false\" tabindex=\"0\">&lt;/iframe>",
//                     "priceCategory": "Small plates",
//                     "coins": 50,
//                     "restaurantView": 6,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef64dedb527e8823099",
//                             "fileName": "c0976622-c636-53ef-b778-216694411de1.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:50.920Z",
//                             "modifiedDate": "2020-03-19T07:27:50.920Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 9,
//                             "_id": "5e731f0c4dedb527e8823564",
//                             "fileName": "d185c646-9909-5e67-b810-d55df348ba46.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.724Z",
//                             "modifiedDate": "2020-03-19T07:28:12.724Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f0c4dedb527e8823565",
//                             "fileName": "106e96f7-0e58-5adc-bea7-cf35c65870db.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.725Z",
//                             "modifiedDate": "2020-03-19T07:28:12.725Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 10,
//                             "_id": "5e731f0c4dedb527e8823566",
//                             "fileName": "234e1da0-c058-5a25-91de-6bcad2fd804d.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.726Z",
//                             "modifiedDate": "2020-03-19T07:28:12.726Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 13,
//                             "_id": "5e731f0c4dedb527e8823568",
//                             "fileName": "a59cdd45-ec06-522b-9b93-653ae4b80911.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.741Z",
//                             "modifiedDate": "2020-03-19T07:28:12.741Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 14,
//                             "_id": "5e731f0c4dedb527e8823569",
//                             "fileName": "e4c2048c-0908-5a62-a97e-8267a3ab725a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.743Z",
//                             "modifiedDate": "2020-03-19T07:28:12.743Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 11,
//                             "_id": "5e731f0c4dedb527e882356a",
//                             "fileName": "9d531a09-98eb-5ff4-9bf3-6c709056a5b2.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.744Z",
//                             "modifiedDate": "2020-03-19T07:28:12.744Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f0c4dedb527e882356b",
//                             "fileName": "de6631fe-44d5-57e7-98ce-98a160bcd769.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.745Z",
//                             "modifiedDate": "2020-03-19T07:28:12.745Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f0c4dedb527e882356c",
//                             "fileName": "c7fc548e-7c27-5bf3-b2d4-59abdb6bea3f.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.758Z",
//                             "modifiedDate": "2020-03-19T07:28:12.758Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 17,
//                             "_id": "5e731f0c4dedb527e882356d",
//                             "fileName": "f3af51c4-3efb-5fd1-b334-1ed133a7bd5e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.760Z",
//                             "modifiedDate": "2020-03-19T07:28:12.760Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f0c4dedb527e882356e",
//                             "fileName": "ee0c450f-ed4f-5397-a9b1-27d8a29c6d8c.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.760Z",
//                             "modifiedDate": "2020-03-19T07:28:12.760Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 16,
//                             "_id": "5e731f0c4dedb527e882356f",
//                             "fileName": "48da6180-bdf2-5605-bb39-443ea8fb0c95.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.761Z",
//                             "modifiedDate": "2020-03-19T07:28:12.761Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f0c4dedb527e8823570",
//                             "fileName": "c672576f-e219-5ecc-a0b3-81620556d7bf.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.765Z",
//                             "modifiedDate": "2020-03-19T07:28:12.765Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f0c4dedb527e8823571",
//                             "fileName": "07802689-08ef-51f9-bc9f-b41f198cedef.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.770Z",
//                             "modifiedDate": "2020-03-19T07:28:12.770Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 12,
//                             "_id": "5e731f0c4dedb527e8823572",
//                             "fileName": "6cb1e269-63ec-51c4-9b86-7855c8b6b347.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.779Z",
//                             "modifiedDate": "2020-03-19T07:28:12.779Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f0c4dedb527e8823573",
//                             "fileName": "1f401e19-eb51-5000-b7ee-5fe6f238a479.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.782Z",
//                             "modifiedDate": "2020-03-19T07:28:12.782Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 15,
//                             "_id": "5e731f0c4dedb527e8823574",
//                             "fileName": "ca019132-b91e-556d-a10e-c8a1f7177506.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.786Z",
//                             "modifiedDate": "2020-03-19T07:28:12.786Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f0c4dedb527e882357b",
//                             "fileName": "329bb8e2-1762-539f-b1e9-5d88c357e96e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.828Z",
//                             "modifiedDate": "2020-03-19T07:28:12.828Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.68974609375,
//                     "totalReviews": 198,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f12",
//                                     "title": "Halal options",
//                                     "slug": "halal-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f13",
//                                     "title": "Plenty for veggies",
//                                     "slug": "plenty-for-veggies"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f15",
//                                     "title": "Private dining",
//                                     "slug": "private-dining"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f05",
//                                     "title": "Afternoon tea",
//                                     "slug": "afternoon-tea"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f42",
//                             "title": "British",
//                             "slug": "british"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f7e",
//                             "title": "Western European",
//                             "slug": "western-european"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-07-05T05:26:47.949Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c716186b6",
//                     "place": {
//                         "placeId": "ChIJa5V3eiJhd0gRXh_hzngCq8U",
//                         "vicinity": "1 Northampton Street, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.127579,
//                                 52.6328403
//                             ]
//                         },
//                         "formatted_address": "1 Northampton St, Leicester LE1 1PA, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 1PA"
//                     },
//                     "title": "Mono Vita",
//                     "slug": "mono-vita-3042",
//                     "ordering": 464,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f4c",
//                         "5e457cf4c3b9e62c71617f4e"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 255 4777"
//                         },
//                         {
//                             "bookNowTel": "0116 426 0777"
//                         },
//                         {
//                             "bookingRequestMobile": "7717511569"
//                         }
//                     ],
//                     "email": "artan.dobra77@gmail.com, cimikazazi@hotmail.co.ukv",
//                     "website": "http://monovita.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f0c",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f1e",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f24",
//                         "5e457cd0c3b9e62c71617f37",
//                         "5e457cd0c3b9e62c71617f3a",
//                         "5e457cd0c3b9e62c71617f3b",
//                         "5e457cd0c3b9e62c71617f40",
//                         "5e457cd0c3b9e62c71617f41"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "4.39"
//                         },
//                         {
//                             "Main": "10.91"
//                         },
//                         {
//                             "Side": "2.43"
//                         },
//                         {
//                             "restaurantPrice": "22.73"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93301410f5b4eaa684b",
//                             "plateform": "tablefy",
//                             "rating": 9.98828125,
//                             "user_ratings_total": 10
//                         },
//                         {
//                             "_id": "5e5e12f2005c42375458a628",
//                             "plateform": "google",
//                             "rating": 4.5,
//                             "user_ratings_total": 139
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.595Z",
//                     "modifiedDate": "2020-02-13T16:45:20.595Z",
//                     "__v": 0,
//                     "callCount": 132,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "restaurantView": 9,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731efc4dedb527e882317d",
//                             "fileName": "5a21843e-1d85-55dc-8fd1-da5feabe3597.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:56.441Z",
//                             "modifiedDate": "2020-03-19T07:27:56.441Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f1b4dedb527e88238de",
//                             "fileName": "07714460-3a3c-51c9-91d2-b846dbf4565f.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:27.635Z",
//                             "modifiedDate": "2020-03-19T07:28:27.635Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f4c4dedb527e88241b5",
//                             "fileName": "2c635971-7977-534b-9b68-7130fe162c9b.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:16.498Z",
//                             "modifiedDate": "2020-03-19T07:29:16.498Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f4c4dedb527e88241b4",
//                             "fileName": "e9c54fc4-f2c6-5e9b-9b32-8268525e9257.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:16.482Z",
//                             "modifiedDate": "2020-03-19T07:29:16.482Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f4c4dedb527e88241b6",
//                             "fileName": "10fe6a89-e479-5016-b00f-f0f0b02a5196.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:16.499Z",
//                             "modifiedDate": "2020-03-19T07:29:16.499Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f4c4dedb527e88241b8",
//                             "fileName": "8a7104ff-d72b-5438-865b-7dfbca974606.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:16.500Z",
//                             "modifiedDate": "2020-03-19T07:29:16.500Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.7470703125,
//                     "totalReviews": 149,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f24",
//                                     "title": "Round tables",
//                                     "slug": "round-tables"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f37",
//                                     "title": "Pasta",
//                                     "slug": "pasta"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3a",
//                                     "title": "Seafood",
//                                     "slug": "seafood"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3b",
//                                     "title": "Steak",
//                                     "slug": "steak"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f41",
//                                     "title": "Tapas / Meze",
//                                     "slug": "tapas-meze"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1e",
//                                     "title": "Sharing plates",
//                                     "slug": "sharing-plates"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0c",
//                                     "title": "Discount",
//                                     "slug": "discount"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f4c",
//                             "title": "Greek",
//                             "slug": "greek"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f4e",
//                             "title": "Mediterranean",
//                             "slug": "mediterranean"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-07-05T05:26:11.178Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c71618609",
//                     "place": {
//                         "placeId": "ChIJ14K-lSFhd0gRAnHnudlIYDk",
//                         "vicinity": "5 Albion Street, Leicester",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.1314967,
//                                 52.6322596
//                             ]
//                         },
//                         "formatted_address": "5 Albion St, Leicester LE1 6GD, UK",
//                         "locality": "Leicester",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LE1 6GD"
//                     },
//                     "title": "Casa Romana",
//                     "slug": "casa-romana-829",
//                     "ordering": 1010,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f4e"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0116 254 1174"
//                         }
//                     ],
//                     "email": "magda.casaromana@gmail.com",
//                     "website": "http://www.casaromana.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f22",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f37",
//                         "5e457cd0c3b9e62c71617f3a",
//                         "5e457cd0c3b9e62c71617f40"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "6.67"
//                         },
//                         {
//                             "Main": "12.59"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "24.26"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93201410f5b4eaa677e",
//                             "plateform": "tablefy",
//                             "rating": 9.707323908792045,
//                             "user_ratings_total": 53
//                         },
//                         {
//                             "_id": "5e5e1a8b20cc343db4b2b9b9",
//                             "plateform": "google",
//                             "rating": 4.7,
//                             "user_ratings_total": 461
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.494Z",
//                     "modifiedDate": "2020-02-13T16:45:20.494Z",
//                     "__v": 0,
//                     "callCount": 1661,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "restaurantView": 33,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef64dedb527e8823091",
//                             "fileName": "5120276c-979e-5246-9b6b-1ee04000aa7e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:50.834Z",
//                             "modifiedDate": "2020-03-19T07:27:50.834Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f0c4dedb527e8823528",
//                             "fileName": "4ed0d202-2025-5cc9-a549-987a3ed17839.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:12.129Z",
//                             "modifiedDate": "2020-03-19T07:28:12.129Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f294dedb527e8823d74",
//                             "fileName": "f6d1b9e9-8f9c-530c-8656-9d074e260451.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.702Z",
//                             "modifiedDate": "2020-03-19T07:28:41.702Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f294dedb527e8823d73",
//                             "fileName": "ec2eb168-0640-543e-a918-501b067d33ff.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.697Z",
//                             "modifiedDate": "2020-03-19T07:28:41.697Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f294dedb527e8823d75",
//                             "fileName": "66cd808c-eee2-525f-9e06-91113e39e588.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.715Z",
//                             "modifiedDate": "2020-03-19T07:28:41.715Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f294dedb527e8823d76",
//                             "fileName": "256f58a1-e55d-59cf-b1ff-7524dd48927a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.720Z",
//                             "modifiedDate": "2020-03-19T07:28:41.720Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f294dedb527e8823d77",
//                             "fileName": "c0a1cafa-2cf9-5085-9e8f-2ee422ba8571.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.726Z",
//                             "modifiedDate": "2020-03-19T07:28:41.726Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f294dedb527e8823d78",
//                             "fileName": "dd8ec0f6-bc18-57e8-9c9a-664f697055f8.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.727Z",
//                             "modifiedDate": "2020-03-19T07:28:41.727Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f294dedb527e8823d79",
//                             "fileName": "e7ff15b4-baf8-5a2f-91d4-f4d0f002245a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.734Z",
//                             "modifiedDate": "2020-03-19T07:28:41.734Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f294dedb527e8823d7a",
//                             "fileName": "383ca307-d51e-53b7-a239-1601af91b019.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.799Z",
//                             "modifiedDate": "2020-03-19T07:28:41.799Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.776830977198012,
//                     "totalReviews": 514,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f22",
//                                     "title": "Free parking",
//                                     "slug": "free-parking"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f37",
//                                     "title": "Pasta",
//                                     "slug": "pasta"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3a",
//                                     "title": "Seafood",
//                                     "slug": "seafood"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f4e",
//                             "title": "Mediterranean",
//                             "slug": "mediterranean"
//                         }
//                     ]
//                 }
//             }
//         ]
//     },
//     {
//         "_id": "5f1acce83aa24708ca135581",
//         "title": "Test list 20",
//         "description": "tesing hi",
//         "createDate": "2020-07-24T11:58:32.376Z",
//         "restaurants": [
//             {
//                 "addedDate": "2021-07-02T11:28:58.204Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c71618716",
//                     "place": {
//                         "placeId": "ChIJGdMjF3ZdeUgRo6VHiJrIyMU",
//                         "vicinity": "Unit 2, Assembly Underground, Civic Court, Great George Street, Leeds",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.5487758,
//                                 53.8006943
//                             ]
//                         },
//                         "formatted_address": "Unit 2, Assembly Underground, Civic Court, Great George St, Leeds LS1 3ED, UK",
//                         "locality": "West Yorkshire",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LS1 3ED"
//                     },
//                     "title": "Slap & Pickle @ Assembly Underground",
//                     "slug": "slap-and-pickle-@-assembly-underground-3689",
//                     "ordering": 7722,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f5b"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "07525 401 328"
//                         }
//                     ],
//                     "email": "",
//                     "website": "https://www.slapandpickle.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f02",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f15",
//                         "5e457cd0c3b9e62c71617f17",
//                         "5e457cd0c3b9e62c71617f19",
//                         "5e457cd0c3b9e62c71617f1b",
//                         "5e457cd0c3b9e62c71617f1c",
//                         "5e457cd0c3b9e62c71617f1f",
//                         "5e457cd0c3b9e62c71617f20",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f25",
//                         "5e457cd0c3b9e62c71617f29",
//                         "5e457cd0c3b9e62c71617f2f",
//                         "5e457cd0c3b9e62c71617f3c",
//                         "5e457cd0c3b9e62c71617f40"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": 0
//                         },
//                         {
//                             "Main": 0
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "5.00"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93401410f5b4eaa68b7",
//                             "plateform": "tablefy",
//                             "rating": 0,
//                             "user_ratings_total": 0
//                         },
//                         {
//                             "_id": "5e54b89e517c3825dd2cee05",
//                             "plateform": "google",
//                             "rating": 4.7,
//                             "user_ratings_total": 31
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.638Z",
//                     "modifiedDate": "2020-02-13T16:45:20.638Z",
//                     "__v": 0,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731f004dedb527e8823256",
//                             "fileName": "b0f0ca11-eb96-5c91-ab86-0d748740394e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:00.294Z",
//                             "modifiedDate": "2020-03-19T07:28:00.294Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f1d4dedb527e8823a31",
//                             "fileName": "bebc5de0-5d30-5910-83af-382143666a40.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:29.926Z",
//                             "modifiedDate": "2020-03-19T07:28:29.926Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f614dedb527e88246f3",
//                             "fileName": "76ea4239-166c-5905-9905-14f8250dd619.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:37.998Z",
//                             "modifiedDate": "2020-03-19T07:29:37.998Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f624dedb527e88246f4",
//                             "fileName": "981e1b16-5a62-5347-b84d-5069a1bfcfba.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.233Z",
//                             "modifiedDate": "2020-03-19T07:29:38.233Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f624dedb527e88246f5",
//                             "fileName": "d79583e1-4f95-5a22-b8e0-9ce46b5a027a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.234Z",
//                             "modifiedDate": "2020-03-19T07:29:38.234Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f624dedb527e88246f6",
//                             "fileName": "73ce5fce-ac0b-5ef5-802d-30a140b7f8aa.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.235Z",
//                             "modifiedDate": "2020-03-19T07:29:38.235Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f624dedb527e88246f7",
//                             "fileName": "3304617d-f984-5b27-8da7-34a0b45e6e86.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.245Z",
//                             "modifiedDate": "2020-03-19T07:29:38.245Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f624dedb527e88246f8",
//                             "fileName": "a89aa9ec-4312-5776-bca0-9a460a0f45e2.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.254Z",
//                             "modifiedDate": "2020-03-19T07:29:38.254Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f624dedb527e88246f9",
//                             "fileName": "f581eabf-6b99-5a92-a9c4-083419d20004.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.255Z",
//                             "modifiedDate": "2020-03-19T07:29:38.255Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f624dedb527e88246fa",
//                             "fileName": "add4e5d1-4f58-50c8-b886-ebeec07c15ef.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.258Z",
//                             "modifiedDate": "2020-03-19T07:29:38.258Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 9,
//                             "_id": "5e731f624dedb527e88246fd",
//                             "fileName": "49cec3b1-8d3d-52dd-aec8-2164c823b376.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:38.282Z",
//                             "modifiedDate": "2020-03-19T07:29:38.282Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 2.35,
//                     "totalReviews": 31,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f20",
//                                     "title": "Baby-changing",
//                                     "slug": "baby-changing"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f02",
//                                     "title": "Child-friendly",
//                                     "slug": "child-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1f",
//                                     "title": "Disabled access",
//                                     "slug": "disabled-access"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f25",
//                                     "title": "Dog-friendly",
//                                     "slug": "dog-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2f",
//                                     "title": "Burgers",
//                                     "slug": "burgers"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3c",
//                                     "title": "Street food",
//                                     "slug": "street-food"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f19",
//                                     "title": "Bar dining",
//                                     "slug": "bar-dining"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f17",
//                                     "title": "Communal seating",
//                                     "slug": "communal-seating"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1b",
//                                     "title": "Fast casual",
//                                     "slug": "fast-casual"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1c",
//                                     "title": "Pop-up",
//                                     "slug": "pop-up"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f15",
//                                     "title": "Private dining",
//                                     "slug": "private-dining"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f29",
//                                     "title": "Family friendly",
//                                     "slug": "family-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f5b",
//                             "title": "American",
//                             "slug": "american"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-07-02T11:23:42.574Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c71618893",
//                     "place": {
//                         "placeId": "ChIJe_phYx1ceUgRGUCsJfkRcPw",
//                         "vicinity": null,
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.5507978,
//                                 53.8007873
//                             ]
//                         },
//                         "formatted_address": "39 Great George St, Leeds LS1 3BB, UK",
//                         "locality": "West Yorkshire",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LS1 3BB"
//                     },
//                     "title": "Line Thai Oriental",
//                     "slug": "line-thai-oriental-2987",
//                     "ordering": 455,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f47"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0113 247 1420"
//                         }
//                     ],
//                     "email": "",
//                     "website": "http://www.linethaioriental.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f12",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f1b",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f29",
//                         "5e457cd0c3b9e62c71617f27",
//                         "5e457cd0c3b9e62c71617f40",
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f0c"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "5.35"
//                         },
//                         {
//                             "Main": "9.14"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "19.49"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93001410f5b4eaa636a",
//                             "plateform": "tablefy",
//                             "rating": 9.36328125,
//                             "user_ratings_total": 8
//                         },
//                         {
//                             "_id": "5e5e12ed005c42375458a61f",
//                             "plateform": "google",
//                             "rating": 4.3,
//                             "user_ratings_total": 95
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.882Z",
//                     "modifiedDate": "2020-02-13T16:45:20.882Z",
//                     "__v": 0,
//                     "callCount": 77,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e85c5b3d5358c37f85d48e7",
//                             "fileName": "e0125c80-2830-53cc-8dc3-e2321174226a.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:03.387Z",
//                             "modifiedDate": "2020-04-02T11:00:03.387Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e85c5b4d5358c37f85d4902",
//                             "fileName": "fa3e7557-2c2c-5021-97c6-16a6323cb27e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.766Z",
//                             "modifiedDate": "2020-04-02T11:00:04.766Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e85c5b4d5358c37f85d4901",
//                             "fileName": "b4e4fc7e-87b4-58c0-8e2a-ef5ed6c91353.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.764Z",
//                             "modifiedDate": "2020-04-02T11:00:04.764Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e85c5b4d5358c37f85d4903",
//                             "fileName": "49cd9df9-512a-5a11-8ca6-a7fe54ff4264.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.767Z",
//                             "modifiedDate": "2020-04-02T11:00:04.767Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e85c5b4d5358c37f85d4904",
//                             "fileName": "48c4cc13-3138-5e03-a6e4-337821ee1a2c.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.768Z",
//                             "modifiedDate": "2020-04-02T11:00:04.768Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e85c5b4d5358c37f85d4905",
//                             "fileName": "58a5c5a3-acf7-5a79-8145-420950889b73.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.769Z",
//                             "modifiedDate": "2020-04-02T11:00:04.769Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e85c5b4d5358c37f85d4906",
//                             "fileName": "e92cf491-eed3-5120-af6c-545ebb516b45.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.769Z",
//                             "modifiedDate": "2020-04-02T11:00:04.769Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e85c5b4d5358c37f85d4907",
//                             "fileName": "f15d4777-7a27-5f76-9212-38af2e07b388.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.770Z",
//                             "modifiedDate": "2020-04-02T11:00:04.770Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.4908203125,
//                     "totalReviews": 103,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f12",
//                                     "title": "Halal options",
//                                     "slug": "halal-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1b",
//                                     "title": "Fast casual",
//                                     "slug": "fast-casual"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f27",
//                                     "title": "Bring your own booze",
//                                     "slug": "bring-your-own-booze"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f29",
//                                     "title": "Family friendly",
//                                     "slug": "family-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0c",
//                                     "title": "Discount",
//                                     "slug": "discount"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f47",
//                             "title": "Thai",
//                             "slug": "thai"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-04-26T11:45:37.014Z",
//                 "restaurant": {
//                     "_id": "5e8342f0426bd609283151b6",
//                     "place": {
//                         "placeId": "ChIJZ_YISduC-DkRvCxsj-Yw40M",
//                         "vicinity": "Kolkata, West Bengal, India",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 88.35713,
//                                 22.50644
//                             ]
//                         },
//                         "formatted_address": "Kolkata, West Bengal, India",
//                         "city": "",
//                         "locality": "Kolkata",
//                         "state": "West Bengal",
//                         "country": "India",
//                         "postal_code": "226016"
//                     },
//                     "title": "FOODieKolkata",
//                     "slug": "foodiekolkata",
//                     "ordering": 1417,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f43"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "+918423541453"
//                         }
//                     ],
//                     "email": "deepbn9@gmail.com",
//                     "website": "https://google.com",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f33",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f0d",
//                         "5e457cd0c3b9e62c71617f0e",
//                         "5e457cd0c3b9e62c71617f2f",
//                         "5e457cd0c3b9e62c71617f30",
//                         "5e457cd0c3b9e62c71617f3a",
//                         "5e457cd0c3b9e62c71617f3c",
//                         "5f14646fb55762717d1f5f08",
//                         "5ed0e95ec38ee77c588ac649",
//                         "5ed0e9c4c38ee77c588ac66f",
//                         "5ed0e9cbc38ee77c588ac673",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f12",
//                         "5e457cd0c3b9e62c71617f08",
//                         "5e457cd0c3b9e62c71617f1b",
//                         "5e457cd0c3b9e62c71617f15",
//                         "5e457cd0c3b9e62c71617f1e",
//                         "5e457cd0c3b9e62c71617f28",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f40",
//                         "5e457cd0c3b9e62c71617f29",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f05",
//                         "5e457cd0c3b9e62c71617f0c",
//                         "5e457cd0c3b9e62c71617f2a",
//                         "5e457cd0c3b9e62c71617f2c"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Dishes": "10.00"
//                         },
//                         {
//                             "Side": "6.00"
//                         },
//                         {
//                             "Dessert": "5.00"
//                         },
//                         {
//                             "Drink": "10.00"
//                         },
//                         {
//                             "restaurantPrice": 41
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "callCount": 0,
//                     "priceCategory": "Small plates",
//                     "virtualTourLink": "&lt;iframe src=\"https://www.google.com/maps/embed?pb=!4v1592389454593!6m8!1m7!1sCAoSLEFGMVFpcE1MeFA1eWoxSVlnOVdIZ3dReFJBYlh3czZlWVZ3d0dUZTV3MmZM!2m2!1d22.50652641389507!2d88.36708483218035!3f259.9693289724221!4f-2.750035865624028!5f0.7820865974627469\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0;\" allowfullscreen=\"\" aria-hidden=\"false\" tabindex=\"0\">&lt;/iframe>",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e8342f0426bd609283151b7",
//                             "plateform": "google"
//                         },
//                         {
//                             "_id": "60af99d99a6b950ad122fc9f",
//                             "plateform": "tablefy",
//                             "rating": 0,
//                             "user_ratings_total": 34
//                         }
//                     ],
//                     "createDate": "2020-03-31T13:17:36.458Z",
//                     "modifiedDate": "2021-06-21T07:31:09.649Z",
//                     "__v": 0,
//                     "coins": 30200,
//                     "restaurantView": 266,
//                     "contentScore": 82,
//                     "openDate": null,
//                     "credits": 80,
//                     "photoList": [
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5f05ac4ecb4b325fccd6c33f",
//                             "fileName": "file-1594207309618.jpg",
//                             "s3Directory": "restaurants",
//                             "status": "active",
//                             "fileOriginalName": "casey-lee-awj7sRviVXo-unsplash.jpg",
//                             "type": "image/jpeg",
//                             "path": "/restaurants//file-1594207309618.jpg",
//                             "sightengineApproved": true,
//                             "mediaType": "restaurant",
//                             "inspected": true,
//                             "description": "",
//                             "createDate": "2020-07-08T11:21:50.478Z",
//                             "modifiedDate": "2020-07-08T11:21:50.478Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5f05ac60cb4b325fccd6c340",
//                             "fileName": "file-1594207327745.jpg",
//                             "s3Directory": "restaurants",
//                             "status": "active",
//                             "fileOriginalName": "alex-munsell-Yr4n8O_3UPc-unsplash.jpg",
//                             "type": "image/jpeg",
//                             "path": "/restaurants//file-1594207327745.jpg",
//                             "sightengineApproved": true,
//                             "mediaType": "restaurant",
//                             "inspected": true,
//                             "description": "steak master",
//                             "createDate": "2020-07-08T11:22:08.752Z",
//                             "modifiedDate": "2020-07-08T11:22:08.752Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "607d3b3e538804430d8c094e",
//                             "fileName": "file-1618819902235.jpg",
//                             "s3Directory": "restaurants",
//                             "status": "active",
//                             "fileOriginalName": "chicken-biryani-recipe.jpg",
//                             "type": "image/jpeg",
//                             "path": "/restaurants//file-1618819902235.jpg",
//                             "sightengineApproved": true,
//                             "mediaType": "restaurant",
//                             "inspected": true,
//                             "description": "",
//                             "createDate": "2021-04-19T08:11:42.406Z",
//                             "modifiedDate": "2021-04-19T08:11:42.406Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5f05ad06cb4b325fccd6c348",
//                             "fileName": "file-1594207493190.jpg",
//                             "s3Directory": "restaurants",
//                             "status": "active",
//                             "fileOriginalName": "taylor-kiser-EvoIiaIVRzU-unsplash.jpg",
//                             "type": "image/jpeg",
//                             "path": "/restaurants//file-1594207493190.jpg",
//                             "sightengineApproved": true,
//                             "mediaType": "restaurant",
//                             "inspected": true,
//                             "description": "",
//                             "createDate": "2020-07-08T11:24:54.691Z",
//                             "modifiedDate": "2020-07-08T11:24:54.691Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "607d7d50538804430d8c0b19",
//                             "fileName": "file-1618836815837.jpg",
//                             "s3Directory": "restaurants",
//                             "status": "active",
//                             "fileOriginalName": "re-1.jpg",
//                             "type": "image/jpeg",
//                             "path": "/restaurants//file-1618836815837.jpg",
//                             "sightengineApproved": true,
//                             "mediaType": "userSuggestion",
//                             "inspected": true,
//                             "description": "",
//                             "createDate": "2021-04-19T12:53:36.123Z",
//                             "modifiedDate": "2021-04-19T12:53:36.123Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 0,
//                     "totalReviews": 34,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f33",
//                                     "title": "chicken",
//                                     "slug": "chicken"
//                                 }
//                             ],
//                             "category": ""
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0d",
//                                     "title": "Michelin Star",
//                                     "slug": "michelin-star"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0e",
//                                     "title": "Michelin-recognised",
//                                     "slug": "michelin-recognised"
//                                 }
//                             ],
//                             "category": "Awards"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2f",
//                                     "title": "Burgers",
//                                     "slug": "burgers"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f30",
//                                     "title": "Curry",
//                                     "slug": "curry"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3a",
//                                     "title": "Seafood",
//                                     "slug": "seafood"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3c",
//                                     "title": "Street food",
//                                     "slug": "street-food"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5f14646fb55762717d1f5f08",
//                                     "title": "Test100",
//                                     "slug": "test100"
//                                 },
//                                 {
//                                     "_id": "5ed0e95ec38ee77c588ac649",
//                                     "title": "test1",
//                                     "slug": "test1"
//                                 },
//                                 {
//                                     "_id": "5ed0e9c4c38ee77c588ac66f",
//                                     "title": "test2",
//                                     "slug": "test2"
//                                 },
//                                 {
//                                     "_id": "5ed0e9cbc38ee77c588ac673",
//                                     "title": "test3",
//                                     "slug": "test3"
//                                 }
//                             ],
//                             "category": "Cuisine"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f12",
//                                     "title": "Halal options",
//                                     "slug": "halal-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f08",
//                                     "title": "Al Fresco",
//                                     "slug": "al-fresco"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1b",
//                                     "title": "Fast casual",
//                                     "slug": "fast-casual"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f15",
//                                     "title": "Private dining",
//                                     "slug": "private-dining"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1e",
//                                     "title": "Sharing plates",
//                                     "slug": "sharing-plates"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f28",
//                                     "title": "Brunch",
//                                     "slug": "brunch"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f29",
//                                     "title": "Family friendly",
//                                     "slug": "family-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f05",
//                                     "title": "Afternoon tea",
//                                     "slug": "afternoon-tea"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0c",
//                                     "title": "Discount",
//                                     "slug": "discount"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2a",
//                                     "title": "Local workers discount",
//                                     "slug": "local-workers-discount"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2c",
//                                     "title": "NHS",
//                                     "slug": "nhs"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f43",
//                             "title": "Indian",
//                             "slug": "indian"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-04-26T08:05:34.827Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c716185c1",
//                     "place": {
//                         "placeId": "ChIJb8QnsXBHnDkRQXu-nyoweBc",
//                         "vicinity": "Kanpur",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 80.3318736,
//                                 26.449923
//                             ]
//                         },
//                         "formatted_address": "Kanpur, Uttar Pradesh, India",
//                         "city": "Kanpur",
//                         "locality": "Kanpur Nagar",
//                         "state": "Uttar Pradesh",
//                         "country": "India"
//                     },
//                     "title": "Dino",
//                     "slug": "dino-279",
//                     "ordering": 5796,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f43",
//                         "5e457cf4c3b9e62c71617f61"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0115 950 4488"
//                         },
//                         {
//                             "bookNowTel": "0115 754 0376"
//                         },
//                         {
//                             "bookingRequestMobile": "7967933185"
//                         }
//                     ],
//                     "email": "info@dinobar.co.uk",
//                     "website": "http://dinobar.co.uk/",
//                     "tags": [
//                         "5e5a2ce2a6d2e9387d4aa42b",
//                         "5e5a2ce2a6d2e9387d4aa42c",
//                         "5e5a2ce2a6d2e9387d4aa416",
//                         "5e5a2ce2a6d2e9387d4aa418",
//                         "5e5a2ce2a6d2e9387d4aa41b",
//                         "5e5a2ce2a6d2e9387d4aa421",
//                         "5e5a2ce2a6d2e9387d4aa422",
//                         "5e5a2ce2a6d2e9387d4aa428",
//                         "5e457cd0c3b9e62c71617f10",
//                         "5e457cd0c3b9e62c71617f0f",
//                         "5e457cd0c3b9e62c71617f0d",
//                         "5e5a2ce2a6d2e9387d4aa42d",
//                         "5e5a2ce2a6d2e9387d4aa3fd",
//                         "5e457cd0c3b9e62c71617f3b",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e5a2ce2a6d2e9387d4aa405",
//                         "5e5a2ce2a6d2e9387d4aa403",
//                         "5e457cd0c3b9e62c71617f19",
//                         "5e5a2ce2a6d2e9387d4aa409",
//                         "5e5a2ce2a6d2e9387d4aa407",
//                         "5e5a2ce2a6d2e9387d4aa40c",
//                         "5e5a2ce2a6d2e9387d4aa40b",
//                         "5e457cd0c3b9e62c71617f0b",
//                         "5e5a2ce2a6d2e9387d4aa434",
//                         "5e5a2ce2a6d2e9387d4aa433",
//                         "5e457cd0c3b9e62c71617f29",
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e5a2ce2a6d2e9387d4aa42f",
//                         "5e5a2ce2a6d2e9387d4aa42e",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f00",
//                         "5e5a2ce2a6d2e9387d4aa437",
//                         "5e5a2ce2a6d2e9387d4aa432",
//                         "5e457cd0c3b9e62c71617f05"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Starter": "6.80"
//                         },
//                         {
//                             "Main": "10.47"
//                         },
//                         {
//                             "Side": "3.13"
//                         },
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "restaurantPrice": 25.4
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "60579d5087a7377ef1220530",
//                             "plateform": "tablefy",
//                             "rating": 9.936855643946274,
//                             "user_ratings_total": 133
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.458Z",
//                     "modifiedDate": "2021-05-18T19:50:46.424Z",
//                     "__v": 0,
//                     "callCount": 1381,
//                     "virtualTourLink": "&lt;iframe src=\"https://www.google.com/maps/embed?pb=!4v1589330470905!6m8!1m7!1sJkfiMQtI3BiRJQz3CuSwLg!2m2!1d52.95315802699564!2d-1.144946277009653!3f16.884785740273713!4f-16.471021993086183!5f0.7820865974627469\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0;\" allowfullscreen=\"\" aria-hidden=\"false\" tabindex=\"0\">&lt;/iframe>",
//                     "priceCategory": "A la carte",
//                     "coins": 1775,
//                     "restaurantView": 339,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef54dedb527e8823018",
//                             "fileName": "511d1120-2d7f-5e42-90d4-6e2576b8002c.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:49.355Z",
//                             "modifiedDate": "2020-03-19T07:27:49.355Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f194dedb527e882386f",
//                             "fileName": "3f08c4ca-528a-5149-8e63-cd8d60f2bd57.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:25.698Z",
//                             "modifiedDate": "2020-03-19T07:28:25.698Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f474dedb527e8824067",
//                             "fileName": "783c0178-da0c-58eb-b757-2298c42f3595.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.085Z",
//                             "modifiedDate": "2020-03-19T07:29:11.085Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f474dedb527e882406c",
//                             "fileName": "ee421a28-ac07-5f15-ac96-9192d4b33eff.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.544Z",
//                             "modifiedDate": "2020-03-19T07:29:11.544Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f474dedb527e882406d",
//                             "fileName": "5c988524-1a5f-5251-be77-58029a97358e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.555Z",
//                             "modifiedDate": "2020-03-19T07:29:11.555Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f474dedb527e8824069",
//                             "fileName": "7222a835-115d-58f5-a578-d1e9ce1c2f1b.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.516Z",
//                             "modifiedDate": "2020-03-19T07:29:11.516Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f474dedb527e8824068",
//                             "fileName": "9f56bebc-e3cc-5008-8a34-2dbcaeca21a9.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.515Z",
//                             "modifiedDate": "2020-03-19T07:29:11.515Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f474dedb527e8824071",
//                             "fileName": "0d6d98e0-d13a-504d-aa52-90cdaaa04791.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.595Z",
//                             "modifiedDate": "2020-03-19T07:29:11.595Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f474dedb527e8824066",
//                             "fileName": "974ee8ae-216a-593f-8c1f-51f1baa0c21e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.036Z",
//                             "modifiedDate": "2020-03-19T07:29:11.036Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "607d3ae5538804430d8c094c",
//                             "fileName": "file-1618819813153.jpg",
//                             "s3Directory": "restaurants",
//                             "status": "active",
//                             "fileOriginalName": "chicken-biryani-recipe.jpg",
//                             "type": "image/jpeg",
//                             "path": "/restaurants//file-1618819813153.jpg",
//                             "sightengineApproved": true,
//                             "mediaType": "restaurant",
//                             "inspected": true,
//                             "description": "",
//                             "createDate": "2021-04-19T08:10:13.310Z",
//                             "modifiedDate": "2021-04-19T08:10:13.310Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 9,
//                             "_id": "5e731f474dedb527e882406b",
//                             "fileName": "90bc638f-ba57-5ea1-9fdb-2acffd7ec502.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.540Z",
//                             "modifiedDate": "2020-03-19T07:29:11.540Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 10,
//                             "_id": "5e731f474dedb527e882406e",
//                             "fileName": "9dc9b61a-6359-5568-b615-c07358690677.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:11.564Z",
//                             "modifiedDate": "2020-03-19T07:29:11.564Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.968427821973137,
//                     "totalReviews": 133,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa42b",
//                                     "title": "Background music",
//                                     "slug": "background-music"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa42c",
//                                     "title": "Child-free zone",
//                                     "slug": "child-free-zone"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa416",
//                                     "title": "Comprehensive wine list",
//                                     "slug": "comprehensive-wine-list"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa418",
//                                     "title": "In-house bar",
//                                     "slug": "in-house-bar"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa41b",
//                                     "title": "Local produce",
//                                     "slug": "local-produce"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa421",
//                                     "title": "Piano",
//                                     "slug": "piano"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa422",
//                                     "title": "Wheelchair access",
//                                     "slug": "wheelchair-access"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa428",
//                                     "title": "Wi-Fi",
//                                     "slug": "wi-fi"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f10",
//                                     "title": "AA Rosette",
//                                     "slug": "aa-rosette"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0f",
//                                     "title": "Good Food Guide",
//                                     "slug": "good-food-guide"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0d",
//                                     "title": "Michelin Star",
//                                     "slug": "michelin-star"
//                                 }
//                             ],
//                             "category": "Awards"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa42d",
//                                     "title": "Cocktails",
//                                     "slug": "cocktails"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa3fd",
//                                     "title": "Seasonal produce",
//                                     "slug": "seasonal-produce"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3b",
//                                     "title": "Steak",
//                                     "slug": "steak"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa405",
//                                     "title": "Low carb dishes",
//                                     "slug": "low-carb-dishes"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa403",
//                                     "title": "Vegetarian",
//                                     "slug": "vegetarian-1"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f19",
//                                     "title": "Bar dining",
//                                     "slug": "bar-dining"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa409",
//                                     "title": "Express lunch available",
//                                     "slug": "express-lunch-available"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa407",
//                                     "title": "Live music/dance",
//                                     "slug": "live-musicdance"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa40c",
//                                     "title": "Private hire",
//                                     "slug": "private-hire"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa40b",
//                                     "title": "Specials board",
//                                     "slug": "specials-board"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0b",
//                                     "title": "Virtual Tours",
//                                     "slug": "virtual-tours"
//                                 }
//                             ],
//                             "category": "Misc"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa434",
//                                     "title": "Afternoon tea served",
//                                     "slug": "afternoon-tea-served"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa433",
//                                     "title": "Business/discrete",
//                                     "slug": "businessdiscrete"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f29",
//                                     "title": "Family friendly",
//                                     "slug": "family-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa42f",
//                                     "title": "Late night bar licence",
//                                     "slug": "late-night-bar-licence"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa42e",
//                                     "title": "Late night opening",
//                                     "slug": "late-night-opening"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f00",
//                                     "title": "Pre-theatre / Early bird",
//                                     "slug": "pre-theatre-early-bird"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa437",
//                                     "title": "Pre-theatre dining",
//                                     "slug": "pre-theatre-dining"
//                                 },
//                                 {
//                                     "_id": "5e5a2ce2a6d2e9387d4aa432",
//                                     "title": "Suitable for office parties",
//                                     "slug": "suitable-for-office-parties"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f05",
//                                     "title": "Afternoon tea",
//                                     "slug": "afternoon-tea"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f43",
//                             "title": "Indian",
//                             "slug": "indian"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f61",
//                             "title": "Global",
//                             "slug": "global"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-04-14T06:30:18.497Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c71618495",
//                     "place": {
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.481545991,
//                                 52.92338115
//                             ]
//                         },
//                         "placeId": null,
//                         "vicinity": "3 Friar Gate, Derby"
//                     },
//                     "title": "The Distillery",
//                     "slug": "the-distillery-1683",
//                     "ordering": 3300,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f44",
//                         "5e457cf4c3b9e62c71617f5b",
//                         "5e457cf4c3b9e62c71617f61"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "01332 293 899"
//                         }
//                     ],
//                     "email": "manager@thedistilleryderby.co.uk",
//                     "website": "http://www.thedistilleryderby.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f20",
//                         "5e457cd0c3b9e62c71617f1f",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f24",
//                         "5e457cd0c3b9e62c71617f2f",
//                         "5e457cd0c3b9e62c71617f3b",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f19",
//                         "5e457cd0c3b9e62c71617f15",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f40",
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f04"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": 0
//                         },
//                         {
//                             "Main": "12.57"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "17.57"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "60af75579a6b950ad122fbf3",
//                             "plateform": "tablefy",
//                             "rating": 9.52191162109375,
//                             "user_ratings_total": 16
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.282Z",
//                     "modifiedDate": "2020-02-13T16:45:20.282Z",
//                     "__v": 0,
//                     "callCount": 248,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "restaurantView": 32,
//                     "hygieneInspectionUrl": null,
//                     "openDate": null,
//                     "contentScore": 52.6,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e85c5b3d5358c37f85d48e6",
//                             "fileName": "45eabb14-0054-5ba8-bc65-db01971c97f7.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:03.386Z",
//                             "modifiedDate": "2020-04-02T11:00:03.386Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e85c5b4d5358c37f85d48ff",
//                             "fileName": "12a2f1d9-728b-5951-91ff-f06c65edcae7.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:04.581Z",
//                             "modifiedDate": "2020-04-02T11:00:04.581Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e85c5b7d5358c37f85d495b",
//                             "fileName": "79d9679f-2670-515e-904f-7d1aa2950643.JPG",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.508Z",
//                             "modifiedDate": "2020-04-02T11:00:07.508Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e85c5b7d5358c37f85d495c",
//                             "fileName": "058d62a9-e212-5bd9-92a1-916257e36622.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.509Z",
//                             "modifiedDate": "2020-04-02T11:00:07.509Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e85c5b7d5358c37f85d495d",
//                             "fileName": "0e02d1c1-5a6f-5b24-86e7-08b99ae552a8.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.510Z",
//                             "modifiedDate": "2020-04-02T11:00:07.510Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e85c5b7d5358c37f85d495e",
//                             "fileName": "2ffe4f47-c7a7-5d8c-a1da-56de79b3021f.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.511Z",
//                             "modifiedDate": "2020-04-02T11:00:07.511Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e85c5b7d5358c37f85d495f",
//                             "fileName": "565af8b4-f35a-575b-91bf-460a85a36b5e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.512Z",
//                             "modifiedDate": "2020-04-02T11:00:07.512Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e85c5b7d5358c37f85d4960",
//                             "fileName": "2165f2ba-f48e-521a-ab04-7f26db7fe758.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.513Z",
//                             "modifiedDate": "2020-04-02T11:00:07.513Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e85c5b7d5358c37f85d495a",
//                             "fileName": "c2c0b8a1-6b4a-5a7e-a337-a5710db6745f.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.507Z",
//                             "modifiedDate": "2020-04-02T11:00:07.507Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e85c5b7d5358c37f85d4961",
//                             "fileName": "2b821be2-d990-574b-977e-1fc14f29fbfd.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-04-02T11:00:07.514Z",
//                             "modifiedDate": "2020-04-02T11:00:07.514Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.760955810546875,
//                     "totalReviews": 16,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f20",
//                                     "title": "Baby-changing",
//                                     "slug": "baby-changing"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1f",
//                                     "title": "Disabled access",
//                                     "slug": "disabled-access"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f24",
//                                     "title": "Round tables",
//                                     "slug": "round-tables"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2f",
//                                     "title": "Burgers",
//                                     "slug": "burgers"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3b",
//                                     "title": "Steak",
//                                     "slug": "steak"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f19",
//                                     "title": "Bar dining",
//                                     "slug": "bar-dining"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f15",
//                                     "title": "Private dining",
//                                     "slug": "private-dining"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f44",
//                             "title": "Malaysian",
//                             "slug": "malaysian"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f5b",
//                             "title": "American",
//                             "slug": "american"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f61",
//                             "title": "Global",
//                             "slug": "global"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-04-14T06:29:36.299Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c71618492",
//                     "place": {
//                         "placeId": "ChIJg4NTijCseUgRdQKSwvqiCko",
//                         "vicinity": "Town Street, Newark",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -0.8915606999999999,
//                                 53.26632589999999
//                             ]
//                         },
//                         "formatted_address": "Town St, Newark NG22 0RS, UK",
//                         "locality": "Nottinghamshire",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "NG22 0RS"
//                     },
//                     "title": "The Duke William",
//                     "slug": "the-duke-william-1651",
//                     "ordering": 362,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f42",
//                         "5e457cf4c3b9e62c71617f7e"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "01522 532753"
//                         }
//                     ],
//                     "email": "dukewilliam@eversosensible.com",
//                     "website": "https://www.dukewilliamlincoln.com/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f00",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f09",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f13",
//                         "5e457cd0c3b9e62c71617f2e",
//                         "5e457cd0c3b9e62c71617f36",
//                         "5e457cd0c3b9e62c71617f3a",
//                         "5e457cd0c3b9e62c71617f40"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": 0
//                         },
//                         {
//                             "Main": "13.83"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "18.83"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5e4fd93001410f5b4eaa64d6",
//                             "plateform": "tablefy",
//                             "rating": 9.726806640625,
//                             "user_ratings_total": 12
//                         },
//                         {
//                             "_id": "5e5e12b5005c42375458a5c2",
//                             "plateform": "google",
//                             "rating": 4.4,
//                             "user_ratings_total": 70
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.281Z",
//                     "modifiedDate": "2020-02-13T16:45:20.281Z",
//                     "__v": 0,
//                     "callCount": 240,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "restaurantView": 15,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef94dedb527e8823114",
//                             "fileName": "35f977e5-ebba-5856-816a-48d9d2e9616f.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:53.462Z",
//                             "modifiedDate": "2020-03-19T07:27:53.462Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f164dedb527e88237aa",
//                             "fileName": "457cbad6-76a1-5675-946b-0bee155aa655.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:22.777Z",
//                             "modifiedDate": "2020-03-19T07:28:22.777Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f3a4dedb527e8823e10",
//                             "fileName": "fe0203ff-70cc-5e18-a3c8-df294a1b500d.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:58.346Z",
//                             "modifiedDate": "2020-03-19T07:28:58.346Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f3a4dedb527e8823e11",
//                             "fileName": "5bc89036-e8bf-57cd-ba9d-b2381763cbbe.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:58.354Z",
//                             "modifiedDate": "2020-03-19T07:28:58.354Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f3a4dedb527e8823e13",
//                             "fileName": "fad677c8-8c38-5711-8050-2909f8dffedc.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:58.356Z",
//                             "modifiedDate": "2020-03-19T07:28:58.356Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f3a4dedb527e8823e14",
//                             "fileName": "1d08bf8b-130e-5a81-a0f3-997b67422891.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:58.364Z",
//                             "modifiedDate": "2020-03-19T07:28:58.364Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f3a4dedb527e8823e12",
//                             "fileName": "590d0cdc-3926-5d1a-a9ed-67b56d1b9b95.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:58.355Z",
//                             "modifiedDate": "2020-03-19T07:28:58.355Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f3a4dedb527e8823e15",
//                             "fileName": "b348be1d-57c2-564d-9f15-6bacd213d422.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:58.365Z",
//                             "modifiedDate": "2020-03-19T07:28:58.365Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.63170166015625,
//                     "totalReviews": 82,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2e",
//                                     "title": "BBQ / Grill",
//                                     "slug": "bbq-grill"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f36",
//                                     "title": "Pancakes",
//                                     "slug": "pancakes"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3a",
//                                     "title": "Seafood",
//                                     "slug": "seafood"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f13",
//                                     "title": "Plenty for veggies",
//                                     "slug": "plenty-for-veggies"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f00",
//                                     "title": "Pre-theatre / Early bird",
//                                     "slug": "pre-theatre-early-bird"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f09",
//                                     "title": "Breakfast",
//                                     "slug": "breakfast"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f42",
//                             "title": "British",
//                             "slug": "british"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f7e",
//                             "title": "Western European",
//                             "slug": "western-european"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2021-03-01T22:34:17.648Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c7161869f",
//                     "place": {
//                         "placeId": "ChIJc3f6-BtceUgR8wHBxyeiTX0",
//                         "vicinity": "1 Albion Place, Leeds",
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.5447417,
//                                 53.79801390000001
//                             ]
//                         },
//                         "formatted_address": "1 Albion Pl, Leeds LS1 6JL, UK",
//                         "locality": "West Yorkshire",
//                         "state": "England",
//                         "country": "United Kingdom",
//                         "postal_code": "LS1 6JL"
//                     },
//                     "title": "Bills Restaurant",
//                     "slug": "bill's-leeds-2898",
//                     "ordering": 7469,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f44",
//                         "5e457cf4c3b9e62c71617f7e"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0113 245 2010"
//                         },
//                         {
//                             "bookNowTel": "0113 415 2010"
//                         }
//                     ],
//                     "email": "leeds@bills-email.co.uk",
//                     "website": "https://bills-website.co.uk/restaurants/leeds/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f2e",
//                         "5e457cd0c3b9e62c71617f2f",
//                         "5e457cd0c3b9e62c71617f34",
//                         "5e457cd0c3b9e62c71617f35",
//                         "5e457cd0c3b9e62c71617f36",
//                         "5e457cd0c3b9e62c71617f38",
//                         "5e457cd0c3b9e62c71617f3a",
//                         "5e457cd0c3b9e62c71617f3b",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f13",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f08",
//                         "5e457cd0c3b9e62c71617f20",
//                         "5e457cd0c3b9e62c71617f1f",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f24",
//                         "5e457cd0c3b9e62c71617f29",
//                         "5e457cd0c3b9e62c71617f05",
//                         "5e457cd0c3b9e62c71617f09",
//                         "5e457cd0c3b9e62c71617f01",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f00",
//                         "5e457cd0c3b9e62c71617f0c"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "6.58"
//                         },
//                         {
//                             "Main": "12.07"
//                         },
//                         {
//                             "Side": "3.10"
//                         },
//                         {
//                             "restaurantPrice": "26.75"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5f75a60922c63d3800ba375f",
//                             "plateform": "tablefy",
//                             "rating": 9.745761081425242,
//                             "user_ratings_total": 84
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.586Z",
//                     "modifiedDate": "2020-02-13T16:45:20.586Z",
//                     "__v": 0,
//                     "callCount": 1125,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 50,
//                     "restaurantView": 19,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731efa4dedb527e8823153",
//                             "fileName": "1a72479f-280c-54e8-92e4-e7800b942375.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:54.258Z",
//                             "modifiedDate": "2020-03-19T07:27:54.258Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f194dedb527e8823853",
//                             "fileName": "dcfba382-f508-5ca1-bda1-cab40d04b098.png",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:25.536Z",
//                             "modifiedDate": "2020-03-19T07:28:25.536Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f434dedb527e8823f5d",
//                             "fileName": "58f61acb-1e14-591d-aa33-477343740ad2.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.198Z",
//                             "modifiedDate": "2020-03-19T07:29:07.198Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f434dedb527e8823f57",
//                             "fileName": "1ece81d2-830b-5284-8816-d753afb1abea.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.099Z",
//                             "modifiedDate": "2020-03-19T07:29:07.099Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f434dedb527e8823f54",
//                             "fileName": "b5d64f17-9c1e-5f6c-850c-deb83b343dd7.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.096Z",
//                             "modifiedDate": "2020-03-19T07:29:07.096Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f434dedb527e8823f50",
//                             "fileName": "ce399cea-0143-5cef-a58c-053760b24355.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.071Z",
//                             "modifiedDate": "2020-03-19T07:29:07.071Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f434dedb527e8823f53",
//                             "fileName": "90c3a124-18a6-5f09-a90a-4c49a7f84f94.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.094Z",
//                             "modifiedDate": "2020-03-19T07:29:07.094Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f434dedb527e8823f55",
//                             "fileName": "53705955-fa67-57c7-8f9b-a41eb030f24e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.098Z",
//                             "modifiedDate": "2020-03-19T07:29:07.098Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f434dedb527e8823f58",
//                             "fileName": "27f2d236-05ec-532d-8f31-3fc5228f17c2.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.134Z",
//                             "modifiedDate": "2020-03-19T07:29:07.134Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f434dedb527e8823f51",
//                             "fileName": "60c4683f-cf8c-5990-8241-aaf2e43f97bc.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.080Z",
//                             "modifiedDate": "2020-03-19T07:29:07.080Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 9,
//                             "_id": "5e731f434dedb527e8823f56",
//                             "fileName": "c12624f2-a317-5742-8b8e-af8eb88d6942.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.099Z",
//                             "modifiedDate": "2020-03-19T07:29:07.099Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 10,
//                             "_id": "5e731f434dedb527e8823f52",
//                             "fileName": "896dbb3f-94b5-530d-947f-ecc0397ac38e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.080Z",
//                             "modifiedDate": "2020-03-19T07:29:07.080Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 11,
//                             "_id": "5e731f434dedb527e8823f4f",
//                             "fileName": "9ffb907b-ae45-5a2c-981f-a5c0306e20a9.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:07.070Z",
//                             "modifiedDate": "2020-03-19T07:29:07.070Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.872880540712621,
//                     "totalReviews": 84,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f20",
//                                     "title": "Baby-changing",
//                                     "slug": "baby-changing"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1f",
//                                     "title": "Disabled access",
//                                     "slug": "disabled-access"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f24",
//                                     "title": "Round tables",
//                                     "slug": "round-tables"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2e",
//                                     "title": "BBQ / Grill",
//                                     "slug": "bbq-grill"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2f",
//                                     "title": "Burgers",
//                                     "slug": "burgers"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f34",
//                                     "title": "Full English",
//                                     "slug": "full-english"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f35",
//                                     "title": "Healthy",
//                                     "slug": "healthy"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f36",
//                                     "title": "Pancakes",
//                                     "slug": "pancakes"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f38",
//                                     "title": "Pie",
//                                     "slug": "pie"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3a",
//                                     "title": "Seafood",
//                                     "slug": "seafood"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3b",
//                                     "title": "Steak",
//                                     "slug": "steak"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f13",
//                                     "title": "Plenty for veggies",
//                                     "slug": "plenty-for-veggies"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f08",
//                                     "title": "Al Fresco",
//                                     "slug": "al-fresco"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f29",
//                                     "title": "Family friendly",
//                                     "slug": "family-friendly"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f01",
//                                     "title": "Large group 20+",
//                                     "slug": "large-group-20+"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f00",
//                                     "title": "Pre-theatre / Early bird",
//                                     "slug": "pre-theatre-early-bird"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f05",
//                                     "title": "Afternoon tea",
//                                     "slug": "afternoon-tea"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f09",
//                                     "title": "Breakfast",
//                                     "slug": "breakfast"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0c",
//                                     "title": "Discount",
//                                     "slug": "discount"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f44",
//                             "title": "Malaysian",
//                             "slug": "malaysian"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f7e",
//                             "title": "Western European",
//                             "slug": "western-european"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "addedDate": "2020-12-31T12:40:21.348Z",
//                 "restaurant": {
//                     "_id": "5e457d20c3b9e62c716187dd",
//                     "place": {
//                         "location": {
//                             "type": "Point",
//                             "coordinates": [
//                                 -1.146702,
//                                 52.951947
//                             ]
//                         },
//                         "placeId": null,
//                         "vicinity": "15 Byard Lane, Nottingham"
//                     },
//                     "title": "Cross Keys",
//                     "slug": "cross-keys-656",
//                     "ordering": 5372,
//                     "region": null,
//                     "county": null,
//                     "city": null,
//                     "cuisine": [
//                         "5e457cf4c3b9e62c71617f42",
//                         "5e457cf4c3b9e62c71617f61"
//                     ],
//                     "phones": [
//                         {
//                             "restaurantTel": "0115 941 7898"
//                         },
//                         {
//                             "bookNowTel": "0115 754 1409"
//                         }
//                     ],
//                     "email": "info@crosskeysnottingham.co.uk",
//                     "website": "http://www.crosskeysnottingham.co.uk/",
//                     "tags": [
//                         "5e457cd0c3b9e62c71617f2f",
//                         "5e457cd0c3b9e62c71617f31",
//                         "5e457cd0c3b9e62c71617f32",
//                         "5e457cd0c3b9e62c71617f34",
//                         "5e457cd0c3b9e62c71617f37",
//                         "5e457cd0c3b9e62c71617f38",
//                         "5e457cd0c3b9e62c71617f3b",
//                         "5e457cd0c3b9e62c71617f06",
//                         "5e457cd0c3b9e62c71617f11",
//                         "5e457cd0c3b9e62c71617f07",
//                         "5e457cd0c3b9e62c71617f13",
//                         "5e457cd0c3b9e62c71617f0a",
//                         "5e457cd0c3b9e62c71617f08",
//                         "5e457cd0c3b9e62c71617f15",
//                         "5e457cd0c3b9e62c71617f1d",
//                         "5e457cd0c3b9e62c71617f20",
//                         "5e457cd0c3b9e62c71617f1f",
//                         "5e457cd0c3b9e62c71617f23",
//                         "5e457cd0c3b9e62c71617f21",
//                         "5e457cd0c3b9e62c71617f0b",
//                         "5e457cd0c3b9e62c71617f09",
//                         "5e457cd0c3b9e62c71617f40",
//                         "5e457cd0c3b9e62c71617f04",
//                         "5e457cd0c3b9e62c71617f00"
//                     ],
//                     "avgPrice": [
//                         {
//                             "Drink": "5.00"
//                         },
//                         {
//                             "Starter": "6.26"
//                         },
//                         {
//                             "Main": "10.86"
//                         },
//                         {
//                             "Side": 0
//                         },
//                         {
//                             "restaurantPrice": "22.12"
//                         }
//                     ],
//                     "ssoReviews": [],
//                     "status": "active",
//                     "restaurantType": "listed",
//                     "plateformRatings": [
//                         {
//                             "_id": "5f75aa8522c63d3800ba376d",
//                             "plateform": "tablefy",
//                             "rating": 9.772815469070338,
//                             "user_ratings_total": 36
//                         }
//                     ],
//                     "createDate": "2020-02-13T16:45:20.808Z",
//                     "modifiedDate": "2021-05-26T06:08:11.697Z",
//                     "__v": 0,
//                     "callCount": 463,
//                     "virtualTourLink": null,
//                     "priceCategory": "A la carte",
//                     "coins": 250,
//                     "restaurantView": 83,
//                     "photoList": [
//                         {
//                             "category": "featured_image",
//                             "viewOrder": 0,
//                             "_id": "5e731ef64dedb527e8823077",
//                             "fileName": "ac5174ce-6043-5ea5-955e-389e512a0d5e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:27:50.550Z",
//                             "modifiedDate": "2020-03-19T07:27:50.550Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "logo",
//                             "viewOrder": 0,
//                             "_id": "5e731f094dedb527e882346f",
//                             "fileName": "005fb7d8-51b8-5c51-beeb-a82e6f424c7e.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:09.364Z",
//                             "modifiedDate": "2020-03-19T07:28:09.364Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "promotion",
//                             "viewOrder": 0,
//                             "_id": "5e731f294dedb527e8823d64",
//                             "fileName": "03763779-344c-5ee1-bc70-15e4aec3260c.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:28:41.551Z",
//                             "modifiedDate": "2020-03-19T07:28:41.551Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 6,
//                             "_id": "5e731f6d4dedb527e882480a",
//                             "fileName": "295da9da-fa04-5752-91fb-2e629f466cd4.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.694Z",
//                             "modifiedDate": "2020-03-19T07:29:49.694Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 8,
//                             "_id": "5e731f6d4dedb527e882480d",
//                             "fileName": "eaaabd08-2736-563d-b389-3fc96c696ee9.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.708Z",
//                             "modifiedDate": "2020-03-19T07:29:49.708Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 7,
//                             "_id": "5e731f6d4dedb527e882480e",
//                             "fileName": "7466329c-d4a7-59fb-8a6d-0281249bf4ec.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.710Z",
//                             "modifiedDate": "2020-03-19T07:29:49.710Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 4,
//                             "_id": "5e731f6d4dedb527e882480f",
//                             "fileName": "45ed5440-8d8c-5a03-84e6-5f6ca6fe5610.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.724Z",
//                             "modifiedDate": "2020-03-19T07:29:49.724Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 5,
//                             "_id": "5e731f6d4dedb527e8824810",
//                             "fileName": "4882cd0e-ca86-5f3b-b726-a23eee29594b.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.725Z",
//                             "modifiedDate": "2020-03-19T07:29:49.725Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 2,
//                             "_id": "5e731f6d4dedb527e8824811",
//                             "fileName": "e86aa46d-da07-5375-8f68-4edbc766fed9.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.727Z",
//                             "modifiedDate": "2020-03-19T07:29:49.727Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 1,
//                             "_id": "5e731f6d4dedb527e8824812",
//                             "fileName": "757e9ad3-3423-533f-88cf-47ff2082b1e4.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.737Z",
//                             "modifiedDate": "2020-03-19T07:29:49.737Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         },
//                         {
//                             "category": "gallery",
//                             "viewOrder": 3,
//                             "_id": "5e731f6d4dedb527e8824814",
//                             "fileName": "fe4e8077-28c2-5537-8272-9ccf9bba6484.jpg",
//                             "status": "active",
//                             "inspected": true,
//                             "description": null,
//                             "createDate": "2020-03-19T07:29:49.754Z",
//                             "modifiedDate": "2020-03-19T07:29:49.754Z",
//                             "inspectionHistory": [],
//                             "__v": 0
//                         }
//                     ],
//                     "rating": 4.886407734535169,
//                     "totalReviews": 36,
//                     "tagList": [
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f20",
//                                     "title": "Baby-changing",
//                                     "slug": "baby-changing"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1f",
//                                     "title": "Disabled access",
//                                     "slug": "disabled-access"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f23",
//                                     "title": "Free wi-fi",
//                                     "slug": "free-wi-fi"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f21",
//                                     "title": "High chairs",
//                                     "slug": "high-chairs"
//                                 }
//                             ],
//                             "category": "Amenity needs"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f2f",
//                                     "title": "Burgers",
//                                     "slug": "burgers"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f31",
//                                     "title": "Dessert",
//                                     "slug": "dessert"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f32",
//                                     "title": "Fish & Chips",
//                                     "slug": "fish-and-chips"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f34",
//                                     "title": "Full English",
//                                     "slug": "full-english"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f37",
//                                     "title": "Pasta",
//                                     "slug": "pasta"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f38",
//                                     "title": "Pie",
//                                     "slug": "pie"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f3b",
//                                     "title": "Steak",
//                                     "slug": "steak"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f06",
//                                     "title": "Sunday Roast",
//                                     "slug": "sunday-roast"
//                                 }
//                             ],
//                             "category": "Cravings"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f11",
//                                     "title": "Dairy-free options",
//                                     "slug": "dairy-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f07",
//                                     "title": "Gluten-free options",
//                                     "slug": "gluten-free-options"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f13",
//                                     "title": "Plenty for veggies",
//                                     "slug": "plenty-for-veggies"
//                                 }
//                             ],
//                             "category": "Dietary"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f08",
//                                     "title": "Al Fresco",
//                                     "slug": "al-fresco"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f15",
//                                     "title": "Private dining",
//                                     "slug": "private-dining"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f1d",
//                                     "title": "Pub dining",
//                                     "slug": "pub-dining"
//                                 }
//                             ],
//                             "category": "Dining Style"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0a",
//                                     "title": "Vegan",
//                                     "slug": "vegan"
//                                 }
//                             ],
//                             "category": "Menu Types"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f0b",
//                                     "title": "Virtual Tours",
//                                     "slug": "virtual-tours"
//                                 }
//                             ],
//                             "category": "Misc"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f40",
//                                     "title": "Celebration",
//                                     "slug": "celebration"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f04",
//                                     "title": "Lunch",
//                                     "slug": "lunch"
//                                 },
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f00",
//                                     "title": "Pre-theatre / Early bird",
//                                     "slug": "pre-theatre-early-bird"
//                                 }
//                             ],
//                             "category": "Occasion"
//                         },
//                         {
//                             "tags": [
//                                 {
//                                     "_id": "5e457cd0c3b9e62c71617f09",
//                                     "title": "Breakfast",
//                                     "slug": "breakfast"
//                                 }
//                             ],
//                             "category": "Offers"
//                         }
//                     ],
//                     "cuisineList": [
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f42",
//                             "title": "British",
//                             "slug": "british"
//                         },
//                         {
//                             "_id": "5e457cf4c3b9e62c71617f61",
//                             "title": "Global",
//                             "slug": "global"
//                         }
//                     ]
//                 }
//             }
//         ]
//     }
//   ],
  sharedListToken: null
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_LISTS_START: 
      return { ...state, errorlistsItems: null, listsItemsLoading: true, controller: action.controller };
    
    case FETCH_LISTS_SUCCESS: 

      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;
      let listRestaurantsArr = [];

      data.map((list) => {
        if (list.restaurants.length !== 0) {
          list.restaurants.map((restaurant) => {
            if (!listRestaurantsArr.includes(restaurant.restaurant)) {
              listRestaurantsArr.push(restaurant.restaurant);
            }
          });
        }
      });
      if (action.isLoadMore || action.isRestaurant) {
        if (currentPage >= state.currentPage) {

          return {
            ...state,
            error: null,
            listsItemsPayload: state.listsItemsPayload.concat(data),
            currentPage,
            pageSize,
            totalItem,
            totalPage,
            isLoading: false
          };
        } else {
          return { ...state, isLoading: false };
        }
      } else {
        if (!action.isRestaurant) {
          return {
            ...state,
            listsPayload: data, //state.listsPayload.concat(data),
            listsRestaurantData: listRestaurantsArr
          }
        }
      }

    
    case FETCH_LISTS_FAILED: 
      return { ...state, listsItemsLoading: false, errorlistsItems: action.payload };
    
    case MANIPULATE_LISTS_START: 
      return { ...state, error: null, isLoading: true };
    
    case MANIPULATE_LISTS_SUCCESS: 
      return {
        ...state,
        error: null,
        isLoading: false,
        listItemAdded: action.payload.data
      };
    
    case MANIPULATE_LISTS_FAILED: 
      return { ...state, isLoading: false, error: action.payload, listItemAdded: null };
    
    case FETCH_LISTS_RESTAURANTS_START: 
      return { ...state, errorRestaurant: null, isLoadingRestaurant: true, controllerRestaurant: action.controllerRestaurant };
    
    case FETCH_LISTS_RESTAURANTS_SUCCESS: 

      const { dataRestaurants, currentPageRestaurant, pageSizeRestaurant, totalItemRestaurant, totalPageRestaurant } = action.payload;
      if (currentPageRestaurant !== state.currentPageRestaurant) {
        return {
          ...state,
          errorRestaurant: null,
          listsRestaurantPayload: state.payload.concat(dataRestaurants),
          currentPageRestaurant,
          pageSizeRestaurant,
          totalItemRestaurant,
          totalPageRestaurant,
          isLoadingRestaurant: false
        };
      } else {
        return { ...state, isLoadingRestaurant: false };
      }
    
    case FETCH_LISTS_RESTAURANTS_FAILED: 
      return { ...state, listsRestaurantPayload: null, isLoadingRestaurant: false, errorRestaurant: action.payload };
    
    case FETCH_SHAREDLIST_START:
      return { ...state, error: null, isLoading: true };

    case FETCH_SHAREDLIST_SUCCESS:
      return { ...state, error: null, isLoading: false, guestSharedList: action.payload.data };

    case FETCH_SHAREDLIST_FAILURE:
      return { ...state, error: action.payload, isLoading: false };

    case FETCH_SHARED_TOKEN_START:
      return { ...state, error: null, isLoading: true };

    case FETCH_SHARED_TOKEN_SUCCESS:
      return { ...state, error: null, isLoading: false, sharedListToken: action.payload.data };

    case FETCH_ACHIEVEMENTS_FAILED:
      return { ...state, error: action.payload, isLoading: false };

    default:
      return state
  }
};

export default reducer;
