import {
  CLEAR_CONFIRMED_BOOKING_DATA,
  RESET_PAGING,
  SAVE_BOOKING_DATA,
  SET_CONFIRMED_BOOKING_DATA,
  UPDATE_BOOKING_REVIEW_DATA,
  UPDATE_BOOKING_DATA,
  UPDATE_BOOKING_DATA_SUCCESS,
  UPDATE_BOOKING_DATA_FAILED,
  GET_BOOKING_DATA,
  GET_BOOKING_DATA_SUCCESS,
  GET_BOOKING_DATA_FAILED,
  CHANGE_BOOKING_STAGE,
  CHANGE_BOOKING_STAGE_SUCCESS,
  CHANGE_BOOKING_STAGE_FAILED,
  CANCEL_BOOKING,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILED,
} from '../../constants/actionTypes';
import { bookingService } from "../../service/booking-service";
import { loginService } from "../../service/login-service";
import { toast } from "react-toastify";

export const updateBooking = data => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateBookingDataStart());
    if (data.userType && data.userType === 'guest') {
      loginService.registration({ ...data, role: 'guest' })
        .then(rawData => {
          return rawData.json();
        })
        .then(response => {
          if (response.status) {
            bookingService.addBooking({ ...data, user: response.data._id })
              .then(rawData => {
                return rawData.json();
              })
              .then(response => {
                if (response.status) {
                  dispatch(updateBookingDataSuccess(response));
                } else {
                  dispatch(updateBookingDataFailed(failureMessage));
                }
              })
              .catch(() => {
                dispatch(updateBookingDataFailed(failureMessage));
              });
          }
        })
    } else {
      bookingService.addBooking(data)
        .then(rawData => {
          return rawData.json();
        })
        .then(response => {
          if (response.status) {
            dispatch(updateBookingDataSuccess(response));
          } else {
            dispatch(updateBookingDataFailed(failureMessage));
          }
        })
        .catch(() => {
          dispatch(updateBookingDataFailed(failureMessage));
        });
    }
  }
};

export const editBooking = (data, id) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateBookingDataStart());
    bookingService.editBooking(data, id)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(updateBookingDataSuccess(response));
        } else {
          dispatch(updateBookingDataFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateBookingDataFailed(failureMessage));
      });
  }
}

export const sendReminder = (data) => {

  const failureMessage = 'Something wrong happened';
  return dispatch => {
    // dispatch(sendReminderStart())
    bookingService.sendReminder(data).then(rawData => {
      return rawData.json();
    }).then(response => {
      if (response.status) {
        toast.success("reminder send successfully")
      }
    }).catch((err) => {

    })
  }
}

export const fetchBookingData = (type, duration, nextPage, appendData) => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    dispatch(getBookingDataStart(type));
    const userId = getState().auth.payload._id;
    let newDuration = {
      fromDate: '',
      toDate: ''
    };
    if (duration) {
      newDuration.fromDate = duration.fromDate || '';
      newDuration.toDate = duration.toDate || '';
    }
    bookingService.getBooking({ userID: userId, type, duration: newDuration, nextPage })
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(getBookingDataSuccess(response, type, appendData));
        } else {
          dispatch(getBookingDataFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(getBookingDataFailed(failureMessage));
      });
  }
};

const updateBookingDataStart = () => {
  return {
    type: UPDATE_BOOKING_DATA
  }
};

const updateBookingDataSuccess = data => {
  return {
    type: UPDATE_BOOKING_DATA_SUCCESS,
    payload: data
  }
};

const updateBookingDataFailed = data => {
  return {
    type: UPDATE_BOOKING_DATA_FAILED,
    payload: data
  }
};

const getBookingDataStart = type => {
  return {
    type: GET_BOOKING_DATA,
    bookingType: type
  }
};

const getBookingDataSuccess = (data, type, appendData) => {
  return {
    type: GET_BOOKING_DATA_SUCCESS,
    payload: data,
    bookingType: type,
    appendData,
  }
};

const getBookingDataFailed = data => {
  return {
    type: GET_BOOKING_DATA_FAILED,
    payload: data
  }
};

export const saveBookingData = data => {
  return {
    type: SAVE_BOOKING_DATA,
    payload: data
  }
};

export const clearConfirmedBooking = () => {
  return {
    type: CLEAR_CONFIRMED_BOOKING_DATA
  }
}

export const setConfirmedBooking = data => {
  return {
    type: SET_CONFIRMED_BOOKING_DATA,
    payload: data
  }
}

export const resetPaging = type => {
  return {
    type: RESET_PAGING,
    payload: type
  }
}

export const updateBookingReviewData = data => {
  return {
    type: UPDATE_BOOKING_REVIEW_DATA,
    payload: data
  }
}

export const changeBookingStage = data => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(changeBookingStageStart());
    bookingService.changeBookingStage(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(changeBookingStageSuccess(response));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(changeBookingStageFailed(failureMessage));
        }
      })
      .catch(() => {
        toast.error(failureMessage);
        dispatch(changeBookingStageFailed(failureMessage));
      });
  }
}

const changeBookingStageStart = () => {
  return {
    type: CHANGE_BOOKING_STAGE
  }
};

const changeBookingStageSuccess = data => {
  return {
    type: CHANGE_BOOKING_STAGE_SUCCESS,
    payload: data
  }
};

const changeBookingStageFailed = data => {
  return {
    type: CHANGE_BOOKING_STAGE_FAILED,
    payload: data
  }
};

export const cancelBooking = data => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(cancelBookingStage());
    bookingService.cancelBooking(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(cancelBookingStageSuccess(response));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(cancelBookingStageFailed(failureMessage));
        }
      })
      .catch(() => {
        toast.error(failureMessage);
        dispatch(cancelBookingStageFailed(failureMessage));
      });
  }
}

const cancelBookingStage = () => {
  return {
    type: CANCEL_BOOKING
  }
};

const cancelBookingStageSuccess = data => {
  return {
    type: CANCEL_BOOKING_SUCCESS,
    payload: data
  }
};

const cancelBookingStageFailed = data => {
  return {
    type: CANCEL_BOOKING_FAILED,
    payload: data
  }
};

// const sendReminderStart = () => {
//   return {
//     type: SEND_REMINDER
//   }
// };




