import React from "react";
import { withRouter } from 'react-router-dom';
import { useBackdrop } from "./customHooks";
import { MOBILE_SIGN_IN, MOBILE_REGISTER } from '../../constants/routes';

const LoginDiscount = ({ onClose, history }) => {
  useBackdrop();

  const handleButtonClick = link => {
    onClose();
    history.push(link);
  }

  return (
    <section className="overlay_welcome_modal v1_signin_modal">
      <button type="button" className="close_welcome_overlay" onClick={onClose}>
        <img src="images/homepage/close_icon_white_25.png" />
      </button>
      <div className="v1_signinmodal_body">
        <div className="v1_modal_img">
          <img src="images/homepage/henrywithwallet.png" />
          <img src="images/homepage/henrywithwallet_sm.png" className="img_for_mobile" />
        </div>
        <h3>Log in to save</h3>
        <p>Members earn coins for booking and reviewing restaurants – join them today for out-of-this-world rewards.</p>
        <div className="nextstep">
          <div className="d-flex">
            <div className="next_step_dstop target_selected">
              <button type="button" onClick={() => handleButtonClick(MOBILE_SIGN_IN)}>Log in</button>
            </div>
            <div className="next_step_dstop bg_border_pink">
              <button type="button" className="btn_nobg" onClick={() => handleButtonClick(MOBILE_REGISTER)}>
                Join for free
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default withRouter(LoginDiscount);