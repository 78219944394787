import React from "react";
import { withRouter } from 'react-router-dom';
import { useBackdrop } from "./customHooks";
import { MY_PROFILE, HOME } from '../../constants/routes';

const FirstCoins = ({ onClose, history }) => {
  useBackdrop();

  const handleButtonClick = link => {
    onClose();
    history.push(link);
  }

  return (
    <section className="overlay_welcome_modal v1_register_modal">
      <button type="button" className="close_welcome_overlay" onClick={onClose}>
        <img src="/images/homepage/close_icon_white_25.png" />
      </button>
      <div className="v1_modal_body">
        <div className="v1_modal_img">
          <img src="/images/homepage/v1_modal_coinwithray.png" alt="coins"/>
          <img src="/images/homepage/v1_modal_coinwithray_sm.png" className="img_for_mobile" alt="coins"/>
        </div>
        <h3>You’ve earned your first<span>50 coins</span></h3>
        <p>Welcome to Tablefy, here's a thank you present for registering!</p>
        <div className="nextstep">
          <div className="d-flex">
            <div className="next_step_dstop target_selected">
              <button
                type="button"
                onClick={() => handleButtonClick(MY_PROFILE)}
              >
                Complete my profile
              </button>
            </div>
            <div className="next_step_dstop btn_tohide">
              <button
                type="button"
                className="btn_nobg"
                onClick={() => handleButtonClick(HOME)}
              >
                Explore restaurants
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="book_table_btn_area new">
        <button type="button" className="dwnld_bookings">Explore restaurants</button>
        <span className="people new">
          <img src="/images/sidebar-people.png" alt="" /><span className="roundlive_red" />
        </span>
      </div>
    </section>
  )
}

export default withRouter(FirstCoins);