import React from "react";
import { useBackdrop } from "./customHooks";
import { MY_COINS } from "../../constants/routes";

const EnoughCoins = ({ onClose, history }) => {
  useBackdrop();

  const handleCoinBundleClicked = () => {
    onClose();
    history.push({
      pathname: MY_COINS,
      state: { fromSuggestion: 'user-buy-coins', type: '' }
    })
  }

  return (
    <section className="buzz_modal">
      <div className="buzz_nocoin">
        <button type="button" className="buzz_close_modal" onClick={onClose}>
          <img src="/images/buzz/buzz_close_white.png" srcset="/images/buzz/buzz_close_white@2x.png 2x" alt="close"/>
        </button>
        <h3 className="buzz_center_heading">
          <span className="pink_txt_heading">Oh no!</span> You don't have enough coins!
        </h3>
        <div className="buzz_emptywallet"><img src="/images/buzz/nocoins_img.png" alt="wallet empty"/></div>
        <div className="buzz_bttm_grpbtn">
          <button type="button" className="btn_buzz_get_coins" onClick={handleCoinBundleClicked}>
            Get more coins
          </button>
          <button type="button" className="btn_buzz_get_coins diff">
            <b>Watch ads</b>
            <span className="buzz_morecoins_curr"><img src="/images/buzz/buzz_coin.png" alt="coins"/>+200</span>
          </button>
        </div>
      </div>
    </section>
  )
}

export default EnoughCoins;