export const authHeader = () => {
  // return authorization header with basic auth credentials
  const userAccessToken = localStorage.getItem('userAccessToken');

  if (userAccessToken) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userAccessToken
    };
  } else {
    return { 'Content-Type': 'application/json' };
  }
};

export const urlEncodedAuthHeader = () => {
  // return authorization header with basic auth credentials
  const userAccessToken = localStorage.getItem('userAccessToken');

  if (userAccessToken) {
    return {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'Authorization': 'Bearer ' + userAccessToken
    };
  } else {
    return { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' };
  }
};
