import React from "react";
import { Route, Redirect } from 'react-router-dom';

import { DESKTOP_REGISTER, HOME } from "../../constants/routes";

export const PrivateRoute = ({ component: Component, role, ...rest }) => {
  const session = localStorage.getItem('userAccessToken');
  const userData = JSON.parse(localStorage.getItem('loginData'));

  const search = rest.location.search;
  const path = rest.location.pathname.split('/');
  let isPanelAdmin = path.includes("restaurant-edit-details") && search !== "";

  if (isPanelAdmin) {
    return (<Route {...rest}
      render={(props) => (<Component {...props} />)} />)
  } else {
    return (

      <Route {...rest} render={(props) => (
        session
          ? role
            ? (role.includes(userData.role)) ? <Component {...props} /> : <Redirect to={{
              pathname: HOME,
              state: { from: props.location }
            }} />
            : <Component {...props} />
          : <Redirect to={{
            pathname: DESKTOP_REGISTER,
            state: { from: props.location }
          }} />
      )} />
    )
  }

};