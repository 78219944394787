import {authHeader} from './auth-header';
import * as apiURL from './Apiconfig';
import {secureFetch} from './secureFetch';

export const cuisineService = {
  getCuisines
};

function getCuisines(search) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.CUISINES}?pageSize=10&currentPage=1&orderBy=fullName&search=${search || ''}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
