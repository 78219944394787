import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const achievementsService = {
  fetchAchievements
};


function fetchAchievements(status) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let url = status === 'pending' ? 'incomplete-achievements' : 'completed-achievements';

  return secureFetch(`${apiURL.ACHIEVEMENTS}/${url}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}