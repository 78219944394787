import React from "react";
import { Link, withRouter } from "react-router-dom";
import GoogleLogin from "react-google-login";

//custom components
import HeaderSignInForm from "./SignInForm";

//constants
import { DESKTOP_REGISTER } from "../../constants/routes";
import { sectionName } from "../../constants/loggerConstants";

const { SECTION_LOGIN_MODAL } = sectionName;

const SignIn = ({ authLoading, onClickLogin, onFBLogin, onForgetPasswordClick, location, onGoogleLogin }) => {
  return (
    <div>
      <h3 className="sign_hed font-22">Log in for your personalised experience</h3>
      <HeaderSignInForm onTryLogin={onClickLogin} loading={authLoading} />
      <div className="forget_pass">
        <button className="link_red" onClick={onForgetPasswordClick}>
          Forgotten password?
        </button>
      </div>
      <div className="sign_with_socail d-flex-align">
        <p>Log in with</p>
        <div className="sign_with_socail_icon">
          <button className="d-flex-align" onClick={onFBLogin}>
            <img src="/images/icon_facebook_round.png" srcset="/images/icon_facebook_round@2x.png 2x" alt="facebook" />
            <span>Facebook</span>
          </button>
        </div>
        <p> or </p>
        <div className="sign_with_socail_icon">
          <GoogleLogin
            clientId="765348126431-bq3tl2i1glq0357udb9hig1ci0o1so90.apps.googleusercontent.com"
            render={renderProps => (
              <button
                className="d-flex-align"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img src="/images/icon_google.png" srcset="/images/icon_google@2x.png 2x" alt="" />
                <span>Google</span>
              </button>
            )}
            buttonText="Login"
            onSuccess={googleUser => onGoogleLogin(googleUser.getAuthResponse().id_token)}
            onFailure={() => console.log('Google login failed')}
            cookiePolicy={'single_host_origin'}
          />
        </div>
      </div>
      <div className="joinfor d-flex-align">
        <p>Not a member?</p>
        <Link
          className="font-weight-900"
          to={{ pathname: DESKTOP_REGISTER, state: { fromSection: SECTION_LOGIN_MODAL, fromPath: location.pathname } }}
        >
          Join for free
        </Link>
      </div>
    </div>
  )
};

export default withRouter(SignIn);
