import { useEffect } from "react";

export const useBackdrop = () => {
  useEffect(() => {
    document.body.classList.add('overlay_body');
    document.body.style.overflow = "hidden";

    return () => {
      document.body.classList.remove('overlay_body');
      document.body.style.overflow = "initial"
    }
  }, [])
}