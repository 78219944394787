import {
  FETCH_RESTAURANT_REWARDS_START,
  FETCH_RESTAURANT_REWARDS_SUCCESS,
  FETCH_RESTAURANT_REWARDS_FAILED,
  FETCH_RESTAURANT_COINS_START,
  FETCH_RESTAURANT_COINS_SUCCESS,
  FETCH_RESTAURANT_COINS_FAILED,
  FETCH_RESTAURANT_COINS_SETTING_START,
  FETCH_RESTAURANT_COINS_SETTING_SUCCESS,
  FETCH_RESTAURANT_COINS_SETTING_FAILED,
  PAY_REWARDS_START,
  PAY_REWARDS_SUCCESS,
  PAY_REWARDS_FAILED,
  FETCH_COIN_REWARDS_START,
  FETCH_COIN_REWARDS_SUCCESS,
  FETCH_COIN_REWARDS_FAILED,
  PAY_RESTAURANT_REWARDS_START,
  PAY_RESTAURANT_REWARDS_SUCCESS,
  PAY_RESTAURANT_REWARDS_FAILED,
  FETCH_REWARDS_START,
  FETCH_REWARDS_SUCCESS,
  FETCH_REWARDS_FAILED,
} from '../../constants/actionTypes';
import { rewardsService } from "../../service/rewards-service";

export const fetchRestaurantRewards = restaurantId => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchRestaurantRewardsStart());
    rewardsService.getRestaurantRewards(restaurantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantRewardsSuccess(response));
        } else {
          dispatch(fetchRestaurantRewardsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchRestaurantRewardsFailed(failureMessage));
      });
  }
};

export const fetchRestaurantCoins = restaurantId => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchRestaurantCoinsStart());
    rewardsService.getAvailableCoins(restaurantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantCoinsSuccess(response.data));
        } else {
          dispatch(fetchRestaurantCoinsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchRestaurantCoinsFailed(failureMessage));
      });
  }
};

export const fetchCoinsSetttings = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchCoinsSetttingsStart());
    rewardsService.getCoinsSetting()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchCoinsSetttingsSuccess(response.data));
        } else {
          dispatch(fetchCoinsSetttingsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchCoinsSetttingsFailed(failureMessage));
      });
  }
};

export const payRewards = (sectionName) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(payRewardsStart());
    rewardsService.payRewardCoins(sectionName)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(payRewardsSuccess(response.data));
        } else {
          dispatch(payRewardsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(payRewardsFailed(failureMessage));
      });
  }
};

export const payRestaurantRewards = (sectionName, restaurantId) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(payRestaurantRewardsStart());
    rewardsService.payRestaurantRewardCoins(sectionName, restaurantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(payRestaurantRewardsSuccess(response.data));
        } else {
          dispatch(payRestaurantRewardsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(payRestaurantRewardsFailed(failureMessage));
      });
  }
};

export const fetchCoinRewards = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchCoinRewardsStart());
    rewardsService.getCoinRewards()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchCoinRewardsSuccess(response.data));
        } else {
          dispatch(fetchCoinRewardsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchCoinRewardsFailed(failureMessage));
      });
  }
};

export const fetchRewards = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchGetRewardsStart());
    rewardsService.getRewards()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchGetRewardsSuccess(response.data));
        } else {
          dispatch(fetchGetRewardsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchGetRewardsFailed(failureMessage));
      });
  }
};

const fetchRestaurantRewardsStart = () => {
  return {
    type: FETCH_RESTAURANT_REWARDS_START
  }
};

const fetchRestaurantRewardsSuccess = data => {
  return {
    type: FETCH_RESTAURANT_REWARDS_SUCCESS,
    payload: data
  }
};

const fetchRestaurantRewardsFailed = data => {
  return {
    type: FETCH_RESTAURANT_REWARDS_FAILED,
    payload: data
  }
};

const fetchRestaurantCoinsStart = () => {
  return {
    type: FETCH_RESTAURANT_COINS_START
  }
};

const fetchRestaurantCoinsSuccess = data => {
  return {
    type: FETCH_RESTAURANT_COINS_SUCCESS,
    payload: data
  }
};

const fetchRestaurantCoinsFailed = data => {
  return {
    type: FETCH_RESTAURANT_COINS_FAILED,
    payload: data
  }
};

const fetchCoinsSetttingsStart = () => {
  return {
    type: FETCH_RESTAURANT_COINS_SETTING_START
  }
};

const fetchCoinsSetttingsSuccess = data => {
  return {
    type: FETCH_RESTAURANT_COINS_SETTING_SUCCESS,
    payload: data
  }
};

const fetchCoinsSetttingsFailed = data => {
  return {
    type: FETCH_RESTAURANT_COINS_SETTING_FAILED,
    payload: data
  }
};

const payRewardsStart = () => {
  return {
    type: PAY_REWARDS_START
  }
};

const payRewardsSuccess = data => {
  return {
    type: PAY_REWARDS_SUCCESS,
    payload: data
  }
};

const payRewardsFailed = data => {
  return {
    type: PAY_REWARDS_FAILED,
    payload: data
  }
};

const payRestaurantRewardsStart = () => {
  return {
    type: PAY_RESTAURANT_REWARDS_START
  }
};

const payRestaurantRewardsSuccess = data => {
  return {
    type: PAY_RESTAURANT_REWARDS_SUCCESS,
    payload: data
  }
};

const payRestaurantRewardsFailed = data => {
  return {
    type: PAY_RESTAURANT_REWARDS_FAILED,
    payload: data
  }
};

const fetchCoinRewardsStart = () => {
  return {
    type: FETCH_COIN_REWARDS_START
  }
};

const fetchCoinRewardsSuccess = data => {
  return {
    type: FETCH_COIN_REWARDS_SUCCESS,
    payload: data
  }
};

const fetchCoinRewardsFailed = data => {
  return {
    type: FETCH_COIN_REWARDS_FAILED,
    payload: data
  }
};

const fetchGetRewardsStart = () => {
  return {
    type: FETCH_REWARDS_START
  }
};

const fetchGetRewardsSuccess = data => {
  return {
    type: FETCH_REWARDS_SUCCESS,
    payload: data
  }
};

const fetchGetRewardsFailed = data => {
  return {
    type: FETCH_REWARDS_FAILED,
    payload: data
  }
};