/**
 * This is base URL for api call
 */

export let BASE_URL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  BASE_URL = "https://dev.tablefy.com:5000/api/";
} else {
  BASE_URL = "https://dev.tablefy.com:5000/api/";
}

export let SOCKET_URL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  SOCKET_URL = "http://localhost:5000";
} else {
  SOCKET_URL = "https://dev.tablefy.com:5000";
}

/**
 * Basic URLs
 */

export const LOGIN_URL = BASE_URL + "authenticate";

//authentication
export const REGISTRATION_URL = BASE_URL + "users/register";
export const FORGOT_PASS_OTP_URL = BASE_URL + "users/password-reset";
export const FORGOT_PASS_URL = BASE_URL + "users/password-reset-verify";
export const FACEBOOK_LOGIN = BASE_URL + "authenticate/facebook";
export const GOOGLE_LOGIN = BASE_URL + "authenticate/google";
export const PROFILE = BASE_URL + "users/profile";
export const CHANGE_PASS_URL = BASE_URL + "users/change-password";

//restaurants
export const RESTAURANTS = BASE_URL + "restaurants";
export const RESTAURANT_LOCATIONS = BASE_URL + "restaurants/locations";
export const PAGE_LEVEL = BASE_URL + "restaurants/page-level";

//users
export const RECENT_SEARCHES = BASE_URL + "users/recent-searches";
export const CLEAR_RECENT_SEARCHES = BASE_URL + "users/clear-recent-searches";
export const VALIDATE_EMAIL = BASE_URL + "users/validate-email";
export const UPDATE_PROFILE = BASE_URL + "users/update-profile";
export const SEARCH_PREFERENCES = BASE_URL + "users/search-preferences";
export const UPDATE_PROFILE_PICTURE = BASE_URL + "users/update-profile-picture";
export const PROFILE_STRENGTH = BASE_URL + "users/profile-strength";
export const SEARCHED_ITEMS = BASE_URL + "search";
export const PAID_COINS = BASE_URL + "users/paid-coins";
export const PENDING_REVIEWS = BASE_URL + "users/pending-reviews";
export const PENDING_PREFS = BASE_URL + "users/pending-preferences";
export const UPDATE_PREFERENCE_TAGS = BASE_URL + "users/update-tags";
export const USER_CONTACTS = BASE_URL + "user-contact";
export const INVITE_USER_CONTACTS = BASE_URL + "users/invite-friend";
export const DASHBOARD = BASE_URL + "users/dashboard";
export const ACHIEVEMENTS = BASE_URL + "users";
export const SNOOZE_SETTINGS = BASE_URL + "users/snooz-setting";
export const USER_OFFERS = BASE_URL + "users/offers";
export const USER_POLL_CARD = BASE_URL + "users/poll-cards";

//top-locations
export const TOP_LOCATIONS = BASE_URL + "top-locations";
export const TOP_LOCATIONS_RESTAURANTS = BASE_URL + "top-locations/restaurants";

//cuisines
export const CUISINES = BASE_URL + "cuisines";

//tag
export const TAGS = BASE_URL + "tags";

//media
export const UPLOAD = BASE_URL + "uploads/";
export const UPLOAD_MEDIA = `${UPLOAD}media`;
//export const UPDATE_DESCRIPTION = BASE_URL + "uploads/media";

//update menu
export const UPDATE_MENU = BASE_URL + "menus";
export const FETCH_MENU_TYPE = BASE_URL + "menu-types";

//booking
export const BOOKING = BASE_URL + "bookings";

//lists
export const MY_LISTS = BASE_URL + "my-lists";

//logging
export const LOGGING = BASE_URL + "action-logs";

//user suggestion
export const USER_SUGGESTIONS = BASE_URL + "user-suggestions";
export const USER_PENDING_SUGGESTIONS =
  BASE_URL + "user-suggestions/pending-suggestion";

//rewards
export const RESTAURANT_REWARDS = BASE_URL + "restaurant-rewards";
export const REWARD_COINS = BASE_URL + "coin-reward/pay-reward-coin";
export const COIN_REWARDS = BASE_URL + "coin-reward";
export const RESTAURANT_REWARD_COINS =
  BASE_URL + "restaurant-panel/pay-reward-coin";
export const TRANSFER_COINS = BASE_URL + "coin-reward/coin-transfer";

//reviews
export const REVIEWS = BASE_URL + "reviews";

//offers
export const OFFERS = BASE_URL + "offers";
export const CHEST = BASE_URL + "user-chest";

//guides
export const GUIDES = BASE_URL + "guides";

//ads
export const ADS = BASE_URL + "ads";

//report issue
export const REPORT_ISSUE = BASE_URL + "report-issue";

//restaurant admin
export const RESTAURANT_ADMIN = BASE_URL + "affiliate-restaurant";
export const NEWS = BASE_URL + "news";
export const NOTIFICATIONS = BASE_URL + "notifications";

//coins settings
export const RESTAURANT_COINS_SETTINGS = BASE_URL + "restaurant-setting";
// export const COIN_BUNDLES = BASE_URL + "settings/coin-bundle";
export const RESTAURANT_REWARDS_SETTINGS = BASE_URL + "settings";
// export const RESTAURANT_REWARD_COINS = BASE_URL + "rewards";
///doubt

//coins settings
export const RESTAURANT_ADMIN_PANEL = BASE_URL + "restaurant-panel";

//coin bundles
export const COIN_BUNDLES = BASE_URL + "coin-bundle";

//payment
export const PAYMENT_METHODS = BASE_URL + "payment-transaction/payment-methods";
export const CREATE_PAYMENT_INTENT =
  BASE_URL + "payment-transaction/create-payment-intent";
export const CREATE_PAYPAL_TRANSACTION =
  BASE_URL + "payment-transaction/create-paypal-transaction";
export const CAPTURE_PAYPAL_TRANSACTION =
  BASE_URL + "payment-transaction/capture-paypal-transaction";
export const ANOTHER_PAYMENT_METHOD = 
  BASE_URL +  "invoice";
//feed service
export const FEEDS = BASE_URL + "feeds";

//call logs
export const CALL_LOGS = BASE_URL + "call-logs";
export const SEND_OTP = CALL_LOGS + "/send-otp";
export const VERIFY_OTP = CALL_LOGS + "/verify-otp";

//claim restaurant
export const RESTAURANT_CLAIM = BASE_URL + "restaurant-claim";

//contact us
export const CONTACT_US = BASE_URL + "contact";

//top 3 bAnner
export const TOP_3_RESTAURANTS = BASE_URL + "banner/top-restaurants";

//top cuisine banner
export const TOP_CUISINES = BASE_URL + "banner/top-cuisine";

//Inspiration Tags
export const INSPIRATION_TAGS = BASE_URL + "banner/inspiration-tags";
