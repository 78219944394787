import {
  FETCH_TAGS_START,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILED,
  FETCH_TAGS_CATEGORY_START,
  FETCH_TAGS_CATEGORY_SUCCESS,
  FETCH_TAGS_CATEGORY_FAILED,
} from '../../constants/actionTypes';
import { tagService } from "../../service/tag-service";

export const fetchTags = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchTagsStart());
    tagService.getTags()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchTagsSuccess(response));
        } else {
          dispatch(fetchTagsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchTagsFailed(failureMessage));
      });
  }
};

export const fetchTagsCategory = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchTagsCategoryStart());
    tagService.getTagsCategory()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchTagsCategorySuccess(response));
        } else {
          dispatch(fetchTagsCategoryFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchTagsCategoryFailed(failureMessage));
      });
  }
};

const fetchTagsStart = () => {
  return {
    type: FETCH_TAGS_START
  }
};

const fetchTagsSuccess = data => {
  return {
    type: FETCH_TAGS_SUCCESS,
    payload: data
  }
};

const fetchTagsFailed = data => {
  return {
    type: FETCH_TAGS_FAILED,
    payload: data
  }
};


const fetchTagsCategoryStart = () => {
  return {
    type: FETCH_TAGS_CATEGORY_START
  }
};

const fetchTagsCategorySuccess = data => {
  return {
    type: FETCH_TAGS_CATEGORY_SUCCESS,
    payload: data
  }
};

const fetchTagsCategoryFailed = data => {
  return {
    type: FETCH_TAGS_CATEGORY_FAILED,
    payload: data
  }
};
