import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const rewardsService = {
  getRestaurantRewards,
  getAvailableCoins,
  getCoinsSetting,
  payRewardCoins,
  payRestaurantRewardCoins,
  getCoinRewards,
  transferCoins,
  getRewards
};

function getRestaurantRewards(restaurantId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANT_REWARDS}/${restaurantId}/new`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getAvailableCoins(restaurantId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANT_REWARDS}/${restaurantId}/get-coins`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getCoinsSetting() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANT_COINS_SETTINGS}/reward-setting?rewardFor=restaurant`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function payRewardCoins(sectionName) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ sectionName: sectionName })
  };

  return secureFetch(`${apiURL.REWARD_COINS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function payRestaurantRewardCoins(sectionName, restaurant) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ sectionName: sectionName, restaurant })
  };

  return secureFetch(`${apiURL.RESTAURANT_REWARD_COINS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getCoinRewards() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.COIN_REWARDS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function transferCoins(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ ...data })
  };

  return secureFetch(`${apiURL.TRANSFER_COINS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}


function getRewards() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANT_COINS_SETTINGS}/reward-setting`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
