import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';
import { authHeader, urlEncodedAuthHeader } from "./auth-header";

export const bookingService = {
  addBooking,
  getBooking,
  editBooking,
  changeBookingStage,
  cancelBooking,
  sendReminder,
};

function addBooking(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return secureFetch(`${apiURL.BOOKING}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function editBooking(data, bookingID) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return secureFetch(`${apiURL.BOOKING}/${bookingID}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getBooking(data) {
  const requestOptions = {
    method: 'GET',
    headers: urlEncodedAuthHeader()
  };

  const { userID, type, duration, nextPage = 1 } = data;
  const { fromDate, toDate } = duration;

  let newType = type;
  if (type === 'history') {
    newType = 'confirmed';
  }
  if (type === 'cancelled') {
    newType = 'cancelled,denied';
  }

  return secureFetch(`${apiURL.BOOKING}/${userID}/${newType}?pageSize=10&currentPage=${nextPage}&fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function changeBookingStage(data) {
  const { bookingID, bookingStage } = data;
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({})
  };

  return secureFetch(`${apiURL.BOOKING}/${bookingID}/${bookingStage}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function cancelBooking(data) {
  const { bookingID, bookingStage, currentBookingStage, reasonForCancellation } = data;
  const requestData = { bookingStage, currentBookingStage };

  if (reasonForCancellation) {
    requestData.reasonForCancellation = reasonForCancellation;
  }

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(requestData)
  };

  return secureFetch(`${apiURL.BOOKING}/${bookingID}/cancel`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}


function sendReminder(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return secureFetch(`${apiURL.BOOKING}/send-reminder`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}