import React from "react";

import App from "../../routes/index";
import Header from "../Header";
import Footer from "../Footer";
import BuzzScreens from "../BuzzScreens";
import ToastSettings from "../ToastSettings";
import MetaTags from "../MetaTags";

//Define layout and it's related styles here

class MainApp extends React.Component {
  render() {
    const {match, location, history} = this.props;

    return (
      <div>
        <Header location={location} history={history}/>
        <MetaTags tablefy="Tablefy" isDashboard={true} />
        <App match={match}/>
        <Footer/>
        <BuzzScreens/>
        <ToastSettings/>
      </div>
    )
  }
}

export default MainApp;
