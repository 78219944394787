import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom';
import {Provider} from "react-redux";

//custom components
import configureStore from "./store";
import App from './containers/App';

//stylesheets
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import "react-datepicker/dist/react-datepicker.css";

//Define the wrapping top level higher order components/packages here

function NextApp() {
  return (
    <Provider store={configureStore}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={App}/>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default NextApp;
