import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//constants
import {
  ENOUGH_COINS,
  LEVEL_UP,
  RESTAURANT_BOOKED,
  DONATE,
  FIRST_COINS,
  LOGIN_DISCOUNT
} from "../../constants/buzzConstants";

//utils
import { hideBuzzScreens } from '../../store/actions';

//components
import EnoughCoins from "./EnoughCoins";
import LevelUp from "./LevelUp";
import RestaurantBooked from "./RestaurantBooked";
import Donate from "./Donate";
import FirstCoins from "./FirstCoins";
import LoginDiscount from "./LoginDiscount";

class BuzzScreens extends React.Component {
  render() {
    const { hideBuzzScreens, screenVisible, history, additionalData } = this.props;
    let chosenScreen = null;

    switch (screenVisible) {
      case ENOUGH_COINS: {
        chosenScreen = <EnoughCoins onClose={() => hideBuzzScreens()} history={history}/>;
        break;
      }
      case LEVEL_UP: {
        chosenScreen = <LevelUp onClose={() => hideBuzzScreens()} details={additionalData}/>;
        break;
      }
      case RESTAURANT_BOOKED: {
        chosenScreen = <RestaurantBooked onClose={() => hideBuzzScreens()}/>;
        break;
      }
      case DONATE: {
        chosenScreen = <Donate onClose={() => hideBuzzScreens()} details={additionalData}/>;
        break;
      }
      case FIRST_COINS: {
        chosenScreen = <FirstCoins onClose={() => hideBuzzScreens()}/>;
        break;
      }
      case LOGIN_DISCOUNT: {
        chosenScreen = <LoginDiscount onClose={() => hideBuzzScreens()}/>;
        break;
      }
    }

    return (
      <React.Fragment>
        {chosenScreen}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ buzzScreens }) => {
  const { screenVisible, additionalData } = buzzScreens;
  return { screenVisible, additionalData };
}

export default withRouter(connect(mapStateToProps, { hideBuzzScreens })(BuzzScreens));