import React from "react";
import { useBackdrop } from "./customHooks";

const EnoughCoins = ({ onClose, details }) => {
  useBackdrop();
  const { badge } = details;

  return (
    <section className="buzz_modal">
      <div className="buzz_level">
        <button type="button" className="buzz_close_modal" onClick={onClose}>
          <img src="/images/buzz/buzz_close_white.png" srcset="/images/buzz/buzz_close_white@2x.png 2x" alt="close"/>
        </button>
        <div className="buzzlevelup_img_main">
          <img src="/images/buzz/levelup_green.png" alt="level-up"/>
          <span className="expert_level_tag">{badge.charAt(0).toUpperCase() + badge.slice(1)}</span>
        </div>
        <p className="to_congrat"><i className="fa fa-check" aria-hidden="true"/> Congratulations!</p>
        <h3 className="buzz_center_heading">You’ve ranked up!</h3>
        <p className="buzz_sm_text">Get 1 deal of the day and 10% increase on all earnings.</p>
        <div className="buzz_bonus_coins">
          <b>Bonus earned</b>
          <span className="buzz_earnedcoins"><img src="/images/buzz/buzz_coin.png" srcset="/images/buzz/buzz_close_white@2x.png 2x" alt="coins"/>50 Coins!</span>
        </div>
      </div>
    </section>
  )
}

export default EnoughCoins;