import {
  FETCH_PAYMENT_METHODS_START,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILED,
  FETCH_INVOICE_START,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_FAILED,
} from '../../constants/actionTypes';
import { paymentService } from '../../service/payment-service';

export const fetchPaymentMethods = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchPaymentMethodsStart());
    paymentService.getPaymentMethods()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchPaymentMethodsSuccess(response));
        } else {
          dispatch(fetchPaymentMethodsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchPaymentMethodsFailed(failureMessage));
      });
  }
};

export const fetchInvoice = (params) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchInvoiceStart());
    paymentService.getInvoice(params)
      .then(rawData => {
        // console.log(rawData)
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchInvoiceSuccess(response));
        } else {
          dispatch(fetchInvoiceFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchInvoiceFailed(failureMessage));
      });
  }
};

const fetchPaymentMethodsStart = () => {
  return {
    type: FETCH_PAYMENT_METHODS_START
  }
};

const fetchPaymentMethodsSuccess = data => {
  return {
    type: FETCH_PAYMENT_METHODS_SUCCESS,
    payload: data
  }
};

const fetchPaymentMethodsFailed = data => {
  return {
    type: FETCH_PAYMENT_METHODS_FAILED,
    payload: data
  }
};

const fetchInvoiceStart = () => {
  return {
    type: FETCH_INVOICE_START
  }
};

const fetchInvoiceSuccess = response => {
  return {
    type: FETCH_INVOICE_SUCCESS,
    payload: response
  }
};

const fetchInvoiceFailed = data => {
  return {
    type: FETCH_INVOICE_FAILED,
    payload: data
  }
};
