import { urlEncodedAuthHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';
import { convertData } from "./convertToUrlEncoded";

export const reportIssueService = {
  addReportIssue
};

function addReportIssue(data) {
  const formBody = convertData(data);

  const requestOptions = {
    method: 'POST',
    headers: urlEncodedAuthHeader(),
    body: formBody
  };

  return secureFetch(`${apiURL.REPORT_ISSUE}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}