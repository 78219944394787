import { MY_COINS } from "./routes";

export const generalToasts = reason => {
  let data;
  switch (reason) {
    case 'dailySignIn': {
      data = {
        title: 'Daily sign in bonus',
        description: 'Enjoy some coins on us',
        image: '/images/toasterpopup/toaster_coin.png',
        buttonLink: MY_COINS,
        buttonText: 'View my balance'
      }
      break;
    }
    case 'pageEditApproved': {
      data = {
        title: 'Page edit approved',
        description: 'Business information updated',
        image: '/images/toasterpopup/toaster_henry.png',
        buttonLink: MY_COINS,
        buttonText: 'View your coin balance'
      }
    }
  }
  return data;
}