import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { throttle, debounce } from "lodash";
import { MY_COINS } from "../../constants/routes";

//stylesheets
//import './Header.css';

//images and icons
import authUserIcon from "../../assets/images/icon_user_avtar.png";

//constants
import {
  HOME,
  LOADING_SEARCH_RESULTS,
  RESTAURANT_DETAILS,
  GUEST_SEARCH_RESULT,
  GUIDES,
} from "../../constants/routes";
import {
  updateWindowWidth,
  tryLogin,
  tryLogout,
  checkAuth,
  fetchTopLocations,
  fetchRecentLocations,
  fetchSearchedItems,
  fetchCuisines,
  fetchAllRestaurants,
  fetchTopTenRestaurants,
  setQueryString,
  fetchTags,
  setTopRestaurantMode,
  saveInteractionLog,
  fetchSearchPrefes,
} from "../../store/actions";
import { createLoggerData } from "../../utils/loggerData";
import { sectionName, logActions } from "../../constants/loggerConstants";
import { extractName } from "../../utils/intro";
import {
  IMAGE_PROFILE_SIZE,
  IMAGE_URL,
  getImageBase,
  USER_FOLER,
} from "../../constants/imageConstants";
import currencyIcon from "../../assets/images/icon_currency.png";
import currencyIconretina from "../../assets/images/icon_currency@2x.png";
//custom components
import Sidebar from "../Sidebar";
import HeaderAuth from "../HeaderAuth";
import SearchDropdown from "../../containers/SearchDropdown";
import CompleteDetailsModal from "../CompleteDetailsModal";
import OutsideAlerter from "../../components/OutsideAlerter";
import SearchedItemsDropdown from "../../components/SearchedItemsDropdown";
import PulseIndicator from "../../components/UI/PulseIndicator";
import { guideService } from "../../service/guide-service";
const {
  SECTION_LOGIN_MODAL,
  SECTION_SEARCH_INPUT,
  SECTION_SEARCH_BAR,
  SECTION_GUIDE,
} = sectionName;
const {
  LOG_SIGN_IN,
  LOG_SIGN_IN_FACEBOOK,
  LOG_SIGN_IN_GOOGLE,
  logInput,
  logSelection,
} = logActions;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      headerMenuExpanded: false,
      dropdownOpen: false,
      searchString: "",
      searchItemsString: "",
      fetchedOptions: [],
      fetchedOptionsGuide: [],
      loginType: "",
      sectionIntro: "",
    };
    this.handleSearchLocation = throttle(this.handleWithSearchString, 700);
    this.handleSearchItems = throttle(this.handleSearchedItem, 700);
    this.handleSearchForGuide = throttle(this.handleSearchStringForGuide, 700);
    this.props.checkAuth();
  }

  updateWidth = () => {
    this.props.updateWindowWidth(window.screen.width);
  };

  debouncedUpdateWidth = debounce(this.updateWidth, 200);

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.setState((prevState) => ({ ...prevState, isOpen: false }));
    });
    window.addEventListener("resize", this.debouncedUpdateWidth);
    this.props.fetchTopLocations();
    this.props.fetchRecentLocations();
    this.props.fetchCuisines();
    this.props.fetchTags();
    this.props.fetchSearchPrefes();
    if (this.props.location.search) {
      const sectionName = extractName(this.props.location.search);
      this.setState({
        sectionIntro: extractName(this.props.location.search),
        headerMenuExpanded: sectionName === "search-info",
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.restaurantFilters &&
      JSON.stringify(this.props.restaurantFilters) !==
        JSON.stringify(prevProps.restaurantFilters) &&
      this.props.restaurantFilters.locationName
    ) {
      this.setState({
        searchString: this.props.restaurantFilters.locationName,
      });
    }
    if (this.props.authUser !== prevProps.authUser && this.props.authUser) {
      if (this.state.loginType === "facebook") {
        this.props.saveInteractionLog(
          createLoggerData(
            SECTION_LOGIN_MODAL,
            LOG_SIGN_IN_FACEBOOK,
            this.props.location.pathname
          )
        );
        this.setState({ loginType: "" });
      } else if (this.state.loginType === "google") {
        this.props.saveInteractionLog(
          createLoggerData(
            SECTION_LOGIN_MODAL,
            LOG_SIGN_IN_GOOGLE,
            this.props.location.pathname
          )
        );
        this.setState({ loginType: "" });
      } else {
        this.props.saveInteractionLog(
          createLoggerData(
            SECTION_LOGIN_MODAL,
            LOG_SIGN_IN,
            this.props.location.pathname
          )
        );
      }
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.state.sectionIntro) {
        this.setState({ sectionIntro: "" });
      }
      if (this.state.headerMenuExpanded) {
        this.setState({ headerMenuExpanded: false });
      }
    }
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener("resize", this.debouncedUpdateWidth);
  }

  handleChangeSearchString = ({ target }, type) => {
    this.setState({ [type]: target.value });
    if (!target.value || target.value.length < 1) {
      this.setState({ fetchedOptionsGuide: [] });
      this.setState({ fetchedOptions: [] });
    }
    if (type === "searchString") {
      this.setState({ searchItemsString: "" });
      this.handleSearchForGuide(target.value);
      this.handleSearchLocation(target.value);
    } else {
      this.handleSearchItems(target.value);
    }
  };

  handleSearchStringForGuide = (text) => {
    guideService
      .getGuidesSearch(text)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status && response.data && response.data.length > 0) {
          //console.log("guides reas ==", response)
          this.setState({ fetchedOptionsGuide: response.data });
        } else {
          this.setState({ fetchedOptionsGuide: [] });
        }
      })
      .catch(() => {
        console.log("error in getch guide search");
      });
  };

  handleWithSearchString = (text) => {
    let autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      {
        input: text,
      },
      (searchResults, status) => {
        if (status === "OK") {
          this.setState({
            fetchedOptions: searchResults.map((result) => ({
              placeName: result.description,
              placeId: result.place_id,
            })),
          });
        }
      }
    );
    this.props.saveInteractionLog(
      createLoggerData(
        SECTION_SEARCH_INPUT,
        logInput(text),
        this.props.location.pathname
      )
    );
    this.props.fetchTopLocations(text);
    this.props.fetchRecentLocations(text);
  };

  handleSearchedItem = (text) => {
    if (text.length > 1) {
      this.props.saveInteractionLog(
        createLoggerData(
          SECTION_SEARCH_INPUT,
          logInput(text),
          this.props.location.pathname
        )
      );
      this.props.fetchSearchedItems(text);
    }
  };

  handleMenuButtonClicked = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleOpenOptions = () => {
    this.setState({ headerMenuExpanded: true });
  };

  handleCloseOptions = () => {
    this.setState({ headerMenuExpanded: false });
  };

  handleInputFocusChange = (visibility) => {
    this.setState({ dropdownOpen: visibility });
    if (visibility === "location") {
      this.props.fetchRecentLocations();
    }
  };

  handleItemClick = (type, identifier, selectedName, selectedItem) => {
    if (type === "restaurants") {
      this.setState({
        searchItemsString: selectedName,
      });
      this.props.saveInteractionLog(
        createLoggerData(
          SECTION_SEARCH_BAR,
          logSelection(identifier, "restaurant"),
          this.props.location.pathname
        )
      );
      return this.props.history.push(`${RESTAURANT_DETAILS}/${identifier}`);
    } else {
      let newLocation =
        this.props.restaurantFilters && this.props.restaurantFilters.location
          ? this.props.restaurantFilters.location
          : {
              latitude: 52.9547831,
              longitude: -1.1581086,
            };
      if (type === "menuTypes") {
        this.props.setQueryString({
          menuType: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || "",
        });
      }
      if (type === "menus") {
        this.props.setQueryString({
          menu: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || "",
        });
      }
      if (type === "tags") {
        this.setState({
          searchItemsString: selectedName || selectedItem.title,
        });
        this.props.setQueryString({
          tag: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || "",
        });
        this.props.saveInteractionLog(
          createLoggerData(
            SECTION_SEARCH_BAR,
            logSelection(`${type} ${selectedName || selectedItem.title}`, ""),
            this.props.location.pathname
          )
        );
      }
      if (type === "cuisines") {
        this.setState({
          searchItemsString: selectedName || selectedItem.title,
        });
        this.props.setQueryString({
          cuisine: identifier,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || "",
        });
        this.props.saveInteractionLog(
          createLoggerData(
            SECTION_SEARCH_BAR,
            logSelection(`${type} ${selectedName || selectedItem.title}`, ""),
            this.props.location.pathname
          )
        );
      }
      if (type === "topTen") {
        this.props.fetchTopTenRestaurants();
        this.props.setTopRestaurantMode();
        this.props.history.push(LOADING_SEARCH_RESULTS);
        return;
      }
      if (type === "allRestaurants") {
        this.props.setQueryString({
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || "",
        });
        this.props.fetchAllRestaurants();
        this.props.history.push(LOADING_SEARCH_RESULTS);
        return;
      }
      if (type === "onEnterKeyPressed") {
        this.props.setQueryString({
          queryString: this.state.searchItemsString,
          location: newLocation,
          locationName: this.props.restaurantFilters.locationName || "",
        });
      }
      this.props.setTopRestaurantMode();
      this.setState({ headerMenuExpanded: false });
      this.props.fetchAllRestaurants();
      this.props.history.push(LOADING_SEARCH_RESULTS);
    }
  };

  handleSearchRestaurantsClick = (type) => {
    this.handleItemClick(type);
  };

  handleOnSearchedItemsKeyPressed = (event) => {
    if (event.key === "Enter") {
      this.handleItemClick("onEnterKeyPressed");
    }
  };

  handleSetLoginType = (type) => {
    this.setState({ loginType: type });
  };

  handleSecondSearchBoxTextClear = (text) => {
    this.setState({ searchItemsString: text });
  };

  render() {
    const {
      isOpen,
      headerMenuExpanded,
      dropdownOpen,
      searchString,
      searchItemsString,
      fetchedOptions,
      fetchedOptionsGuide,
      sectionIntro,
    } = this.state;
    const {
      authUser,
      tryLogout,
      searchedItems,
      restaurantFilters,
      searchedItemsLoading,
      userData,
      location,
    } = this.props;

    let headerClasses = "top-header v1website_header Collapse ";
    if (headerMenuExpanded) {
      headerClasses = "top-header v1website_header Collapse expandAll";
    }

    let searchBoxesVisible = true;
    if (
      location.pathname === HOME ||
      ((location.pathname === GUEST_SEARCH_RESULT ||
        location.pathname.indexOf(GUIDES) !== -1) &&
        !authUser)
    ) {
      searchBoxesVisible = false;
    }

    return (
      <header>
        <OutsideAlerter
          className={headerClasses}
          onClickOutside={() => this.handleCloseOptions(headerClasses)}
        >
          <div className="wrapper ">
            <div className="d-flex-header">
              <div
                className="toggle_menu"
                onClick={this.handleMenuButtonClicked}
              >
                <img src="/images/homepage/icon_menu_burger.png" alt="" />
              </div>
              <div className="logo">
                <Link to={HOME}>
                  <img
                    src="/images/homepage/tablefy_logo_primary.png"
                    srcset="/images/homepage/tablefy_logo_primary@2x.png 2x"
                    alt=""
                  />
                </Link>
              </div>
              <div className="logo logo_for_tablet">
                <Link to={HOME}>
                  <img
                    src="/images/homepage/tablefy_logo_primary_md.png"
                    alt=""
                  />
                </Link>
              </div>
              {searchBoxesVisible && (
                <div className="d-flex-align header_top_location_outer">
                  <div className="header_top_location">
                    <i className="fa fa-map-marker" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter location"
                      value={searchString}
                      readOnly
                      style={{
                        backgroundColor: searchString ? "#ffffff" : null,
                      }}
                    />
                    <button className="btn">
                      <i className="fa fa-send" />
                    </button>
                  </div>
                  <div className="header_top_sea ">
                    <button className="btn">
                      <i className="fa fa-search" />
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by cuisine, name, dish etc."
                      value={searchItemsString}
                      readOnly
                      style={{
                        backgroundColor: searchItemsString ? "#ffffff" : null,
                      }}
                    />
                  </div>
                </div>
              )}
              <HeaderAuth
                headerClasses={headerClasses}
                onSetLoginType={this.handleSetLoginType}
                parentHeaderVisible={headerMenuExpanded}
                onCloseDropdown={this.handleCloseOptions}
              />
              <Sidebar
                visible={isOpen}
                onCloseClick={this.handleMenuButtonClicked}
                tryLogout={tryLogout}
                authUser={authUser}
              />
              <CompleteDetailsModal />
            </div>
          </div>
        </OutsideAlerter>
        <div className="header_other v1website_header ">
          <div className="wrapper mx-20">
            <div className="d-flex-header">
              <div className="logo">
                <Link to={HOME}>
                  <img
                    src="/images/homepage/tablefy_logo_primary.png"
                    srcset="/images/homepage/tablefy_logo_primary@2x.png 2x"
                    alt="logo"
                  />
                </Link>
              </div>
              {searchBoxesVisible && (
                <div className="d-flex-align header_top_location_outer">
                  <div className="header_top_location">
                    {sectionIntro === "search-info" ? (
                      <PulseIndicator
                        style={{ position: "absolute", left: -38, top: 12 }}
                      />
                    ) : null}
                    <i className="fa fa-map-marker" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter location"
                      onFocus={() => this.handleInputFocusChange("location")}
                      onBlur={() => this.handleInputFocusChange(false)}
                      onChange={(e) =>
                        this.handleChangeSearchString(e, "searchString")
                      }
                      value={searchString}
                      style={{
                        backgroundColor: searchString ? "#ffffff" : null,
                      }}
                    />
                    <button className="btn">
                      <i className="fa fa-send" />
                    </button>
                    <SearchDropdown
                      visible={dropdownOpen === "location"}
                      fetchedOptions={fetchedOptions}
                      fetchedOptionsGuide={fetchedOptionsGuide}
                      handleSecondSearchBoxTextClear={
                        this.handleSecondSearchBoxTextClear
                      }
                    />
                  </div>
                  <div className="header_top_sea tb-300-dashboard">
                    <button className="btn">
                      <i className="fa fa-search" />
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by cuisine, name, dish etc."
                      onFocus={() => this.handleInputFocusChange("filters")}
                      onBlur={() => this.handleInputFocusChange(false)}
                      onChange={(e) =>
                        this.handleChangeSearchString(e, "searchItemsString")
                      }
                      value={searchItemsString}
                      disabled={!restaurantFilters.locationName}
                      style={{
                        backgroundColor: searchItemsString ? "#ffffff" : null,
                      }}
                      onKeyDown={this.handleOnSearchedItemsKeyPressed}
                    />
                    <SearchedItemsDropdown
                      dropdownOpen={dropdownOpen}
                      searchedItems={searchedItems}
                      onMenuItemClick={this.handleItemClick}
                      filters={restaurantFilters}
                      loading={searchedItemsLoading}
                      isSearchBoxEmpty={!searchItemsString}
                      searchedItemLength={searchItemsString.length > 1}
                      onSearchAllRestaurants={this.handleSearchRestaurantsClick}
                    />
                  </div>
                </div>
              )}
              <div className="top-header-right">
                <div className="d-flex-align open_header">
                  <Link to={MY_COINS}>
                    {authUser ? (
                      <div className="top-header-cart d-flex-align">
                        <img
                          src={currencyIcon}
                          srcset={`${currencyIconretina} 2x`}
                          alt="currency"
                          className="top-header-currency"
                        />
                        <span
                          className="top-header-price"
                          style={{ paddingLeft: 8, paddingRight: 20 }}
                        >
                          {Math.floor(userData.coins) || 0}
                        </span>
                      </div>
                    ) : (
                      <div className="top-header-cart d-flex-align">
                        <img
                          src="/images/homepage/coin_grey_22.png"
                          alt="currency"
                        />
                        <span className="grey_coin_amount">0</span>
                      </div>
                    )}
                  </Link>
                  <div
                    className="top-user ovaluser_loop po-rel"
                    onClick={this.handleOpenOptions}
                  >
                    <span className="grey_menubar_icon">
                      <img src="/images/homepage/menubar_icon.png" />
                    </span>
                    <div className="top-user-picture d-flex-align top-dropdown-outer po-rel">
                      {userData &&
                      userData.profilePicture &&
                      userData.profilePictureDetails ? (
                        <img
                          // src={`${IMAGE_URL_ROOT}/users/${IMAGE_PROFILE_SIZE}/${userData.profilePictureDetails.fileName}`}
                          src={`${IMAGE_URL}/${getImageBase(
                            userData.profilePictureDetails.fileName,
                            USER_FOLER,
                            IMAGE_PROFILE_SIZE.width,
                            IMAGE_PROFILE_SIZE.height
                          )}`}
                          alt="user"
                          onError={(event) => {
                            event.target.src = authUserIcon;
                          }}
                          className="user_avtar"
                        />
                      ) : (
                        <img
                          src="/images/homepage/acnt_user.png"
                          className="user_avtar"
                          alt="user"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ auth, restaurant, user }) => {
  return {
    authLoading: auth.isLoading,
    authUser: !!auth.payload,
    restaurantFilters: restaurant.filters,
    searchedItems: user.searchedItemsPayload,
    searchedItemsLoading: user.searchedItemsLoading,
    userData: auth.payload,
    searchPreferences: user.searchPreferences,
    recentLocations: user.recentLocationsPayload,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateWindowWidth,
    tryLogin,
    tryLogout,
    checkAuth,
    fetchTopLocations,
    fetchRecentLocations,
    fetchSearchedItems,
    fetchCuisines,
    fetchAllRestaurants,
    fetchTopTenRestaurants,
    setQueryString,
    fetchTags,
    setTopRestaurantMode,
    saveInteractionLog,
    fetchSearchPrefes,
  })(Header)
);
