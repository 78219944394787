import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const paymentService = {
  getPaymentMethods,
  createPaymentIntent,
  createPaypalTransaction,
  capturePaypalTransaction,
  getInvoice,
  anotherPaymentMethod,
};

function createPaymentIntent(bundleId, paymentId, restaurant) {
  const data = {
    item: bundleId
  }

  if (paymentId) {
    data.paymentMethod = paymentId;
  }
  if (restaurant) {
    data.restaurant = restaurant;
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ ...data })
  };

  return secureFetch(`${apiURL.CREATE_PAYMENT_INTENT}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getPaymentMethods() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.PAYMENT_METHODS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getInvoice(params) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.ANOTHER_PAYMENT_METHOD}/?pageSize=10&currentPage=${params && params.currentPage ? params.currentPage : 1}${params.fromDate == "" || params.fromDate == undefined ? "" : `&startDate=${params.fromDate}`}${params.toDate == "" || params.toDate == undefined  ? "" : `&endDate=${params.toDate}`}&restaurantId=${params && params.restaurantId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    })
}

function createPaypalTransaction(item, restaurantId) {
  const data = {
    item
  }

  if (restaurantId) {
    data.restaurant = restaurantId;
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ ...data })
  };

  return secureFetch(`${apiURL.CREATE_PAYPAL_TRANSACTION}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function capturePaypalTransaction(orderId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ orderId })
  };

  return secureFetch(`${apiURL.CAPTURE_PAYPAL_TRANSACTION}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function anotherPaymentMethod(item) {
  const data = {
    item
  }

  // if (restaurantId) {
  //   data.restaurant = restaurantId;
  // }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ ...data.item.payloadValues })
  };

  return secureFetch(`${apiURL.ANOTHER_PAYMENT_METHOD}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}