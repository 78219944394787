import {
  FETCH_ALL_RESTAURANTS_START,
  FETCH_ALL_RESTAURANTS_SUCCESS,
  FETCH_ALL_RESTAURANTS_FAILED,
  SET_QUERY_STRING,
  REMOVE_QUERY_STRING,
  FETCH_RESTAURANT_DETAILS_START,
  FETCH_RESTAURANT_DETAILS_SUCCESS,
  FETCH_RESTAURANT_DETAILS_FAILED,
  FETCH_RESTAURANT_LOCATIONS_START,
  FETCH_RESTAURANT_LOCATIONS_SUCCESS,
  FETCH_RESTAURANT_LOCATIONS_FAILED,
  SET_TOP_RESTAURANT_MODE,
  REMOVE_TOP_RESTAURANT_MODE,
  UPDATE_RESTAURANT_DETAILS_START,
  UPDATE_RESTAURANT_DETAILS_SUCCESS,
  UPDATE_RESTAURANT_DETAILS_FAILED,
  UPDATE_RESTAURANT_HYGIENE_START,
  UPDATE_RESTAURANT_HYGIENE_SUCCESS,
  UPDATE_RESTAURANT_HYGIENE_FAILED,
  SET_UPDATE_RESTAURANT_STATUS,
  REMOVE_UPDATE_RESTAURANT_STATUS,
  UPDATE_RESTAURANT_DATA,
  FETCH_RESTAURANT_DETAIL_OFFERS_START,
  FETCH_RESTAURANT_DETAIL_OFFERS_SUCCESS,
  FETCH_RESTAURANT_DETAIL_OFFERS_FAILED,
  FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_START,
  FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_SUCCESS,
  FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_FAILED,
  FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_START,
  FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_SUCCESS,
  FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_FAILED,
  UPDATE_RESTAURANT_COINS,
  FETCH_BOOKING_OFFERS_EXCEPTION,
  FETCH_BOOKING_OFFERS_EXCEPTION_SUCCESS,
  FETCH_BOOKING_OFFERS_EXCEPTION_FAILED
} from '../../constants/actionTypes';
import {DESKTOP_SMALL_MAX, DESKTOP_SMALL_MIN } from "../../constants/displayTypes";
import { restaurantService } from '../../service/restaurant-service';
import { offersService } from '../../service/offers-service';
import { topLocationService } from '../../service/topLocationService';
import { storeRecentLocations } from '../../utils/recentLocationsContainer';
import { sortMenus } from "../../utils/sortItems";
import { toast } from "react-toastify";

export const fetchAllRestaurants = () => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const { currentPage, totalPage, filters, controller } = getState().restaurant;
    const nextPage = currentPage + 1;
    if (nextPage !== 1 && (nextPage > totalPage)) return;
    let pageSize = (window.innerWidth >= DESKTOP_SMALL_MIN && window.innerWidth <= DESKTOP_SMALL_MAX) ? 21 : 22
    //console.log("pagesize = ", pageSize)
    //controller.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
      searchQuery: filters.queryString || '',
      location: filters.location
        ? `%7B%22lat%22:${filters.location.latitude},%22lng%22:${filters.location.longitude}%7D`
        : '',
      cuisine: filters.cuisine || '',
      menu: filters.menu || '',
      menuType: filters.menuType || '',
      tag: filters.tag || '',
      orderBy: filters.orderBy || '',
      mapRadius: filters.mapRadius || '',
      placeId: filters.placeId || '',
      name: filters.locationName || '',
      saveRecent: filters.saveRecent || '',
      pageSize: pageSize
    };
    if (filters && filters.locationName) {
      storeRecentLocations(filters, 'coordinate');
    }
    dispatch(fetchAllRestaurantsStart(newController));
    const signal = newController.signal;
    restaurantService.getAllRestaurants(params, signal)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchAllRestaurantsSuccess(response));
        } else {
          dispatch(fetchAllRestaurantsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchAllRestaurantsFailed(failureMessage));
      });
  }
};

export const fetchTopTenRestaurants = () => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const { filters } = getState().restaurant;

    const params = {
      currentPage: 1,
      orderBy: 'rating',
      pageSize: 10,
      location: filters.location
        ? `%7B%22lat%22:${filters.location.latitude},%22lng%22:${filters.location.longitude}%7D`
        : '',
      cuisine: '',
      menu: '',
      menuType: '',
      tag: '',
      searchQuery: '',
    };

    dispatch(setQueryString({ ...filters }));
    dispatch(fetchAllRestaurantsStart());
    restaurantService.getAllRestaurants(params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchAllRestaurantsSuccess({ ...response, totalItem: 10, totalPage: 1 }));
        } else {
          dispatch(fetchAllRestaurantsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchAllRestaurantsFailed(failureMessage));
      });
  }
};

export const updateViewCount = (id) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    restaurantService.updateViewCount(id)
      .then(rawData => {
        return rawData.json();

      }).then(response => {
        //console.log("view count ===", response)
      })
  }
}

export const fetchRestaurantDetails = (id) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchRestaurantDetailsStart());
    restaurantService.getRestaurantDetails(id)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          let newMenuList = [];
          response.data.menuList.forEach(menuData => {
            if (menuData.menus && menuData.menus.length) {
              menuData.menus.forEach(menuItem => {
                const newMenuItem = {
                  ...menuItem,
                  menuTypeID: menuData.menuType._id,
                  menuTypeTitle: menuData.menuType.title
                };
                newMenuList.push(newMenuItem);
              });
            }
          });
          const textReviews = [];
          const imageReviews = [];
          response.data.reviewList.forEach(review => {
            if (review.photoDetails && review.photoDetails.length) {
              imageReviews.push({ ...review });
            }
            if (review.review) {
              textReviews.push({ ...review });
            }
          });
          const sortedMenuList = sortMenus(newMenuList);
          dispatch(fetchRestaurantDetailsSuccess({ ...response.data, imageReviews, textReviews }, sortedMenuList));
        } else {
          dispatch(fetchRestaurantDetailsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchRestaurantDetailsFailed(failureMessage));
      });
  }
};

export const updateRestaurantDetails = (id, details) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateRestaurantDetailsStart());
    restaurantService.updateRestaurantDetails(id, details)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(updateRestaurantDetailsSuccess(response.data));
          dispatch(setUpdateRestaurantStatus());
        } else {
          dispatch(updateRestaurantDetailsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateRestaurantDetailsFailed(failureMessage));
      });
  }
};

export const updateRestaurantDetailsHygiene = id => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(updateRestaurantHygieneStart());
    restaurantService.updateRestaurantDetailsHygiene(id)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status && response.data.hygieneRecordUpdated) {
          dispatch(updateRestaurantHygieneSuccess(response.data.restaurantDetails));
        } else {
          dispatch(updateRestaurantHygieneFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateRestaurantHygieneFailed(failureMessage));
      });
  }
};

export const fetchRestaurantLocations = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchRestaurantLocationsStart());
    topLocationService.getTopLocations()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantLocationsSuccess(response.data));
        } else {
          dispatch(fetchRestaurantLocationsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchRestaurantLocationsFailed(failureMessage));
      });
  }
};

export const fetchRestaurantDetailOffers = (id, category) => {

  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchRestaurantDetailOffersStart());
    offersService.getRestaurantDetailOffers(id, category)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantDetailOffersSuccess({ ...response.data }));
        } else {
          dispatch(fetchRestaurantDetailsFailed(failureMessage));
        }
      })
      .catch((error) => {
        dispatch(fetchRestaurantDetailsFailed(failureMessage));
      });
  }
};

export const fetchRestaurantDetailExceptionOffers = (id, dataObj) => {

  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchRestaurantDetailExceptionOffersStart());
    offersService.getRestaurantDetailExceptionOffers(id, dataObj)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantDetailExceptionOffersSuccess({ ...response.data }));
        } else {
          dispatch(fetchRestaurantDetailExceptionOffersFailed(failureMessage));
        }
      })
      .catch((error) => {
        dispatch(fetchRestaurantDetailExceptionOffersFailed(failureMessage));
      });
  }
};

export const fetchRestaurantDetailOtherOffers = (id, dataObj) => {

  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchRestaurantDetailOtherOffersStart());
    offersService.getRestaurantDetailOtherOffers(id, dataObj)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchRestaurantDetailOtherOffersSuccess({ ...response.data }));
        } else {
          dispatch(fetchRestaurantDetailOtherOffersFailed(failureMessage));
        }
      })
      .catch((error) => {
        dispatch(fetchRestaurantDetailOtherOffersFailed(failureMessage));
      });
  }
};

const fetchAllRestaurantsStart = controller => {
  return {
    type: FETCH_ALL_RESTAURANTS_START,
    controller
  }
};

const fetchAllRestaurantsSuccess = data => {
  return {
    type: FETCH_ALL_RESTAURANTS_SUCCESS,
    payload: data
  }
};

const fetchAllRestaurantsFailed = data => {
  return {
    type: FETCH_ALL_RESTAURANTS_FAILED,
    payload: data
  }
};

const fetchRestaurantDetailsStart = () => {
  return {
    type: FETCH_RESTAURANT_DETAILS_START
  }
};

const fetchRestaurantDetailOffersStart = () => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_START
  }
};

export const fetchRestaurantDetailOffersSuccess = (data) => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_SUCCESS,
    payload: data,
  }
};

export const fetchRestaurantDetailOffersFailed = data => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_FAILED,
    payload: data
  }
};

const fetchRestaurantDetailExceptionOffersStart = () => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_START
  }
};

export const fetchRestaurantDetailExceptionOffersSuccess = (data) => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_SUCCESS,
    payload: data,
  }
};

export const fetchRestaurantDetailExceptionOffersFailed = data => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_FAILED,
    payload: data
  }
};

const fetchRestaurantDetailOtherOffersStart = () => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_START
  }
};

export const fetchRestaurantDetailOtherOffersSuccess = (data) => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_SUCCESS,
    payload: data,
  }
};

export const fetchRestaurantDetailOtherOffersFailed = data => {
  return {
    type: FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_FAILED,
    payload: data
  }
};

export const fetchRestaurantDetailsSuccess = (data, menuList) => {
  return {
    type: FETCH_RESTAURANT_DETAILS_SUCCESS,
    payload: data,
    menuList
  }
};

export const fetchRestaurantDetailsFailed = data => {
  return {
    type: FETCH_RESTAURANT_DETAILS_FAILED,
    payload: data
  }
};

const fetchRestaurantLocationsStart = () => {
  return {
    type: FETCH_RESTAURANT_LOCATIONS_START
  }
};

const fetchRestaurantLocationsSuccess = data => {
  return {
    type: FETCH_RESTAURANT_LOCATIONS_SUCCESS,
    payload: data
  }
};

const fetchRestaurantLocationsFailed = data => {
  return {
    type: FETCH_RESTAURANT_LOCATIONS_FAILED,
    payload: data
  }
};

export const setQueryString = filters => {
  return {
    type: SET_QUERY_STRING,
    payload: filters
  }
};

export const removeQueryString = () => {
  return {
    type: REMOVE_QUERY_STRING
  }
};

export const setTopRestaurantMode = () => {
  return {
    type: SET_TOP_RESTAURANT_MODE
  }
};

export const removeTopRestaurantMode = () => {
  return {
    type: REMOVE_TOP_RESTAURANT_MODE
  }
};

const updateRestaurantDetailsStart = () => {
  return {
    type: UPDATE_RESTAURANT_DETAILS_START
  }
};

const updateRestaurantDetailsSuccess = data => {
  return {
    type: UPDATE_RESTAURANT_DETAILS_SUCCESS,
    payload: data
  }
};

const updateRestaurantDetailsFailed = data => {
  return {
    type: UPDATE_RESTAURANT_DETAILS_FAILED,
    payload: data
  }
};

const updateRestaurantHygieneStart = () => {
  return {
    type: UPDATE_RESTAURANT_HYGIENE_START
  }
};

const updateRestaurantHygieneSuccess = data => {
  return {
    type: UPDATE_RESTAURANT_HYGIENE_SUCCESS,
    payload: data
  }
};

const updateRestaurantHygieneFailed = data => {
  return {
    type: UPDATE_RESTAURANT_HYGIENE_FAILED,
    payload: data
  }
};

export const setUpdateRestaurantStatus = () => {
  return {
    type: SET_UPDATE_RESTAURANT_STATUS,
  }
};

export const removeUpdateRestaurantStatus = () => {
  return {
    type: REMOVE_UPDATE_RESTAURANT_STATUS,
  }
};

export const updateRestaurantData = data => {
  return {
    type: UPDATE_RESTAURANT_DATA,
    payload: data
  }
}

export const updateRestaurantCoins = coins => {
  return {
    type: UPDATE_RESTAURANT_COINS,
    payload: coins
  }
}

export const fetchBookingOfferException = (id, dataObj) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchBookingOfferExceptionStart());
    offersService.getRestaurantDetailExceptionOffers(id, dataObj)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchBookingOfferExceptionSuccess([...response.data]));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message;
          toast.error(errorMessage);
          dispatch(fetchBookingOfferExceptionFailed(errorMessage));
        }
      })
      .catch(() => {
        toast.error(failureMessage);
        dispatch(fetchBookingOfferExceptionFailed(failureMessage));
      });
  }
};

const fetchBookingOfferExceptionStart = () => {
  return {
    type: FETCH_BOOKING_OFFERS_EXCEPTION
  }
};

const fetchBookingOfferExceptionSuccess = (data) => {
  return {
    type: FETCH_BOOKING_OFFERS_EXCEPTION_SUCCESS,
    payload: data,
  }
};

const fetchBookingOfferExceptionFailed = data => {
  return {
    type: FETCH_BOOKING_OFFERS_EXCEPTION_FAILED,
    payload: data
  }
};
