import { toast } from "react-toastify";
import { feedService } from "../../service/feed-service";
import {
  FETCH_FEEDS_START,
  FETCH_FEEDS_SUCCESS,
  FETCH_FEEDS_FAILED,
  RESET_FEED_CURRENT_PAGE,
  UPDATE_FEED_START,
  UPDATE_FEED_SUCCESS,
  UPDATE_FEED_FAILED,
  DELETE_FEED_START,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAILED,
  FETCH_FEEDS_COUNT_START,
  FETCH_FEEDS_COUNT_SUCCESS,
  FETCH_FEEDS_COUNT_FAILED
} from "../../constants/actionTypes";

export const fetchFeeds = currentPage => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    const params = {
      currentPage,
    };
    dispatch(fetchFeedsStart());
    feedService.getFeeds(params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchFeedsSuccess(response));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(fetchFeedsFailed(errorMessage));
        }
      })
      .catch(() => {
        dispatch(fetchFeedsFailed(failureMessage));
      });
  }
};

export const fetchNewFeedsCount = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchNewFeedsCountStart());
    feedService.getNewFeedsCount()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchNewFeedsCountSuccess(response));
        } else {

          dispatch(fetchNewFeedsCountFailed('Error while fetching new feeds count!'));
        }
      })
      .catch(() => {
        dispatch(fetchNewFeedsCountFailed(failureMessage));
      });
  }
};


const fetchFeedsStart = () => {
  return {
    type: FETCH_FEEDS_START
  }
};

const fetchFeedsSuccess = data => {
  return {
    type: FETCH_FEEDS_SUCCESS,
    payload: data
  }
};

const fetchFeedsFailed = data => {
  return {
    type: FETCH_FEEDS_FAILED,
    payload: data
  }
};

export const resetFeedCurrentPage = () => {
  return {
    type: RESET_FEED_CURRENT_PAGE
  }
}

export const updateFeed = (id) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    const status = { readStatus: true }
    dispatch(updateFeedStart());
    feedService.updateFeed(id, status)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(updateFeedSuccess(response.data));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(updateFeedFailed(errorMessage));
        }
      })
      .catch(() => {
        dispatch(updateFeedFailed(failureMessage));
      });
  }
}

const updateFeedStart = () => {
  return {
    type: UPDATE_FEED_START
  }
};

const updateFeedSuccess = data => {
  return {
    type: UPDATE_FEED_SUCCESS,
    payload: data
  }
};

const updateFeedFailed = data => {
  return {
    type: UPDATE_FEED_FAILED,
    payload: data
  }
};

export const removeFeed = id => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const status = { deleteStatus: true }
    const { currentPage } = getState().feed;
    dispatch(removeFeedStart());
    feedService.updateFeed(id, status)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(removeFeedSuccess(response.data));
          feedService.getFeeds({ currentPage })
            .then(rawData => {
              return rawData.json();
            })
            .then(listResponse => {
              if (listResponse.status) {
                dispatch(fetchFeedsSuccess(listResponse));
              } else {
                const errorMessage = Array.isArray(listResponse.message) ? listResponse.message[0] : listResponse.message
                toast.error(errorMessage);
                dispatch(fetchFeedsFailed(errorMessage));
              }
            })
            .catch(() => {
              dispatch(fetchFeedsFailed(failureMessage));
              toast.error(failureMessage);
            });
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(removeFeedFailed(errorMessage));
        }
      })
      .catch(() => {
        dispatch(removeFeedFailed(failureMessage));
      });
  }
}

const removeFeedStart = () => {
  return {
    type: DELETE_FEED_START
  }
};

const removeFeedSuccess = data => {
  return {
    type: DELETE_FEED_SUCCESS,
    payload: data
  }
};

const removeFeedFailed = data => {
  return {
    type: DELETE_FEED_FAILED,
    payload: data
  }
};

const fetchNewFeedsCountStart = () => {
  return {
    type: FETCH_FEEDS_COUNT_START
  }
};

const fetchNewFeedsCountSuccess = data => {
  return {
    type: FETCH_FEEDS_COUNT_SUCCESS,
    payload: data
  }
};

const fetchNewFeedsCountFailed = data => {
  return {
    type: FETCH_FEEDS_COUNT_FAILED,
    payload: data
  }
};