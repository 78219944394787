import React from "react";
import { useFormik } from "formik";

//custom components
import SpinnerWhite from "../../components/Spinner/SpinnerWhite";

//constants
import { resetPasswordLinkSchema } from "../../validationSchema/resetPasswordLink";

const ResetPassword = ({ onBackClick, onResetPasswordSubmit, isLoading }) => {
  const formik = useFormik({
    initialValues: {
      emailOrMobile: ''
    },
    validationSchema: resetPasswordLinkSchema,
    onSubmit: (values) => onResetPasswordSubmit(values),
  });

  return (
    <div>
      <form className="home_forget_pass" onSubmit={formik.handleSubmit}>
        <div className="home_step_back d-inline-flex" onClick={onBackClick}>
          <img src="/images/icon_back_red.png" srcset="/images/icon_back_red@2x.png 2x" alt="" />
          <span>Sign in</span>
        </div>
        <h4 className="font-weight-900">Reset your password</h4>
        <p>Enter the email address associated with your Go dine account, and we’ll email you a link
          to reset your password.</p>
        <div className="sing_input_outer">
          <div className={`justify-center d-flex  input-outer-div ${formik.touched.emailOrMobile && formik.errors.emailOrMobile ? "field-error" : ""}`}>
            <div className="w-100">
              <input
                // className="form-control sing_input"
                className={`removed-div-shadow input100 form-control ${formik.values.emailOrMobile === '' ? '' : 'has-val'} ${formik.touched.emailOrMobile && formik.errors.confirmationToken ? "form_error" : ""}`}
                id="emailOrMobile"
                name="emailOrMobile"
                type="text"
                placeholder="Email or mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emailOrMobile}
              />
            </div>
            {formik.touched.emailOrMobile && formik.errors.emailOrMobile && <span className="mr-15 mb-5px pointer"><img className="info-size icon-postion" src="images/booking/icon_info.png" /></span>}
          </div>
          {formik.touched.emailOrMobile && formik.errors.emailOrMobile ? (
            <div className="invalid-feedback" style={{ display: "block" }}>{formik.errors.emailOrMobile}</div>
          ) : null}
        </div>
        <button className="btn btn-red width-240" type="submit">
          {isLoading
            ? <SpinnerWhite />
            : 'Send link'}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
