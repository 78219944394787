import { cloneDeep } from 'lodash';
import {
  FETCH_FEEDS_START,
  FETCH_FEEDS_SUCCESS,
  FETCH_FEEDS_FAILED,
  RESET_FEED_CURRENT_PAGE,
  UPDATE_FEED_START,
  UPDATE_FEED_SUCCESS,
  UPDATE_FEED_FAILED,
  DELETE_FEED_START,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAILED,
  FETCH_FEEDS_COUNT_START,
  FETCH_FEEDS_COUNT_SUCCESS,
  FETCH_FEEDS_COUNT_FAILED
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
  updateLoading: false,
  feedCount: 0,
  feedListMobile: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEEDS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_FEEDS_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;
      return {
        ...state,
        error: null,
        payload: cloneDeep(data),
        feedListMobile: state.feedListMobile.concat(data),
        currentPage,
        pageSize,
        totalItem,
        totalPage,
        isLoading: false
      };
    }
    case FETCH_FEEDS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case RESET_FEED_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: 0,
        pageSize: null,
        totalItem: null,
        totalPage: null,
        payload: [],
        feedListMobile: []
      }
    }
    case UPDATE_FEED_START: {
      return { ...state, updateLoading: true, error: null };
    }
    case UPDATE_FEED_SUCCESS: {
      const updateIndex = state.payload.findIndex(item => item._id === action.payload._id);
      const newPayload = cloneDeep(state.payload);
      newPayload[updateIndex].readStatus = true
      return { ...state, payload: cloneDeep(newPayload), updateLoading: false };
    }
    case UPDATE_FEED_FAILED: {
      return { ...state, updateLoading: false, error: action.payload };
    }
    case DELETE_FEED_START: {
      return { ...state, updateLoading: true, error: null };
    }
    case DELETE_FEED_SUCCESS: {
      return { ...state, updateLoading: false, error: null };
    }
    case DELETE_FEED_FAILED: {
      return { ...state, updateLoading: false, error: action.payload };
    }
    case FETCH_FEEDS_COUNT_START: {
      return { ...state, error: null };
    }
    case FETCH_FEEDS_COUNT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        feedCount: data
      };
    }
    case FETCH_FEEDS_COUNT_FAILED: {
      return { ...state, feedCount: 0, error: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
