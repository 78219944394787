import { SHOW_BUZZ_SCREEN, HIDE_BUZZ_SCREEN } from '../../constants/actionTypes';

export const showBuzzScreens = (type, otherData) => {
  return {
    type: SHOW_BUZZ_SCREEN,
    payload: type,
    additionalData: { ...otherData }
  }
}

export const hideBuzzScreens = () => {
  return {
    type: HIDE_BUZZ_SCREEN
  }
}