import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

//custom components
import MainApp from './MainApp';
import RestaurantEmbed from "../RestaurantEmbed";

//route constants
import { HOME } from "../../constants/routes";

//Define the authentication and routing logic here

class App extends React.Component {
  constructor(props) {
    super(props);
    window.tablefyHistory = this.props.history;
  }

  render() {
    const { match, location } = this.props;

    if (location.pathname === '/') {
      return (<Redirect to={HOME}/>);
    }

    return (
      <React.Fragment>
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <Switch>
          <Route path={`${match.url}restaurant-embed/:id`} exact component={RestaurantEmbed}/>
          <Route path={`${match.url}`} component={MainApp}/>
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
