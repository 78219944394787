import React from "react";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import io from "socket.io-client";

import {
  hideToastSettings,
  fetchProfile,
  updateRestaurantCoins,
  updateUserCoins,
  showBuzzScreens
} from '../../store/actions';
import { userService } from "../../service/user-service";
import { SOCKET_URL } from "../../service/Apiconfig";
import { LEVEL_UP } from "../../constants/buzzConstants";

import SpinnerWhite from "../../components/Spinner/SpinnerWhite";
import ToastMessages from "../ToastMessages";

const options = [
  { value: 'tomorrow', label: 'Until tomorrow' },
  { value: 'week', label: 'For a week' },
]

class ToastSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      submitting: false
    }

    const socket = io.connect(SOCKET_URL);
    const toastOptions = {
      autoClose: true,
      closeOnClick: false,
      className: 'tablefyToast',
      position: "top-right",
      closeButton: false
    }

    socket.on('restaurantWalletUpdate', (res) => {
      const { authUser } = this.props;
      if (res && res.restaurantData && !!authUser && (!authUser.snoozSetting || moment().isAfter(moment(authUser.snoozSetting)))) {
        this.props.updateRestaurantCoins(res.restaurantData.coins);
      }
    })

    socket.on('userWalletUpdate', (res) => {
      const { authUser } = this.props;
      if (!!authUser && (!authUser.snoozSetting || moment().isAfter(moment(authUser.snoozSetting)))) {
        const { userData } = res;
        if (!isNaN(userData.coins)) {
          //this.props.updateUserCoins(res.userData.coins); // commentinng as wronn cpins showinng in header
          this.props.fetchProfile()
        }
        if (userData.reason && (userData.reason === 'dailySignIn' || userData.reason === 'pageEditApproved')) {
          toast(<ToastMessages toastData={{ ...userData }}/>, toastOptions)
        } else {
          if (userData.reason === 'levelUp')
            this.props.showBuzzScreens(LEVEL_UP, userData)
        }
      }
    })

    socket.on('achievementCompleted', res => {
      const { achievementData } = res
      const { authUser } = this.props;
      if (achievementData && achievementData.achievement && !!authUser && (!authUser.snoozSetting || moment().isAfter(moment(authUser.snoozSetting)))) {
        toast(<ToastMessages achievement={{ ...achievementData.achievement }}/>, toastOptions)
      }
    })
  }

  handleSelectValueChange = selectValue => {
    this.setState({ selectValue });
  }

  handleSubmit = () => {
    const { selectValue } = this.state;
    const snoozSetting = moment();
    if (selectValue) {
      if (selectValue.value === 'tomorrow') {
        snoozSetting.add(24, 'hours');
        //console.log(snoozSetting.format('YYYY-MM-DD HH:mm:ss'))
      }
      if (selectValue.value === 'week') {
        snoozSetting.add(1, 'w');
        //console.log(snoozSetting.format('YYYY-MM-DD HH:mm:ss'))
      }
    }
    this.setState({ submitting: true });
    userService.snoozeSettings({ snoozSetting })
      .then(rawData => rawData.json())
      .then(response => {
        if (response.status) {
          toast.success('Toast settings saved!');
          this.props.fetchProfile();
          this.props.hideToastSettings();
        } else {
          toast.error(Array.isArray(response.message) ? response.message[0] : response.message);
        }
        this.setState({ submitting: false });
      })
      .catch(() => {
        this.setState({ submitting: false });
        toast.error('Something wrong happened')
      })
  }

  render() {
    const { selectValue, submitting } = this.state;
    const { modalVisible, hideToastSettings } = this.props;
    return (
      <Modal show={modalVisible} onHide={hideToastSettings} size="sm">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 20 }}>Snooze notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            options={options}
            className="invite-select"
            classNamePrefix="invite-select"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                text: '#14233C',
                primary25: '#FFE5E5',
                primary50: '#FFE5E5',
                primary: '#ff4a5e',
              },
            })}
            onChange={this.handleSelectValueChange}
            value={selectValue}
            placeholder='Select snooze duration'
          />
          <div className="nextstep">
            <div className={`next_step_dstop ${selectValue ? 'target_selected' : ''}`} style={{ width: '100%' }}>
              <button type="button" disabled={!(selectValue) || submitting} onClick={this.handleSubmit}>
                {submitting ? <SpinnerWhite/> : 'Confirm'}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { toastSettings: modalVisible } = settings;
  const { payload: authUser } = auth
  return { modalVisible, authUser }
}

export default connect(mapStateToProps, {
  hideToastSettings,
  fetchProfile,
  updateUserCoins,
  updateRestaurantCoins,
  showBuzzScreens
})(ToastSettings);