import { authHeader, urlEncodedAuthHeader } from "./auth-header";
import * as apiURL from "./Apiconfig";
import { secureFetch } from "./secureFetch";
import { convertData } from "./convertToUrlEncoded";

export const userService = {
  getRecentSearches,
  getSearchedItems,
  clearRecentSearches,
  validateEmail,
  updateProfile,
  updateSearchPrefs,
  getSearchPrefs,
  updateProfilePicture,
  getProfileStrength,
  getPaidCoins,
  getUserCoinBundles,
  getPendingReviews,
  getPendingPrefs,
  updatePrefTags,
  saveUserContacts,
  getUserContacts,
  inviteUserContacts,
  snoozeSettings,
  getPollCards,
  updatePollCards,
};

function getRecentSearches(searchQuery) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(
    `${apiURL.RECENT_SEARCHES}?search=${searchQuery}`,
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function clearRecentSearches() {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.CLEAR_RECENT_SEARCHES}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getSearchedItems(searchQuery) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(
    `${apiURL.SEARCHED_ITEMS}?search=${searchQuery}`,
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function validateEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.VALIDATE_EMAIL}/${email}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function updateProfile(userData) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(userData),
  };

  return secureFetch(`${apiURL.UPDATE_PROFILE}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function updateSearchPrefs(data) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return secureFetch(`${apiURL.SEARCH_PREFERENCES}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getSearchPrefs() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.SEARCH_PREFERENCES}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function updateProfilePicture(userData) {
  const formBody = convertData(userData);
  const requestOptions = {
    method: "POST",
    headers: authHeader(), // urlEncodedAuthHeader(),
    body: JSON.stringify(userData),
  };

  return secureFetch(`${apiURL.UPDATE_PROFILE_PICTURE}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getProfileStrength() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.PROFILE_STRENGTH}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getPaidCoins() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.PAID_COINS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getUserCoinBundles() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(
    `${apiURL.COIN_BUNDLES}?bundleFor=user,both`,
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getPendingReviews() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.PENDING_REVIEWS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getPendingPrefs() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.PENDING_PREFS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function updatePrefTags(data) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return secureFetch(`${apiURL.UPDATE_PREFERENCE_TAGS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function saveUserContacts(contacts) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(contacts),
  };

  return secureFetch(`${apiURL.USER_CONTACTS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getUserContacts(searchText) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(
    `${apiURL.USER_CONTACTS}?search=${searchText || ""}`,
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function inviteUserContacts(contacts) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(contacts),
  };

  return secureFetch(`${apiURL.INVITE_USER_CONTACTS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function snoozeSettings(dateTime) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(dateTime),
  };

  return secureFetch(`${apiURL.SNOOZE_SETTINGS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getPollCards() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.USER_POLL_CARD}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function updatePollCards(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return secureFetch(`${apiURL.USER_POLL_CARD}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}
