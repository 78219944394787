import {
  FETCH_TOP_LOCATIONS_START,
  FETCH_TOP_LOCATIONS_SUCCESS,
  FETCH_TOP_LOCATIONS_FAILED,
  FETCH_RECENT_LOCATIONS_START,
  FETCH_RECENT_LOCATIONS_SUCCESS,
  FETCH_RECENT_LOCATIONS_FAILED,
  CLEAR_RECENT_LOCATIONS_START,
  CLEAR_RECENT_LOCATIONS_SUCCESS,
  CLEAR_RECENT_LOCATIONS_FAILED,
  FETCH_SEARCHED_ITEMS_START,
  FETCH_SEARCHED_ITEMS_SUCCESS,
  FETCH_SEARCHED_ITEMS_FAILED,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_SEARCH_PREFES,
  UPDATE_SEARCH_PREFES_SUCCESS,
  UPDATE_SEARCH_PREFES_FAILED,
  FETCH_SEARCH_PREFES,
  FETCH_SEARCH_PREFES_SUCCESS,
  FETCH_SEARCH_PREFES_FAILED,
  UPDATE_PROFILE_PIC,
  UPDATE_PROFILE_PIC_SUCCESS,
  UPDATE_PROFILE_PIC_FAILED,
  FETCH_PROFILE_STRENGTH,
  FETCH_PROFILE_STRENGTH_SUCCESS,
  FETCH_PROFILE_STRENGTH_FAILED,
  FETCH_PAID_COINS_START,
  FETCH_PAID_COINS_SUCCESS,
  FETCH_PAID_COINS_FAILED,
  FETCH_USER_COIN_BUNDLES_START,
  FETCH_USER_COIN_BUNDLES_SUCCESS,
  FETCH_USER_COIN_BUNDLES_FAILED,
  FETCH_PENDING_REVIEWS_START,
  FETCH_PENDING_REVIEWS_SUCCESS,
  FETCH_PENDING_REVIEWS_FAILED,
  UPDATE_PENDING_REVIEW_DATA,
  FETCH_PENDING_PREFS_START,
  FETCH_PENDING_PREFS_SUCCESS,
  FETCH_PENDING_PREFS_FAILED,
  REMOVE_PENDING_PREF,
  FETCH_ACHIEVEMENTS_START,
  FETCH_ACHIEVEMENTS_SUCCESS,
  FETCH_ACHIEVEMENTS_FAILED,
  FETCH_POLLCARDS_SUCCESS,
  FETCH_POLLCARDS_FAILED,
  FETCH_POLLCARDS_START,
  UPDATE_POLLCARDS_START,
  UPDATE_POLLCARDS_SUCCESS,
  UPDATE_POLLCARDS_FAILED,
} from "../../constants/actionTypes";
import { userService } from "../../service/user-service";
import { topLocationService } from "../../service/topLocationService";
import { updateUserData, fetchProfile } from "./Auth";

export const fetchTopLocations = (text) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchTopLocationsStart());
    topLocationService
      .getTopLocations(text || "")
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        //console.log("top locations =", response)
        if (response.status) {
          dispatch(fetchTopLocationsSuccess(response));
        } else {
          dispatch(fetchTopLocationsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchTopLocationsFailed(failureMessage));
      });
  };
};

export const fetchRecentLocations = (text) => {
  const failureMessage = "Something wrong happened";
  return (dispatch, getState) => {
    dispatch(fetchRecentLocationsStart());
    const authUser = !!getState().auth.payload; // && getState().auth.payload.userName);
    if (authUser) {
      userService
        .getRecentSearches(text || "")
        .then((rawData) => {
          return rawData.json();
        })
        .then((response) => {
          //console.log("recent searched ==", response)
          if (response.status) {
            dispatch(fetchRecentLocationsSuccess(response));
          } else {
            dispatch(fetchRecentLocationsFailed(failureMessage));
          }
        })
        .catch(() => {
          dispatch(fetchRecentLocationsFailed(failureMessage));
        });
    } else {
      const recentLocations = JSON.parse(
        localStorage.getItem("recentLocations")
      );
      dispatch(
        fetchRecentLocationsSuccess({ status: true, data: recentLocations })
      );
    }
  };
};

export const clearRecentLocations = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch, getState) => {
    dispatch(clearRecentLocationsStart());
    const authUser = !!(
      getState().auth.payload && getState().auth.payload.userName
    );
    if (authUser) {
      userService
        .clearRecentSearches()
        .then((rawData) => {
          return rawData.json();
        })
        .then((response) => {
          if (response.status) {
            dispatch(clearRecentLocationsSuccess(response));
          } else {
            dispatch(clearRecentLocationsFailed(failureMessage));
          }
        })
        .catch(() => {
          dispatch(clearRecentLocationsFailed(failureMessage));
        });
    } else {
      localStorage.removeItem("recentLocations");
      dispatch(clearRecentLocationsSuccess());
    }
  };
};

export const fetchSearchedItems = (text) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchSearchedItemsStart());
    userService
      .getSearchedItems(text || "")
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchSearchedItemsSuccess(response));
        } else {
          dispatch(fetchSearchedItemsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchSearchedItemsFailed(failureMessage));
      });
  };
};

export const updateUserProfile = (userData) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(updateUserProfileStart());
    userService
      .updateProfile(userData)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(updateUserProfileSuccess("Update success"));
          dispatch(updateUserData(response.data[0]));
          dispatch(fetchProfile());
        } else {
          dispatch(updateUserProfileFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateUserProfileFailed(failureMessage));
      });
  };
};

export const fetchSearchPrefes = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchSearchPrefesStart());
    userService
      .getSearchPrefs()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchSearchPrefesSuccess(response.data));
        } else {
          dispatch(fetchSearchPrefesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchSearchPrefesFailed(failureMessage));
      });
  };
};

export const updateSearchPrefs = (data) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(updateSearchPrefesStart());
    userService
      .updateSearchPrefs(data)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(updateSearchPrefesSuccess(response.data));
        } else {
          dispatch(updateSearchPrefesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateSearchPrefesFailed(failureMessage));
      });
  };
};

export const updateProfileImage = (data) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(updateProfilePicStart());
    userService
      .updateProfilePicture(data)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(updateProfilePicSuccess(response.data));
          dispatch(
            updateUserData({
              profilePicture: response.data[0].profilePictureDetails._id,
              profilePictureDetails: {
                ...response.data[0].profilePictureDetails,
              },
            })
          );
          dispatch(fetchProfile());
        } else {
          dispatch(updateProfilePicFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateProfilePicFailed(failureMessage));
      });
  };
};

export const fetchProfileStrength = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchProfileStrengthStart());
    userService
      .getProfileStrength()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchProfileStrengthSuccess(response.data));
        } else {
          dispatch(fetchProfileStrengthFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchProfileStrengthFailed(failureMessage));
      });
  };
};

export const fetchPaidCoins = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchPaidCoinsStart());
    userService
      .getPaidCoins()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchPaidCoinsSuccess(response.data));
        } else {
          dispatch(fetchPaidCoinsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchPaidCoinsFailed(failureMessage));
      });
  };
};

export const fetchUserCoinBundles = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchCoinBundlesStart());
    userService
      .getUserCoinBundles()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchCoinBundlesSuccess(response.data));
        } else {
          dispatch(fetchCoinBundlesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchCoinBundlesFailed(failureMessage));
      });
  };
};

export const fetchPendingReviews = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchPendingReviewsStart());
    userService
      .getPendingReviews()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchPendingReviewsSuccess(response.data));
        } else {
          dispatch(fetchPendingReviewsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchPendingReviewsFailed(failureMessage));
      });
  };
};

export const fetchPendingPrefs = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchPendingPrefsStart());
    userService
      .getPendingPrefs()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchPendingPrefsSuccess(response.data));
        } else {
          dispatch(fetchPendingPrefsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchPendingPrefsFailed(failureMessage));
      });
  };
};

export const fetchPollCards = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(fetchPreferencesStart());
    userService
      .getPollCards()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(fetchPreferencesSuccess(response.data));
        } else {
          dispatch(fetchPreferencesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchPreferencesFailed(failureMessage));
      });
  };
};

export const updatePollCards = (data) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(updateSearchPreferencesStart());
    userService
      .updatePollCards(data)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(updateSearchPreferencesSuccess(response.data));
        } else {
          dispatch(updateSearchPreferencesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateSearchPrefesFailed(failureMessage));
      });
  };
};

const fetchTopLocationsStart = () => {
  return {
    type: FETCH_TOP_LOCATIONS_START,
  };
};

const fetchTopLocationsSuccess = (data) => {
  return {
    type: FETCH_TOP_LOCATIONS_SUCCESS,
    payload: data,
  };
};

const fetchTopLocationsFailed = (data) => {
  return {
    type: FETCH_TOP_LOCATIONS_FAILED,
    payload: data,
  };
};

const fetchRecentLocationsStart = () => {
  return {
    type: FETCH_RECENT_LOCATIONS_START,
  };
};

const fetchRecentLocationsSuccess = (data) => {
  return {
    type: FETCH_RECENT_LOCATIONS_SUCCESS,
    payload: data,
  };
};

const fetchRecentLocationsFailed = (data) => {
  return {
    type: FETCH_RECENT_LOCATIONS_FAILED,
    payload: data,
  };
};

const clearRecentLocationsStart = () => {
  return {
    type: CLEAR_RECENT_LOCATIONS_START,
  };
};

const clearRecentLocationsSuccess = () => {
  return {
    type: CLEAR_RECENT_LOCATIONS_SUCCESS,
  };
};

const clearRecentLocationsFailed = (data) => {
  return {
    type: CLEAR_RECENT_LOCATIONS_FAILED,
    payload: data,
  };
};

const fetchSearchedItemsStart = () => {
  return {
    type: FETCH_SEARCHED_ITEMS_START,
  };
};

const fetchSearchedItemsSuccess = (data) => {
  return {
    type: FETCH_SEARCHED_ITEMS_SUCCESS,
    payload: data,
  };
};

const fetchSearchedItemsFailed = (data) => {
  return {
    type: FETCH_SEARCHED_ITEMS_FAILED,
    payload: data,
  };
};

const updateUserProfileStart = () => {
  return {
    type: UPDATE_USER_PROFILE,
  };
};

const updateUserProfileSuccess = (data) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: data,
  };
};

const updateUserProfileFailed = (data) => {
  return {
    type: UPDATE_USER_PROFILE_FAILED,
    payload: data,
  };
};

const updateSearchPrefesStart = () => {
  return {
    type: UPDATE_SEARCH_PREFES,
  };
};

const updateSearchPrefesSuccess = (data) => {
  return {
    type: UPDATE_SEARCH_PREFES_SUCCESS,
    payload: data,
  };
};

const updateSearchPrefesFailed = (data) => {
  return {
    type: UPDATE_SEARCH_PREFES_FAILED,
    payload: data,
  };
};

const fetchSearchPrefesStart = () => {
  return {
    type: FETCH_SEARCH_PREFES,
  };
};

const fetchSearchPrefesSuccess = (data) => {
  return {
    type: FETCH_SEARCH_PREFES_SUCCESS,
    payload: data,
  };
};

const fetchSearchPrefesFailed = (data) => {
  return {
    type: FETCH_SEARCH_PREFES_FAILED,
    payload: data,
  };
};

const updateProfilePicStart = () => {
  return {
    type: UPDATE_PROFILE_PIC,
  };
};

const updateProfilePicSuccess = (data) => {
  return {
    type: UPDATE_PROFILE_PIC_SUCCESS,
    payload: data,
  };
};

const updateProfilePicFailed = (data) => {
  return {
    type: UPDATE_PROFILE_PIC_FAILED,
    payload: data,
  };
};

const fetchProfileStrengthStart = () => {
  return {
    type: FETCH_PROFILE_STRENGTH,
  };
};

const fetchProfileStrengthSuccess = (data) => {
  return {
    type: FETCH_PROFILE_STRENGTH_SUCCESS,
    payload: data,
  };
};

const fetchProfileStrengthFailed = () => {
  return {
    type: FETCH_PROFILE_STRENGTH_FAILED,
  };
};

const fetchPaidCoinsStart = () => {
  return {
    type: FETCH_PAID_COINS_START,
  };
};

const fetchPaidCoinsSuccess = (data) => {
  return {
    type: FETCH_PAID_COINS_SUCCESS,
    payload: data,
  };
};

const fetchPaidCoinsFailed = (data) => {
  return {
    type: FETCH_PAID_COINS_FAILED,
    payload: data,
  };
};

const fetchCoinBundlesStart = () => {
  return {
    type: FETCH_USER_COIN_BUNDLES_START,
  };
};

const fetchCoinBundlesSuccess = (data) => {
  return {
    type: FETCH_USER_COIN_BUNDLES_SUCCESS,
    payload: data,
  };
};

const fetchCoinBundlesFailed = (data) => {
  return {
    type: FETCH_USER_COIN_BUNDLES_FAILED,
    payload: data,
  };
};

const fetchPendingReviewsStart = () => {
  return {
    type: FETCH_PENDING_REVIEWS_START,
  };
};

const fetchPendingReviewsSuccess = (data) => {
  return {
    type: FETCH_PENDING_REVIEWS_SUCCESS,
    payload: data,
  };
};

const fetchPendingReviewsFailed = (data) => {
  return {
    type: FETCH_PENDING_REVIEWS_FAILED,
    payload: data,
  };
};

const fetchPendingPrefsStart = () => {
  return {
    type: FETCH_PENDING_PREFS_START,
  };
};

const fetchPendingPrefsSuccess = (data) => {
  return {
    type: FETCH_PENDING_PREFS_SUCCESS,
    payload: data,
  };
};

const fetchPendingPrefsFailed = (data) => {
  return {
    type: FETCH_PENDING_PREFS_FAILED,
    payload: data,
  };
};

const fetchPreferencesStart = () => {
  return {
    type: FETCH_POLLCARDS_START,
  };
};

const fetchPreferencesSuccess = (data) => {
  return {
    type: FETCH_POLLCARDS_SUCCESS,
    payload: data,
  };
};

const fetchPreferencesFailed = (data) => {
  return {
    type: FETCH_POLLCARDS_FAILED,
    payload: data,
  };
};

export const updatePendingReviewData = (data) => {
  return {
    type: UPDATE_PENDING_REVIEW_DATA,
    payload: data,
  };
};

export const removePendingPref = (data) => {
  return {
    type: REMOVE_PENDING_PREF,
    payload: data,
  };
};

const updateSearchPreferencesStart = () => {
  return {
    type: UPDATE_POLLCARDS_START,
  };
};

const updateSearchPreferencesSuccess = (data) => {
  return {
    type: UPDATE_POLLCARDS_SUCCESS,
    payload: data,
  };
};

const updateSearchPreferencesFailed = (data) => {
  return {
    type: UPDATE_POLLCARDS_FAILED,
    payload: data,
  };
};
