import queryString from "query-string";

export const extractName = str => {
  const queryObj = queryString.parse(str);
  if (Object.keys(queryObj).includes('intro')) {
    return queryObj.intro
  } else {
    return ''
  }
}
