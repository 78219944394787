import {
    ADD_REPORT_ISSUE_START,
    ADD_REPORT_ISSUE_SUCCESS,
    ADD_REPORT_ISSUE_FAILED
} from '../../constants/actionTypes';

import { reportIssueService } from '../../service/report-issue-service';

export const addReportIssue = (data) => {

    const failureMessage = 'Something wrong happened';
    return dispatch => {
        dispatch(fetchAddReportIssueStart());
        reportIssueService.addReportIssue(data)
            .then(rawData => {
                return rawData.json();
            })
            .then(response => {
                if (response.status) {
                    dispatch(fetchAddReportIssueSuccess({ ...response.data }));
                } else {
                    dispatch(fetchAddReportIssueFailed(failureMessage));
                }
            })
            .catch((error) => {
                dispatch(fetchAddReportIssueFailed(failureMessage));
            });
    }
};

const fetchAddReportIssueStart = () => {
    return {
        type: ADD_REPORT_ISSUE_START
    }
};

export const fetchAddReportIssueSuccess = (data) => {
    return {
        type: ADD_REPORT_ISSUE_SUCCESS,
        payload: data,
    }
};

export const fetchAddReportIssueFailed = data => {
    return {
        type: ADD_REPORT_ISSUE_FAILED,
        payload: data
    }
};