import * as Yup from "yup";

export const loginSchema = Yup.object({
  emailOrMobile: Yup.string()
    .test("emailOrMobile", "Must be a valid email address or mobile number", val => {
      return (
          /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val))
        || (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(val)
        );
    })
    .required('Required'),
  password: Yup.string()
    .min(8, 'Must be 8 characters or above')
    .required('Required'),
});
