import {
  ADD_OFFER_START,
  ADD_OFFER_SUCCESS,
  ADD_OFFER_FAILED,
  UPDATE_OFFER_START,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILED,
  DELETE_OFFER_START,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAILED,
  FETCH_USER_CHEST_OFFERS_START,
  FETCH_USER_CHEST_OFFERS_SUCCESS,
  FETCH_USER_CHEST_OFFERS_FAILED,
  FETCH_DAILY_CHEST_OFFERS_START,
  FETCH_DAILY_CHEST_OFFERS_SUCCESS,
  FETCH_DAILY_CHEST_OFFERS_FAILED,
  ADD_CHEST_OFFER_START,
  ADD_CHEST_OFFER_SUCCESS,
  ADD_CHEST_OFFER_FAILED,
  FETCH_OFFER_START,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFER_FAILED,
  FETCH_OFFER_USER_MAY_LIKE_START,
  FETCH_OFFER_USER_MAY_LIKE_FAILED,
  FETCH_OFFER_USER_MAY_LIKE_SUCCESS,
} from "../../constants/actionTypes";

const initialState = {
  controller: new AbortController(),
  error: null,
  isLoading: false,
  payload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
  filters: {},
  chestError: null,
  chestIsLoading: false,
  chestPayload: [],
  dailyChestPayload: [],
  offerError: null,
  isOfferLoading: false,
  offerPayload: [],
  offersUserMayLike: []
  // offersUserMayLike: [
  //         {
  //           "_id": "5e8342f0426bd609283151b6",
  //           "place": {
  //             "placeId": "ChIJZ_YISduC-DkRvCxsj-Yw40M",
  //             "vicinity": "Kolkata, West Bengal, India",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 88.35713,
  //                 22.50644
  //               ]
  //             },
  //             "formatted_address": "Kolkata, West Bengal, India",
  //             "city": "",
  //             "locality": "Kolkata",
  //             "state": "West Bengal",
  //             "country": "India",
  //             "postal_code": "226016"
  //           },
  //           "title": "FOODieKolkata",
  //           "slug": "foodiekolkata",
  //           "ordering": 1417,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f43"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "+918423541453"
  //             }
  //           ],
  //           "email": "deepbn9@gmail.com",
  //           "website": "https://google.com",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f33",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f0d",
  //             "5e457cd0c3b9e62c71617f0e",
  //             "5e457cd0c3b9e62c71617f2f",
  //             "5e457cd0c3b9e62c71617f30",
  //             "5e457cd0c3b9e62c71617f3a",
  //             "5e457cd0c3b9e62c71617f3c",
  //             "5f14646fb55762717d1f5f08",
  //             "5ed0e95ec38ee77c588ac649",
  //             "5ed0e9c4c38ee77c588ac66f",
  //             "5ed0e9cbc38ee77c588ac673",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f12",
  //             "5e457cd0c3b9e62c71617f08",
  //             "5e457cd0c3b9e62c71617f1b",
  //             "5e457cd0c3b9e62c71617f15",
  //             "5e457cd0c3b9e62c71617f1e",
  //             "5e457cd0c3b9e62c71617f28",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f40",
  //             "5e457cd0c3b9e62c71617f29",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f05",
  //             "5e457cd0c3b9e62c71617f0c",
  //             "5e457cd0c3b9e62c71617f2a",
  //             "5e457cd0c3b9e62c71617f2c"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Dishes": "10.00"
  //             },
  //             {
  //               "Side": "6.00"
  //             },
  //             {
  //               "Dessert": "5.00"
  //             },
  //             {
  //               "Drink": "10.00"
  //             },
  //             {
  //               "restaurantPrice": 41
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "callCount": 0,
  //           "priceCategory": "Small plates",
  //           "virtualTourLink": "&lt;iframe src=\"https://www.google.com/maps/embed?pb=!4v1592389454593!6m8!1m7!1sCAoSLEFGMVFpcE1MeFA1eWoxSVlnOVdIZ3dReFJBYlh3czZlWVZ3d0dUZTV3MmZM!2m2!1d22.50652641389507!2d88.36708483218035!3f259.9693289724221!4f-2.750035865624028!5f0.7820865974627469\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0;\" allowfullscreen=\"\" aria-hidden=\"false\" tabindex=\"0\">&lt;/iframe>",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e8342f0426bd609283151b7",
  //               "plateform": "google"
  //             },
  //             {
  //               "_id": "60af99d99a6b950ad122fc9f",
  //               "plateform": "tablefy",
  //               "rating": 0,
  //               "user_ratings_total": 34
  //             }
  //           ],
  //           "createDate": "2020-03-31T13:17:36.458Z",
  //           "modifiedDate": "2021-06-21T07:31:09.649Z",
  //           "__v": 0,
  //           "coins": 30200,
  //           "restaurantView": 238,
  //           "contentScore": 82,
  //           "openDate": null,
  //           "credits": 80,
  //           "restaurantId": "5e8342f0426bd609283151b6",
  //           "bookingData": [
  //             {
  //               "_id": "60d17847f60bef344ed354cd",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": {
  //                 "_id": "5feccf8742c65151f82d847d",
  //                 "restaurant": "5e8342f0426bd609283151b6",
  //                 "user": "5ee376450198393ee8cea9d9",
  //                 "title": null,
  //                 "description": null,
  //                 "startDate": "2020-12-30T00:00:00.000Z",
  //                 "endDate": null,
  //                 "appliedOn": {
  //                   "day": 3,
  //                   "time": [
  //                     7,
  //                     8
  //                   ]
  //                 },
  //                 "menus": [],
  //                 "offerCost": null,
  //                 "menuCost": null,
  //                 "labels": [],
  //                 "discount": {
  //                   "type": "rewards",
  //                   "value": 350
  //                 },
  //                 "discountType": "percent",
  //                 "offerCategory": "weeklyOffer",
  //                 "status": "active",
  //                 "inspected": false,
  //                 "createDate": "2020-12-30T19:05:43.454Z",
  //                 "modifiedDate": "2020-12-30T19:05:43.454Z",
  //                 "inspectionHistory": [],
  //                 "__v": 0
  //               },
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": {
  //                 "expiryDate": "2021-06-22T00:00:00.000Z",
  //                 "redeemDate": null,
  //                 "status": "active",
  //                 "_id": "60c892748639f7077f6c3a62",
  //                 "chest": {
  //                   "url": "/dashboard/my-offers",
  //                   "status": "active",
  //                   "user": "60ab6f202e6daa412ccc6758",
  //                   "chest": "5ec23f0cf051ab306fbdf334",
  //                   "title": "Unlock any offer for free within 7 days",
  //                   "expiry": 7,
  //                   "valuex": 0,
  //                   "tracked": false,
  //                   "frequency": 5,
  //                   "currentFrequency": 2,
  //                   "weightage": 1,
  //                   "activeDate": "2021-06-15",
  //                   "__v": 0
  //                 },
  //                 "chestCoin": 1,
  //                 "user": "60ab6f202e6daa412ccc6758",
  //                 "activeDate": "2021-06-15T00:00:00.000Z",
  //                 "createdDate": "2021-06-15T11:43:48.646Z",
  //                 "modifiedDate": "2021-06-15T11:43:48.646Z",
  //                 "__v": 0
  //               },
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-22T06:31:29.553Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 1,
  //               "bookingDate": "2021-06-23T07:30:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-22T05:42:31.663Z",
  //               "modifiedDate": "2021-06-22T06:31:29.553Z",
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d17905f60bef344ed354db",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": {
  //                 "_id": "60cc43d47a54b747f94bee1c",
  //                 "restaurant": "5e8342f0426bd609283151b6",
  //                 "user": "5ee376450198393ee8cea9d9",
  //                 "title": null,
  //                 "description": null,
  //                 "startDate": "2021-06-18T00:00:00.000Z",
  //                 "endDate": null,
  //                 "appliedOn": {
  //                   "day": 3,
  //                   "time": [
  //                     8,
  //                     10
  //                   ],
  //                   "repeat": false
  //                 },
  //                 "menus": [],
  //                 "offerCost": 0,
  //                 "menuCost": null,
  //                 "labels": [],
  //                 "discount": {
  //                   "type": "percent",
  //                   "value": 40,
  //                   "conjunction": false
  //                 },
  //                 "discountType": "percent",
  //                 "offerCategory": "weeklyOffer",
  //                 "status": "active",
  //                 "inspected": false,
  //                 "createDate": "2021-06-18T06:57:24.856Z",
  //                 "modifiedDate": "2021-06-18T06:57:24.856Z",
  //                 "inspectionHistory": [],
  //                 "__v": 0
  //               },
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": {
  //                 "expiryDate": "2021-06-22T00:00:00.000Z",
  //                 "redeemDate": null,
  //                 "status": "active",
  //                 "_id": "60c892748639f7077f6c3a62",
  //                 "chest": {
  //                   "url": "/dashboard/my-offers",
  //                   "status": "active",
  //                   "user": "60ab6f202e6daa412ccc6758",
  //                   "chest": "5ec23f0cf051ab306fbdf334",
  //                   "title": "Unlock any offer for free within 7 days",
  //                   "expiry": 7,
  //                   "valuex": 0,
  //                   "tracked": false,
  //                   "frequency": 5,
  //                   "currentFrequency": 2,
  //                   "weightage": 1,
  //                   "activeDate": "2021-06-15",
  //                   "__v": 0
  //                 },
  //                 "chestCoin": 1,
  //                 "user": "60ab6f202e6daa412ccc6758",
  //                 "activeDate": "2021-06-15T00:00:00.000Z",
  //                 "createdDate": "2021-06-15T11:43:48.646Z",
  //                 "modifiedDate": "2021-06-15T11:43:48.646Z",
  //                 "__v": 0
  //               },
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-22T06:31:40.289Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 2,
  //               "bookingDate": "2021-06-23T08:45:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-22T05:45:41.106Z",
  //               "modifiedDate": "2021-06-22T06:31:40.289Z",
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d5ba5eba92690eb9bbdd4c",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": null,
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": null,
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-25T11:16:20.506Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 1,
  //               "bookingDate": "2021-06-30T12:00:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-25T11:13:34.750Z",
  //               "modifiedDate": "2021-06-25T11:16:20.506Z",
  //               "__v": 0
  //             }
  //           ],
  //           "totalBookings": 3,
  //           "offerData": [
  //             {
  //               "_id": "5f699bc8bac4f640fb4a7a1c",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "title": "Test",
  //               "description": "test desc",
  //               "startDate": null,
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [],
  //                 "5": [],
  //                 "6": []
  //               },
  //               "menus": [
  //                 "5e4d501afef48878c35c563e"
  //               ],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-09-22T06:38:00.552Z",
  //               "modifiedDate": "2020-09-22T06:38:39.307Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5fec5da042c65151f82d8423",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-17T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   15,
  //                   19
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 0,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20,
  //                 "conjunction": true
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-12-30T10:59:44.397Z",
  //               "modifiedDate": "2021-06-17T05:00:09.518Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5feccf8742c65151f82d847d",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-12-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   7,
  //                   8
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 350
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-12-30T19:05:43.454Z",
  //               "modifiedDate": "2020-12-30T19:05:43.454Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "603d6c313e7f3f25a6fce12a",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-03-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   20,
  //                   21
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 450,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-03-01T22:35:29.570Z",
  //               "modifiedDate": "2021-03-01T22:35:29.570Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "604bab693e7f3f25a6fceb12",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-03-12T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   15,
  //                   16
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 0,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-03-12T17:56:57.839Z",
  //               "modifiedDate": "2021-03-12T17:56:57.839Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "604babd13e7f3f25a6fceb16",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-03-12T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   16,
  //                   19
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 300,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-03-12T17:58:41.134Z",
  //               "modifiedDate": "2021-03-12T17:58:53.492Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60799653538804430d8c0792",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": "Summer Offer",
  //               "description": "Enjoy summer with delicious food.",
  //               "startDate": null,
  //               "endDate": "2021-10-30T00:00:00.000Z",
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "PM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "PM"
  //                 ],
  //                 "5": [],
  //                 "6": []
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-04-16T13:51:15.952Z",
  //               "modifiedDate": "2021-04-16T13:51:15.953Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60799688538804430d8c0796",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": "Spring Offer",
  //               "description": "Get the spring tasty.",
  //               "startDate": null,
  //               "endDate": "2021-12-30T00:00:00.000Z",
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "2": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "4": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "6": [
  //                   "PM",
  //                   "AM"
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-04-16T13:52:08.267Z",
  //               "modifiedDate": "2021-04-16T13:52:08.267Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60c838538639f7077f6c38fe",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-15T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   11,
  //                   12
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": 20,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20,
  //                 "conjunction": true
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-15T05:19:15.330Z",
  //               "modifiedDate": "2021-06-15T05:19:15.330Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60c845b98639f7077f6c395c",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": "daily offer",
  //               "description": "daily offer",
  //               "startDate": "2021-06-17T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "AM"
  //                 ],
  //                 "2": [
  //                   "PM"
  //                 ],
  //                 "3": [],
  //                 "4": [],
  //                 "5": [],
  //                 "6": []
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-15T06:16:25.190Z",
  //               "modifiedDate": "2021-06-15T06:16:25.190Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60c850368639f7077f6c3972",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-15T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   7,
  //                   11
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": 20,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 30,
  //                 "conjunction": true
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-15T07:01:10.601Z",
  //               "modifiedDate": "2021-06-15T07:09:13.477Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60c85b8f8639f7077f6c3983",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-15T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": "",
  //                 "time": [
  //                   14,
  //                   13
  //                 ],
  //                 "repeat": false
  //               },
  //               "menus": [],
  //               "offerCost": 20,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-15T07:49:35.909Z",
  //               "modifiedDate": "2021-06-15T07:49:35.911Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60c879768639f7077f6c3a07",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-15T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   7,
  //                   9
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": 10,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20,
  //                 "conjunction": true
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-15T09:57:10.116Z",
  //               "modifiedDate": "2021-06-15T09:57:10.117Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60cae8b26e98a01bccd6898d",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-17T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   15,
  //                   16
  //                 ],
  //                 "repeat": false
  //               },
  //               "menus": [],
  //               "offerCost": 0,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-17T06:16:18.109Z",
  //               "modifiedDate": "2021-06-17T06:16:18.111Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60caef336e98a01bccd6899b",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-17T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   16,
  //                   17
  //                 ],
  //                 "repeat": false
  //               },
  //               "menus": [],
  //               "offerCost": 50,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-17T06:44:03.820Z",
  //               "modifiedDate": "2021-06-17T06:44:03.821Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60cc2e397a54b747f94bed66",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-18T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   10,
  //                   11
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 250,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-18T05:25:13.864Z",
  //               "modifiedDate": "2021-06-18T05:25:13.864Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60cc43d47a54b747f94bee1c",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-18T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   8,
  //                   10
  //                 ],
  //                 "repeat": false
  //               },
  //               "menus": [],
  //               "offerCost": 0,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-18T06:57:24.856Z",
  //               "modifiedDate": "2021-06-18T06:57:24.856Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d0894eebe6725c300c849e",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-21T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   19,
  //                   20
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": 100,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40,
  //                 "conjunction": true
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-21T12:42:54.542Z",
  //               "modifiedDate": "2021-06-21T12:42:54.542Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d5d2aeba92690eb9bbde03",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-25T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   7,
  //                   10
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 10,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-25T12:57:18.253Z",
  //               "modifiedDate": "2021-06-25T12:57:18.253Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 19,
  //           "photoList": [
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5f05ac4ecb4b325fccd6c33f",
  //               "fileName": "file-1594207309618.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "casey-lee-awj7sRviVXo-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207309618.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2020-07-08T11:21:50.478Z",
  //               "modifiedDate": "2020-07-08T11:21:50.478Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5f05ac60cb4b325fccd6c340",
  //               "fileName": "file-1594207327745.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "alex-munsell-Yr4n8O_3UPc-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207327745.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "steak master",
  //               "createDate": "2020-07-08T11:22:08.752Z",
  //               "modifiedDate": "2020-07-08T11:22:08.752Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "607d3b3e538804430d8c094e",
  //               "fileName": "file-1618819902235.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "chicken-biryani-recipe.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1618819902235.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2021-04-19T08:11:42.406Z",
  //               "modifiedDate": "2021-04-19T08:11:42.406Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5f05ad06cb4b325fccd6c348",
  //               "fileName": "file-1594207493190.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "taylor-kiser-EvoIiaIVRzU-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207493190.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2020-07-08T11:24:54.691Z",
  //               "modifiedDate": "2020-07-08T11:24:54.691Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "607d7d50538804430d8c0b19",
  //               "fileName": "file-1618836815837.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "re-1.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1618836815837.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "userSuggestion",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2021-04-19T12:53:36.123Z",
  //               "modifiedDate": "2021-04-19T12:53:36.123Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 0,
  //           "totalReviews": 34
  //         },
  //         {
  //           "_id": "5e457d1ec3b9e62c71617fc2",
  //           "place": {
  //             "placeId": "ChIJ3SUCMdbDeUgRGFcW5qFlgsw",
  //             "vicinity": "1st Floor, 16-22 Goose Gate, Nottingham",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 -1.1429632,
  //                 52.9535312
  //               ]
  //             },
  //             "formatted_address": "1st Floor, 16-22 Goose Gate, Nottingham NG1 1FF, UK",
  //             "locality": "Nottingham",
  //             "state": "England",
  //             "country": "United Kingdom",
  //             "postal_code": "NG1 1FF"
  //           },
  //           "title": "The Larder on Goosegate",
  //           "slug": "the-larder-on-goosegate-1",
  //           "ordering": 29,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f42",
  //             "5e457cf4c3b9e62c71617f7e"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "0115 950 0111"
  //             },
  //             {
  //               "bookNowTel": "0115 754 0859"
  //             },
  //             {
  //               "bookingRequestMobile": "7903135645"
  //             }
  //           ],
  //           "email": "info@thelarderongoosegate.co.uk",
  //           "website": "http://www.thelarderongoosegate.co.uk/",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f00",
  //             "5e457cd0c3b9e62c71617f01",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f05",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f0b",
  //             "5e457cd0c3b9e62c71617f0e",
  //             "5e457cd0c3b9e62c71617f0f",
  //             "5e457cd0c3b9e62c71617f11",
  //             "5e457cd0c3b9e62c71617f13",
  //             "5e457cd0c3b9e62c71617f15",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f2a",
  //             "5e457cd0c3b9e62c71617f2c",
  //             "5e457cd0c3b9e62c71617f35",
  //             "5e457cd0c3b9e62c71617f3a",
  //             "5e457cd0c3b9e62c71617f3b",
  //             "5e457cd0c3b9e62c71617f3e",
  //             "5e457cd0c3b9e62c71617f40",
  //             "5e5a2ce2a6d2e9387d4aa400",
  //             "5e5a2ce2a6d2e9387d4aa403",
  //             "5e5a2ce2a6d2e9387d4aa405",
  //             "5e5a2ce2a6d2e9387d4aa409",
  //             "5e5a2ce2a6d2e9387d4aa40c",
  //             "5e5a2ce2a6d2e9387d4aa41b",
  //             "5e5a2ce2a6d2e9387d4aa428",
  //             "5e5a2ce2a6d2e9387d4aa432"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Drink": "5.00"
  //             },
  //             {
  //               "Starter": "6.87"
  //             },
  //             {
  //               "Main": "19.47"
  //             },
  //             {
  //               "Side": 0
  //             },
  //             {
  //               "restaurantPrice": "31.33"
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e4fd93101410f5b4eaa66f2",
  //               "plateform": "tablefy",
  //               "rating": 8.620068289440145,
  //               "user_ratings_total": 104
  //             },
  //             {
  //               "_id": "5e5e11d9005c42375458a475",
  //               "plateform": "google",
  //               "rating": 4.5,
  //               "user_ratings_total": 201
  //             }
  //           ],
  //           "createDate": "2020-02-13T16:45:18.627Z",
  //           "modifiedDate": "2020-02-13T16:45:18.627Z",
  //           "__v": 0,
  //           "callCount": 1257,
  //           "virtualTourLink": null,
  //           "priceCategory": "A la carte",
  //           "coins": 225,
  //           "restaurantView": 4,
  //           "restaurantId": "5e457d1ec3b9e62c71617fc2",
  //           "bookingData": [],
  //           "totalBookings": 0,
  //           "offerData": [
  //             {
  //               "_id": "5efd85d96166ef07692ca1fd",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-28T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   12,
  //                   14
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T06:59:37.632Z",
  //               "modifiedDate": "2020-07-02T06:59:37.632Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd86406166ef07692ca201",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-28T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   14,
  //                   16
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 350
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:01:20.739Z",
  //               "modifiedDate": "2020-07-02T07:01:20.739Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd8d576166ef07692ca21a",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-28T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   16,
  //                   19
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:31:35.803Z",
  //               "modifiedDate": "2020-07-02T07:31:35.803Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd8d9c6166ef07692ca21b",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-29T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   1,
  //                   4
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 30
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:32:44.466Z",
  //               "modifiedDate": "2020-07-02T07:32:44.466Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd8dd56166ef07692ca21e",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-29T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   4,
  //                   6
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:33:41.344Z",
  //               "modifiedDate": "2020-07-02T07:33:41.344Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd8e096166ef07692ca220",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-29T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   22,
  //                   24
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 450
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:34:33.567Z",
  //               "modifiedDate": "2020-07-02T07:34:33.567Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd91416166ef07692ca6e9",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   2,
  //                   5
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 100
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:48:17.769Z",
  //               "modifiedDate": "2020-07-02T07:48:17.770Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd927f6166ef07692ca784",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   22,
  //                   24
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:53:35.946Z",
  //               "modifiedDate": "2020-07-02T07:53:35.947Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd934c6166ef07692ca787",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   6,
  //                   9
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 450
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:57:00.709Z",
  //               "modifiedDate": "2020-07-02T07:57:00.709Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd937a6166ef07692ca788",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   10,
  //                   12
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 30
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:57:46.101Z",
  //               "modifiedDate": "2020-07-02T07:57:46.101Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd9dcf6166ef07692ca89c",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   22,
  //                   24
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T08:41:51.315Z",
  //               "modifiedDate": "2020-07-02T08:41:51.315Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd9e5d6166ef07692ca89e",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   12,
  //                   16
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 150
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T08:44:13.429Z",
  //               "modifiedDate": "2020-07-02T08:44:13.429Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd9fe06166ef07692ca8a4",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   18,
  //                   21
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 500
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T08:50:40.846Z",
  //               "modifiedDate": "2020-07-02T08:50:40.846Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efda62b6166ef07692ca8ba",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-03T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 5,
  //                 "time": [
  //                   12,
  //                   14
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T09:17:31.844Z",
  //               "modifiedDate": "2020-07-02T09:17:31.844Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efda65b6166ef07692ca8bb",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-03T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 5,
  //                 "time": [
  //                   14,
  //                   18
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 500
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T09:18:19.965Z",
  //               "modifiedDate": "2020-07-02T09:18:19.966Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efda6b66166ef07692ca8bc",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-02T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 4,
  //                 "time": [
  //                   2,
  //                   4
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 500
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T09:19:50.574Z",
  //               "modifiedDate": "2020-07-02T09:19:50.574Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efda71d6166ef07692ca8bf",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-04T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 6,
  //                 "time": [
  //                   2,
  //                   5
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T09:21:33.506Z",
  //               "modifiedDate": "2020-07-02T09:21:33.506Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5eff027275e2ad2e1c60c531",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-04T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 6,
  //                 "time": [
  //                   24,
  //                   1
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-03T10:03:30.052Z",
  //               "modifiedDate": "2020-07-03T10:03:30.052Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5eff027875e2ad2e1c60c532",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-04T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 6,
  //                 "time": [
  //                   24,
  //                   1
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-03T10:03:36.985Z",
  //               "modifiedDate": "2020-07-03T10:03:36.985Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60c7956a3ce5ee38acd59472",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "user": "5dd8f3dbb2c69f43c4f23dc2",
  //               "title": "Private Offer",
  //               "description": "Testing Offer",
  //               "startDate": "2020-08-25T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   15,
  //                   18
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": 125,
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "conjunction": false,
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-14T17:44:10.349Z",
  //               "modifiedDate": "2021-06-14T17:44:10.349Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 20,
  //           "photoList": [
  //             {
  //               "category": "featured_image",
  //               "viewOrder": 0,
  //               "_id": "5e731ef44dedb527e8822ff0",
  //               "fileName": "c5134a40-b73f-5f37-abbf-51bf2a2d09cc.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:27:48.945Z",
  //               "modifiedDate": "2020-03-19T07:27:48.945Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "logo",
  //               "viewOrder": 0,
  //               "_id": "5e731f024dedb527e88232a8",
  //               "fileName": "d82583c5-da45-5012-ab88-786bc2f0043e.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:28:02.118Z",
  //               "modifiedDate": "2020-03-19T07:28:02.118Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "promotion",
  //               "viewOrder": 0,
  //               "_id": "5e731f234dedb527e8823bd7",
  //               "fileName": "f8ce284e-af7f-541f-9f29-d072a169569a.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:28:35.999Z",
  //               "modifiedDate": "2020-03-19T07:28:35.999Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5e731f654dedb527e88247c9",
  //               "fileName": "c8ddf1f7-14f6-52f2-a61c-d7a5ea41125b.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.043Z",
  //               "modifiedDate": "2020-03-19T07:29:41.043Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 7,
  //               "_id": "5e731f654dedb527e88247cb",
  //               "fileName": "6ad734ac-70e9-5d2d-955b-5123c0559718.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.047Z",
  //               "modifiedDate": "2020-03-19T07:29:41.047Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 8,
  //               "_id": "5e731f654dedb527e88247cc",
  //               "fileName": "f1fa134a-0da5-5cf4-a253-8dabdd2f7ef6.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.060Z",
  //               "modifiedDate": "2020-03-19T07:29:41.060Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5e731f654dedb527e88247cd",
  //               "fileName": "0eab7819-c957-523f-a0cf-ea2d6b3eca7d.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.061Z",
  //               "modifiedDate": "2020-03-19T07:29:41.061Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 9,
  //               "_id": "5e731f654dedb527e88247cf",
  //               "fileName": "77f08de0-e9c5-5e77-8340-d19a5439cad7.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.071Z",
  //               "modifiedDate": "2020-03-19T07:29:41.071Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "5e731f654dedb527e88247d0",
  //               "fileName": "ea3978b1-7615-5717-95ab-1175a8775fe2.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.077Z",
  //               "modifiedDate": "2020-03-19T07:29:41.077Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 10,
  //               "_id": "5e731f654dedb527e88247d1",
  //               "fileName": "ffe6459e-509c-5a5c-8154-78a5e9e7e462.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.078Z",
  //               "modifiedDate": "2020-03-19T07:29:41.078Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5e731f654dedb527e88247d2",
  //               "fileName": "5fa591ce-536c-544d-91ac-6c3cb917e606.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.329Z",
  //               "modifiedDate": "2020-03-19T07:29:41.329Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 6,
  //               "_id": "5e731f654dedb527e88247d3",
  //               "fileName": "a15ef5f2-e7e3-578c-af45-492cb9c21b39.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.330Z",
  //               "modifiedDate": "2020-03-19T07:29:41.330Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 10,
  //               "_id": "5e731f654dedb527e88247d5",
  //               "fileName": "26713543-3555-58bc-a586-97706c1c7ec0.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.387Z",
  //               "modifiedDate": "2020-03-19T07:29:41.387Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "5e731f654dedb527e88247d4",
  //               "fileName": "bcd5f6f1-0687-5e05-a966-ea04528a97f4.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.331Z",
  //               "modifiedDate": "2020-03-19T07:29:41.331Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 10,
  //               "_id": "5e731f654dedb527e88247d6",
  //               "fileName": "6d433ac9-9ada-5abe-b63d-bd2d8b12200d.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.391Z",
  //               "modifiedDate": "2020-03-19T07:29:41.391Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 4.405017072360036,
  //           "totalReviews": 305
  //         },
  //         {
  //           "_id": "5e457d1fc3b9e62c71618133",
  //           "place": {
  //             "placeId": "ChIJG5iteT3xeUgRvqFJ6S0yDCg",
  //             "vicinity": "20 Green Lane, Derby",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 -1.4778891,
  //                 52.92120979999999
  //               ]
  //             },
  //             "formatted_address": "20 Green Ln, Derby DE1 1RP, UK",
  //             "locality": "Derby",
  //             "state": "England",
  //             "country": "United Kingdom",
  //             "postal_code": "DE1 1RP"
  //           },
  //           "title": "Thai Boran",
  //           "slug": "thai-boran-3128",
  //           "ordering": 7579,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f43",
  //             "5e457cf4c3b9e62c71617f87"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "01332 405894"
  //             },
  //             {
  //               "bookNowTel": "01332 809 894"
  //             }
  //           ],
  //           "email": "ingthaisong70@hotmail.com, contact@thaiboranrestaurant.co.uk",
  //           "website": "https://www.thaiboranrestaurant.co.uk",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f0d",
  //             "5e457cd0c3b9e62c71617f0e",
  //             "5e457cd0c3b9e62c71617f33",
  //             "5e457cd0c3b9e62c71617f3a",
  //             "5ed0e9c4c38ee77c588ac66f",
  //             "5e457cd0c3b9e62c71617f11",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f12",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f08",
  //             "5e457cd0c3b9e62c71617f1b",
  //             "5e457cd0c3b9e62c71617f15",
  //             "5e457cd0c3b9e62c71617f1e",
  //             "5e457cd0c3b9e62c71617f1f",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f28",
  //             "5e457cd0c3b9e62c71617f0b",
  //             "5e457cd0c3b9e62c71617f40",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f03",
  //             "5e457cd0c3b9e62c71617f2a",
  //             "5e457cd0c3b9e62c71617f2c",
  //             "5e457cd0c3b9e62c71617f0c"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Drink": "5.00"
  //             },
  //             {
  //               "Starter": "7.12"
  //             },
  //             {
  //               "Main": "10.31"
  //             },
  //             {
  //               "Side": "3.50"
  //             },
  //             {
  //               "restaurantPrice": "25.93"
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "plateformRatings": [
  //             {
  //               "_id": "5fe07902d0a07c0b087e062e",
  //               "plateform": "tablefy",
  //               "rating": 9.53125,
  //               "user_ratings_total": 9
  //             }
  //           ],
  //           "createDate": "2020-02-13T16:45:19.669Z",
  //           "modifiedDate": "2021-04-09T05:57:52.478Z",
  //           "__v": 0,
  //           "callCount": 85,
  //           "virtualTourLink": null,
  //           "priceCategory": "A la carte",
  //           "coins": 2200,
  //           "restaurantView": 47,
  //           "contentScore": 73.6,
  //           "restaurantId": "5e457d1fc3b9e62c71618133",
  //           "bookingData": [],
  //           "totalBookings": 0,
  //           "offerData": [
  //             {
  //               "_id": "5ef5a5a9dfec8c73a65e0a34",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": "Student Offer",
  //               "description": "Testing Offer",
  //               "startDate": "2020-06-29T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": [
  //                   "AM",
  //                   "PM"
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": "125",
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-26T07:37:13.524Z",
  //               "modifiedDate": "2020-06-26T07:37:13.524Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5ef98c3fdfec8c73a65e0aa9",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   2,
  //                   4
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 30
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-29T06:37:51.349Z",
  //               "modifiedDate": "2020-06-29T06:37:51.350Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efbfd87d9d9b843fa943114",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   7,
  //                   9
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-01T03:05:43.511Z",
  //               "modifiedDate": "2020-07-01T03:05:43.511Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efbfde8d9d9b843fa943116",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-02T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 4,
  //                 "time": [
  //                   6,
  //                   8
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 30
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-01T03:07:20.193Z",
  //               "modifiedDate": "2020-07-01T03:07:20.193Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efc20acd9d9b843fa94311f",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": "Student Offer1",
  //               "description": "Testing Offer1",
  //               "startDate": "2020-06-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": [
  //                   "AM",
  //                   "PM"
  //                 ]
  //               },
  //               "menus": [
  //                 "5e4d501afef48878c35c5640"
  //               ],
  //               "offerCost": "300",
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-01T05:35:40.063Z",
  //               "modifiedDate": "2020-07-01T05:35:40.064Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd74ab6166ef07692ca1f1",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": "Student Offer2",
  //               "description": "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //               "startDate": "2020-07-02T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "1": [
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": [
  //                   "PM"
  //                 ]
  //               },
  //               "menus": [
  //                 "5e4d501afef48878c35c5640"
  //               ],
  //               "offerCost": "300",
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T05:46:19.799Z",
  //               "modifiedDate": "2020-07-02T05:46:19.799Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5eff055575e2ad2e1c60c533",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": "Student Offer",
  //               "description": "Testing Offer",
  //               "startDate": "2020-07-03T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": [
  //                   "AM",
  //                   "PM"
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": "250",
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-03T10:15:49.629Z",
  //               "modifiedDate": "2020-07-03T10:15:49.629Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5eff09d075e2ad2e1c60c535",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-04T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 5,
  //                 "time": [
  //                   24,
  //                   1
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-03T10:34:56.714Z",
  //               "modifiedDate": "2020-07-03T10:34:56.714Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5f017910cb85e119c7092600",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-07T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   4,
  //                   6
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-05T06:54:08.194Z",
  //               "modifiedDate": "2020-07-05T06:54:08.194Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5fe2f7680e016c55186c1873",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "user": "5dd8f3dbb2c69f43c4f23dc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-12-23T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   17,
  //                   19
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 54,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 30
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-12-23T07:53:12.610Z",
  //               "modifiedDate": "2020-12-23T10:46:57.583Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 10,
  //           "photoList": [
  //             {
  //               "category": "featured_image",
  //               "viewOrder": 0,
  //               "_id": "5e85c5b3d5358c37f85d48e8",
  //               "fileName": "62c8c935-094e-5293-b3ba-ee7b08c8d8b4.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:03.387Z",
  //               "modifiedDate": "2020-04-02T11:00:03.387Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "promotion",
  //               "viewOrder": 0,
  //               "_id": "5e85c5b4d5358c37f85d4909",
  //               "fileName": "3c22fc72-f690-5626-ae8b-56b9cf029309.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:04.891Z",
  //               "modifiedDate": "2020-04-02T11:00:04.891Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5e85c5b7d5358c37f85d4970",
  //               "fileName": "0fdf38fd-52ca-56cc-a2fc-3528cad89539.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.796Z",
  //               "modifiedDate": "2020-04-02T11:00:07.796Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5e85c5b7d5358c37f85d4977",
  //               "fileName": "26009e05-6787-5d76-b433-05a0d0d61640.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.857Z",
  //               "modifiedDate": "2020-04-02T11:00:07.857Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "5e85c5b7d5358c37f85d4971",
  //               "fileName": "fe6bc949-83c2-560f-92de-a4ba230123b0.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.797Z",
  //               "modifiedDate": "2020-04-02T11:00:07.797Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5e85c5b7d5358c37f85d4975",
  //               "fileName": "5110db33-f14c-5b86-bbd7-10a33723532e.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.828Z",
  //               "modifiedDate": "2020-04-02T11:00:07.828Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "5e85c5b7d5358c37f85d4973",
  //               "fileName": "c5e850f5-b8b6-56b1-ba6f-5b30d3329ef6.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.826Z",
  //               "modifiedDate": "2020-04-02T11:00:07.826Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 6,
  //               "_id": "5e85c5b7d5358c37f85d496f",
  //               "fileName": "82a3ef00-dfaf-5a39-ba2c-1af0df9233f5.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.795Z",
  //               "modifiedDate": "2020-04-02T11:00:07.795Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 7,
  //               "_id": "5e85c5b7d5358c37f85d4972",
  //               "fileName": "4ce402d6-66b9-5e2e-bc63-353304ea97f5.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.797Z",
  //               "modifiedDate": "2020-04-02T11:00:07.797Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 4.765625,
  //           "totalReviews": 9
  //         },
  //         {
  //           "_id": "5e457d20c3b9e62c71618318",
  //           "place": {
  //             "placeId": "ChIJ9UngE8vDeUgRG1XgteF-shs",
  //             "vicinity": "28 Maid Marian Way, Nottingham",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 -1.1534642,
  //                 52.9522527
  //               ]
  //             },
  //             "formatted_address": "28 Maid Marian Way, Nottingham NG1 6GF, UK",
  //             "locality": "Nottingham",
  //             "state": "England",
  //             "country": "United Kingdom",
  //             "postal_code": "NG1 6GF"
  //           },
  //           "title": "The Maharaja's Retreat",
  //           "slug": "the-maharaja's-retreat-3387",
  //           "ordering": 553,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f43"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "0115 958 0115"
  //             },
  //             {
  //               "bookNowTel": "0115 754 1115"
  //             },
  //             {
  //               "bookingRequestMobile": "7706263949"
  //             }
  //           ],
  //           "email": "info@maharajas-retreat.co.uk, andeep@maharajas-retreat.co.uk",
  //           "website": "https://www.maharajas-retreat.co.uk",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f00",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f11",
  //             "5e457cd0c3b9e62c71617f12",
  //             "5e457cd0c3b9e62c71617f13",
  //             "5e457cd0c3b9e62c71617f1f",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f22",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f24",
  //             "5e457cd0c3b9e62c71617f30",
  //             "5e457cd0c3b9e62c71617f40"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Drink": "5.00"
  //             },
  //             {
  //               "Starter": "7.94"
  //             },
  //             {
  //               "Main": "14.11"
  //             },
  //             {
  //               "Side": "2.97"
  //             },
  //             {
  //               "restaurantPrice": "30.02"
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e4fd92f01410f5b4eaa61ac",
  //               "plateform": "tablefy",
  //               "rating": 5,
  //               "user_ratings_total": 1
  //             },
  //             {
  //               "_id": "5e5e1323005c42375458a681",
  //               "plateform": "google",
  //               "rating": 4.7,
  //               "user_ratings_total": 41
  //             }
  //           ],
  //           "createDate": "2020-02-13T16:45:20.020Z",
  //           "modifiedDate": "2020-02-13T16:45:20.020Z",
  //           "__v": 0,
  //           "callCount": 33,
  //           "virtualTourLink": null,
  //           "priceCategory": "A la carte",
  //           "coins": 50,
  //           "restaurantView": 3,
  //           "restaurantId": "5e457d20c3b9e62c71618318",
  //           "bookingData": [],
  //           "totalBookings": 0,
  //           "offerData": [
  //             {
  //               "_id": "5efb2f70d9d9b843fa942c29",
  //               "restaurant": "5e457d20c3b9e62c71618318",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   8,
  //                   10
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-30T12:26:24.591Z",
  //               "modifiedDate": "2020-06-30T12:26:24.591Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efb2f7ed9d9b843fa942c2a",
  //               "restaurant": "5e457d20c3b9e62c71618318",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   4,
  //                   6
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-30T12:26:38.300Z",
  //               "modifiedDate": "2020-06-30T12:26:38.300Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 2,
  //           "photoList": [
  //             {
  //               "category": "featured_image",
  //               "viewOrder": 0,
  //               "_id": "5e731efe4dedb527e88231ca",
  //               "fileName": "9190d776-e5fa-52ac-a275-2d2ed38f04dc.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:27:58.040Z",
  //               "modifiedDate": "2020-03-19T07:27:58.040Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "promotion",
  //               "viewOrder": 0,
  //               "_id": "5e731f1c4dedb527e882392d",
  //               "fileName": "5ceb43d6-a1c8-5e96-a926-a9a3a0db15ea.png",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:28:28.037Z",
  //               "modifiedDate": "2020-03-19T07:28:28.037Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 7,
  //               "_id": "5e731f554dedb527e8824413",
  //               "fileName": "a82761ec-e89c-55ac-98ed-c755d64ac357.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.598Z",
  //               "modifiedDate": "2020-03-19T07:29:25.598Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5e731f554dedb527e8824414",
  //               "fileName": "05fcc3e6-877c-508e-914c-4b259b3f10c9.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.599Z",
  //               "modifiedDate": "2020-03-19T07:29:25.599Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5e731f554dedb527e8824416",
  //               "fileName": "1bde3a75-d8e9-5ff9-953a-a1a5c71c2fa7.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.610Z",
  //               "modifiedDate": "2020-03-19T07:29:25.610Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5e731f554dedb527e8824417",
  //               "fileName": "84f9ba10-f2ef-56ab-af1e-41a7d0743e21.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.632Z",
  //               "modifiedDate": "2020-03-19T07:29:25.632Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "5e731f554dedb527e8824418",
  //               "fileName": "cfaff745-2ec8-577d-a014-4a6aa7d07d1e.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.634Z",
  //               "modifiedDate": "2020-03-19T07:29:25.634Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 6,
  //               "_id": "5e731f554dedb527e8824419",
  //               "fileName": "de8694a8-809c-53d7-8ced-5a5bc8870076.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.648Z",
  //               "modifiedDate": "2020-03-19T07:29:25.648Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "5e731f554dedb527e882441b",
  //               "fileName": "249e7562-dfeb-5f12-89d3-b7dcbb3285c9.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.660Z",
  //               "modifiedDate": "2020-03-19T07:29:25.660Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 3.6,
  //           "totalReviews": 42
  //         },
  //         {
  //           "_id": "5e457d20c3b9e62c7161888f",
  //           "place": {
  //             "placeId": "ChIJE5LlHr_imzkRp9qohP3ByCI",
  //             "vicinity": "Bar First Floor, Hyatt Regency, Lucknow",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 81.00581199999999,
  //                 26.8660839
  //               ]
  //             },
  //             "formatted_address": "Bar First Floor, Hyatt Regency, Vibhuti Khand, Gomti Nagar, Lucknow, Uttar Pradesh 226010, India",
  //             "city": "Lucknow",
  //             "locality": "Lucknow",
  //             "state": "Uttar Pradesh",
  //             "country": "India",
  //             "postal_code": "226010"
  //           },
  //           "title": "All Bar One",
  //           "slug": "all-bar-one-2965",
  //           "ordering": 453,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f42",
  //             "5e457cf4c3b9e62c71617f4e",
  //             "5e457cf4c3b9e62c71617f61",
  //             "5e457cf4c3b9e62c71617f7e"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "0113 244 8835"
  //             }
  //           ],
  //           "email": "",
  //           "website": "http://www.allbarone.co.uk/national-search/yorkshire-and-the-humber/all-bar-one-millennium-square-leeds",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f00",
  //             "5e457cd0c3b9e62c71617f01",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f09",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f11",
  //             "5e457cd0c3b9e62c71617f13",
  //             "5e457cd0c3b9e62c71617f19",
  //             "5e457cd0c3b9e62c71617f1e",
  //             "5e457cd0c3b9e62c71617f1f",
  //             "5e457cd0c3b9e62c71617f20",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f24",
  //             "5e457cd0c3b9e62c71617f28",
  //             "5e457cd0c3b9e62c71617f29",
  //             "5e457cd0c3b9e62c71617f2f",
  //             "5e457cd0c3b9e62c71617f34",
  //             "5e457cd0c3b9e62c71617f3c",
  //             "5e457cd0c3b9e62c71617f41"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Drink": "5.00"
  //             },
  //             {
  //               "Starter": "9.17"
  //             },
  //             {
  //               "Main": "8.90"
  //             },
  //             {
  //               "Side": "3.25"
  //             },
  //             {
  //               "restaurantPrice": "26.32"
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e4fd93001410f5b4eaa6366",
  //               "plateform": "tablefy",
  //               "rating": 0,
  //               "user_ratings_total": 0
  //             },
  //             {
  //               "_id": "5e5e12e8005c42375458a61d",
  //               "plateform": "google",
  //               "rating": 4.1,
  //               "user_ratings_total": 17
  //             }
  //           ],
  //           "createDate": "2020-02-13T16:45:20.881Z",
  //           "modifiedDate": "2020-02-13T16:45:20.881Z",
  //           "__v": 0,
  //           "virtualTourLink": null,
  //           "priceCategory": "A la carte",
  //           "restaurantId": "5e457d20c3b9e62c7161888f",
  //           "bookingData": [],
  //           "totalBookings": 0,
  //           "offerData": [
  //             {
  //               "_id": "5fe32415df291f1ff3581787",
  //               "restaurant": "5e457d20c3b9e62c7161888f",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": "Bangla Fish Fry",
  //               "description": "test description",
  //               "startDate": null,
  //               "endDate": "2022-12-23T00:00:00.000Z",
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [],
  //                 "3": [],
  //                 "4": [
  //                   "PM"
  //                 ],
  //                 "5": [
  //                   "AM"
  //                 ],
  //                 "6": []
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-12-23T11:03:49.235Z",
  //               "modifiedDate": "2020-12-23T11:03:49.235Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "606d729dba81fc3b08430d81",
  //               "restaurant": "5e457d20c3b9e62c7161888f",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": "Cheapest burger today",
  //               "description": "40% off",
  //               "startDate": null,
  //               "endDate": "2022-04-22T00:00:00.000Z",
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [],
  //                 "3": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "4": [],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": []
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-04-07T08:51:41.721Z",
  //               "modifiedDate": "2021-04-07T08:51:41.721Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "606d72e5ba81fc3b08430d85",
  //               "restaurant": "5e457d20c3b9e62c7161888f",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-04-07T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   7,
  //                   10
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 10,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-04-07T08:52:53.217Z",
  //               "modifiedDate": "2021-04-07T08:52:53.217Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 3,
  //           "photoList": [
  //             {
  //               "category": "featured_image",
  //               "viewOrder": 0,
  //               "_id": "5e731efa4dedb527e8823164",
  //               "fileName": "94cff13c-a060-5810-9992-d2ad41a45c3d.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:27:54.739Z",
  //               "modifiedDate": "2020-03-19T07:27:54.739Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "promotion",
  //               "viewOrder": 0,
  //               "_id": "5e731f1a4dedb527e88238bb",
  //               "fileName": "de59d419-7a11-5a4a-b8bb-34602a2f8a00.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:28:26.260Z",
  //               "modifiedDate": "2020-03-19T07:28:26.260Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "5e731f494dedb527e882410c",
  //               "fileName": "a702bfd1-8874-509c-bb91-fca89d4ba90e.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.388Z",
  //               "modifiedDate": "2020-03-19T07:29:13.388Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5e731f494dedb527e882410b",
  //               "fileName": "5a28a26c-04b8-5889-bdbe-a3d0cfad1b63.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.388Z",
  //               "modifiedDate": "2020-03-19T07:29:13.388Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 7,
  //               "_id": "5e731f494dedb527e882410d",
  //               "fileName": "9711dab4-e404-5774-8bd8-75c1fedc2ea7.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.389Z",
  //               "modifiedDate": "2020-03-19T07:29:13.389Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5e731f494dedb527e882410f",
  //               "fileName": "7b59a39b-c28b-5605-9700-fc958555cfa3.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.399Z",
  //               "modifiedDate": "2020-03-19T07:29:13.399Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 9,
  //               "_id": "5e731f494dedb527e8824110",
  //               "fileName": "c8ad7143-dd9d-5bf3-bfcd-7d89971cf339.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.400Z",
  //               "modifiedDate": "2020-03-19T07:29:13.400Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5e731f494dedb527e8824111",
  //               "fileName": "a5a8e877-c8df-5615-b754-74209e7e4a6c.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.401Z",
  //               "modifiedDate": "2020-03-19T07:29:13.401Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "5e731f494dedb527e8824112",
  //               "fileName": "1dc737bc-d99d-5bb2-82ea-ae18996126aa.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.402Z",
  //               "modifiedDate": "2020-03-19T07:29:13.402Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 6,
  //               "_id": "5e731f494dedb527e8824113",
  //               "fileName": "f9330388-0f97-50ea-84af-b0594217d1ec.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.404Z",
  //               "modifiedDate": "2020-03-19T07:29:13.404Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 8,
  //               "_id": "5e731f494dedb527e8824114",
  //               "fileName": "31be12ba-3617-50bc-ba95-6cad73e5b236.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:13.405Z",
  //               "modifiedDate": "2020-03-19T07:29:13.405Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 2.05,
  //           "totalReviews": 17
  //         },
  //         {
  //           "_id": "5e457d1ec3b9e62c71617fc3",
  //           "place": {
  //             "placeId": "ChIJ3SUCMdbDeUgRGFcW5qFlgsw",
  //             "vicinity": "1st Floor, 16-22 Goose Gate, Nottingham",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 -1.1429632,
  //                 52.9535312
  //               ]
  //             },
  //             "formatted_address": "1st Floor, 16-22 Goose Gate, Nottingham NG1 1FF, UK",
  //             "locality": "Nottingham",
  //             "state": "England",
  //             "country": "United Kingdom",
  //             "postal_code": "NG1 1FF"
  //           },
  //           "title": "The Larder on Goosegate2",
  //           "slug": "the-larder-on-goosegate-1",
  //           "ordering": 29,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f42",
  //             "5e457cf4c3b9e62c71617f7e"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "0115 950 0111"
  //             },
  //             {
  //               "bookNowTel": "0115 754 0859"
  //             },
  //             {
  //               "bookingRequestMobile": "7903135645"
  //             }
  //           ],
  //           "email": "info@thelarderongoosegate.co.uk",
  //           "website": "http://www.thelarderongoosegate.co.uk/",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f00",
  //             "5e457cd0c3b9e62c71617f01",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f05",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f0b",
  //             "5e457cd0c3b9e62c71617f0e",
  //             "5e457cd0c3b9e62c71617f0f",
  //             "5e457cd0c3b9e62c71617f11",
  //             "5e457cd0c3b9e62c71617f13",
  //             "5e457cd0c3b9e62c71617f15",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f2a",
  //             "5e457cd0c3b9e62c71617f2c",
  //             "5e457cd0c3b9e62c71617f35",
  //             "5e457cd0c3b9e62c71617f3a",
  //             "5e457cd0c3b9e62c71617f3b",
  //             "5e457cd0c3b9e62c71617f3e",
  //             "5e457cd0c3b9e62c71617f40",
  //             "5e5a2ce2a6d2e9387d4aa400",
  //             "5e5a2ce2a6d2e9387d4aa403",
  //             "5e5a2ce2a6d2e9387d4aa405",
  //             "5e5a2ce2a6d2e9387d4aa409",
  //             "5e5a2ce2a6d2e9387d4aa40c",
  //             "5e5a2ce2a6d2e9387d4aa41b",
  //             "5e5a2ce2a6d2e9387d4aa428",
  //             "5e5a2ce2a6d2e9387d4aa432"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Drink": "5.00"
  //             },
  //             {
  //               "Starter": "6.87"
  //             },
  //             {
  //               "Main": "19.47"
  //             },
  //             {
  //               "Side": 0
  //             },
  //             {
  //               "restaurantPrice": "31.33"
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e4fd93101410f5b4eaa66f2",
  //               "plateform": "tablefy",
  //               "rating": 8.620068289440145,
  //               "user_ratings_total": 104
  //             },
  //             {
  //               "_id": "5e5e11d9005c42375458a475",
  //               "plateform": "google",
  //               "rating": 4.5,
  //               "user_ratings_total": 201
  //             }
  //           ],
  //           "createDate": "2020-02-13T16:45:18.627Z",
  //           "modifiedDate": "2020-02-13T16:45:18.627Z",
  //           "__v": 0,
  //           "callCount": 1257,
  //           "virtualTourLink": null,
  //           "priceCategory": "A la carte",
  //           "coins": 225,
  //           "restaurantView": 4,
  //           "restaurantId": "5e457d1ec3b9e62c71617fc2",
  //           "bookingData": [],
  //           "totalBookings": 0,
  //           "offerData": [
  //             {
  //               "_id": "5efd85d96166ef07692ca1fd",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-28T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   12,
  //                   14
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T06:59:37.632Z",
  //               "modifiedDate": "2020-07-02T06:59:37.632Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd86406166ef07692ca201",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-28T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   14,
  //                   16
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 350
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:01:20.739Z",
  //               "modifiedDate": "2020-07-02T07:01:20.739Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd91416166ef07692ca6e9",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   2,
  //                   5
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 100
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:48:17.769Z",
  //               "modifiedDate": "2020-07-02T07:48:17.770Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efd934c6166ef07692ca787",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   6,
  //                   9
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 450
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-02T07:57:00.709Z",
  //               "modifiedDate": "2020-07-02T07:57:00.709Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60c7956a3ce5ee38acd59472",
  //               "restaurant": "5e457d1ec3b9e62c71617fc2",
  //               "user": "5dd8f3dbb2c69f43c4f23dc2",
  //               "title": "Private Offer",
  //               "description": "Testing Offer",
  //               "startDate": "2020-08-25T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   15,
  //                   18
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": 125,
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "conjunction": false,
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-14T17:44:10.349Z",
  //               "modifiedDate": "2021-06-14T17:44:10.349Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 20,
  //           "photoList": [
  //             {
  //               "category": "featured_image",
  //               "viewOrder": 0,
  //               "_id": "5e731ef44dedb527e8822ff0",
  //               "fileName": "c5134a40-b73f-5f37-abbf-51bf2a2d09cc.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:27:48.945Z",
  //               "modifiedDate": "2020-03-19T07:27:48.945Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "logo",
  //               "viewOrder": 0,
  //               "_id": "5e731f024dedb527e88232a8",
  //               "fileName": "d82583c5-da45-5012-ab88-786bc2f0043e.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:28:02.118Z",
  //               "modifiedDate": "2020-03-19T07:28:02.118Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "promotion",
  //               "viewOrder": 0,
  //               "_id": "5e731f234dedb527e8823bd7",
  //               "fileName": "f8ce284e-af7f-541f-9f29-d072a169569a.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:28:35.999Z",
  //               "modifiedDate": "2020-03-19T07:28:35.999Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5e731f654dedb527e88247c9",
  //               "fileName": "c8ddf1f7-14f6-52f2-a61c-d7a5ea41125b.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.043Z",
  //               "modifiedDate": "2020-03-19T07:29:41.043Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 7,
  //               "_id": "5e731f654dedb527e88247cb",
  //               "fileName": "6ad734ac-70e9-5d2d-955b-5123c0559718.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.047Z",
  //               "modifiedDate": "2020-03-19T07:29:41.047Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 8,
  //               "_id": "5e731f654dedb527e88247cc",
  //               "fileName": "f1fa134a-0da5-5cf4-a253-8dabdd2f7ef6.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.060Z",
  //               "modifiedDate": "2020-03-19T07:29:41.060Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5e731f654dedb527e88247cd",
  //               "fileName": "0eab7819-c957-523f-a0cf-ea2d6b3eca7d.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.061Z",
  //               "modifiedDate": "2020-03-19T07:29:41.061Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 9,
  //               "_id": "5e731f654dedb527e88247cf",
  //               "fileName": "77f08de0-e9c5-5e77-8340-d19a5439cad7.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.071Z",
  //               "modifiedDate": "2020-03-19T07:29:41.071Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "5e731f654dedb527e88247d0",
  //               "fileName": "ea3978b1-7615-5717-95ab-1175a8775fe2.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.077Z",
  //               "modifiedDate": "2020-03-19T07:29:41.077Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 10,
  //               "_id": "5e731f654dedb527e88247d1",
  //               "fileName": "ffe6459e-509c-5a5c-8154-78a5e9e7e462.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.078Z",
  //               "modifiedDate": "2020-03-19T07:29:41.078Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5e731f654dedb527e88247d2",
  //               "fileName": "5fa591ce-536c-544d-91ac-6c3cb917e606.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.329Z",
  //               "modifiedDate": "2020-03-19T07:29:41.329Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 6,
  //               "_id": "5e731f654dedb527e88247d3",
  //               "fileName": "a15ef5f2-e7e3-578c-af45-492cb9c21b39.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.330Z",
  //               "modifiedDate": "2020-03-19T07:29:41.330Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 10,
  //               "_id": "5e731f654dedb527e88247d5",
  //               "fileName": "26713543-3555-58bc-a586-97706c1c7ec0.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.387Z",
  //               "modifiedDate": "2020-03-19T07:29:41.387Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "5e731f654dedb527e88247d4",
  //               "fileName": "bcd5f6f1-0687-5e05-a966-ea04528a97f4.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.331Z",
  //               "modifiedDate": "2020-03-19T07:29:41.331Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 10,
  //               "_id": "5e731f654dedb527e88247d6",
  //               "fileName": "6d433ac9-9ada-5abe-b63d-bd2d8b12200d.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:41.391Z",
  //               "modifiedDate": "2020-03-19T07:29:41.391Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 4.405017072360036,
  //           "totalReviews": 305
  //         },
  //         {
  //           "_id": "5e457d1fc3b9e62c71618134",
  //           "place": {
  //             "placeId": "ChIJG5iteT3xeUgRvqFJ6S0yDCg",
  //             "vicinity": "20 Green Lane, Derby",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 -1.4778891,
  //                 52.92120979999999
  //               ]
  //             },
  //             "formatted_address": "20 Green Ln, Derby DE1 1RP, UK",
  //             "locality": "Derby",
  //             "state": "England",
  //             "country": "United Kingdom",
  //             "postal_code": "DE1 1RP"
  //           },
  //           "title": "Thai Boran",
  //           "slug": "thai-boran-3128",
  //           "ordering": 7579,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f43",
  //             "5e457cf4c3b9e62c71617f87"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "01332 405894"
  //             },
  //             {
  //               "bookNowTel": "01332 809 894"
  //             }
  //           ],
  //           "email": "ingthaisong70@hotmail.com, contact@thaiboranrestaurant.co.uk",
  //           "website": "https://www.thaiboranrestaurant.co.uk",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f0d",
  //             "5e457cd0c3b9e62c71617f0e",
  //             "5e457cd0c3b9e62c71617f33",
  //             "5e457cd0c3b9e62c71617f3a",
  //             "5ed0e9c4c38ee77c588ac66f",
  //             "5e457cd0c3b9e62c71617f11",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f12",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f08",
  //             "5e457cd0c3b9e62c71617f1b",
  //             "5e457cd0c3b9e62c71617f15",
  //             "5e457cd0c3b9e62c71617f1e",
  //             "5e457cd0c3b9e62c71617f1f",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f28",
  //             "5e457cd0c3b9e62c71617f0b",
  //             "5e457cd0c3b9e62c71617f40",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f03",
  //             "5e457cd0c3b9e62c71617f2a",
  //             "5e457cd0c3b9e62c71617f2c",
  //             "5e457cd0c3b9e62c71617f0c"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Drink": "5.00"
  //             },
  //             {
  //               "Starter": "7.12"
  //             },
  //             {
  //               "Main": "10.31"
  //             },
  //             {
  //               "Side": "3.50"
  //             },
  //             {
  //               "restaurantPrice": "25.93"
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "plateformRatings": [
  //             {
  //               "_id": "5fe07902d0a07c0b087e062e",
  //               "plateform": "tablefy",
  //               "rating": 9.53125,
  //               "user_ratings_total": 9
  //             }
  //           ],
  //           "createDate": "2020-02-13T16:45:19.669Z",
  //           "modifiedDate": "2021-04-09T05:57:52.478Z",
  //           "__v": 0,
  //           "callCount": 85,
  //           "virtualTourLink": null,
  //           "priceCategory": "A la carte",
  //           "coins": 2200,
  //           "restaurantView": 47,
  //           "contentScore": 73.6,
  //           "restaurantId": "5e457d1fc3b9e62c71618133",
  //           "bookingData": [],
  //           "totalBookings": 0,
  //           "offerData": [
  //             {
  //               "_id": "5ef5a5a9dfec8c73a65e0a34",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": "Student Offer",
  //               "description": "Testing Offer",
  //               "startDate": "2020-06-29T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": [
  //                   "AM",
  //                   "PM"
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": "125",
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-26T07:37:13.524Z",
  //               "modifiedDate": "2020-06-26T07:37:13.524Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5ef98c3fdfec8c73a65e0aa9",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-06-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   2,
  //                   4
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 30
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-29T06:37:51.349Z",
  //               "modifiedDate": "2020-06-29T06:37:51.350Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efbfd87d9d9b843fa943114",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   7,
  //                   9
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-01T03:05:43.511Z",
  //               "modifiedDate": "2020-07-01T03:05:43.511Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efc20acd9d9b843fa94311f",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": "Student Offer1",
  //               "description": "Testing Offer1",
  //               "startDate": "2020-06-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": [
  //                   "AM",
  //                   "PM"
  //                 ]
  //               },
  //               "menus": [
  //                 "5e4d501afef48878c35c5640"
  //               ],
  //               "offerCost": "300",
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-01T05:35:40.063Z",
  //               "modifiedDate": "2020-07-01T05:35:40.064Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5eff055575e2ad2e1c60c533",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": "Student Offer",
  //               "description": "Testing Offer",
  //               "startDate": "2020-07-03T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "6": [
  //                   "AM",
  //                   "PM"
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": "250",
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-03T10:15:49.629Z",
  //               "modifiedDate": "2020-07-03T10:15:49.629Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5eff09d075e2ad2e1c60c535",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-04T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 5,
  //                 "time": [
  //                   24,
  //                   1
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-03T10:34:56.714Z",
  //               "modifiedDate": "2020-07-03T10:34:56.714Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5f017910cb85e119c7092600",
  //               "restaurant": "5e457d1fc3b9e62c71618133",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-07T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   4,
  //                   6
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-07-05T06:54:08.194Z",
  //               "modifiedDate": "2020-07-05T06:54:08.194Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 10,
  //           "photoList": [
  //             {
  //               "category": "featured_image",
  //               "viewOrder": 0,
  //               "_id": "5e85c5b3d5358c37f85d48e8",
  //               "fileName": "62c8c935-094e-5293-b3ba-ee7b08c8d8b4.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:03.387Z",
  //               "modifiedDate": "2020-04-02T11:00:03.387Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "promotion",
  //               "viewOrder": 0,
  //               "_id": "5e85c5b4d5358c37f85d4909",
  //               "fileName": "3c22fc72-f690-5626-ae8b-56b9cf029309.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:04.891Z",
  //               "modifiedDate": "2020-04-02T11:00:04.891Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5e85c5b7d5358c37f85d4970",
  //               "fileName": "0fdf38fd-52ca-56cc-a2fc-3528cad89539.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.796Z",
  //               "modifiedDate": "2020-04-02T11:00:07.796Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5e85c5b7d5358c37f85d4977",
  //               "fileName": "26009e05-6787-5d76-b433-05a0d0d61640.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.857Z",
  //               "modifiedDate": "2020-04-02T11:00:07.857Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "5e85c5b7d5358c37f85d4971",
  //               "fileName": "fe6bc949-83c2-560f-92de-a4ba230123b0.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.797Z",
  //               "modifiedDate": "2020-04-02T11:00:07.797Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5e85c5b7d5358c37f85d4975",
  //               "fileName": "5110db33-f14c-5b86-bbd7-10a33723532e.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.828Z",
  //               "modifiedDate": "2020-04-02T11:00:07.828Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "5e85c5b7d5358c37f85d4973",
  //               "fileName": "c5e850f5-b8b6-56b1-ba6f-5b30d3329ef6.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.826Z",
  //               "modifiedDate": "2020-04-02T11:00:07.826Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 6,
  //               "_id": "5e85c5b7d5358c37f85d496f",
  //               "fileName": "82a3ef00-dfaf-5a39-ba2c-1af0df9233f5.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.795Z",
  //               "modifiedDate": "2020-04-02T11:00:07.795Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 7,
  //               "_id": "5e85c5b7d5358c37f85d4972",
  //               "fileName": "4ce402d6-66b9-5e2e-bc63-353304ea97f5.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-04-02T11:00:07.797Z",
  //               "modifiedDate": "2020-04-02T11:00:07.797Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 4.765625,
  //           "totalReviews": 9
  //         },
  //         {
  //           "_id": "5e8342f0426bd609283151b5",
  //           "place": {
  //             "placeId": "ChIJZ_YISduC-DkRvCxsj-Yw40M",
  //             "vicinity": "Kolkata, West Bengal, India",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 88.35713,
  //                 22.50644
  //               ]
  //             },
  //             "formatted_address": "Kolkata, West Bengal, India",
  //             "city": "",
  //             "locality": "Kolkata",
  //             "state": "West Bengal",
  //             "country": "India",
  //             "postal_code": "226016"
  //           },
  //           "title": "FOODieKolkata",
  //           "slug": "foodiekolkata",
  //           "ordering": 1417,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f43"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "+918423541453"
  //             }
  //           ],
  //           "email": "deepbn9@gmail.com",
  //           "website": "https://google.com",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f33",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f0d",
  //             "5e457cd0c3b9e62c71617f0e",
  //             "5e457cd0c3b9e62c71617f2f",
  //             "5e457cd0c3b9e62c71617f30",
  //             "5e457cd0c3b9e62c71617f3a",
  //             "5e457cd0c3b9e62c71617f3c",
  //             "5f14646fb55762717d1f5f08",
  //             "5ed0e95ec38ee77c588ac649",
  //             "5ed0e9c4c38ee77c588ac66f",
  //             "5ed0e9cbc38ee77c588ac673",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f12",
  //             "5e457cd0c3b9e62c71617f08",
  //             "5e457cd0c3b9e62c71617f1b",
  //             "5e457cd0c3b9e62c71617f15",
  //             "5e457cd0c3b9e62c71617f1e",
  //             "5e457cd0c3b9e62c71617f28",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f40",
  //             "5e457cd0c3b9e62c71617f29",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f05",
  //             "5e457cd0c3b9e62c71617f0c",
  //             "5e457cd0c3b9e62c71617f2a",
  //             "5e457cd0c3b9e62c71617f2c"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Dishes": "10.00"
  //             },
  //             {
  //               "Side": "6.00"
  //             },
  //             {
  //               "Dessert": "5.00"
  //             },
  //             {
  //               "Drink": "10.00"
  //             },
  //             {
  //               "restaurantPrice": 41
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "callCount": 0,
  //           "priceCategory": "Small plates",
  //           "virtualTourLink": "&lt;iframe src=\"https://www.google.com/maps/embed?pb=!4v1592389454593!6m8!1m7!1sCAoSLEFGMVFpcE1MeFA1eWoxSVlnOVdIZ3dReFJBYlh3czZlWVZ3d0dUZTV3MmZM!2m2!1d22.50652641389507!2d88.36708483218035!3f259.9693289724221!4f-2.750035865624028!5f0.7820865974627469\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0;\" allowfullscreen=\"\" aria-hidden=\"false\" tabindex=\"0\">&lt;/iframe>",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e8342f0426bd609283151b7",
  //               "plateform": "google"
  //             },
  //             {
  //               "_id": "60af99d99a6b950ad122fc9f",
  //               "plateform": "tablefy",
  //               "rating": 0,
  //               "user_ratings_total": 34
  //             }
  //           ],
  //           "createDate": "2020-03-31T13:17:36.458Z",
  //           "modifiedDate": "2021-06-21T07:31:09.649Z",
  //           "__v": 0,
  //           "coins": 30200,
  //           "restaurantView": 238,
  //           "contentScore": 82,
  //           "openDate": null,
  //           "credits": 80,
  //           "restaurantId": "5e8342f0426bd609283151b6",
  //           "bookingData": [
  //             {
  //               "_id": "60d17847f60bef344ed354cd",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": {
  //                 "_id": "5feccf8742c65151f82d847d",
  //                 "restaurant": "5e8342f0426bd609283151b6",
  //                 "user": "5ee376450198393ee8cea9d9",
  //                 "title": null,
  //                 "description": null,
  //                 "startDate": "2020-12-30T00:00:00.000Z",
  //                 "endDate": null,
  //                 "appliedOn": {
  //                   "day": 3,
  //                   "time": [
  //                     7,
  //                     8
  //                   ]
  //                 },
  //                 "menus": [],
  //                 "offerCost": null,
  //                 "menuCost": null,
  //                 "labels": [],
  //                 "discount": {
  //                   "type": "rewards",
  //                   "value": 350
  //                 },
  //                 "discountType": "percent",
  //                 "offerCategory": "weeklyOffer",
  //                 "status": "active",
  //                 "inspected": false,
  //                 "createDate": "2020-12-30T19:05:43.454Z",
  //                 "modifiedDate": "2020-12-30T19:05:43.454Z",
  //                 "inspectionHistory": [],
  //                 "__v": 0
  //               },
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": {
  //                 "expiryDate": "2021-06-22T00:00:00.000Z",
  //                 "redeemDate": null,
  //                 "status": "active",
  //                 "_id": "60c892748639f7077f6c3a62",
  //                 "chest": {
  //                   "url": "/dashboard/my-offers",
  //                   "status": "active",
  //                   "user": "60ab6f202e6daa412ccc6758",
  //                   "chest": "5ec23f0cf051ab306fbdf334",
  //                   "title": "Unlock any offer for free within 7 days",
  //                   "expiry": 7,
  //                   "valuex": 0,
  //                   "tracked": false,
  //                   "frequency": 5,
  //                   "currentFrequency": 2,
  //                   "weightage": 1,
  //                   "activeDate": "2021-06-15",
  //                   "__v": 0
  //                 },
  //                 "chestCoin": 1,
  //                 "user": "60ab6f202e6daa412ccc6758",
  //                 "activeDate": "2021-06-15T00:00:00.000Z",
  //                 "createdDate": "2021-06-15T11:43:48.646Z",
  //                 "modifiedDate": "2021-06-15T11:43:48.646Z",
  //                 "__v": 0
  //               },
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-22T06:31:29.553Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 1,
  //               "bookingDate": "2021-06-23T07:30:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-22T05:42:31.663Z",
  //               "modifiedDate": "2021-06-22T06:31:29.553Z",
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d17905f60bef344ed354db",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": {
  //                 "_id": "60cc43d47a54b747f94bee1c",
  //                 "restaurant": "5e8342f0426bd609283151b6",
  //                 "user": "5ee376450198393ee8cea9d9",
  //                 "title": null,
  //                 "description": null,
  //                 "startDate": "2021-06-18T00:00:00.000Z",
  //                 "endDate": null,
  //                 "appliedOn": {
  //                   "day": 3,
  //                   "time": [
  //                     8,
  //                     10
  //                   ],
  //                   "repeat": false
  //                 },
  //                 "menus": [],
  //                 "offerCost": 0,
  //                 "menuCost": null,
  //                 "labels": [],
  //                 "discount": {
  //                   "type": "percent",
  //                   "value": 40,
  //                   "conjunction": false
  //                 },
  //                 "discountType": "percent",
  //                 "offerCategory": "weeklyOffer",
  //                 "status": "active",
  //                 "inspected": false,
  //                 "createDate": "2021-06-18T06:57:24.856Z",
  //                 "modifiedDate": "2021-06-18T06:57:24.856Z",
  //                 "inspectionHistory": [],
  //                 "__v": 0
  //               },
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": {
  //                 "expiryDate": "2021-06-22T00:00:00.000Z",
  //                 "redeemDate": null,
  //                 "status": "active",
  //                 "_id": "60c892748639f7077f6c3a62",
  //                 "chest": {
  //                   "url": "/dashboard/my-offers",
  //                   "status": "active",
  //                   "user": "60ab6f202e6daa412ccc6758",
  //                   "chest": "5ec23f0cf051ab306fbdf334",
  //                   "title": "Unlock any offer for free within 7 days",
  //                   "expiry": 7,
  //                   "valuex": 0,
  //                   "tracked": false,
  //                   "frequency": 5,
  //                   "currentFrequency": 2,
  //                   "weightage": 1,
  //                   "activeDate": "2021-06-15",
  //                   "__v": 0
  //                 },
  //                 "chestCoin": 1,
  //                 "user": "60ab6f202e6daa412ccc6758",
  //                 "activeDate": "2021-06-15T00:00:00.000Z",
  //                 "createdDate": "2021-06-15T11:43:48.646Z",
  //                 "modifiedDate": "2021-06-15T11:43:48.646Z",
  //                 "__v": 0
  //               },
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-22T06:31:40.289Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 2,
  //               "bookingDate": "2021-06-23T08:45:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-22T05:45:41.106Z",
  //               "modifiedDate": "2021-06-22T06:31:40.289Z",
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d5ba5eba92690eb9bbdd4c",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": null,
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": null,
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-25T11:16:20.506Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 1,
  //               "bookingDate": "2021-06-30T12:00:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-25T11:13:34.750Z",
  //               "modifiedDate": "2021-06-25T11:16:20.506Z",
  //               "__v": 0
  //             }
  //           ],
  //           "totalBookings": 3,
  //           "offerData": [
  //             {
  //               "_id": "5f699bc8bac4f640fb4a7a1c",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "title": "Test",
  //               "description": "test desc",
  //               "startDate": null,
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [],
  //                 "5": [],
  //                 "6": []
  //               },
  //               "menus": [
  //                 "5e4d501afef48878c35c563e"
  //               ],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-09-22T06:38:00.552Z",
  //               "modifiedDate": "2020-09-22T06:38:39.307Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5fec5da042c65151f82d8423",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-17T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   15,
  //                   19
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 0,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20,
  //                 "conjunction": true
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-12-30T10:59:44.397Z",
  //               "modifiedDate": "2021-06-17T05:00:09.518Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5feccf8742c65151f82d847d",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-12-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   7,
  //                   8
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 350
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-12-30T19:05:43.454Z",
  //               "modifiedDate": "2020-12-30T19:05:43.454Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "604babd13e7f3f25a6fceb16",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-03-12T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 2,
  //                 "time": [
  //                   16,
  //                   19
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 300,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-03-12T17:58:41.134Z",
  //               "modifiedDate": "2021-03-12T17:58:53.492Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 19,
  //           "photoList": [
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5f05ac4ecb4b325fccd6c33f",
  //               "fileName": "file-1594207309618.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "casey-lee-awj7sRviVXo-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207309618.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2020-07-08T11:21:50.478Z",
  //               "modifiedDate": "2020-07-08T11:21:50.478Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5f05ac60cb4b325fccd6c340",
  //               "fileName": "file-1594207327745.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "alex-munsell-Yr4n8O_3UPc-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207327745.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "steak master",
  //               "createDate": "2020-07-08T11:22:08.752Z",
  //               "modifiedDate": "2020-07-08T11:22:08.752Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "607d3b3e538804430d8c094e",
  //               "fileName": "file-1618819902235.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "chicken-biryani-recipe.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1618819902235.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2021-04-19T08:11:42.406Z",
  //               "modifiedDate": "2021-04-19T08:11:42.406Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5f05ad06cb4b325fccd6c348",
  //               "fileName": "file-1594207493190.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "taylor-kiser-EvoIiaIVRzU-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207493190.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2020-07-08T11:24:54.691Z",
  //               "modifiedDate": "2020-07-08T11:24:54.691Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "607d7d50538804430d8c0b19",
  //               "fileName": "file-1618836815837.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "re-1.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1618836815837.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "userSuggestion",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2021-04-19T12:53:36.123Z",
  //               "modifiedDate": "2021-04-19T12:53:36.123Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 0,
  //           "totalReviews": 34
  //         },
  //         {
  //           "_id": "5e457d20c3b9e62c716183182",
  //           "place": {
  //             "placeId": "ChIJ9UngE8vDeUgRG1XgteF-shs",
  //             "vicinity": "28 Maid Marian Way, Nottingham",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 -1.1534642,
  //                 52.9522527
  //               ]
  //             },
  //             "formatted_address": "28 Maid Marian Way, Nottingham NG1 6GF, UK",
  //             "locality": "Nottingham",
  //             "state": "England",
  //             "country": "United Kingdom",
  //             "postal_code": "NG1 6GF"
  //           },
  //           "title": "The Maharaja's Retreat",
  //           "slug": "the-maharaja's-retreat-3387",
  //           "ordering": 553,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f43"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "0115 958 0115"
  //             },
  //             {
  //               "bookNowTel": "0115 754 1115"
  //             },
  //             {
  //               "bookingRequestMobile": "7706263949"
  //             }
  //           ],
  //           "email": "info@maharajas-retreat.co.uk, andeep@maharajas-retreat.co.uk",
  //           "website": "https://www.maharajas-retreat.co.uk",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f00",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f11",
  //             "5e457cd0c3b9e62c71617f12",
  //             "5e457cd0c3b9e62c71617f13",
  //             "5e457cd0c3b9e62c71617f1f",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f22",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f24",
  //             "5e457cd0c3b9e62c71617f30",
  //             "5e457cd0c3b9e62c71617f40"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Drink": "5.00"
  //             },
  //             {
  //               "Starter": "7.94"
  //             },
  //             {
  //               "Main": "14.11"
  //             },
  //             {
  //               "Side": "2.97"
  //             },
  //             {
  //               "restaurantPrice": "30.02"
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e4fd92f01410f5b4eaa61ac",
  //               "plateform": "tablefy",
  //               "rating": 5,
  //               "user_ratings_total": 1
  //             },
  //             {
  //               "_id": "5e5e1323005c42375458a681",
  //               "plateform": "google",
  //               "rating": 4.7,
  //               "user_ratings_total": 41
  //             }
  //           ],
  //           "createDate": "2020-02-13T16:45:20.020Z",
  //           "modifiedDate": "2020-02-13T16:45:20.020Z",
  //           "__v": 0,
  //           "callCount": 33,
  //           "virtualTourLink": null,
  //           "priceCategory": "A la carte",
  //           "coins": 50,
  //           "restaurantView": 3,
  //           "restaurantId": "5e457d20c3b9e62c71618318",
  //           "bookingData": [],
  //           "totalBookings": 0,
  //           "offerData": [
  //             {
  //               "_id": "5efb2f70d9d9b843fa942c29",
  //               "restaurant": "5e457d20c3b9e62c71618318",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   8,
  //                   10
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-30T12:26:24.591Z",
  //               "modifiedDate": "2020-06-30T12:26:24.591Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5efb2f7ed9d9b843fa942c2a",
  //               "restaurant": "5e457d20c3b9e62c71618318",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-07-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   4,
  //                   6
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 20
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-06-30T12:26:38.300Z",
  //               "modifiedDate": "2020-06-30T12:26:38.300Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 2,
  //           "photoList": [
  //             {
  //               "category": "featured_image",
  //               "viewOrder": 0,
  //               "_id": "5e731efe4dedb527e88231ca",
  //               "fileName": "9190d776-e5fa-52ac-a275-2d2ed38f04dc.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:27:58.040Z",
  //               "modifiedDate": "2020-03-19T07:27:58.040Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "promotion",
  //               "viewOrder": 0,
  //               "_id": "5e731f1c4dedb527e882392d",
  //               "fileName": "5ceb43d6-a1c8-5e96-a926-a9a3a0db15ea.png",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:28:28.037Z",
  //               "modifiedDate": "2020-03-19T07:28:28.037Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 7,
  //               "_id": "5e731f554dedb527e8824413",
  //               "fileName": "a82761ec-e89c-55ac-98ed-c755d64ac357.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.598Z",
  //               "modifiedDate": "2020-03-19T07:29:25.598Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5e731f554dedb527e8824414",
  //               "fileName": "05fcc3e6-877c-508e-914c-4b259b3f10c9.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.599Z",
  //               "modifiedDate": "2020-03-19T07:29:25.599Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5e731f554dedb527e8824416",
  //               "fileName": "1bde3a75-d8e9-5ff9-953a-a1a5c71c2fa7.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.610Z",
  //               "modifiedDate": "2020-03-19T07:29:25.610Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5e731f554dedb527e8824417",
  //               "fileName": "84f9ba10-f2ef-56ab-af1e-41a7d0743e21.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.632Z",
  //               "modifiedDate": "2020-03-19T07:29:25.632Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "5e731f554dedb527e8824418",
  //               "fileName": "cfaff745-2ec8-577d-a014-4a6aa7d07d1e.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.634Z",
  //               "modifiedDate": "2020-03-19T07:29:25.634Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 6,
  //               "_id": "5e731f554dedb527e8824419",
  //               "fileName": "de8694a8-809c-53d7-8ced-5a5bc8870076.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.648Z",
  //               "modifiedDate": "2020-03-19T07:29:25.648Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "5e731f554dedb527e882441b",
  //               "fileName": "249e7562-dfeb-5f12-89d3-b7dcbb3285c9.jpg",
  //               "status": "active",
  //               "inspected": true,
  //               "description": null,
  //               "createDate": "2020-03-19T07:29:25.660Z",
  //               "modifiedDate": "2020-03-19T07:29:25.660Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 3.6,
  //           "totalReviews": 42
  //         },
  //         {
  //           "_id": "5e8342f0426bd609283151b6",
  //           "place": {
  //             "placeId": "ChIJZ_YISduC-DkRvCxsj-Yw40M",
  //             "vicinity": "Kolkata, West Bengal, India",
  //             "location": {
  //               "type": "Point",
  //               "coordinates": [
  //                 88.35713,
  //                 22.50644
  //               ]
  //             },
  //             "formatted_address": "Kolkata, West Bengal, India",
  //             "city": "",
  //             "locality": "Kolkata",
  //             "state": "West Bengal",
  //             "country": "India",
  //             "postal_code": "226016"
  //           },
  //           "title": "FOODieKolkata",
  //           "slug": "foodiekolkata",
  //           "ordering": 1417,
  //           "region": null,
  //           "county": null,
  //           "city": null,
  //           "cuisine": [
  //             "5e457cf4c3b9e62c71617f43"
  //           ],
  //           "phones": [
  //             {
  //               "restaurantTel": "+918423541453"
  //             }
  //           ],
  //           "email": "deepbn9@gmail.com",
  //           "website": "https://google.com",
  //           "tags": [
  //             "5e457cd0c3b9e62c71617f33",
  //             "5e457cd0c3b9e62c71617f23",
  //             "5e457cd0c3b9e62c71617f21",
  //             "5e457cd0c3b9e62c71617f0d",
  //             "5e457cd0c3b9e62c71617f0e",
  //             "5e457cd0c3b9e62c71617f2f",
  //             "5e457cd0c3b9e62c71617f30",
  //             "5e457cd0c3b9e62c71617f3a",
  //             "5e457cd0c3b9e62c71617f3c",
  //             "5f14646fb55762717d1f5f08",
  //             "5ed0e95ec38ee77c588ac649",
  //             "5ed0e9c4c38ee77c588ac66f",
  //             "5ed0e9cbc38ee77c588ac673",
  //             "5e457cd0c3b9e62c71617f07",
  //             "5e457cd0c3b9e62c71617f12",
  //             "5e457cd0c3b9e62c71617f08",
  //             "5e457cd0c3b9e62c71617f1b",
  //             "5e457cd0c3b9e62c71617f15",
  //             "5e457cd0c3b9e62c71617f1e",
  //             "5e457cd0c3b9e62c71617f28",
  //             "5e457cd0c3b9e62c71617f0a",
  //             "5e457cd0c3b9e62c71617f40",
  //             "5e457cd0c3b9e62c71617f29",
  //             "5e457cd0c3b9e62c71617f04",
  //             "5e457cd0c3b9e62c71617f05",
  //             "5e457cd0c3b9e62c71617f0c",
  //             "5e457cd0c3b9e62c71617f2a",
  //             "5e457cd0c3b9e62c71617f2c"
  //           ],
  //           "avgPrice": [
  //             {
  //               "Dishes": "10.00"
  //             },
  //             {
  //               "Side": "6.00"
  //             },
  //             {
  //               "Dessert": "5.00"
  //             },
  //             {
  //               "Drink": "10.00"
  //             },
  //             {
  //               "restaurantPrice": 41
  //             }
  //           ],
  //           "ssoReviews": [],
  //           "status": "active",
  //           "restaurantType": "listed",
  //           "callCount": 0,
  //           "priceCategory": "Small plates",
  //           "virtualTourLink": "&lt;iframe src=\"https://www.google.com/maps/embed?pb=!4v1592389454593!6m8!1m7!1sCAoSLEFGMVFpcE1MeFA1eWoxSVlnOVdIZ3dReFJBYlh3czZlWVZ3d0dUZTV3MmZM!2m2!1d22.50652641389507!2d88.36708483218035!3f259.9693289724221!4f-2.750035865624028!5f0.7820865974627469\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0;\" allowfullscreen=\"\" aria-hidden=\"false\" tabindex=\"0\">&lt;/iframe>",
  //           "plateformRatings": [
  //             {
  //               "_id": "5e8342f0426bd609283151b7",
  //               "plateform": "google"
  //             },
  //             {
  //               "_id": "60af99d99a6b950ad122fc9f",
  //               "plateform": "tablefy",
  //               "rating": 0,
  //               "user_ratings_total": 34
  //             }
  //           ],
  //           "createDate": "2020-03-31T13:17:36.458Z",
  //           "modifiedDate": "2021-06-21T07:31:09.649Z",
  //           "__v": 0,
  //           "coins": 30200,
  //           "restaurantView": 238,
  //           "contentScore": 82,
  //           "openDate": null,
  //           "credits": 80,
  //           "restaurantId": "5e8342f0426bd609283151b6",
  //           "bookingData": [
  //             {
  //               "_id": "60d17847f60bef344ed354cd",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": {
  //                 "_id": "5feccf8742c65151f82d847d",
  //                 "restaurant": "5e8342f0426bd609283151b6",
  //                 "user": "5ee376450198393ee8cea9d9",
  //                 "title": null,
  //                 "description": null,
  //                 "startDate": "2020-12-30T00:00:00.000Z",
  //                 "endDate": null,
  //                 "appliedOn": {
  //                   "day": 3,
  //                   "time": [
  //                     7,
  //                     8
  //                   ]
  //                 },
  //                 "menus": [],
  //                 "offerCost": null,
  //                 "menuCost": null,
  //                 "labels": [],
  //                 "discount": {
  //                   "type": "rewards",
  //                   "value": 350
  //                 },
  //                 "discountType": "percent",
  //                 "offerCategory": "weeklyOffer",
  //                 "status": "active",
  //                 "inspected": false,
  //                 "createDate": "2020-12-30T19:05:43.454Z",
  //                 "modifiedDate": "2020-12-30T19:05:43.454Z",
  //                 "inspectionHistory": [],
  //                 "__v": 0
  //               },
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": {
  //                 "expiryDate": "2021-06-22T00:00:00.000Z",
  //                 "redeemDate": null,
  //                 "status": "active",
  //                 "_id": "60c892748639f7077f6c3a62",
  //                 "chest": {
  //                   "url": "/dashboard/my-offers",
  //                   "status": "active",
  //                   "user": "60ab6f202e6daa412ccc6758",
  //                   "chest": "5ec23f0cf051ab306fbdf334",
  //                   "title": "Unlock any offer for free within 7 days",
  //                   "expiry": 7,
  //                   "valuex": 0,
  //                   "tracked": false,
  //                   "frequency": 5,
  //                   "currentFrequency": 2,
  //                   "weightage": 1,
  //                   "activeDate": "2021-06-15",
  //                   "__v": 0
  //                 },
  //                 "chestCoin": 1,
  //                 "user": "60ab6f202e6daa412ccc6758",
  //                 "activeDate": "2021-06-15T00:00:00.000Z",
  //                 "createdDate": "2021-06-15T11:43:48.646Z",
  //                 "modifiedDate": "2021-06-15T11:43:48.646Z",
  //                 "__v": 0
  //               },
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-22T06:31:29.553Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 1,
  //               "bookingDate": "2021-06-23T07:30:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-22T05:42:31.663Z",
  //               "modifiedDate": "2021-06-22T06:31:29.553Z",
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d17905f60bef344ed354db",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": {
  //                 "_id": "60cc43d47a54b747f94bee1c",
  //                 "restaurant": "5e8342f0426bd609283151b6",
  //                 "user": "5ee376450198393ee8cea9d9",
  //                 "title": null,
  //                 "description": null,
  //                 "startDate": "2021-06-18T00:00:00.000Z",
  //                 "endDate": null,
  //                 "appliedOn": {
  //                   "day": 3,
  //                   "time": [
  //                     8,
  //                     10
  //                   ],
  //                   "repeat": false
  //                 },
  //                 "menus": [],
  //                 "offerCost": 0,
  //                 "menuCost": null,
  //                 "labels": [],
  //                 "discount": {
  //                   "type": "percent",
  //                   "value": 40,
  //                   "conjunction": false
  //                 },
  //                 "discountType": "percent",
  //                 "offerCategory": "weeklyOffer",
  //                 "status": "active",
  //                 "inspected": false,
  //                 "createDate": "2021-06-18T06:57:24.856Z",
  //                 "modifiedDate": "2021-06-18T06:57:24.856Z",
  //                 "inspectionHistory": [],
  //                 "__v": 0
  //               },
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": {
  //                 "expiryDate": "2021-06-22T00:00:00.000Z",
  //                 "redeemDate": null,
  //                 "status": "active",
  //                 "_id": "60c892748639f7077f6c3a62",
  //                 "chest": {
  //                   "url": "/dashboard/my-offers",
  //                   "status": "active",
  //                   "user": "60ab6f202e6daa412ccc6758",
  //                   "chest": "5ec23f0cf051ab306fbdf334",
  //                   "title": "Unlock any offer for free within 7 days",
  //                   "expiry": 7,
  //                   "valuex": 0,
  //                   "tracked": false,
  //                   "frequency": 5,
  //                   "currentFrequency": 2,
  //                   "weightage": 1,
  //                   "activeDate": "2021-06-15",
  //                   "__v": 0
  //                 },
  //                 "chestCoin": 1,
  //                 "user": "60ab6f202e6daa412ccc6758",
  //                 "activeDate": "2021-06-15T00:00:00.000Z",
  //                 "createdDate": "2021-06-15T11:43:48.646Z",
  //                 "modifiedDate": "2021-06-15T11:43:48.646Z",
  //                 "__v": 0
  //               },
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-22T06:31:40.289Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 2,
  //               "bookingDate": "2021-06-23T08:45:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-22T05:45:41.106Z",
  //               "modifiedDate": "2021-06-22T06:31:40.289Z",
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60d5ba5eba92690eb9bbdd4c",
  //               "lastName": "wjdbh",
  //               "specialRequest": "Nothing",
  //               "bookedBy": "user",
  //               "platform": "web",
  //               "bookingStage": "confirmed",
  //               "restaurantExplanation": null,
  //               "reasonForCancellation": null,
  //               "weeklyOffer": null,
  //               "exceptionOffer": null,
  //               "otherOffer": null,
  //               "defaultBonus": 50,
  //               "appliedChest": null,
  //               "ad": null,
  //               "status": "active",
  //               "actionLog": [
  //                 {
  //                   "user": "5dd8f3dbb2c69f43c4f23dc2",
  //                   "addedDate": "2021-06-25T11:16:20.506Z",
  //                   "action": "confirmed"
  //                 }
  //               ],
  //               "user": "60ab6f202e6daa412ccc6758",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "people": 1,
  //               "bookingDate": "2021-06-30T12:00:00.000Z",
  //               "phone": "447845656422",
  //               "email": "disevi6354@sc2hub.com",
  //               "firstName": "For pass",
  //               "createDate": "2021-06-25T11:13:34.750Z",
  //               "modifiedDate": "2021-06-25T11:16:20.506Z",
  //               "__v": 0
  //             }
  //           ],
  //           "totalBookings": 3,
  //           "offerData": [
  //             {
  //               "_id": "5f699bc8bac4f640fb4a7a1c",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "title": "Test",
  //               "description": "test desc",
  //               "startDate": null,
  //               "endDate": null,
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "AM",
  //                   "PM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [],
  //                 "5": [],
  //                 "6": []
  //               },
  //               "menus": [
  //                 "5e4d501afef48878c35c563e"
  //               ],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-09-22T06:38:00.552Z",
  //               "modifiedDate": "2020-09-22T06:38:39.307Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "5feccf8742c65151f82d847d",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2020-12-30T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   7,
  //                   8
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 350
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2020-12-30T19:05:43.454Z",
  //               "modifiedDate": "2020-12-30T19:05:43.454Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "603d6c313e7f3f25a6fce12a",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-03-01T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   20,
  //                   21
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": 450,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-03-01T22:35:29.570Z",
  //               "modifiedDate": "2021-03-01T22:35:29.570Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60799653538804430d8c0792",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": "Summer Offer",
  //               "description": "Enjoy summer with delicious food.",
  //               "startDate": null,
  //               "endDate": "2021-10-30T00:00:00.000Z",
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "PM"
  //                 ],
  //                 "2": [
  //                   "PM"
  //                 ],
  //                 "3": [
  //                   "PM"
  //                 ],
  //                 "4": [
  //                   "PM"
  //                 ],
  //                 "5": [],
  //                 "6": []
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-04-16T13:51:15.952Z",
  //               "modifiedDate": "2021-04-16T13:51:15.953Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60799688538804430d8c0796",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": "Spring Offer",
  //               "description": "Get the spring tasty.",
  //               "startDate": null,
  //               "endDate": "2021-12-30T00:00:00.000Z",
  //               "appliedOn": {
  //                 "0": [],
  //                 "1": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "2": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "3": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "4": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "5": [
  //                   "PM",
  //                   "AM"
  //                 ],
  //                 "6": [
  //                   "PM",
  //                   "AM"
  //                 ]
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": null,
  //               "discountType": "percent",
  //               "offerCategory": "otherOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-04-16T13:52:08.267Z",
  //               "modifiedDate": "2021-04-16T13:52:08.267Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60cae8b26e98a01bccd6898d",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-17T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   15,
  //                   16
  //                 ],
  //                 "repeat": false
  //               },
  //               "menus": [],
  //               "offerCost": 0,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 50,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-17T06:16:18.109Z",
  //               "modifiedDate": "2021-06-17T06:16:18.111Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60caef336e98a01bccd6899b",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-17T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 0,
  //                 "time": [
  //                   16,
  //                   17
  //                 ],
  //                 "repeat": false
  //               },
  //               "menus": [],
  //               "offerCost": 50,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-17T06:44:03.820Z",
  //               "modifiedDate": "2021-06-17T06:44:03.821Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60cc2e397a54b747f94bed66",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-18T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 1,
  //                 "time": [
  //                   10,
  //                   11
  //                 ],
  //                 "repeat": true
  //               },
  //               "menus": [],
  //               "offerCost": null,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "rewards",
  //                 "value": 250,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-18T05:25:13.864Z",
  //               "modifiedDate": "2021-06-18T05:25:13.864Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "_id": "60cc43d47a54b747f94bee1c",
  //               "restaurant": "5e8342f0426bd609283151b6",
  //               "user": "5ee376450198393ee8cea9d9",
  //               "title": null,
  //               "description": null,
  //               "startDate": "2021-06-18T00:00:00.000Z",
  //               "endDate": null,
  //               "appliedOn": {
  //                 "day": 3,
  //                 "time": [
  //                   8,
  //                   10
  //                 ],
  //                 "repeat": false
  //               },
  //               "menus": [],
  //               "offerCost": 0,
  //               "menuCost": null,
  //               "labels": [],
  //               "discount": {
  //                 "type": "percent",
  //                 "value": 40,
  //                 "conjunction": false
  //               },
  //               "discountType": "percent",
  //               "offerCategory": "weeklyOffer",
  //               "status": "active",
  //               "inspected": false,
  //               "createDate": "2021-06-18T06:57:24.856Z",
  //               "modifiedDate": "2021-06-18T06:57:24.856Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "offerCount": 19,
  //           "photoList": [
  //             {
  //               "category": "gallery",
  //               "viewOrder": 1,
  //               "_id": "5f05ac4ecb4b325fccd6c33f",
  //               "fileName": "file-1594207309618.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "casey-lee-awj7sRviVXo-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207309618.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2020-07-08T11:21:50.478Z",
  //               "modifiedDate": "2020-07-08T11:21:50.478Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 2,
  //               "_id": "5f05ac60cb4b325fccd6c340",
  //               "fileName": "file-1594207327745.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "alex-munsell-Yr4n8O_3UPc-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207327745.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "steak master",
  //               "createDate": "2020-07-08T11:22:08.752Z",
  //               "modifiedDate": "2020-07-08T11:22:08.752Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 3,
  //               "_id": "607d3b3e538804430d8c094e",
  //               "fileName": "file-1618819902235.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "chicken-biryani-recipe.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1618819902235.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2021-04-19T08:11:42.406Z",
  //               "modifiedDate": "2021-04-19T08:11:42.406Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 4,
  //               "_id": "5f05ad06cb4b325fccd6c348",
  //               "fileName": "file-1594207493190.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "taylor-kiser-EvoIiaIVRzU-unsplash.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1594207493190.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "restaurant",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2020-07-08T11:24:54.691Z",
  //               "modifiedDate": "2020-07-08T11:24:54.691Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             },
  //             {
  //               "category": "gallery",
  //               "viewOrder": 5,
  //               "_id": "607d7d50538804430d8c0b19",
  //               "fileName": "file-1618836815837.jpg",
  //               "s3Directory": "restaurants",
  //               "status": "active",
  //               "fileOriginalName": "re-1.jpg",
  //               "type": "image/jpeg",
  //               "path": "/restaurants//file-1618836815837.jpg",
  //               "sightengineApproved": true,
  //               "mediaType": "userSuggestion",
  //               "inspected": true,
  //               "description": "",
  //               "createDate": "2021-04-19T12:53:36.123Z",
  //               "modifiedDate": "2021-04-19T12:53:36.123Z",
  //               "inspectionHistory": [],
  //               "__v": 0
  //             }
  //           ],
  //           "rating": 0,
  //           "totalReviews": 34
  //         }
  // ],

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OFFER_START: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case ADD_OFFER_SUCCESS: {
      return { ...state, error: null, isLoading: true };
    }
    case ADD_OFFER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: null,
        payload: action.payload,
      };
    }
    case UPDATE_OFFER_START: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case UPDATE_OFFER_SUCCESS: {
      return { ...state, error: null, isLoading: true };
    }
    case UPDATE_OFFER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: null,
        payload: action.payload,
      };
    }
    case DELETE_OFFER_START: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case DELETE_OFFER_SUCCESS: {
      return { ...state, error: null, isLoading: true };
    }
    case DELETE_OFFER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: null,
        payload: action.payload,
      };
    }
    case FETCH_USER_CHEST_OFFERS_START: {
      return { ...state, chestIsLoading: false, chestError: action.payload };
    }
    case FETCH_USER_CHEST_OFFERS_SUCCESS: {
      return {
        ...state,
        chestError: null,
        chestIsLoading: true,
        chestPayload: action.payload,
      };
    }
    case FETCH_USER_CHEST_OFFERS_FAILED: {
      return { ...state, chestIsLoading: false, chestError: null };
    }
    case FETCH_DAILY_CHEST_OFFERS_START: {
      return { ...state, isLoading: false, dailyChestError: action.payload };
    }
    case FETCH_DAILY_CHEST_OFFERS_SUCCESS: {
      return {
        ...state,
        dailyChestError: null,
        isLoading: true,
        dailyChestPayload: action.payload,
      };
    }
    case FETCH_DAILY_CHEST_OFFERS_FAILED: {
      return { ...state, chestIsLoading: false, dailyChestError: null };
    }
    case ADD_CHEST_OFFER_START: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case ADD_CHEST_OFFER_SUCCESS: {
      return { ...state, error: null, isLoading: true };
    }
    case ADD_CHEST_OFFER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: null,
        payload: action.payload,
      };
    }
    case FETCH_OFFER_START: {
      return { ...state, isOfferLoading: false, offerError: action.payload };
    }
    case FETCH_OFFER_SUCCESS: {
      return {
        ...state,
        offerError: null,
        isOfferLoading: true,
        offerPayload: action.payload,
      };
    }
    case FETCH_OFFER_FAILED: {
      return {
        ...state,
        isOfferLoading: false,
        offerError: null,
        offerPayload: null,
      };
    }
    case FETCH_OFFER_USER_MAY_LIKE_START: {
      return { ...state, isLoading: false };
    }
    case FETCH_OFFER_USER_MAY_LIKE_SUCCESS: {
      return { ...state,
         offersUserMayLike: action.payload 
        };
    }
    case FETCH_OFFER_USER_MAY_LIKE_FAILED: {
      return { ...state, isLoading: false, error: null };
    }
    default:
      return state;
  }
};

export default reducer;
