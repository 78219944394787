import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const tagService = {
  getTags,
  getTagsCategory
};

function getTags() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.TAGS}?pageSize=all&currentPage=1&orderBy=fullName&search`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getTagsCategory() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.TAGS}/categories?pageSize=10&currentPage=1&orderBy=fullName&search=`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
