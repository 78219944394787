import { CREATE_AD, CREATE_AD_FAILURE, CREATE_AD_SUCCESS, FETCH_ADS, FETCH_ADS_FAILURE, FETCH_ADS_SUCCESS ,GET_EST_PRICE,GET_EST_PRICE_SUCCESS,GET_EST_FAILURE} from "../../constants/actionTypes"

const initialSettings = {
  error: null,
  isLoading: false,
  liveAds: null,
  draftAds: null,
  completedAds: null,
  liveAdsLoading: false,
  draftAdsLoading: false,
  completedAdsLoading: false,
  estimatedPrice:null
}

const reducer = (state = initialSettings, action) => {

  switch (action.type) {
    case CREATE_AD:
      return { ...state, error: null, isLoading: true };
    case CREATE_AD_SUCCESS:
      //const { data } = action.payload;
      return {
        ...state,
        error: null,
        isLoading: false
      };
    case CREATE_AD_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case FETCH_ADS:
      if (action.bookingType === 'draft') {
        return { ...state, error: null, draftAdsLoading: true };
      } else if (action.bookingType === 'completed') {
        return { ...state, error: null, completedAdsLoading: true };
      } else {//if (action.status === 'live') {

        return { ...state, error: null, liveAdsLoading: true };
      }
    case FETCH_ADS_SUCCESS:
      const { data } = action.payload
      if (action.status === 'draft') {
        return {
          ...state,
          error: null,
          draftAds: data,
          draftAdsLoading: false,
          completedAdsLoading: false,
          liveAdsLoading: false
        };
      } else if (action.status === 'completed') {
        return {
          ...state,
          error: null,
          completedAds: data,
          completedAdsLoading: false
        };
      }
      else { //if (action.status === 'live') {
        return {
          ...state,
          error: null,
          liveAds: data,
          draftAdsLoading: false,
          completedAdsLoading: false,
          liveAdsLoading: false
        };
      }
    case FETCH_ADS_FAILURE:
      return {
        ...state
        , payload: null,
        isLoading: false,
        error: action.payload, draftAdsLoading: false,
        completedAdsLoading: false,
        liveAdsLoading: false

      };
    case GET_EST_PRICE_SUCCESS:

    return {
      ...state,
      estimatedPrice:action.payload.data
    }
    default:
      return state;
  }
}
export default reducer