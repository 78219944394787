import { cloneDeep } from 'lodash';

import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  REGISTRATION_START,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILED,
  CLEAR_REGISTRATION_SUCCESS,
  FORGOT_PASSWORD_OTP_START,
  FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_OTP_FAILED,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_OTP_CLEAN,
  UPDATE_USER_DATA,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  UPDATE_USER_COINS
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: null,
  logoutLoading: false,
  registrationLoading: false,
  registrationPayload: null,
  forgetPasswordOtpLoading: false,
  forgetPasswordOtpPayload: null,
  forgetPasswordLoading: false,
  forgetPasswordPayload: null,
  profileFetching: false,
  changePasswordLoading: false,
  changePasswordPayload: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START: {
      return { ...state, error: null, isLoading: true };
    }
    case LOGIN_SUCCESS: {
      return { ...state, error: null, payload: action.payload, isLoading: false };
    }
    case LOGIN_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    case LOGOUT_START: {
      return { ...state, error: null, logoutLoading: true };
    }
    case LOGOUT_SUCCESS: {
      return { ...state, error: null, payload: null, logoutLoading: false };
    }
    case LOGOUT_FAILED: {
      return { ...state, payload: null, logoutLoading: false, error: action.payload };
    }
    case REGISTRATION_START: {
      return { ...state, error: null, registrationLoading: true };
    }
    case REGISTRATION_SUCCESS: {
      return { ...state, error: null, registrationPayload: action.payload, registrationLoading: false };
    }
    case CLEAR_REGISTRATION_SUCCESS: {
      return { ...state, registrationPayload: null };
    }
    case REGISTRATION_FAILED: {
      return { ...state, registrationPayload: null, registrationLoading: false, error: action.payload };
    }
    case FORGOT_PASSWORD_OTP_START: {
      return { ...state, error: null, forgetPasswordOtpLoading: true };
    }
    case FORGOT_PASSWORD_OTP_SUCCESS: {
      return { ...state, error: null, forgetPasswordOtpPayload: action.payload, forgetPasswordOtpLoading: false };
    }
    case FORGOT_PASSWORD_OTP_FAILED: {
      return { ...state, forgetPasswordOtpPayload: null, forgetPasswordOtpLoading: false, error: action.payload };
    }
    case FORGOT_PASSWORD_START: {
      return { ...state, error: null, forgetPasswordLoading: true };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        forgetPasswordOtpPayload: null,
        forgetPasswordPayload: action.payload,
        forgetPasswordLoading: false
      };
    }
    case FORGOT_PASSWORD_FAILED: {
      return {
        ...state,
        forgetPasswordPayload: null,
        forgetPasswordLoading: false,
        error: action.payload
      };
    }
    case FORGOT_PASSWORD_OTP_CLEAN: {
      return { forgetPasswordPayload: null }
    }
    case UPDATE_USER_DATA: {
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload
        }
      }
    }
    case FETCH_PROFILE: {
      return { ...state, error: null, profileFetching: true };
    }
    case FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        error: null,
        payload: {
          ...state.payload,
          ...action.payload
        },
        profileFetching: false
      };
    }
    case FETCH_PROFILE_FAILURE: {
      return {
        ...state,
        profileFetching: false,
        error: action.payload
      };
    }
    case CHANGE_PASSWORD_START: {
      return { ...state, error: null, changePasswordLoading: true };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        changePasswordPayload: action.payload,
        changePasswordLoading: false
      };
    }
    case CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        changePasswordPayload: null,
        changePasswordLoading: false,
        error: action.payload
      };
    }
    case UPDATE_USER_COINS: {
      return {
        ...state,
        payload: { ...cloneDeep(state.payload), coins: action.payload }
      }
    }
    default:
      return state
  }
};

export default reducer;
