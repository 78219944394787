import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const suggestionService = {
  postSuggestion,
  getPendingSuggestions
};

function postSuggestion(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return secureFetch(`${apiURL.USER_SUGGESTIONS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getPendingSuggestions() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.USER_PENDING_SUGGESTIONS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
