import { toast } from "react-toastify";

import {
  ADD_OFFER_START,
  ADD_OFFER_SUCCESS,
  ADD_OFFER_FAILED,
  UPDATE_OFFER_START,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILED,
  DELETE_OFFER_START,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAILED,
  FETCH_USER_CHEST_OFFERS_START,
  FETCH_USER_CHEST_OFFERS_SUCCESS,
  FETCH_USER_CHEST_OFFERS_FAILED,
  FETCH_DAILY_CHEST_OFFERS_START,
  FETCH_DAILY_CHEST_OFFERS_SUCCESS,
  FETCH_DAILY_CHEST_OFFERS_FAILED,
  ADD_CHEST_OFFER_START,
  ADD_CHEST_OFFER_SUCCESS,
  ADD_CHEST_OFFER_FAILED,
  FETCH_OFFER_START,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFER_FAILED,
  FETCH_OFFER_USER_MAY_LIKE_START,
  FETCH_OFFER_USER_MAY_LIKE_SUCCESS,
  FETCH_OFFER_USER_MAY_LIKE_FAILED,
} from "../../constants/actionTypes";

import { offersService } from "../../service/offers-service";

export const addOffer = (details, category) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(addOfferStart());
    offersService
      .addOffer(details)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          toast.success(`${category} offer added successfully!`);
          dispatch(addOfferSuccess(response.data));
        } else {
          dispatch(addOfferFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(addOfferFailed(failureMessage));
      });
  };
};

export const updateOffer = (offerId, details, category) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(updateOfferStart());
    offersService
      .updateOffer(offerId, details)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          toast.success(`${category} offer updated successfully!`);
          dispatch(updateOfferSuccess(response.data));
        } else {
          dispatch(updateOfferFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(updateOfferFailed(failureMessage));
      });
  };
};

export const deleteOffer = (offerId, category) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(deleteOfferStart());
    offersService
      .deleteOffer(offerId)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          toast.success(`${category} offer deleted successfully!`);
          dispatch(deleteOfferSuccess(response.data));
        } else {
          dispatch(deleteOfferFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(deleteOfferFailed(failureMessage));
      });
  };
};

export const getUserChestOffer = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(getUserChestOfferStart());
    offersService
      .getChestOffer()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(getUserChestOfferSuccess(response.data));
        } else {
          dispatch(getUserChestOfferFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(getUserChestOfferSuccess(failureMessage));
      });
  };
};

export const getDailyChestOffer = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(getDailyChestOfferStart());
    offersService
      .getDailyChestOffer()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(getDailyChestOfferSuccess(response.data));
        } else {
          dispatch(getDailyChestOfferFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(getDailyChestOfferSuccess(failureMessage));
      });
  };
};

export const getOffersUserMayLike = () => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(getOfferUserMayLikeStart());
    offersService
      .getOfferUserMayLike()
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        //console.log("offers may like ==", response)

        if (response.status) {
          dispatch(getOfferUserMayLikeSuccess(response.data));
        } else {
          dispatch(getOfferUserMayLikeFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(getOfferUserMayLikeSuccess(failureMessage));
      });
  };
};

export const addDailyChestOffer = (chest) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(addDailyChestOfferStart());
    offersService
      .addDailyChestOffer(chest)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(addDailyChestOfferSuccess(response.data));
        } else {
          dispatch(addDailyChestOfferFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(addDailyChestOfferSuccess(failureMessage));
      });
  };
};

export const fetchOffer = (id) => {
  const failureMessage = "Something wrong happened";
  return (dispatch) => {
    dispatch(getOfferStart());
    offersService
      .getOffer(id)
      .then((rawData) => {
        return rawData.json();
      })
      .then((response) => {
        if (response.status) {
          dispatch(getOfferSuccess(response.data));
        } else {
          dispatch(getOfferFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(getOfferFailed(failureMessage));
      });
  };
};

const addOfferStart = () => {
  return {
    type: ADD_OFFER_START,
  };
};

const addOfferSuccess = (data) => {
  return {
    type: ADD_OFFER_SUCCESS,
    payload: data,
  };
};

const addOfferFailed = (data) => {
  return {
    type: ADD_OFFER_FAILED,
    payload: data,
  };
};

const updateOfferStart = () => {
  return {
    type: UPDATE_OFFER_START,
  };
};

const updateOfferSuccess = (data) => {
  return {
    type: UPDATE_OFFER_SUCCESS,
    payload: data,
  };
};

const updateOfferFailed = (data) => {
  return {
    type: UPDATE_OFFER_FAILED,
    payload: data,
  };
};

const deleteOfferStart = () => {
  return {
    type: DELETE_OFFER_START,
  };
};

const deleteOfferSuccess = (data) => {
  return {
    type: DELETE_OFFER_SUCCESS,
    payload: data,
  };
};

const deleteOfferFailed = (data) => {
  return {
    type: DELETE_OFFER_FAILED,
    payload: data,
  };
};

const getUserChestOfferStart = () => {
  return {
    type: FETCH_USER_CHEST_OFFERS_START,
  };
};

const getUserChestOfferSuccess = (data) => {
  return {
    type: FETCH_USER_CHEST_OFFERS_SUCCESS,
    payload: data,
  };
};

const getUserChestOfferFailed = (data) => {
  return {
    type: FETCH_USER_CHEST_OFFERS_FAILED,
    payload: data,
  };
};

const getOfferUserMayLikeStart = (data) => {
  return {
    type: FETCH_OFFER_USER_MAY_LIKE_START,
    payload: data,
  };
};

const getOfferUserMayLikeSuccess = (data) => {
  return {
    type: FETCH_OFFER_USER_MAY_LIKE_SUCCESS,
    payload: data,
  };
};

const getOfferUserMayLikeFailed = (data) => {
  return {
    type: FETCH_OFFER_USER_MAY_LIKE_FAILED,
    payload: data,
  };
};

const getDailyChestOfferStart = () => {
  return {
    type: FETCH_DAILY_CHEST_OFFERS_START,
  };
};

const getDailyChestOfferSuccess = (data) => {
  return {
    type: FETCH_DAILY_CHEST_OFFERS_SUCCESS,
    payload: data,
  };
};

const getDailyChestOfferFailed = (data) => {
  return {
    type: FETCH_DAILY_CHEST_OFFERS_FAILED,
    payload: data,
  };
};

const addDailyChestOfferStart = () => {
  return {
    type: ADD_CHEST_OFFER_START,
  };
};

const addDailyChestOfferSuccess = (data) => {
  return {
    type: ADD_CHEST_OFFER_SUCCESS,
    payload: data,
  };
};

const addDailyChestOfferFailed = (data) => {
  return {
    type: ADD_CHEST_OFFER_FAILED,
    payload: data,
  };
};

const getOfferStart = () => {
  return {
    type: FETCH_OFFER_START,
  };
};

const getOfferSuccess = (data) => {
  return {
    type: FETCH_OFFER_SUCCESS,
    payload: data,
  };
};

const getOfferFailed = (data) => {
  return {
    type: FETCH_OFFER_FAILED,
    payload: data,
  };
};
