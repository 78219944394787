import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//stylesheets
//import './Footer.css';

//assets and images
import tick1 from "../../assets/images/tick1.png";
import retinatick1 from "../../assets/images/tick1@2x.png";
import tick2 from "../../assets/images/tick2.png";
import retinatick2 from "../../assets/images/tick2@2x.png";
import tick3 from "../../assets/images/tick3.png";
import retinatick3 from "../../assets/images/tick3@2x.png";
import tick4 from "../../assets/images/tick4.png";
import retinatick4 from "../../assets/images/tick4@2x.png";
import tick5 from "../../assets/images/tick5.png";
import retinatick5 from "../../assets/images/tick5@2x.png";

//constants
import {
  BENEFITS,
  CONTACT_US,
  LIST_A_RESTAURANT,
  PRIVACY_POLICY,
  SITEMAP,
  TERMS_CONDITIONS,
  MY_PROFILE,
} from "../../constants/routes";
import { saveInteractionLog } from "../../store/actions";
import { createLoggerData } from "../../utils/loggerData";
import { sectionName, logActions } from "../../constants/loggerConstants";
import Breadcrumbs from "../Breadcrumbs";

const { SECTION_FOOTER } = sectionName;
const { LOG_BENEFITS } = logActions;
const Footer = ({ footerVisible, location, saveInteractionLog, ...props }) => {
  const path = location.pathname.split("/");

  const handleBenefits = () => {
    saveInteractionLog(
      createLoggerData(SECTION_FOOTER, LOG_BENEFITS, location.pathname)
    );
  };
  return (
    <footer
      style={{ display: !footerVisible ? "none" : null }}
      className="v1_home_footer_main"
    >
      <div className="topfooter">
        <div className="wrapper mx-20">
          <ul className="d-flex-new">
            <li>
              <img src={tick1} srcset={`${retinatick1} 2x`} alt="footer" />
              3,000+ restaurants
            </li>
            <li>
              <img src={tick2} srcset={`${retinatick2} 2x`} alt="footer" />
              100,000+ members
            </li>
            <li>
              <img src={tick3} srcset={`${retinatick3} 2x`} alt="footer" />
              Hundreds of special offers
            </li>
            <li>
              <img src={tick4} srcset={`${retinatick4} 2x`} alt="footer" />
              Reviews from 10+ platforms
            </li>
            <li>
              <img src={tick5} srcset={`${retinatick5} 2x`} alt="footer" />
              Earn reward coins!
            </li>
          </ul>
        </div>
      </div>
      <div className="bootomfooter mb-70-fortablet">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <a href="/">
                <img
                  src="/images/homepage/tablefy_footer_logo_primary.png"
                  srcset="/images/homepage/tablefy_footer_logo_primary@2x.png 2x"
                  alt="logo"
                  className="img-responsive"
                />
              </a>
            </div>
            <div className="col-md-9">
              <div className="fbreadcum">
                {!path.includes("home") && !path.includes("error") && (
                  <Breadcrumbs
                    path={location}
                    restaurant={props.restaurantDetails}
                  />
                )}
              </div>
              <div className="footermenu">
                <ul>
                  {props.authDetails && (
                    <li>
                      <Link
                        to={{
                          pathname: MY_PROFILE,
                          state: {
                            fromSection: SECTION_FOOTER,
                            fromPath: location.pathname,
                          },
                        }}
                      >
                        My account
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to={{
                        pathname: BENEFITS,
                        // state: {
                        //   fromSection: SECTION_FOOTER,
                        //   fromPath: location.pathname,
                        // },
                      }}
                      onClick={handleBenefits}
                    >
                      Benefits
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: LIST_A_RESTAURANT,
                        state: {
                          fromSection: SECTION_FOOTER,
                          fromPath: location.pathname,
                        },
                      }}
                    >
                      List a restaurant
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: CONTACT_US,
                        state: {
                          fromSection: SECTION_FOOTER,
                          fromPath: location.pathname,
                        },
                      }}
                    >
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="copyrestmenu">
                <div className="copyrighttext">©2021 Tablefy Inc.</div>
                <div className="restmenu">
                  <ul>
                    <li>
                      <Link
                        to={{
                          pathname: SITEMAP,
                          state: {
                            fromSection: SECTION_FOOTER,
                            fromPath: location.pathname,
                          },
                        }}
                      >
                        Sitemap
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: PRIVACY_POLICY,
                          state: {
                            fromSection: SECTION_FOOTER,
                            fromPath: location.pathname,
                          },
                        }}
                      >
                        Privacy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: TERMS_CONDITIONS,
                          state: {
                            fromSection: SECTION_FOOTER,
                            fromPath: location.pathname,
                          },
                        }}
                      >
                        T&amp;Cs
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = ({ settings, restaurant, auth }) => {
  return {
    footerVisible: settings.footerShown,
    restaurantDetails: restaurant.detailsPayload,
    authDetails: auth.payload,
  };
};

export default withRouter(
  connect(mapStateToProps, { saveInteractionLog })(Footer)
);
