export const storeRecentLocations = (data, type) => {
  // console.log("storinf serch ==", data)
  const recentLocations = JSON.parse(localStorage.getItem('recentLocations')) || [];
  const newData = {
    _id: data.locationName || data.title,
    areaName: data.areaName || '',
    subAreaName: data.subAreaName || '',
    guide: data.guide || '',
    type: type,
    place: {
      name: data.locationName || data.title,
      placeId: data.placeId,
      location: {
        coordinates: (data.location) ? [data.location.longitude, data.location.latitude] : [0,0]
      }
    }
  };
  const foundIndex = recentLocations.findIndex(x => x._id === (data.locationName || data.title));
  if (foundIndex !== -1) {
    recentLocations.splice(foundIndex, 1);
  }
  recentLocations.unshift(newData);
  if (recentLocations.length > 3) {
    recentLocations.pop()
  }
  // console.log("recent location after update =", recentLocations)
  localStorage.setItem('recentLocations', JSON.stringify(recentLocations));
};
