import {FETCH_TOP3_BANNER_RESTAURANTS,
    FETCH_TOP3_BANNER_RESTAURANTS_SUCCESS,
    FETCH_TOP3_BANNER_RESTAURANTS_FAILED,
    FETCH_TOP_BANNER_CUISINES,
    FETCH_TOP_BANNER_CUISINES_SUCCESS,
    FETCH_TOP_BANNER_CUISINES_FAILED,
    FETCH_INSPIRATION_TAGS,
    FETCH_INSPIRATION_TAGS_SUCCESS,
    FETCH_INSPIRATION_TAGS_FAILED
} from '../../constants/actionTypes'
import { bannerService } from '../../service/banner-service'

export const fetchTop3BannerRestaurants = () => {
    const failureMessage = 'Something wrong happened';
    return (dispatch, getState) => {
      const { filters } = getState().restaurant;
  
      const params = {
        location: filters.location
          ? `%7B%22lat%22:${filters.location.latitude},%22lng%22:${filters.location.longitude}%7D`
          : '',
      };
  
      dispatch(fetchTop3BannerRestaurantStart());
      bannerService.getTop3Restaurants(params)
        .then(rawData => {
          return rawData.json();
        })
        .then(response => {
          if (response.status) {
            dispatch(fetchTop3BannerRestaurantsSuccess(response.data));
          } else {
            dispatch(fetchTop3BannerRestaurantsFailed(failureMessage));
          }
        })
        .catch(() => {
          dispatch(fetchTop3BannerRestaurantsFailed(failureMessage));
        });
    }
  };

  const fetchTop3BannerRestaurantStart = () => {
    return {
      type: FETCH_TOP3_BANNER_RESTAURANTS
    }
  };

  const fetchTop3BannerRestaurantsSuccess = data => {
    return {
      type: FETCH_TOP3_BANNER_RESTAURANTS_SUCCESS,
      payload: data
    }
  };
  
  const fetchTop3BannerRestaurantsFailed = data => {
    return {
      type: FETCH_TOP3_BANNER_RESTAURANTS_FAILED,
      payload: data
    }
  };

  //cuisine banner

  export const fetchTopBannerCuisines = () => {
    const failureMessage = 'Something wrong happened';
    return (dispatch, getState) => {
      const { filters } = getState().restaurant;
  
      const params = {
        location: filters.location
          ? `%7B%22lat%22:${filters.location.latitude},%22lng%22:${filters.location.longitude}%7D`
          : '',
      };
  
      dispatch(fetchTopBannerCuisineStart());
      bannerService.getTopCuisines(params)
        .then(rawData => {
          return rawData.json();
        })
        .then(response => {
          if (response.status) {
            dispatch(fetchTopBannerCuisineSuccess(response.data));
          } else {
            dispatch(fetchTopBannerCuisineFailed(failureMessage));
          }
        })
        .catch(() => {
          dispatch(fetchTopBannerCuisineFailed(failureMessage));
        });
    }
  };

  const fetchTopBannerCuisineStart = () => {
    return {
      type: FETCH_TOP_BANNER_CUISINES
    }
  };

  const fetchTopBannerCuisineSuccess = data => {
    return {
      type: FETCH_TOP_BANNER_CUISINES_SUCCESS,
      payload: (data) ? data : {}
    }
  };
  
  const fetchTopBannerCuisineFailed = data => {
    return {
      type: FETCH_TOP_BANNER_CUISINES_FAILED,
      payload: data
    }
  };

  // inspiration banners

  export const fetchInspirationTags = () => {
    const failureMessage = 'Something wrong happened';
    return (dispatch, getState) => {
      const { filters } = getState().restaurant;
  
      const params = {
        location: filters.location
          ? `%7B%22lat%22:${filters.location.latitude},%22lng%22:${filters.location.longitude}%7D`
          : '',
      };
  
      dispatch(fetchInspirationTagsStart());
      bannerService.getInspirationTags(params)
        .then(rawData => {
          return rawData.json();
        })
        .then(response => {
          if (response.status) {
            dispatch(fetchInspirationTagsSuccess(response.data));
          } else {
            dispatch(fetchInspirationTagsFailed(failureMessage));
          }
        })
        .catch(() => {
          dispatch(fetchInspirationTagsFailed(failureMessage));
        });
    }
  };

  const fetchInspirationTagsStart = () => {
    return {
      type: FETCH_INSPIRATION_TAGS
    }
  };

  const fetchInspirationTagsSuccess = data => {
    return {
      type: FETCH_INSPIRATION_TAGS_SUCCESS,
      payload: data
    }
  };
  
  const fetchInspirationTagsFailed = data => {
    return {
      type: FETCH_INSPIRATION_TAGS_FAILED,
      payload: data
    }
  };