import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const feedService = {
  getFeeds,
  updateFeed,
  getNewFeedsCount
};

function getFeeds({ currentPage, pageSize }) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const newPageSize = pageSize || 6

  return secureFetch(`${apiURL.FEEDS}?pageSize=${newPageSize}&currentPage=${currentPage}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateFeed(feedId, status) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(status)
  };

  return secureFetch(`${apiURL.FEEDS}/${feedId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getNewFeedsCount() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.FEEDS}/count`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}
