import { SHOW_BUZZ_SCREEN, HIDE_BUZZ_SCREEN } from '../../constants/actionTypes';

const initialState = {
  screenVisible: '',
  additionalData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BUZZ_SCREEN: {
      return { ...state, screenVisible: action.payload, additionalData: action.additionalData || null }
    }
    case HIDE_BUZZ_SCREEN: {
      return { ...state, screenVisible: '', additionalData: null }
    }
    default:
      return state
  }
}

export default reducer;