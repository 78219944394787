import {
  FETCH_GUIDES_START,
  FETCH_GUIDES_SUCCESS,
  FETCH_GUIDES_FAILED,
  FETCH_GUIDE_DETAILS_START,
  FETCH_GUIDE_DETAILS_SUCCESS,
  FETCH_GUIDE_DETAILS_FAILED,
  FETCH_GUIDE_RESTAURANTS_START,
  FETCH_GUIDE_RESTAURANTS_SUCCESS,
  FETCH_GUIDE_RESTAURANTS_FAILED,
  FETCH_GUIDE_MAP_RESTAURANTS_START,
  FETCH_GUIDE_MAP_RESTAURANTS_SUCCESS,
  FETCH_GUIDE_MAP_RESTAURANTS_FAILED,
  GUIDE_PAGING_RESET,
  GUIDE_DETAILS_RESET,
  FETCH_GUIDE_RESTAURANTS_MAP_POINTER_START,
  FETCH_GUIDE_RESTAURANTS_MAP_POINTER_SUCCESS,
  FETCH_GUIDE_RESTAURANTS_MAP_POINTER_FAILED
} from '../../constants/actionTypes';
import { DESKTOP_SMALL_MAX, DESKTOP_SMALL_MIN } from "../../constants/displayTypes";
import { guideService } from "../../service/guide-service";
import { storeRecentLocations } from '../../utils/recentLocationsContainer';

export const fetchGuides = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchGuidesStart());
    guideService.getGuides()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchGuidesSuccess(response));
        } else {
          dispatch(fetchGuidesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchGuidesFailed(failureMessage));
      });
  }
};

// export const fetchGuideSearch = (search) => {
//   const failureMessage = 'Something wrong happened';
//   return dispatch => {
//     guideService.getGuidesSearch(search)
//     .then(rawData => {
//       return rawData.json()
//     })
//     .then(response => {
//       if(response.status){

//       }
//     })
//     .catch(() => {
//       console.log("error in getch guide search")
//     })
//   }
// }

export const fetchGuideRestaurants = ({ guide = '', areaName = '', subAreaName = '', saveRecent = false, title = "", mapPointer = ''}) => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const { currentPage, totalPage, controller } = getState().guide;
    const { filters } = getState().restaurant;
    const nextPage = parseInt(currentPage) + 1;
    if (nextPage !== 1 && (nextPage > totalPage)) return;
    let pageSize = (window.innerWidth >= DESKTOP_SMALL_MIN && window.innerWidth <= DESKTOP_SMALL_MAX) ? 21 : 22
    if (controller)
      controller.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
      cuisine: filters.cuisine || '',
      menu: filters.menu || '',
      menuType: filters.menuType || '',
      tag: filters.tag || '',
      orderBy: filters.orderBy || '',
      guide,
      areaName,
      subAreaName,
      saveRecent: saveRecent || '',
      title,
      pageSize,
      mapPointer
    };
    
    storeRecentLocations({title,areaName,guide,subAreaName}, 'guide');
    dispatch(fetchGuideRestaurantsStart(newController));
    const signal = newController.signal;
    guideService.getGuideRestaurants(params, signal)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchGuideRestaurantsSuccess(response));
        } else {
          dispatch(fetchGuideRestaurantsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchGuideRestaurantsFailed(failureMessage));
      });
  }
};

export const fetchGuideMapRestaurants = ({ guide = '', areaName = '', subAreaName = '', saveRecent = false, title = "" , mapPointer = ''}) => {
  const failureMessage = 'Something wrong happened';
  // if(!guide && !areaName)
  //   return
  return (dispatch, getState) => {
    const { currentPage, totalPage, controller } = getState().guide;
    const { filters } = getState().restaurant;
    const nextPage = parseInt(currentPage) + 1;
    if (nextPage !== 1 && (nextPage > totalPage)) return;
    let pageSize = (window.innerWidth >= DESKTOP_SMALL_MIN && window.innerWidth <= DESKTOP_SMALL_MAX) ? 21 : 22
    if (controller)
      controller.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
      cuisine: filters.cuisine || '',
      menu: filters.menu || '',
      menuType: filters.menuType || '',
      tag: filters.tag || '',
      orderBy: filters.orderBy || '',
      guide,
      areaName,
      subAreaName,
      saveRecent: '',
      title,
      pageSize,
      mapPointer
    };
    
    dispatch(fetchGuideMapRestaurantsStart(newController));
     //const signal = newController.signal;
    guideService.getGuideRestaurants(params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchGuideMapRestaurantsSuccess(response));
        } else {
          
          dispatch(fetchGuideMapRestaurantsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchGuideMapRestaurantsFailed(failureMessage));
      });
  }
};

export const fetchGuideDetails = (slug, parent = '') => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchGuideDetailsStart());
    guideService.getGuideDetails({ slug, parent })
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchGuideDetailsSuccess(response));
        } else {
          dispatch(fetchGuideDetailsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchGuideDetailsFailed(failureMessage));
      });
  }
};

export const fetchMapPointer = (location) => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const { filters } = getState().restaurant;

    const params = {
      cuisine: filters.cuisine || '',
      location: filters.location
        ? `%7B%22lat%22:${filters.location.latitude},%22lng%22:${filters.location.longitude}%7D`
        : '',
      menu: filters.menu || '',
      menuType: filters.menuType || '',
      tag: filters.tag || '',
      orderBy: filters.orderBy || '',
      mapRadius: filters.mapRadius || '',
    };
    dispatch(fetchMapPointerStart());
    guideService.getMapPointer(params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchMapPointerSuccess(response));
        } else {
          dispatch(fetchMapPointerFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchMapPointerFailed(failureMessage));
      });
  }

}

const fetchGuideRestaurantsStart = controller => {
  return {
    type: FETCH_GUIDE_RESTAURANTS_START,
    controller
  }
};

const fetchGuideRestaurantsSuccess = data => {
  return {
    type: FETCH_GUIDE_RESTAURANTS_SUCCESS,
    payload: data
  }
};

const fetchGuideRestaurantsFailed = data => {
  return {
    type: FETCH_GUIDE_RESTAURANTS_FAILED,
    payload: data
  }
};

const fetchGuideMapRestaurantsStart = controller => {
  return {
    type: FETCH_GUIDE_MAP_RESTAURANTS_START,
    controller
  }
};

const fetchGuideMapRestaurantsSuccess = data => {
  return {
    type: FETCH_GUIDE_MAP_RESTAURANTS_SUCCESS,
    payload: data
  }
};

const fetchGuideMapRestaurantsFailed = data => {
  return {
    type: FETCH_GUIDE_MAP_RESTAURANTS_FAILED,
    payload: data
  }
};

const fetchGuidesStart = () => {
  return {
    type: FETCH_GUIDES_START
  }
};

const fetchGuidesSuccess = data => {
  return {
    type: FETCH_GUIDES_SUCCESS,
    payload: data
  }
};

const fetchGuidesFailed = data => {
  return {
    type: FETCH_GUIDES_FAILED,
    payload: data
  }
};

const fetchGuideDetailsStart = () => {
  return {
    type: FETCH_GUIDE_DETAILS_START
  }
};

const fetchGuideDetailsSuccess = data => {
  return {
    type: FETCH_GUIDE_DETAILS_SUCCESS,
    payload: data
  }
};

const fetchGuideDetailsFailed = data => {
  return {
    type: FETCH_GUIDE_DETAILS_FAILED,
    payload: data
  }
};

export const resetGuidePagination = () => {
  return {
    type: GUIDE_PAGING_RESET
  }
}

export const resetGuideDetails = () => {
  return {
    type: GUIDE_DETAILS_RESET
  }
}

const fetchMapPointerStart = () => {
  return {
    type: FETCH_GUIDE_RESTAURANTS_MAP_POINTER_START
  }
};

const fetchMapPointerSuccess = data => {
  return {
    type: FETCH_GUIDE_RESTAURANTS_MAP_POINTER_SUCCESS,
    payload: data
  }
};

const fetchMapPointerFailed = data => {
  return {
    type: FETCH_GUIDE_RESTAURANTS_MAP_POINTER_FAILED,
    payload: data
  }
};