import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../containers/PrivateRoute";

import asyncComponent from "../utils/asyncComponent";
import Dashboard from "./Dashboard";
import RestaurantAdmin from "./ResturantAdmin";
import TableBooking from "./TableBooking";
import { ADMIN } from "../constants/userRoles";
import Error404 from "../containers/Error404";

const App = ({ match }) => {
  return (
    <div>
      <Switch>
        <Route path={`${match.url}mobile-sign-in`} component={asyncComponent(() => import('./SignInMobile/index'))} />
        <Route path={`${match.url}register`} component={asyncComponent(() => import('./Register/index'))} />
        <Route path={`${match.url}mobile-register`} component={asyncComponent(() => import('./MobileRegister/index'))} />
        <Route path={`${match.url}restaurant-details/:id`} exact component={asyncComponent(() => import('./Details/index'))} />
        <PrivateRoute path={`${match.url}restaurant-edit-details/:id`} exact component={asyncComponent(() => import('./RestaurantDetailsEdit/index'))} />
        <PrivateRoute path={`${match.url}user-restaurant-suggestion/:id`} exact component={asyncComponent(() => import('./RestaurantDetailsEditUser/index'))} />
        <PrivateRoute path={`${match.url}restaurant-admin-edit/:id`} exact component={asyncComponent(() => import('./RestaurantAdminEdit/index'))} />
        <Route path={`${match.url}restaurant-menu/:id`} exact component={asyncComponent(() => import('./RestaurantMenu/index'))} />
        <PrivateRoute role={[ADMIN]} path={`${match.url}restaurant-menu-edit/:id`} exact component={asyncComponent(() => import('./RestaurantMenuEdit/index'))} />
        <Route path={`${match.url}guest-search-result`} component={asyncComponent(() => import('./GuestSearchResult/index'))} />
        <Route path={`${match.url}guides/:guidetype/:location`} exact component={asyncComponent(() => import('./Guides/index'))} />
        <Route path={`${match.url}guides/:guidetype/:location/:sublocation`} component={asyncComponent(() => import('./Guides/index'))} />
        <Route path={`${match.url}user-search-result`} component={asyncComponent(() => import('./UserSearchResult/index'))} />
        <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home/index'))} />
        <Route path={`${match.url}loading-search-results`} exact component={asyncComponent(() => import('./LoadingSearchResults/index'))} />
        <Route path={`${match.url}loading-search-results/:type`} component={asyncComponent(() => import('./LoadingSearchResults/index'))} />
        <Route path={`${match.url}restaurant-closed`} component={asyncComponent(() => import('./RestaurantClosed/index'))} />
        <Route path={`${match.url}registration-benefits`} component={asyncComponent(() => import('./RegistrationBenefits/index'))} />
        <Route path={`${match.url}reset-password-confirm`} component={asyncComponent(() => import('./ResetPasswordConfirm/index'))} />
        <Route path={`${match.url}mobile-reset-password`} component={asyncComponent(() => import('./ResetPasswordMobile/index'))} />
        <Route path={`${match.url}privacy-policy`} component={asyncComponent(() => import('./PrivacyPolicy/index'))} />
        <Route path={`${match.url}terms-conditions`} component={asyncComponent(() => import('./TermsAndConditions/index'))} />
        <Route path={`${match.url}list-a-restaurant`} component={asyncComponent(() => import('./ListRestaurant/index'))} />
        <Route path={`${match.url}contact-us`} component={asyncComponent(() => import('./ContactUs/index'))} />
        <Route path={`${match.url}benefits`} component={asyncComponent(() => import('./Benefits/index'))} />
        <Route path={`${match.url}sitemap`} component={asyncComponent(() => import('./Sitemap/index'))} />
        <Route path={`${match.url}user-list/share/:id`} component={asyncComponent(() => import('./SharedList/index'))} />
        <PrivateRoute path={`${match.url}change-password`} component={asyncComponent(() => import('./ChangePassword/index'))} />
        <PrivateRoute path={`${match.url}dashboard`} component={Dashboard} />
        <PrivateRoute path={`${match.url}restaurant-admin`} component={RestaurantAdmin} />
        <Route path={`${match.url}table-booking`} component={TableBooking} />
        <Route path={`${match.url}error`} component={Error404} />
      </Switch>
    </div>
  )
};

export default App;
