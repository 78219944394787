import React from "react";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { connect } from 'react-redux';
import { updateUserProfile } from '../../store/actions';

import CompleteDetailsForm from './CompleteDetailsForm';

class CompleteDetailsModal extends React.Component {
  render() {
    const { authUser, userData, updateUserProfile, authLoading } = this.props;
    let modalVisible = false;
    if (authUser) {
      modalVisible = !userData.email
    }

    return (
      <Modal
        show={modalVisible}
        centered
        dialogClassName="registration_modal"
        backdropClassName="registartion_modal_backdrop"
        onEnter={this.handleModalEnter}
      >
        <ModalHeader>
          <ModalTitle as="h5">Complete your details</ModalTitle>
        </ModalHeader>
        <ModalBody>
          { authUser && <CompleteDetailsForm
            initialData={userData}
            onSubmit={updateUserProfile}
            loading={authLoading}
          /> }
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    authLoading: auth.isLoading,
    authUser: !!auth.payload,
    userData: auth.payload
  }
};

export default connect(mapStateToProps, { updateUserProfile })(CompleteDetailsModal);