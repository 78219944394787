import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';
import { TOP_3_RESTAURANTS, TOP_CUISINES, INSPIRATION_TAGS } from "./Apiconfig";

export const bannerService = {
    getTop3Restaurants,
    getTopCuisines,
    getInspirationTags
  };

function getTop3Restaurants({location}) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    let api = TOP_3_RESTAURANTS + (location ? (`?location=${location}`) : "")
    return secureFetch(api, requestOptions)
      .then(data => {
        return data;
      }).catch(err => {
        return err;
      });
}

function getTopCuisines({location}) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    let api = TOP_CUISINES + (location ? (`?location=${location}`) : "")
    return secureFetch(api, requestOptions)
      .then(data => {
        return data;
      }).catch(err => {
        return err;
      });
}

function getInspirationTags({location}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let api = INSPIRATION_TAGS + (location ? (`?location=${location}`) : "")
  return secureFetch(api, requestOptions)
    .then(data => {
      return data;
    }).catch(err => {
      return err;
    });
}