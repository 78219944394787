import {
  WINDOW_WIDTH,
  TOGGLE_COLLAPSED_NAV,
  HIDE_FOOTER,
  SHOW_FOOTER,
  CHANGE_MAP_VIEW,
  SHOW_TOAST_SETTINGS,
  HIDE_TOAST_SETTINGS
} from "../../constants/actionTypes";

const initialSettings = {
  navCollapsed: false,
  width: typeof window === 'object' ? window.innerWidth : null,
  footerShown: true,
  mapViewOpen: false,
  toastSettings: false
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case HIDE_FOOTER:
      return {
        ...state,
        footerShown: false,
      };
    case SHOW_FOOTER:
      return {
        ...state,
        footerShown: true,
      };
    case CHANGE_MAP_VIEW: {
      return { ...state, mapViewOpen: action.payload }
    }
    case SHOW_TOAST_SETTINGS: {
      return { ...state, toastSettings: true }
    }
    case HIDE_TOAST_SETTINGS: {
      return { ...state, toastSettings: false }
    }
    default:
      return state;
  }
};

export default settings;
