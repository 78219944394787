import {
  FETCH_TOP_RESTAURANTS,
  FETCH_TOP_RESTAURANTS_SUCCESS,
  FETCH_TOP_RESTAURANTS_FAILED,
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOP_RESTAURANTS: {
      return {...state, error: null, isLoading: true};
    }
    case FETCH_TOP_RESTAURANTS_SUCCESS: {
      const {data, currentPage, pageSize, totalItem, totalPage} = action.payload;
      if (currentPage !== state.currentPage) {
        return {
          ...state,
          error: null,
          payload: state.payload.concat(data),
          currentPage,
          pageSize,
          totalItem,
          totalPage,
          isLoading: false
        };
      } else {
        return state;
      }
    }
    case FETCH_TOP_RESTAURANTS_FAILED: {
      return {...state, payload: null, isLoading: false, error: action.payload};
    }
    default:
      return state
  }
};

export default reducer;
