import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { showToastSettings } from '../../store/actions';
import { generalToasts } from "../../constants/toastConstants";
import { achievementsData } from "../../constants/achievementsData";

class ToastMessages extends React.Component {
  render() {
    const { closeToast, showToastSettings, toastData, history, achievement } = this.props;
    if (toastData) {
      const selectedData = generalToasts(toastData.reason);
      const { title, description, image, buttonLink, buttonText } = selectedData;

      return (
        <section className="toaster_pop">
          <div className="toasterpop_body">
            <div className="toaster_pop_left">
              <h4>{title}</h4>
              <p>{description}</p>
              <div className="toaster_bonus">
                <b>Bonus</b>
                <span>
                <img src="/images/toasterpopup/toaster_coin_sm.png" alt="coin-icon"/>
                <span className="toaster_bonus_added">+</span>50 coins
              </span>
              </div>
              <button type="button" className="tstr_pink_btn" id="btn_vw_balnc"
                      onClick={() => history.push(buttonLink)}>
                {buttonText}
              </button>
            </div>
            <div className="toaster_pop_right">
              <img src={image} className="toaster_img_main" alt={`${title} toast`}/>
              <div className="toaster_button_block">
                <button
                  type="button"
                  className="btn_toaster_setting"
                  id="toaster_settings"
                  onClick={showToastSettings}
                >
                  <img src="/images/toasterpopup/toaster_settings.png" alt="toast-settings"/>
                </button>
                <button
                  type="button"
                  className="btn_toaster_setting mr-0"
                  id="toaster_cls"
                  onClick={closeToast}
                >
                  <img src="/images/toasterpopup/toaster_close.png" alt="toast-close"/>
                </button>
              </div>
            </div>
          </div>
        </section>
      )
    }

    if (achievement) {
      const { title, description, coins, level, type } = achievement;
      let image = '/images/achievement/Generic Achievement.png'
      const achievementDataIndex = achievementsData.findIndex(achievement => achievement.title === title);
      if (achievementDataIndex !== -1) {
        image = `/images/achievement/${achievementsData[achievementDataIndex].imageFile}`
      }

      return (
        <section className="toaster_pop">
          <div className="toasterpop_body">
            <div className="toaster_pop_left">
              <h4>{title}</h4>
              <p>{description}</p>
              <div className="progress level_complete">
                <div className="progress-bar" role="progressbar" style={{ width: '100%' }}>
                  <i className="fa fa-check" aria-hidden="true"/> Level {type === 'oneof' ? 1 : level} Completed!
                </div>
              </div>
              <div className="achv_coins_bttm">
                You earned
                <span className="achv_coin">
                  <img src="/images/toasterpopup/toaster_coin_sm.png"/>{coins} Coins!
                </span>
              </div>
            </div>
            <div className="toaster_pop_right">
              <img src={image} className="toaster_img_main" alt={`${title} toast`}/>
              <div className="toaster_button_block">
                <button
                  type="button"
                  className="btn_toaster_setting"
                  id="toaster_settings"
                  onClick={showToastSettings}
                >
                  <img src="/images/toasterpopup/toaster_settings.png" alt="toast-settings"/>
                </button>
                <button
                  type="button"
                  className="btn_toaster_setting mr-0"
                  id="toaster_cls"
                  onClick={closeToast}
                >
                  <img src="/images/toasterpopup/toaster_close.png" alt="toast-close"/>
                </button>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }
}

export default withRouter(connect(null, { showToastSettings })(ToastMessages));