import React from "react";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
import Slider from "react-slick";
import { connect } from "react-redux";
import _ from "lodash";

//constants
import { bookingTimes } from "../../constants/timeGaps";
import {
  saveBookingData,
  fetchBookingOfferException,
} from "../../store/actions";
import { findColor } from "../../utils/offerColor";
import InfoModal from "../../containers/InfoModal";
import OutsideAlerter from "../../components/OutsideAlerter";

class BookTableNew extends React.Component {
  constructor(props) {
    super(props);
    const newTimes = [];
    let tempTime = [];
    let count = 1;

    bookingTimes.forEach((time) => {
      tempTime.push(time);
      count = count + 1;
      if (count === 7) {
        count = 1;
        newTimes.push(tempTime);
        tempTime = [];
      }
    });

    let newDate = moment();
    let newSelectedTime = "12:00 pm";
    let newGuestCount = 1;

    // if (this.props.bookingData) {
    //   const { guestCount, date, selectedTime } = this.props.bookingData;
    //   newDate = date;
    //   newSelectedTime = selectedTime;
    //   newGuestCount = guestCount;
    // }

    this.state = {
      peopleExpanding: false,
      calenderVisible: false,
      focused: true,
      date: newDate,
      bookTimes: newTimes,
      selectedTime: newSelectedTime,
      guestCount: newGuestCount,
      exceptionOffersFetched: false,
      selectedDayOffer: null,
      selectedExceptions: [],
      calenderOffersSet: false,
      selectedTimeOffer: null,
    };
  }


  componentDidUpdate(prevProps, prevState, snap) {
    const { detailsPayload, fetchBookingOfferException, offerExceptions } =
      this.props;
    const { date, exceptionOffersFetched, calenderOffersSet, selectedTime } =
      this.state;
    if (detailsPayload) {
      //this.setState({ exceptionOffersFetched: true });
      const newDate = _.cloneDeep(date);
      const startDate = newDate.startOf("month").format("YYYY-MM-DD");
      const endDate = newDate.endOf("month").format("YYYY-MM-DD");
      // fetchBookingOfferException(detailsPayload._id, {
      //   startDate,
      //   endDate,
      //   category: "exceptionOffer",
      // });
      if(!calenderOffersSet){
        setTimeout(() => {
          this.onDateChange(date)
          this.setState({calenderOffersSet:true})
        }, 1000)
       
      }
    }

    //if (
      // exceptionOffersFetched &&
      // offerExceptions &&
      // offerExceptions.length &&
     // !calenderOffersSet
    //) {
    // this.onDateChange(date)
    //  this.setState({calenderOffersSet:true})
      //alert("hi")
    //   const { selectedDayOffer, selectedExceptions } =
    //     this.handleFindSelectedDayOffer(date);

    //   let discountText = "";
    //   let chosenOffer = null;
    //   // if (selectedExceptions && selectedExceptions.length) {
    //   //   selectedExceptions.forEach((exceptionOffer) => {
    //   //     const { text, offer } = this.handleFindDiscountDetails({
    //   //       offerData: exceptionOffer,
    //   //       time: selectedTime,
    //   //       initialText: discountText,
    //   //       selectedOffer: chosenOffer,
    //   //     });
    //   //     discountText = text;
    //   //     chosenOffer = offer;
    //   //   });
    //   // }
    //   //if (discountText === "") {
    //     selectedDayOffer &&
    //       selectedDayOffer.forEach((dayOffer) => {
    //         const { text, offer } = this.handleFindDiscountDetails({
    //           offerData: dayOffer,
    //           time: selectedTime,
    //           initialText: discountText,
    //           selectedOffer: chosenOffer,
    //         });
    //         discountText = text;
    //         chosenOffer = offer;
    //       });
    //  // }

    //   this.setState({
    //     selectedDayOffer,
    //     selectedExceptions,
    //     calenderOffersSet: true,
    //     selectedTimeOffer: chosenOffer,
    //   });

   // }
  }

  handleToggleExpand = (value) => {
    this.setState({ peopleExpanding: value });
  };

  handleCalenderToggle = (value) => {
    this.setState({ calenderVisible: value });
  };

  onDateChange = (date) => {
    const { selectedDayOffer, selectedExceptions } =
      this.handleFindSelectedDayOffer(date);
    this.setState({
      date,
      calenderVisible: false,
      selectedDayOffer,
      selectedExceptions,
    });
  };

  onFocusChange = () => {
    // Force the focused states to always be truthy so that date is always selectable
    this.setState({ focused: true });
  };

  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  handleTimeSelect = (value, offer) => {
    this.setState({
      selectedTime: value,
      selectedTimeOffer: _.cloneDeep(offer),
    });
  };

  handleChangePeopleCount = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === "inc") {
      if (this.state.guestCount < 50) {
        this.setState((prevState) => ({
          ...prevState,
          guestCount: prevState.guestCount + 1,
        }));
      }
    }
    if (type === "desc") {
      if (this.state.guestCount > 1) {
        this.setState((prevState) => ({
          ...prevState,
          guestCount: prevState.guestCount - 1,
        }));
      }
    }
  };

  handleSubmit = () => {
    const { guestCount, date, selectedTime, selectedTimeOffer } = this.state;

    if (this.props.detailsPayload) {
      const { _id, slug, title } = this.props.detailsPayload;
      this.props.saveBookingData({
        guestCount,
        date,
        selectedTime,
        specialRequest: "",
        restaurantData: { _id, slug, title },
        offerData: _.cloneDeep(selectedTimeOffer),
      });
      localStorage.setItem(
        "offerData",
        JSON.stringify(
          selectedTimeOffer && selectedTimeOffer.discount
            ? selectedTimeOffer.discount
            : ""
        )
      );
      this.props.onSubmitClicked();
    }
  };

  handleBookingPageSubmit = () => {
    const { date, selectedTime, guestCount, selectedTimeOffer } = this.state;
    this.props.onBookingPageSubmit({
      date,
      selectedTime,
      guestCount,
      offerData: _.cloneDeep(selectedTimeOffer),
    });
    this.props.onSubmitClicked();
  };

  handleMonthChange = (date) => {
    const newDate = _.cloneDeep(date);
    const startDate = newDate.startOf("month").format("YYYY-MM-DD");
    const endDate = newDate.endOf("month").format("YYYY-MM-DD");
    this.props.fetchBookingOfferException(this.props.detailsPayload._id, {
      startDate,
      endDate,
      category: "exceptionOffer",
    });
  };

  handleFindSelectedDayOffer = (date) => {
    const { offerDetails, offerExceptions } = this.props;
    let selectedDayOffer;
    if (
      offerDetails !== undefined &&
      offerDetails[0] !== undefined &&
      Object.keys(offerDetails[0])[0] === "weeklyOffer"
    ) {
      const weeklyOfferDetails = offerDetails[0]["weeklyOffer"];
      const selectedDay = weeklyOfferDetails.find((dayOffer) => {
        return Number(moment(date).format("d")) === dayOffer._id;
      });
      if (selectedDay) {
        selectedDayOffer = selectedDay.data;
      }
    }

    let selectedExceptions = [];
    // if (offerExceptions && offerExceptions.length) {
    //   selectedExceptions = offerExceptions.filter((exception) => {
    //     const { startDate, endDate } = exception;
    //     const newStartDate = moment(startDate);
    //     const newEndDate = moment(endDate);
    //     return date.isBetween(newStartDate, newEndDate, undefined, "[)");
    //   });
    //}

    return { selectedDayOffer, selectedExceptions };
  };

  handleFindDiscountDetails = ({
    offerData,
    time,
    initialText,
    selectedOffer,
  }) => {
    let newText = initialText || "";
    let chosenOffer = selectedOffer;
    const { appliedOn, discount } = offerData;
    const format = "H:mm";
    const startTime = moment(`${appliedOn.time[0]}:00`, format);
    const endTime = moment(`${appliedOn.time[1]}:00`, format);
    //console.log(time, appliedOn[0], appliedOn[1])
    if (moment(time, "h:mm a").isBetween(startTime, endTime, undefined, "[]")) {
      chosenOffer = _.cloneDeep(offerData);
      if (discount.type === "percent") {
        const color = findColor({
          offerValue: discount.value,
          type: "percent",
        });
        newText = <p style={{ color }}>{`${discount.value} %`}</p>;
      }
      if (discount.type === "rewards") {
        const color = findColor({
          offerValue: discount.value,
          type: "rewards",
        });
        newText = (
          <p style={{ color }}>
            <img
              src="/images/coin.png"
              className="book-time-coins"
              alt="coins"
            />
            {`+${discount.value}`}
          </p>
        );
      }
    }

    return { text: newText, offer: chosenOffer };
  };

  handleReportPage = () => {
    this.props.reportIssue();
  };

  isBeforeDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

    const aYear = a.year();
    const aMonth = a.month();

    const bYear = b.year();
    const bMonth = b.month();

    const isSameYear = aYear === bYear;
    const isSameMonth = aMonth === bMonth;

    if (isSameYear && isSameMonth) return a.date() < b.date();
    if (isSameYear) return aMonth < bMonth;
    return aYear < bYear;
  }
  isInclusivelyAfterDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !this.isBeforeDay(a, b);
  }

  render() {
    const {
      peopleExpanding,
      focused,
      date,
      calenderVisible,
      bookTimes,
      selectedTime,
      guestCount,
      selectedDayOffer,
      selectedExceptions,
    } = this.state;
    const {
      visible,
      onClose,
      bookingSubmit,
      editPage,
      menuPage,
      offerDetails,
      offerExceptions,
      isEmbed,
      authDetails,
    } = this.props;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      initialSlide: 0,
      arrows: false,
    };

    let fixedClass = "fixed";
    if (editPage) {
      fixedClass = "fixed fixed-edit";
    }
    if (menuPage) {
      fixedClass = "fixed fixed-menu";
    }

    let classNames = `sidebar bookatable ${
      bookingSubmit ? "booktable-d-none" : ""
    } ${visible ? "show" : ""}`;
    if (this.props.isFixed) {
      classNames = `sidebar bookatable ${
        bookingSubmit ? "booktable-d-none" : ""
      } ${visible ? "show" : ""} ${fixedClass}`;
    }

    return (
      <div className={`${isEmbed ? "" : "bookatable-outer"}`}>
        <div
          className={classNames}
          ref={(el) => (this.inputElement = el)}
          style={
            isEmbed && !visible
              ? { position: "initial", width: "100%" }
              : isEmbed && visible
              ? { height: "100%", top: 0 }
              : null
          }
        >
          <div className="collapsed_table_area">
            <ul>
              <li>
                <div className="accordion" id="accordionExample">
                  <div>
                    <div id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link people_btn"
                          type="button"
                        >
                          {peopleExpanding ? (
                            <div
                              className="collapsed_table_lsit d-flex align-items-center justify-content-between"
                              onClick={() => this.handleToggleExpand(false)}
                            >
                              <span className="d-flex align-items-center">
                                <img
                                  src="/images/booking/person.png"
                                  srcset="/images/booking/person@2x.png 2x"
                                  alt=""
                                />
                                <div className="next_people">
                                  <img
                                    src="/images/booking/decrease.png"
                                    srcset="/images/booking/decrease@2x.png 2x"
                                    alt=""
                                    onClick={(e) =>
                                      this.handleChangePeopleCount(e, "desc")
                                    }
                                    style={{ width: "unset", marginRight: 0 }}
                                  />
                                  <span>{guestCount}</span>
                                  <img
                                    src="/images/booking/increase.png"
                                    srcset="/images/booking/increase@2x.png 2x"
                                    alt=""
                                    onClick={(e) =>
                                      this.handleChangePeopleCount(e, "inc")
                                    }
                                    style={{ width: "unset", marginRight: 0 }}
                                  />
                                  <p>people</p>
                                </div>
                              </span>
                              <img
                                className="down_arrow"
                                src="/images/icon_arrow_down.png"
                                alt=""
                              />
                            </div>
                          ) : (
                            <div
                              className="collapsed_table_lsit d-flex align-items-center justify-content-between"
                              onClick={() => this.handleToggleExpand(true)}
                            >
                              <span className="d-flex align-items-center">
                                <img
                                  src="/images/booking/person.png"
                                  srcset="/images/booking/person@2x.png 2x"
                                  alt=""
                                />
                                <div className="first_people">
                                  <p>{guestCount} People</p>
                                </div>
                              </span>
                              <img
                                className="up_arrow"
                                src="/images/icon_arrow_up.png"
                                alt=""
                              />
                            </div>
                          )}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    ></div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="collapsed_table_lsit d-flex align-items-center justify-content-between"
                  onClick={() => this.handleCalenderToggle(!calenderVisible)}
                  style={calenderVisible ? { borderBottom: "unset" } : null}
                >
                  <span>
                    <img
                      src="/images/booking/calender.png"
                      srcset="/images/booking/calender@2x.png 2x"
                      alt=""
                    />
                    {moment(date).format("ddd D MMM")}
                  </span>
                  <img
                    src="/images/icon_arrow_down.png"
                    srcset="/images/icon_arrow_down@2x.png 2x"
                    alt=""
                  />
                </div>
                {calenderVisible ? (
                  <div className="collapsed-calender">
                    <DayPickerSingleDateController
                      onDateChange={this.onDateChange}
                      onFocusChange={this.onFocusChange}
                      focused={focused}
                      date={date}
                      isOutsideRange={(day) =>
                        !this.isInclusivelyAfterDay(day, moment())
                      }
                      noBorder
                      onPrevMonthClick={this.handleMonthChange}
                      onNextMonthClick={this.handleMonthChange}
                      renderDayContents={(day) => {
                        let biggestDiscount = 0;
                        offerExceptions &&
                          Object.keys(offerExceptions).length &&
                          Object.keys(offerExceptions).forEach(
                            (exceptionCode) => {
                              const exception = offerExceptions[exceptionCode];
                              if (
                                moment(day).isBetween(
                                  moment(exception.createDate).utc(),
                                  moment(exception.endDate).utc()
                                )
                              ) {
                                if (exception.discount.type === "percent") {
                                  biggestDiscount =
                                    exception.discount.value > biggestDiscount
                                      ? exception.discount.value
                                      : biggestDiscount;
                                }
                              }
                            }
                          );
                        if (
                          offerDetails &&
                          offerDetails instanceof Object &&
                          Object.keys(offerDetails[0])[0] === "weeklyOffer"
                        ) {
                          const weeklyOfferDetails =
                            offerDetails[0]["weeklyOffer"];
                          const selectedWeek = weeklyOfferDetails.find(
                            (dayOffer) => {
                              return (
                                Number(moment(day).format("d")) === dayOffer._id
                              );
                            }
                          );
                          if (selectedWeek) {
                            selectedWeek.data.forEach((weekData) => {
                              if (weekData.discount.type === "percent") {
                                biggestDiscount =
                                  weekData.discount.value > biggestDiscount
                                    ? weekData.discount.value
                                    : biggestDiscount;
                              }
                            });
                          }
                        }
                        let discountColor = findColor({
                          offerValue: biggestDiscount,
                          type: "percent",
                        });

                        return (
                          <span className="react-dates-item">
                            <b className="react-dates-day">
                              {moment(day).format("D")}
                            </b>
                            <small
                              className="react-dates-data"
                              style={{ color: discountColor }}
                            >
                              {biggestDiscount ? `${biggestDiscount}%` : ""}
                            </small>
                          </span>
                        );
                      }}
                      daySize={48}
                      navPrev={
                        <div className="react-dates-arrow left">
                          <img
                            src="/images/red_arrow_right.png"
                            srcset="/images/red_arrow_right@2x.png 2x"
                            alt="arrow"
                          />
                        </div>
                      }
                      navNext={
                        <div className="react-dates-arrow right">
                          <img
                            src="/images/red_arrow_left.png"
                            srcset="/images/red_arrow_left@2x.png 2x"
                            alt="arrow"
                          />
                        </div>
                      }
                      hideKeyboardShortcutsPanel
                    />
                  </div>
                ) : null}
              </li>
              <li>
                <div className="collapsed_table_lsit select_time d-flex align-items-center justify-content-between">
                  <span>
                    <img
                      src="/images/booking/clock-grey.png"
                      srcset="/images/booking/clock-grey@2x.png 2x"
                      alt=""
                    />
                    select a time
                  </span>
                  <span className="arrow_area d-flex">
                    <img
                      src="/images/red_arrow_right.png"
                      srcset="/images/red_arrow_right@2x.png 2x"
                      alt=""
                      onClick={this.previous}
                    />
                    <img
                      src="/images/red_arrow_left.png"
                      srcset="/images/red_arrow_left@2x.png 2x"
                      alt=""
                      onClick={this.next}
                    />
                  </span>
                </div>
                {
                  <Slider {...settings} ref={(c) => (this.slider = c)}>
                    {bookTimes.map((bookTime, index) => {
                      // console.log("bookingTime===",bookTime)

                      return (
                        <div
                          key={index}
                          className="select_time_area d-flex justify-content-between flex-wrap"
                        >
                          {bookTime.map((time) => {
                            let discountText = "";
                            let chosenOffer = null;
                            // if (
                            //   selectedExceptions &&
                            //   selectedExceptions.length
                            // ) {
                            //   selectedExceptions.forEach((exceptionOffer) => {
                            //    // console.log("selectedExceptions====", exceptionOffer)
                            //     const {
                            //       text,
                            //       offer,
                            //     } = this.handleFindDiscountDetails({
                            //       offerData: exceptionOffer,
                            //       time,
                            //       initialText: discountText,
                            //       selectedOffer: chosenOffer,
                            //     });
                            //     discountText = text;
                            //     chosenOffer = offer;
                            //   });
                            // }
                            //if (discountText === "") {
                              selectedDayOffer &&
                                selectedDayOffer.forEach((dayOffer) => {
                                  // console.log("selectedDayOffer====",dayOffer)
                                  const { text, offer } =
                                    this.handleFindDiscountDetails({
                                      offerData: dayOffer,
                                      time,
                                      initialText: discountText,
                                      selectedOffer: chosenOffer,
                                    });
                                  discountText = text;
                                  chosenOffer = offer;
                                });
                            //}
                            return (
                              <div
                                className={`single_select_time ${
                                  selectedTime === time ? "active" : ""
                                }`}
                                key={time}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.handleTimeSelect(time, chosenOffer)
                                }
                              >
                                <span>{time}</span>
                                {discountText}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Slider>
                }
                {bookingSubmit ? (
                  <div className="btn_area d-flex justify-content-between">
                    <button
                      className="add_new_btn"
                      style={{ width: "100%" }}
                      onClick={this.handleBookingPageSubmit}
                    >
                      Save changes
                    </button>
                  </div>
                ) : (
                  <div className="btn_area d-flex justify-content-between">
                    <div className="btn_area-resp-back" onClick={onClose}>
                      <img
                        src="/images/icon_back_red.png"
                        srcset="/images/icon_back_red@2x.png 2x"
                        alt="back"
                      />
                    </div>
                    <button
                      className="add_new_btn"
                      style={{ width: "100%" }}
                      onClick={this.handleSubmit}
                    >
                      Book a table at {selectedTime}
                    </button>
                  </div>
                )}
                <span className="bookin_free">
                  Booking is free fast &amp; easy!
                  <InfoModal
                    width={15}
                    height={15}
                    position="left"
                    type="bookingTable"
                  />
                </span>
              </li>
            </ul>
          </div>
          {authDetails && (
            <div className="booktable_report_page">
              <span onClick={this.handleReportPage}>
                <img src="/images/icon_report_grey.png" />
                Report this page
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ restaurant, booking }) => {
  const {
    detailsPayload,
    bookingOffers: offerExceptions,
    bookingOffersLoading,
  } = restaurant;
  const { bookingData } = booking;
  return { detailsPayload, bookingData, bookingOffersLoading, offerExceptions };
};

export default connect(mapStateToProps, {
  saveBookingData,
  fetchBookingOfferException,
})(BookTableNew);
