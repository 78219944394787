import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

//constants
import {
  MY_BOOKINGS,
  MY_DASHBOARD,
  MY_LISTS,
  MY_OFFERS,
  MY_PROFILE,
  MY_PAGES,
  MY_COINS,
  MY_HELP,
  MY_ACHIEVEMENTS,
} from "../../constants/routes";

import { fetchNewFeedsCount } from "../../store/actions";

class DashboardHeader extends React.Component {
  componentDidMount() {
    this.props.fetchNewFeedsCount();
  }

  render() {
    const { newFeedsCount } = this.props;
    return (
      <div className="user_search_nav bg-blue1 fixed_topnav_for_ads_page">
        <div className="wrapper p_36 user_search_nav_inner nav_modified mx-20 mob-mx-20">
          <ul className="d-flex-align user_search_nav top_blue_nav1 userarea font-14">
            <li>
              <NavLink activeClassName="active" to={MY_DASHBOARD}>
                Dashboard
                {newFeedsCount !== 0 && (
                  <span className="no_in_round">{newFeedsCount}</span>
                )}
              </NavLink>
            </li>
            <li className="menu_bookings">
              <NavLink activeClassName="active" to={MY_BOOKINGS}>
                Bookings
              </NavLink>
            </li>
            <li className="menu_offers">
              <NavLink activeClassName="active" to={MY_OFFERS}>
                Offers
              </NavLink>
            </li>
            <li className="menu_list">
              <NavLink activeClassName="active" to={MY_LISTS}>
                Saved Lists
              </NavLink>
            </li>
            <li className="menu_bookings">
              <NavLink activeClassName="active" to={MY_COINS}>
                Coins
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to={MY_ACHIEVEMENTS}>
                Achievements
              </NavLink>
            </li>
          </ul>
          <ul className="d-flex-align user_search_nav top_blue_nav1 userarea font-14">
            <li>
              <NavLink activeClassName="active" to={MY_PAGES}>
                Pages
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to={MY_PROFILE}>
                Account
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to={MY_HELP}>
                Help
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ feed }) => {
  return {
    newFeedsCount: feed.feedCount,
  };
};

export default connect(mapStateToProps, {
  fetchNewFeedsCount,
})(DashboardHeader);
