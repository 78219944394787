import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { breadcrumbs } from '../../constants/breadcrumbsContants';

const Breadcrumbs = ({ path, restaurant }) => {

  const crumbsData = {
    title: restaurant ? restaurant.title : "",
    slug: restaurant ? restaurant.slug : "",
  }
  const breadcrumbsArr = getBreadcrumbItems(path, breadcrumbs, crumbsData);

  return (
    <Breadcrumb bsPrefix="breadcrumb">
      {
        breadcrumbsArr.map((breadcrumbs, index) => {
          const { label, link } = breadcrumbs;
          const active = index === (breadcrumbsArr.length - 1);
          return (
            <React.Fragment key={index}>
              <Breadcrumb.Item href={link} active={active}>{label}</Breadcrumb.Item>
            </React.Fragment>
          );
        })
      }
    </Breadcrumb >
  );
};


function getBreadcrumbItems(path, breadcrumbs, crumbsData) {

  let pathLocation = path.pathname.split('/');
  let returnCrumbsArr = [];
  let pageName = '';
  let location = null;
  let newState = path.state;
  if (typeof newState !== "undefined" && typeof pathname !== "undefined") {
    let type = (path.state && newState.from) ? newState.from.pathname.split('/') : [];

    if (type.includes('guest-search-result')) {
      type = 'guest-search-result';
    } if (type.includes('guides')) {
      type = 'guides';
    } if (type.includes('my-lists')) {
      type = 'my-lists';
    }
    crumbsData.type = type;
    // crumbsData.link = newState.fr;
  }

  switch (pathLocation.length) {
    case (2):
      pageName = 'home';
      location = pathLocation[1];
      break;
    case (3):
      pageName = 'dashboard';
      location = pathLocation[2];
      break;
    case (4):
      pageName = 'restaurant-admin';
      location = pathLocation[3];
      break;
    default:
      pageName = '';
      returnCrumbsArr = [];
  }


  if (pathLocation.includes('guides')) {
    pageName = 'home';
    location = pathLocation[1];
  }
  if (pathLocation.includes('restaurant-details')) {
    pageName = 'restaurant-details';
    location = pathLocation[1];
  }
  if (pathLocation.includes('restaurant-edit-details') || pathLocation.includes('user-restaurant-suggestion')) {
    pageName = 'restaurant-edit-details';
    location = pathLocation[1];
  }
  if (pathLocation.includes('restaurant-menu')) {
    pageName = 'restaurant-menu';
    location = pathLocation[1];
  }
  if (pathLocation.includes('register')) {
    pageName = 'register';
    location = pathLocation[1];
  }

  if (pageName !== '') {
    returnCrumbsArr = getCrumbsList(pageName, location, breadcrumbs, crumbsData);
  }

  return returnCrumbsArr;
}

function getCrumbsList(main, category, breadcrumbs, crumbsData = {}) {
  let returnCrumbsArr = [];
  breadcrumbs.map((item, index) => {
    if (item.category === main) {
      if (main !== "home") {
        returnCrumbsArr.push({ label: "Home", link: "/home" });
      }
      if (item.category === "restaurant-details" || item.category === "restaurant-menu" ||
        item.category === "restaurant-edit-details") {

        if (crumbsData.type !== "") {
          item.pages.map((page) => {
            if (page.name === crumbsData.type) {
              returnCrumbsArr.push({ label: page.label, link: crumbsData.link });
            }
          });
        }

        let link = '/restaurant-details/' + crumbsData.slug;
        returnCrumbsArr.push({ label: crumbsData.title, link: link });
        if (item.category === "restaurant-menu") {
          returnCrumbsArr.push({ label: 'Menu', link: '' });
        }
        if (item.category === "restaurant-edit-details") {
          returnCrumbsArr.push({ label: 'Edit', link: '' });
        }
      } else {
        returnCrumbsArr.push({ label: item.label, link: item.link });

      }

      item.pages.map((page) => {
        if (page.name === category) {
          returnCrumbsArr.push({ label: page.label, link: page.name });
        }
      });
    }
  });
  return returnCrumbsArr;
}

export default Breadcrumbs;