import {
  LOG_INTERACTION,
  LOG_INTERACTION_SUCCESS,
  LOG_INTERACTION_FAILURE,
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_INTERACTION: {
      return { ...state, error: null, isLoading: true };
    }
    case LOG_INTERACTION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false
      };
    }
    case LOG_INTERACTION_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
