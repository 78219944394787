import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const contactService = {
  addContact,
  addEnquiry
};

function addContact(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return secureFetch(`${apiURL.CONTACT_US}/contact-us`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function addEnquiry(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return secureFetch(`${apiURL.CONTACT_US}/enquiry`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}
