import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

//custom styles
import './Dashboard.css';

//custom components
import asyncComponent from "../../utils/asyncComponent";
import DashboardHeader from "../../containers/DashboardHeader";

const Dashboard = ({ match, ...props }) => {
  const [redirection, setRedirection] = useState(false);

  useEffect(() => {
    if (!props.authUser) {
      setRedirection(true);
    }
  }, [props.authUser]);

  if (redirection) {
    return (
      <div>
        <Redirect to={`/home`} />
      </div>)
  } else {
    return (
      <div className="my-app mt_70 new_for_ad_mobile mt_new_for_ads">
        <DashboardHeader />
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/my-dashboard`} />
          <Route path={`${match.url}/my-dashboard`} component={asyncComponent(() => import('./MyDashboard'))} />
          <Route path={`${match.url}/my-bookings`} component={asyncComponent(() => import('./MyBookings'))} />
          <Route path={`${match.url}/my-offers`} component={asyncComponent(() => import('./MyOffers'))} />
          <Route path={`${match.url}/my-lists`} component={asyncComponent(() => import('./MyLists'))} />
          <Route path={`${match.url}/my-lists/:id`} component={asyncComponent(() => import('./MyLists'))} />
          <Route path={`${match.url}/my-profile`} component={asyncComponent(() => import('./MyProfile'))} />
          <Route path={`${match.url}/my-pages`} component={asyncComponent(() => import('./MyPages'))} />
          <Route path={`${match.url}/my-coins`} component={asyncComponent(() => import('./MyCoins'))} />
          <Route path={`${match.url}/my-help`} component={asyncComponent(() => import('./MyHelp'))} />
          <Route path={`${match.url}/my-achievements`} component={asyncComponent(() => import('./MyAchievements'))} />
        </Switch>
      </div>
    )
  }
};

const mapStateToProps = ({ auth }) => {
  return {
    authUser: !!auth.payload
  }
};

export default connect(mapStateToProps)(Dashboard);
