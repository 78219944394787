import React from "react";

const LoaderPage = () => {
  return (
    <div className="page_spinner">
      <div className="loader"/>
    </div>
  )
};

export default LoaderPage;