import {
  FETCH_TOP_RESTAURANTS,
  FETCH_TOP_RESTAURANTS_SUCCESS,
  FETCH_TOP_RESTAURANTS_FAILED,
} from '../../constants/actionTypes';
import { topLocationService } from '../../service/topLocationService';

export const fetchTopRatedRestaurants = () => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const {currentPage, totalPage} = getState().topLocation;
    const nextPage = parseInt(currentPage) + 1;
    if (nextPage !== 1 && (nextPage > totalPage)) return;

    const params = {
      currentPage: nextPage,
    };
    dispatch(fetchTopRatedRestaurantsStart());
    topLocationService.getTopRatedRestaurants(params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchTopRatedRestaurantsSuccess(response));
        } else {
          dispatch(fetchTopRatedRestaurantFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchTopRatedRestaurantFailed(failureMessage));
      });
  }
};

const fetchTopRatedRestaurantsStart = () => {
  return {
    type: FETCH_TOP_RESTAURANTS
  }
};

const fetchTopRatedRestaurantsSuccess = data => {
  return {
    type: FETCH_TOP_RESTAURANTS_SUCCESS,
    payload: data
  }
};

const fetchTopRatedRestaurantFailed = data => {
  return {
    type: FETCH_TOP_RESTAURANTS_FAILED,
    payload: data
  }
};
