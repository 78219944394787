import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const loginService = {
  login,
  facebookLogin,
  googleLogin,
  logout,
  registration,
  getForgotPasswordOtp,
  setNewPassword,
  getProfile,
  changePassword
};

function login(username, password, otherData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ email: username, password, inviteCode: otherData.inviteCode })
  };

  return secureFetch(apiURL.LOGIN_URL, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function facebookLogin(token, otherData) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.FACEBOOK_LOGIN}/?access_token=${token}&inviteCode=${otherData.inviteCode}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function googleLogin(token, otherData) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.GOOGLE_LOGIN}/?access_token=${token}&inviteCode=${otherData.inviteCode}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function registration(registrationData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ ...registrationData, gender: '' })
  };

  return secureFetch(apiURL.REGISTRATION_URL, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function logout() {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return secureFetch(apiURL.LOGIN_URL, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getForgotPasswordOtp(email) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ email })
  };

  return secureFetch(apiURL.FORGOT_PASS_OTP_URL, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function setNewPassword(resetData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ ...resetData })
  };

  return secureFetch(apiURL.FORGOT_PASS_URL, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getProfile() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.PROFILE}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function changePassword(resetData) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(resetData)
  };

  return secureFetch(apiURL.CHANGE_PASS_URL, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function validateToken() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };
  return secureFetch(apiURL.VALIDATE_TOKEN, requestOptions)
    .then(response => {
      const isValid = response.status === 200;
      if (!isValid) Cookies.remove('userAccessToken');
      return isValid;
    })
    .catch(error => console.log(error));
}*/

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
