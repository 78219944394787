import {
  ADD_REVIEW_START,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_FAILED,
} from '../../constants/actionTypes';
import { reviewService } from "../../service/review-service";
import { setUpdateRestaurantStatus } from './Restaurant';
import { setSuggestionStatus } from './UserSuggestion';
import { updateBookingReviewData } from "./Booking";
import { updatePendingReviewData } from "./User";

export const addReview = (data) => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    dispatch(addReviewStart());
    reviewService.postReview(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(addReviewSuccess(response));
          if (!data.pageName) {
            if (getState().auth.payload.role === 'admin') {
              dispatch(setUpdateRestaurantStatus());
            } else {
              dispatch(setSuggestionStatus());
            }
          }
          if (data.pageName === 'bookingHistory') {
            dispatch(updateBookingReviewData(response.data[0]))
          }
          if (data.pageName === 'userCoins') {
            dispatch(updatePendingReviewData(response.data[0]))
          }
        } else {
          dispatch(addReviewFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(addReviewFailed(failureMessage));
      });
  }
};

export const updateReview = (data, id) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(addReviewStart());
    reviewService.updateReview(data, id)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(addReviewSuccess(response));
          if (data.pageName === 'bookingHistory') {
            dispatch(updateBookingReviewData(response.data[0]))
          }
          if (data.pageName === 'userCoins') {
            dispatch(updatePendingReviewData(response.data[0]))
          }
        } else {
          dispatch(addReviewFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(addReviewFailed(failureMessage));
      });
  }
};

const addReviewStart = () => {
  return {
    type: ADD_REVIEW_START
  }
};

const addReviewSuccess = data => {
  return {
    type: ADD_REVIEW_SUCCESS,
    payload: data
  }
};

const addReviewFailed = data => {
  return {
    type: ADD_REVIEW_FAILED,
    payload: data
  }
};
