import { cloneDeep } from "lodash";
import {
  FETCH_TOP_LOCATIONS_START,
  FETCH_TOP_LOCATIONS_SUCCESS,
  FETCH_TOP_LOCATIONS_FAILED,
  FETCH_RECENT_LOCATIONS_START,
  FETCH_RECENT_LOCATIONS_SUCCESS,
  FETCH_RECENT_LOCATIONS_FAILED,
  CLEAR_RECENT_LOCATIONS_START,
  CLEAR_RECENT_LOCATIONS_SUCCESS,
  CLEAR_RECENT_LOCATIONS_FAILED,
  FETCH_SEARCHED_ITEMS_START,
  FETCH_SEARCHED_ITEMS_SUCCESS,
  FETCH_SEARCHED_ITEMS_FAILED,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_SEARCH_PREFES,
  UPDATE_SEARCH_PREFES_SUCCESS,
  UPDATE_SEARCH_PREFES_FAILED,
  FETCH_SEARCH_PREFES,
  FETCH_SEARCH_PREFES_SUCCESS,
  FETCH_SEARCH_PREFES_FAILED,
  UPDATE_PROFILE_PIC,
  UPDATE_PROFILE_PIC_SUCCESS,
  UPDATE_PROFILE_PIC_FAILED,
  FETCH_PROFILE_STRENGTH,
  FETCH_PROFILE_STRENGTH_SUCCESS,
  FETCH_PROFILE_STRENGTH_FAILED,
  FETCH_PAID_COINS_START,
  FETCH_PAID_COINS_SUCCESS,
  FETCH_PAID_COINS_FAILED,
  FETCH_USER_COIN_BUNDLES_START,
  FETCH_USER_COIN_BUNDLES_SUCCESS,
  FETCH_USER_COIN_BUNDLES_FAILED,
  FETCH_PENDING_REVIEWS_START,
  FETCH_PENDING_REVIEWS_SUCCESS,
  FETCH_PENDING_REVIEWS_FAILED,
  UPDATE_PENDING_REVIEW_DATA,
  FETCH_PENDING_PREFS_START,
  FETCH_PENDING_PREFS_SUCCESS,
  FETCH_PENDING_PREFS_FAILED,
  REMOVE_PENDING_PREF,
  FETCH_POLLCARDS_SUCCESS,
  FETCH_POLLCARDS_FAILED,
  FETCH_POLLCARDS_START,
  UPDATE_POLLCARDS_START,
  UPDATE_POLLCARDS_SUCCESS,
  UPDATE_POLLCARDS_FAILED,
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  recentLocationsPayload: [],
  recentLocationsLoading: false,
  searchedItemsPayload: null,
  searchedItemsLoading: false,
  updateUser: "",
  userUpdating: false,
  searchPreferences: null,
  preferenceUpdating: false,
  profilePicUpdating: false,
  profilePic: null,
  profileStrength: null,
  profileStrengthLoading: false,
  scoringFields: null,
  paidCoinFields: [],
  paidCoinsLoading: false,
  coinBundles: [],
  coinBundlesLoading: false,
  pendingReviews: [],
  pendingReviewsLoading: false,
  pendingPrefs: [],
  pendingPrefsLoading: false,
  preferencesCardLoading: false,
  preferences: [],
  updatedPreferences: [],
  updatedPreferencesLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOP_LOCATIONS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_TOP_LOCATIONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false,
      };
    }
    case FETCH_TOP_LOCATIONS_FAILED: {
      return {
        ...state,
        payload: null,
        isLoading: false,
        error: action.payload,
      };
    }
    case FETCH_RECENT_LOCATIONS_START: {
      return { ...state, error: null, recentLocationsLoading: true };
    }
    case FETCH_RECENT_LOCATIONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        recentLocationsPayload: data,
        recentLocationsLoading: false,
      };
    }
    case FETCH_RECENT_LOCATIONS_FAILED: {
      return {
        ...state,
        recentLocationsPayload: null,
        recentLocationsLoading: false,
        error: action.payload,
      };
    }
    case CLEAR_RECENT_LOCATIONS_START: {
      return { ...state, error: null, recentLocationsLoading: true };
    }
    case CLEAR_RECENT_LOCATIONS_SUCCESS: {
      return {
        ...state,
        error: null,
        recentLocationsPayload: [],
        recentLocationsLoading: false,
      };
    }
    case CLEAR_RECENT_LOCATIONS_FAILED: {
      return {
        ...state,
        recentLocationsPayload: [],
        recentLocationsLoading: false,
        error: action.payload,
      };
    }
    case FETCH_SEARCHED_ITEMS_START: {
      return { ...state, error: null, searchedItemsLoading: true };
    }
    case FETCH_SEARCHED_ITEMS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        searchedItemsPayload: data,
        searchedItemsLoading: false,
      };
    }
    case FETCH_SEARCHED_ITEMS_FAILED: {
      return {
        ...state,
        searchedItemsPayload: null,
        searchedItemsLoading: false,
        error: action.payload,
      };
    }
    case UPDATE_USER_PROFILE: {
      return { ...state, error: null, userUpdating: true };
    }
    case UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        error: null,
        updateUser: action.payload,
        userUpdating: false,
      };
    }
    case UPDATE_USER_PROFILE_FAILED: {
      return {
        ...state,
        updateUser: null,
        userUpdating: false,
        error: action.payload,
      };
    }
    case UPDATE_SEARCH_PREFES: {
      return { ...state, error: null, preferenceUpdating: true };
    }
    case UPDATE_SEARCH_PREFES_SUCCESS: {
      const newPrefs = cloneDeep(state.searchPreferences);
      return {
        ...state,
        error: null,
        searchPreferences: { ...newPrefs, ...action.payload },
        preferenceUpdating: false,
      };
    }
    case UPDATE_SEARCH_PREFES_FAILED: {
      return { ...state, preferenceUpdating: false, error: action.payload };
    }
    case FETCH_SEARCH_PREFES: {
      return { ...state, error: null, preferenceUpdating: true };
    }
    case FETCH_SEARCH_PREFES_SUCCESS: {
      return {
        ...state,
        error: null,
        searchPreferences: action.payload,
        preferenceUpdating: false,
      };
    }
    case FETCH_SEARCH_PREFES_FAILED: {
      return { ...state, preferenceUpdating: false, error: action.payload };
    }
    case UPDATE_PROFILE_PIC: {
      return { ...state, error: null, profilePicUpdating: true };
    }
    case UPDATE_PROFILE_PIC_SUCCESS: {
      return {
        ...state,
        error: null,
        profilePic: action.payload,
        profilePicUpdating: false,
      };
    }
    case UPDATE_PROFILE_PIC_FAILED: {
      return { ...state, profilePicUpdating: false, error: action.payload };
    }
    case FETCH_PROFILE_STRENGTH: {
      return { ...state, error: null, profileStrengthLoading: true };
    }
    case FETCH_PROFILE_STRENGTH_SUCCESS: {
      return {
        ...state,
        error: null,
        profileStrength: action.payload.profileScore,
        profileStrengthLoading: false,
        scoringFields: [...action.payload.profileFieldsForScore],
      };
    }
    case FETCH_PROFILE_STRENGTH_FAILED: {
      return { ...state, profileStrengthLoading: false, error: action.payload };
    }
    case FETCH_PAID_COINS_START: {
      return { ...state, error: null, paidCoinsLoading: true };
    }
    case FETCH_PAID_COINS_SUCCESS: {
      return {
        ...state,
        error: null,
        paidCoinFields: action.payload,
        paidCoinsLoading: false,
      };
    }
    case FETCH_PAID_COINS_FAILED: {
      return { ...state, paidCoinsLoading: false, error: action.payload };
    }
    case FETCH_USER_COIN_BUNDLES_START: {
      return { ...state, error: null, coinBundlesLoading: true };
    }
    case FETCH_USER_COIN_BUNDLES_SUCCESS: {
      return {
        ...state,
        error: null,
        coinBundles: action.payload,
        coinBundlesLoading: false,
      };
    }
    case FETCH_USER_COIN_BUNDLES_FAILED: {
      return { ...state, coinBundlesLoading: false, error: action.payload };
    }
    case FETCH_PENDING_REVIEWS_START: {
      return { ...state, error: null, pendingReviewsLoading: true };
    }
    case FETCH_PENDING_REVIEWS_SUCCESS: {
      return {
        ...state,
        error: null,
        pendingReviews: action.payload,
        pendingReviewsLoading: false,
      };
    }
    case FETCH_PENDING_REVIEWS_FAILED: {
      return { ...state, pendingReviewsLoading: false, error: action.payload };
    }
    case UPDATE_PENDING_REVIEW_DATA: {
      const newBookingHistory = cloneDeep(state.pendingReviews);
      const updateBookingIndex = newBookingHistory.findIndex(
        (booking) => booking._id === action.payload.booking
      );
      if (updateBookingIndex !== -1) {
        newBookingHistory[updateBookingIndex].reviewData = {
          ...action.payload,
        };
      }
      return {
        ...state,
        pendingReviews: newBookingHistory,
      };
    }
    case FETCH_PENDING_PREFS_START: {
      return { ...state, error: null, pendingPrefsLoading: true };
    }
    case FETCH_PENDING_PREFS_SUCCESS: {
      return {
        ...state,
        error: null,
        pendingPrefs: action.payload,
        pendingPrefsLoading: false,
      };
    }
    case FETCH_PENDING_PREFS_FAILED: {
      return { ...state, pendingPrefsLoading: false, error: action.payload };
    }
    case REMOVE_PENDING_PREF: {
      return {
        ...state,
        pendingPrefs: state.pendingPrefs.filter(
          (pref) => pref._id !== action.payload
        ),
      };
    }
    case FETCH_POLLCARDS_START: {
      return {
        ...state,
        error: null,
        preferencesLoading: true,
      };
    }
    case FETCH_POLLCARDS_SUCCESS: {
      return {
        ...state,
        error: null,
        preferences: action.payload,
        preferencesLoading: false,
      };
    }
    case FETCH_POLLCARDS_FAILED: {
      return {
        ...state.booking,
        preferencesLoading: false,
        error: action.payload,
      };
    }
    case UPDATE_POLLCARDS_START: {
      return {
        ...state,
        error: null,
        updatedPreferencesLoading: true,
      };
    }
    case UPDATE_POLLCARDS_SUCCESS: {
      return {
        ...state,
        updatedPreferences: action.payload,
        error: null,
        updatedPreferencesLoading: false,
      };
    }
    case UPDATE_POLLCARDS_FAILED: {
      return {
        ...state,
        error: action.payload,
        updatedPreferencesLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
