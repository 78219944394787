import React from "react";
import { useBackdrop } from "./customHooks";

const RestaurantBooked = ({ onClose }) => {
  useBackdrop();

  return (
    <section className="buzz_modal">
      <div className="buzz_restro_booked">
        <button type="button" className="buzz_close_modal" onClick={onClose}>
          <img
            src="/images/buzz/buzz_close_white.png"
            srcset="/images/buzz/buzz_close_white@2x.png 2x"
            alt="close"
          />
        </button>
        <div className="buzz_restrobooked_img">
          <img
            src="/images/buzz/henrywithspoon.png"
            srcset="/images/buzz/henrywithspoon@2x.png 2x"
            alt="henry"
          />
        </div>
        <p className="to_congrat">Success!</p>
        <h3 className="buzz_center_heading">Restaurant booked!</h3>
        <p className="buzz_sm_text">
          You will receive text and email confirmation shortly.
        </p>
        <div className="buzz_bonus_coins">
          <b>You've earned</b>
          <span className="buzz_earnedcoins">
            <img
              src="/images/buzz/buzz_coin.png"
              srcset="/images/buzz/buzz_coin@2x.png 2x"
              alt="coins"
            />
            50 Coins!
          </span>
        </div>
      </div>
    </section>
  );
};

export default RestaurantBooked;
