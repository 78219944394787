import React, { useState } from "react";
import { useFormik } from "formik";

//custom components
import SpinnerWhite from "../../components/Spinner/SpinnerWhite";

//constants
import { loginSchema } from "../../validationSchema/login";

const HeaderSignInForm = ({ onTryLogin, loading }) => {
  const [showPassword, setShowPassword] = useState(false)
  const formik = useFormik({
    initialValues: {
      emailOrMobile: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: values => {
      onTryLogin(values);
    },
  });

  return (
    <form className="sign_form" onSubmit={formik.handleSubmit}>
      <div className="sing_input_outer">
        <div className={`wrap-input100 input-position input100 form-control plr-none ${formik.values.emailOrMobile === '' ? '' : 'has-val'} ${formik.touched.emailOrMobile && formik.errors.password ? "field-error" : ""}`}>
          <input
            // className={`form-control sing_input ${formik.touched.emailOrMobile && formik.errors.emailOrMobile ? "field-error" : ""}`}
            className={`input-box-flex ${formik.touched.emailOrMobile && formik.errors.emailOrMobile ? "font-red" : ""}`}
            id="emailOrMobile"
            name="emailOrMobile"
            type="text"
            placeholder="Email or mobile number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailOrMobile}
          />
          {formik.touched.emailOrMobile && formik.errors.emailOrMobile && <span className="icon-info-position"><img className="info-size" src="images/booking/icon_info.png" /></span>}
        </div>
        {formik.touched.emailOrMobile && formik.errors.emailOrMobile ? (
          <div className="invalid-feedback " style={{ display: "block" }}>{formik.errors.emailOrMobile}</div>
        ) : null}
      </div>
      <div className="sing_input_outer">
        <div className={`wrap-input100 input-position input100 form-control plr-none ${formik.values.password === '' ? '' : 'has-val'} ${formik.touched.password && formik.errors.password ? "field-error" : ""}`}>
          <span onClick={() => { setShowPassword(!showPassword) }} className="icon-password-position"><img className="eye-size" src={`${formik.touched.password && formik.errors.password ? "/images/booking/eye-red.png" : "/images/booking/eye.png"}`} alt="" /></span>
          <input
            // className={`form-control sing_input pl-30 ${formik.touched.password && formik.errors.password ? "field-error" : ""} `}
            className={`input-box-flex ${formik.touched.password && formik.errors.password ? "font-red" : ""}`}
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />

          {formik.touched.password && formik.errors.password && <span className="icon-info-position"><img className="info-size icon-postion" src="images/booking/icon_info.png" /></span>}
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className=""> <div className="invalid-feedback" style={{ display: "block" }}>{formik.errors.password}</div></div>
        ) : null}

      </div>
      <button className="btn btn-red width-240 mt-0 " type="submit">
        {loading
          ? <SpinnerWhite />
          : 'Log in'}
      </button>
    </form>
  );
};

export default HeaderSignInForm;