export const findColor = ({ offerValue, type }) => {
  let discountColor = '';
  if (type === 'percent') {
    if (offerValue <= 10) {
      discountColor = '#F18805';
    } else if (offerValue <= 20 && offerValue > 10) {
      discountColor = '#E67016';
    } else if (offerValue <= 30 && offerValue > 20) {
      discountColor = '#DB5826';
    } else if (offerValue <= 40 && offerValue > 30) {
      discountColor = '#DB5826';
    } else if (offerValue <= 50 && offerValue > 40) {
      discountColor = '#C42847';
    } else {
      discountColor = '#C42847';
    }
  }
  if (type === 'rewards') {
    if (offerValue <= 50) {
      discountColor = '#00BBFF';
    } else if (offerValue <= 150 && offerValue > 50) {
      discountColor = '#0063D0';
    } else if (offerValue <= 250 && offerValue > 150) {
      discountColor = '#1875D5';
    } else if (offerValue <= 350 && offerValue > 250) {
      discountColor = '#0063D0';
    } else if (offerValue <= 450 && offerValue > 350) {
      discountColor = '#2F2EAC';
    } else {
      discountColor = '#3B0B97';
    }
  }
  return discountColor;
}