import {
  FETCH_MENU_EDIT,
  FETCH_MENU_EDIT_SUCCESS,
  FETCH_MENU_EDIT_FAILED,
  FETCH_MENU_TYPE,
  FETCH_MENU_TYPE_SUCCESS,
  FETCH_MENU_TYPE_FAILED,
  FETCH_ARCHIVE_MENU,
  FETCH_ARCHIVE_MENU_SUCCESS,
  FETCH_ARCHIVE_MENU_FAILED,
  FETCH_ARCHIVE_MENU_CLEAR
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  menuTypeIsLoading: true,
  menuTypePayload: [],
  archivedMenuLoading: false,
  archivedMenuPayload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENU_EDIT: {
      return {...state, error: null, isLoading: true};
    }
    case FETCH_MENU_EDIT_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false
      };
    }
    case FETCH_MENU_EDIT_FAILED: {
      return {...state, payload: null, isLoading: false, error: action.payload};
    }
    case FETCH_MENU_TYPE: {
      return {...state, error: null, menuTypeIsLoading: true};
    }
    case FETCH_MENU_TYPE_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        error: null,
        menuTypePayload: data,
        menuTypeIsLoading: false
      };
    }
    case FETCH_MENU_TYPE_FAILED: {
      return {...state, menuTypePayload: [], menuTypeIsLoading: false, error: action.payload};
    }
    case FETCH_ARCHIVE_MENU: {
      return {...state, error: null, archivedMenuLoading: true};
    }
    case FETCH_ARCHIVE_MENU_SUCCESS: {
      const {data, currentPage, pageSize, totalItem, totalPage} = action.payload;
      if (currentPage !== state.currentPage) {
        return {
          ...state,
          error: null,
          archivedMenuPayload: state.archivedMenuPayload.concat(data),
          currentPage,
          pageSize,
          totalItem,
          totalPage,
          archivedMenuLoading: false
        };
      } else {
        return state;
      }
    }
    case FETCH_ARCHIVE_MENU_FAILED: {
      return {...state, archivedMenuPayload: null, archivedMenuLoading: false, error: action.payload};
    }
    case FETCH_ARCHIVE_MENU_CLEAR: {
      return {
        ...state,
        error: null,
        archivedMenuLoading: false,
        archivedMenuPayload: [],
        currentPage: 0,
        pageSize: null,
        totalItem: null,
        totalPage: null,
      };
    }
    default:
      return state
  }
};

export default reducer;
