import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

//custom components
import asyncComponent from "../../utils/asyncComponent";

const Dashboard = ({match}) => (
  <div>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/submit-booking`}/>
      <Route path={`${match.url}/submit-booking/:restaurantID`} exact component={asyncComponent(() => import('./BookingSubmit'))}/>
      <Route path={`${match.url}/submit-booking`} component={asyncComponent(() => import('./BookingSubmit'))}/>
      <Route path={`${match.url}/loading`} component={asyncComponent(() => import('./BookingLoading'))}/>
      <Route path={`${match.url}/confirm-booking`} component={asyncComponent(() => import('./BookingConfirm'))}/>
    </Switch>
  </div>
);

export default Dashboard;
