// Customizer const
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSED_NAV";
export const HIDE_FOOTER = "HIDE_FOOTER";
export const SHOW_FOOTER = "SHOW_FOOTER";
export const SHOW_TOAST_SETTINGS = "SHOW_TOAST_SETTINGS";
export const HIDE_TOAST_SETTINGS = "HIDE_TOAST_SETTINGS";

//Authentication
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const REGISTRATION_START = "REGISTRATION_START";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const CLEAR_REGISTRATION_SUCCESS = "CLEAR_REGISTRATION_SUCCESS";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";
export const FORGOT_PASSWORD_OTP_START = "FORGOT_PASSWORD_OTP_START";
export const FORGOT_PASSWORD_OTP_SUCCESS = "FORGOT_PASSWORD_OTP_SUCCESS";
export const FORGOT_PASSWORD_OTP_FAILED = "FORGOT_PASSWORD_OTP_FAILED";
export const FORGOT_PASSWORD_OTP_CLEAN = "FORGOT_PASSWORD_OTP_CLEAN";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const UPDATE_USER_COINS = "UPDATE_USER_COINS";

//Restaurant
export const FETCH_ALL_RESTAURANTS_START = "FETCH_ALL_RESTAURANTS_START";
export const FETCH_ALL_RESTAURANTS_SUCCESS = "FETCH_ALL_RESTAURANTS_SUCCESS";
export const FETCH_ALL_RESTAURANTS_FAILED = "FETCH_ALL_RESTAURANTS_FAILED";
export const SET_QUERY_STRING = "SET_QUERY_STRING";
export const REMOVE_QUERY_STRING = "REMOVE_QUERY_STRING";
export const FETCH_RESTAURANT_DETAILS_START = "FETCH_RESTAURANT_DETAILS_START";
export const FETCH_RESTAURANT_DETAILS_SUCCESS =
  "FETCH_RESTAURANT_DETAILS_SUCCESS";
export const FETCH_RESTAURANT_DETAILS_FAILED =
  "FETCH_RESTAURANT_DETAILS_FAILED";
export const FETCH_RESTAURANT_LOCATIONS_START =
  "FETCH_RESTAURANT_LOCATIONS_START";
export const FETCH_RESTAURANT_LOCATIONS_SUCCESS =
  "FETCH_RESTAURANT_LOCATIONS_SUCCESS";
export const FETCH_RESTAURANT_LOCATIONS_FAILED =
  "FETCH_RESTAURANT_LOCATIONS_FAILED";
export const CHANGE_MAP_VIEW = "CHANGE_MAP_VIEW";
export const SET_TOP_RESTAURANT_MODE = "SET_TOP_RESTAURANT_MODE";
export const REMOVE_TOP_RESTAURANT_MODE = "REMOVE_TOP_RESTAURANT_MODE";

//Restaurant Update
export const UPDATE_RESTAURANT_DETAILS_START =
  "UPDATE_RESTAURANT_DETAILS_START";
export const UPDATE_RESTAURANT_DETAILS_SUCCESS =
  "UPDATE_RESTAURANT_DETAILS_SUCCESS";
export const UPDATE_RESTAURANT_DETAILS_FAILED =
  "UPDATE_RESTAURANT_DETAILS_FAILED";
export const UPDATE_RESTAURANT_HYGIENE_START =
  "UPDATE_RESTAURANT_HYGIENE_START";
export const UPDATE_RESTAURANT_HYGIENE_SUCCESS =
  "UPDATE_RESTAURANT_HYGIENE_SUCCESS";
export const UPDATE_RESTAURANT_HYGIENE_FAILED =
  "UPDATE_RESTAURANT_HYGIENE_FAILED";
export const SET_UPDATE_RESTAURANT_STATUS = "SET_UPDATE_RESTAURANT_STATUS";
export const REMOVE_UPDATE_RESTAURANT_STATUS =
  "REMOVE_UPDATE_RESTAURANT_STATUS";
export const UPDATE_RESTAURANT_DATA = "UPDATE_RESTAURANT_DATA";
export const UPDATE_RESTAURANT_COINS = "UPDATE_RESTAURANT_COINS";

//User
export const FETCH_TOP_LOCATIONS_START = "FETCH_TOP_LOCATIONS_START";
export const FETCH_TOP_LOCATIONS_SUCCESS = "FETCH_TOP_LOCATIONS_SUCCESS";
export const FETCH_TOP_LOCATIONS_FAILED = "FETCH_TOP_LOCATIONS_FAILED";
export const FETCH_RECENT_LOCATIONS_START = "FETCH_RECENT_LOCATIONS_START";
export const FETCH_RECENT_LOCATIONS_SUCCESS = "FETCH_RECENT_LOCATIONS_SUCCESS";
export const FETCH_RECENT_LOCATIONS_FAILED = "FETCH_RECENT_LOCATIONS_FAILED";
export const CLEAR_RECENT_LOCATIONS_START = "CLEAR_RECENT_LOCATIONS_START";
export const CLEAR_RECENT_LOCATIONS_SUCCESS = "CLEAR_RECENT_LOCATIONS_SUCCESS";
export const CLEAR_RECENT_LOCATIONS_FAILED = "CLEAR_RECENT_LOCATIONS_FAILED";
export const FETCH_SEARCHED_ITEMS_START = "FETCH_SEARCHED_ITEMS_START";
export const FETCH_SEARCHED_ITEMS_SUCCESS = "FETCH_SEARCHED_ITEMS_SUCCESS";
export const FETCH_SEARCHED_ITEMS_FAILED = "FETCH_SEARCHED_ITEMS_FAILED";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";
export const UPDATE_SEARCH_PREFES = "UPDATE_SEARCH_PREFES";
export const UPDATE_SEARCH_PREFES_SUCCESS = "UPDATE_SEARCH_PREFES_SUCCESS";
export const UPDATE_SEARCH_PREFES_FAILED = "UPDATE_SEARCH_PREFES_FAILED";
export const FETCH_SEARCH_PREFES = "FETCH_SEARCH_PREFES";
export const FETCH_SEARCH_PREFES_SUCCESS = "FETCH_SEARCH_PREFES_SUCCESS";
export const FETCH_SEARCH_PREFES_FAILED = "FETCH_SEARCH_PREFES_FAILED";
export const UPDATE_PROFILE_PIC = "UPDATE_PROFILE_PIC";
export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE_PIC_FAILED = "UPDATE_PROFILE_PIC_FAILED";
export const FETCH_PROFILE_STRENGTH = "FETCH_PROFILE_STRENGTH";
export const FETCH_PROFILE_STRENGTH_SUCCESS = "FETCH_PROFILE_STRENGTH_SUCCESS";
export const FETCH_PROFILE_STRENGTH_FAILED = "FETCH_PROFILE_STRENGTH_FAILED";
export const FETCH_PAID_COINS_START = "FETCH_PAID_COINS_START";
export const FETCH_PAID_COINS_SUCCESS = "FETCH_PAID_COINS_SUCCESS";
export const FETCH_PAID_COINS_FAILED = "FETCH_PAID_COINS_FAILED";
export const FETCH_USER_COIN_BUNDLES_START = "FETCH_USER_COIN_BUNDLES_START";
export const FETCH_USER_COIN_BUNDLES_SUCCESS =
  "FETCH_USER_COIN_BUNDLES_SUCCESS";
export const FETCH_USER_COIN_BUNDLES_FAILED = "FETCH_USER_COIN_BUNDLES_FAILED";
export const FETCH_PENDING_REVIEWS_START = "FETCH_PENDING_REVIEWS_START";
export const FETCH_PENDING_REVIEWS_SUCCESS = "FETCH_PENDING_REVIEWS_SUCCESS";
export const FETCH_PENDING_REVIEWS_FAILED = "FETCH_PENDING_REVIEWS_FAILED";
export const FETCH_PENDING_PREFS_START = "FETCH_PENDING_PREFS_START";
export const FETCH_PENDING_PREFS_SUCCESS = "FETCH_PENDING_PREFS_SUCCESS";
export const FETCH_PENDING_PREFS_FAILED = "FETCH_PENDING_PREFS_FAILED";
export const REMOVE_PENDING_PREF = "REMOVE_PENDING_PREF";
export const UPDATE_PENDING_REVIEW_DATA = "UPDATE_PENDING_REVIEW_DATA";
export const FETCH_POLLCARDS_SUCCESS = "FETCH_POLLCARDS_CARD_SUCCESS";
export const FETCH_POLLCARDS_FAILED = "FETCH_POLLCARDS_CARL_FAILED";
export const FETCH_POLLCARDS_START = " FETCH_POLLCARDS_CARD_START";
export const UPDATE_POLLCARDS_START = "UPDATE_POLLCARDS_START";
export const UPDATE_POLLCARDS_SUCCESS = "UPDATE_POLLCARDS_SUCCESS";
export const UPDATE_POLLCARDS_FAILED = "UPDATE_POLLCARDS_FAILED";

//Cuisines
export const FETCH_CUISINES_START = "FETCH_CUISINES_START";
export const FETCH_CUISINES_SUCCESS = "FETCH_CUISINES_SUCCESS";
export const FETCH_FILTERED_CUISINES_SUCCESS =
  "FETCH_FILTERED_CUISINES_SUCCESS";
export const FETCH_CUISINES_FAILED = "FETCH_CUISINES_FAILED";

//Tags
export const FETCH_TAGS_START = "FETCH_TAGS_START";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILED = "FETCH_TAGS_FAILED";
export const FETCH_TAGS_CATEGORY_START = "FETCH_TAGS_CATEGORY_START";
export const FETCH_TAGS_CATEGORY_SUCCESS = "FETCH_TAGS_CATEGORY_SUCCESS";
export const FETCH_TAGS_CATEGORY_FAILED = "FETCH_TAGS_CATEGORY_FAILED";

//Reviews
export const ADD_REVIEW_START = "ADD_REVIEW_START";
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";
export const ADD_REVIEW_FAILED = "ADD_REVIEW_FAILED";

//Top Locations
export const FETCH_TOP_RESTAURANTS = "FETCH_TOP_RESTAURANTS";
export const FETCH_TOP_RESTAURANTS_SUCCESS = "FETCH_TOP_RESTAURANTS_SUCCESS";
export const FETCH_TOP_RESTAURANTS_FAILED = "FETCH_TOP_RESTAURANTS_FAILED";

//Menus
export const FETCH_MENU_EDIT = "FETCH_MENU_EDIT";
export const FETCH_MENU_EDIT_SUCCESS = "FETCH_MENU_EDIT_SUCCESS";
export const FETCH_MENU_EDIT_FAILED = "FETCH_MENU_EDIT_FAILED";
export const FETCH_MENU_TYPE = "FETCH_MENU_TYPE";
export const FETCH_MENU_TYPE_SUCCESS = "FETCH_MENU_TYPE_SUCCESS";
export const FETCH_MENU_TYPE_FAILED = "FETCH_MENU_TYPE_FAILED";
export const FETCH_ARCHIVE_MENU = "FETCH_ARCHIVE_MENU";
export const FETCH_ARCHIVE_MENU_SUCCESS = "FETCH_ARCHIVE_MENU_SUCCESS";
export const FETCH_ARCHIVE_MENU_FAILED = "FETCH_ARCHIVE_MENU_FAILED";
export const FETCH_ARCHIVE_MENU_CLEAR = "FETCH_ARCHIVE_MENU_CLEAR";

//Booking
export const SAVE_BOOKING_DATA = "SAVE_BOOKING_DATA";
export const UPDATE_BOOKING_DATA = "UPDATE_BOOKING_DATA";
export const UPDATE_BOOKING_DATA_SUCCESS = "UPDATE_BOOKING_DATA_SUCCESS";
export const UPDATE_BOOKING_DATA_FAILED = "UPDATE_BOOKING_DATA_FAILED";
export const GET_BOOKING_DATA = "GET_BOOKING_DATA";
export const GET_BOOKING_DATA_SUCCESS = "GET_BOOKING_DATA_SUCCESS";
export const GET_BOOKING_DATA_FAILED = "GET_BOOKING_DATA_FAILED";
export const CLEAR_CONFIRMED_BOOKING_DATA = "CLEAR_CONFIRMED_BOOKING_DATA";
export const SET_CONFIRMED_BOOKING_DATA = "SET_CONFIRMED_BOOKING_DATA";
export const RESET_PAGING = "RESET_PAGING";
export const UPDATE_BOOKING_REVIEW_DATA = "UPDATE_BOOKING_REVIEW_DATA";
export const CHANGE_BOOKING_STAGE = "CHANGE_BOOKING_STAGE";
export const CHANGE_BOOKING_STAGE_SUCCESS = "CHANGE_BOOKING_STAGE_SUCCESS";
export const CHANGE_BOOKING_STAGE_FAILED = "CHANGE_BOOKING_STAGE_FAILED";
export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_FAILED = "CANCEL_BOOKING_FAILED";

//Tags
export const FETCH_LISTS_START = "FETCH_LISTS_START";
export const FETCH_LISTS_SUCCESS = "FETCH_LISTS_SUCCESS";
export const FETCH_LISTS_FAILED = "FETCH_LISTS_FAILED";
export const MANIPULATE_LISTS_START = "MANIPULATE_LISTS_START";
export const MANIPULATE_LISTS_SUCCESS = "MANIPULATE_LISTS_SUCCESS";
export const MANIPULATE_LISTS_FAILED = "MANIPULATE_LISTS_FAILED";
export const FETCH_LISTS_RESTAURANTS_START = "FETCH_LISTS_RESTAURANTS_START";
export const FETCH_LISTS_RESTAURANTS_SUCCESS =
  "FETCH_LISTS_RESTAURANTS_SUCCESS";
export const FETCH_LISTS_RESTAURANTS_FAILED = "FETCH_LISTS_RESTAURANTS_FAILED";

//Logger
export const LOG_INTERACTION = "LOG_INTERACTION";
export const LOG_INTERACTION_SUCCESS = "LOG_INTERACTION_SUCCESS";
export const LOG_INTERACTION_FAILURE = "LOG_INTERACTION_FAILURE";

//User Suggestion
export const ADD_SUGGESTION_START = "ADD_SUGGESTION_START";
export const ADD_SUGGESTION_SUCCESS = "ADD_SUGGESTION_SUCCESS";
export const ADD_SUGGESTION_FAILED = "ADD_SUGGESTION_FAILED";
export const REMOVE_SUGGESTION_STATUS = "REMOVE_SUGGESTION_STATUS";
export const SET_SUGGESTION_STATUS = "SET_SUGGESTION_STATUS";
export const FETCH_PENDING_SUGGESTIONS_START =
  "FETCH_PENDING_SUGGESTIONS_START";
export const FETCH_PENDING_SUGGESTIONS_SUCCESS =
  "FETCH_PENDING_SUGGESTIONS_SUCCESS";
export const FETCH_PENDING_SUGGESTIONS_FAILED =
  "FETCH_PENDING_SUGGESTIONS_FAILED";

//Rewards
export const FETCH_RESTAURANT_REWARDS_START = "FETCH_RESTAURANT_REWARDS_START";
export const FETCH_RESTAURANT_REWARDS_SUCCESS =
  "FETCH_RESTAURANT_REWARDS_SUCCESS";
export const FETCH_RESTAURANT_REWARDS_FAILED =
  "FETCH_RESTAURANT_REWARDS_FAILED";
export const FETCH_RESTAURANT_COINS_START = "FETCH_RESTAURANT_COINS_START";
export const FETCH_RESTAURANT_COINS_SUCCESS = "FETCH_RESTAURANT_COINS_SUCCESS";
export const FETCH_RESTAURANT_COINS_FAILED = "FETCH_RESTAURANT_COINS_FAILED";
export const PAY_REWARDS_START = "PAY_REWARDS_START";
export const PAY_REWARDS_SUCCESS = "PAY_REWARDS_SUCCESS";
export const PAY_REWARDS_FAILED = "PAY_REWARDS_FAILED";
export const PAY_RESTAURANT_REWARDS_START = "PAY_RESTAURANT_REWARDS_START";
export const PAY_RESTAURANT_REWARDS_SUCCESS = "PAY_RESTAURANT_REWARDS_SUCCESS";
export const PAY_RESTAURANT_REWARDS_FAILED = "PAY_RESTAURANT_REWARDS_FAILED";
export const FETCH_COIN_REWARDS_START = "FETCH_COIN_REWARDS_START";
export const FETCH_COIN_REWARDS_SUCCESS = "FETCH_COIN_REWARDS_SUCCESS";
export const FETCH_COIN_REWARDS_FAILED = "FETCH_COIN_REWARDS_FAILED";
export const FETCH_REWARDS_START = "FETCH_REWARDS_START";
export const FETCH_REWARDS_SUCCESS = "FETCH_REWARDS_SUCCESS";
export const FETCH_REWARDS_FAILED = "FETCH_REWARDS_FAILED";

//Offers
export const FETCH_RESTAURANT_DETAIL_OFFERS_START =
  "FETCH_RESTAURANT_DETAIL_OFFERS_START";
export const FETCH_RESTAURANT_DETAIL_OFFERS_SUCCESS =
  "FETCH_RESTAURANT_DETAIL_OFFERS_SUCCESS";
export const FETCH_RESTAURANT_DETAIL_OFFERS_FAILED =
  "FETCH_RESTAURANT_DETAIL_OFFERS_FAILED";
export const FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_START =
  "FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_START";
export const FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_SUCCESS =
  "FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_SUCCESS";
export const FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_FAILED =
  "FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_FAILED";
export const FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_START =
  "FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_START";
export const FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_SUCCESS =
  "FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_SUCCESS";
export const FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_FAILED =
  "FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_FAILED";
export const ADD_OFFER_START = "ADD_OFFER_START";
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS";
export const ADD_OFFER_FAILED = "ADD_OFFER_FAILED";
export const UPDATE_OFFER_START = "UPDATE_OFFER_START";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILED = "UPDATE_OFFER_FAILED";
export const DELETE_OFFER_START = "DELETE_OFFER_START";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILED = "DELETE_OFFER_FAILED";
export const FETCH_BOOKING_OFFERS_EXCEPTION = "FETCH_BOOKING_OFFERS_EXCEPTION";
export const FETCH_BOOKING_OFFERS_EXCEPTION_SUCCESS =
  "FETCH_BOOKING_OFFERS_EXCEPTION_SUCCESS";
export const FETCH_BOOKING_OFFERS_EXCEPTION_FAILED =
  "FETCH_BOOKING_OFFERS_EXCEPTION_FAILED";

export const FETCH_USER_CHEST_OFFERS_START = "FETCH_USER_CHEST_OFFERS_START";
export const FETCH_USER_CHEST_OFFERS_SUCCESS =
  "FETCH_USER_CHEST_OFFERS_SUCCESS";
export const FETCH_USER_CHEST_OFFERS_FAILED = "FETCH_USER_CHEST_OFFERS_FAILED";
export const FETCH_DAILY_CHEST_OFFERS_START = "FETCH_DAILY_CHEST_OFFERS_START";
export const FETCH_DAILY_CHEST_OFFERS_SUCCESS =
  "FETCH_DAILY_CHEST_OFFERS_SUCCESS";
export const FETCH_DAILY_CHEST_OFFERS_FAILED =
  "FETCH_DAILY_CHEST_OFFERS_FAILED";
export const ADD_CHEST_OFFER_START = "ADD_CHEST_OFFER_START";
export const ADD_CHEST_OFFER_SUCCESS = "ADD_CHEST_OFFER_SUCCESS";
export const ADD_CHEST_OFFER_FAILED = "ADD_CHEST_OFFER_FAILED";
export const FETCH_OFFER_START = "FETCH_OFFER_START";
export const FETCH_OFFER_SUCCESS = "FETCH_OFFER_SUCCESS";
export const FETCH_OFFER_FAILED = "FETCH_OFFER_FAILED";
export const FETCH_OFFER_USER_MAY_LIKE_START =
  "FETCH_OFFER_USER_MAY_LIKE_START";
export const FETCH_OFFER_USER_MAY_LIKE_SUCCESS =
  "FETCH_OFFER_USER_MAY_LIKE_SUCCESS";
export const FETCH_OFFER_USER_MAY_LIKE_FAILED =
  "FETCH_OFFER_USER_MAY_LIKE_FAILED";

//Guides
export const FETCH_GUIDES_START = "FETCH_GUIDES_START";
export const FETCH_GUIDES_SUCCESS = "FETCH_GUIDES_SUCCESS";
export const FETCH_GUIDES_FAILED = "FETCH_GUIDES_FAILED";
export const FETCH_GUIDE_DETAILS_START = "FETCH_GUIDE_DETAILS_START";
export const FETCH_GUIDE_DETAILS_SUCCESS = "FETCH_GUIDE_DETAILS_SUCCESS";
export const FETCH_GUIDE_DETAILS_FAILED = "FETCH_GUIDE_DETAILS_FAILED";
export const FETCH_GUIDE_RESTAURANTS_START = "FETCH_GUIDE_RESTAURANTS_START";
export const FETCH_GUIDE_RESTAURANTS_SUCCESS =
  "FETCH_GUIDE_RESTAURANTS_SUCCESS";
export const FETCH_GUIDE_RESTAURANTS_FAILED = "FETCH_GUIDE_RESTAURANTS_FAILED";
export const FETCH_GUIDE_MAP_RESTAURANTS_START =
  "FETCH_GUIDE_MAP_RESTAURANTS_START";
export const FETCH_GUIDE_MAP_RESTAURANTS_SUCCESS =
  "FETCH_GUIDE_MAP_RESTAURANTS_SUCCESS";
export const FETCH_GUIDE_MAP_RESTAURANTS_FAILED =
  "FETCH_GUIDE_MAP_RESTAURANTS_FAILED";
export const GUIDE_PAGING_RESET = "GUIDE_PAGING_RESET";
export const GUIDE_DETAILS_RESET = "GUIDE_DETAILS_RESET";
export const FETCH_GUIDE_RESTAURANTS_MAP_POINTER_START =
  "FETCH_GUIDE_RESTAURANTS_MAP_POINTER_START";
export const FETCH_GUIDE_RESTAURANTS_MAP_POINTER_SUCCESS =
  "FETCH_GUIDE_RESTAURANTS_MAP_POINTER_SUCCESS";
export const FETCH_GUIDE_RESTAURANTS_MAP_POINTER_FAILED =
  "FETCH_GUIDE_RESTAURANTS_MAP_POINTER_FAILED";

//Report issue
export const ADD_REPORT_ISSUE_START = "ADD_REPORT_ISSUE_START";
export const ADD_REPORT_ISSUE_SUCCESS = "ADD_REPORT_ISSUE_SUCCESS";
export const ADD_REPORT_ISSUE_FAILED = "ADD_REPORT_ISSUE_FAILED";

//Restaurant admin
export const CLAIM_RESTAURANT_START = "CLAIM_RESTAURANT_START";
export const CLAIM_RESTAURANT_SUCCESS = "CLAIM_RESTAURANT_SUCCESS";
export const CLAIM_RESTAURANT_FAILED = "CLAIM_RESTAURANT_FAILED";
export const FETCH_LISTS_RESTAURANTS_AFFILIATED_START =
  "FETCH_LISTS_RESTAURANTS_AFFILIATED_START";
export const FETCH_LISTS_RESTAURANTS_AFFILIATED_SUCCESS =
  "FETCH_LISTS_RESTAURANTS_AFFILIATED_SUCCESS";
export const FETCH_LISTS_RESTAURANTS_AFFILIATED_FAILED =
  "FETCH_LISTS_RESTAURANTS_AFFILIATED_FAILED";
export const CHECK_RESTAURANT_ADMIN_START = "CHECK_RESTAURANT_ADMIN_START";
export const CHECK_RESTAURANT_ADMIN_SUCCESS = "CHECK_RESTAURANT_ADMIN_SUCCESS";
export const CHECK_RESTAURANT_ADMIN_FAILED = "CHECK_RESTAURANT_ADMIN_FAILED";
export const UPDATE_RESTAURANT_ADMIN_START = "UPDATE_RESTAURANT_ADMIN_START";
export const UPDATE_RESTAURANT_ADMIN_SUCCESS =
  "UPDATE_RESTAURANT_ADMIN_SUCCESS";
export const UPDATE_RESTAURANT_ADMIN_FAILED = "UPDATE_RESTAURANT_ADMIN_FAILED";
export const SET_UPDATE_RESTAURANT_ADMIN_STATUS =
  "SET_UPDATE_RESTAURANT_ADMIN_STATUS";
export const REMOVE_UPDATE_RESTAURANT_ADMIN_STATUS =
  "REMOVE_UPDATE_RESTAURANT_STATUS";
export const LIST_RESTAURANT_ADMIN_BOOKING_START =
  "LIST_RESTAURANT_ADMIN_BOOKING_START";
export const LIST_RESTAURANT_ADMIN_BOOKING_SUCCESS =
  "LIST_RESTAURANT_ADMIN_BOOKING_SUCCESS";
export const LIST_RESTAURANT_ADMIN_BOOKING_FAILED =
  "LIST_RESTAURANT_ADMIN_BOOKING_FAILED";
export const LIST_RESTAURANT_ADMIN_ALL_BOOKING_START =
  "LIST_RESTAURANT_ADMIN_ALL_BOOKING_START";
export const LIST_RESTAURANT_ADMIN_ALL_BOOKING_SUCCESS =
  "LIST_RESTAURANT_ADMIN_ALL_BOOKING_SUCCESS";
export const LIST_RESTAURANT_ADMIN_ALL_BOOKING_FAILED =
  "LIST_RESTAURANT_ADMIN_ALL_BOOKING_FAILED";
export const REJECT_RESTAURANT_ADMIN_BOOKING_START =
  "REJECT_RESTAURANT_ADMIN_BOOKING_START";
export const REJECT_RESTAURANT_ADMIN_BOOKING_SUCCESS =
  "REJECT_RESTAURANT_ADMIN_BOOKING_SUCCESS";
export const REJECT_RESTAURANT_ADMIN_BOOKING_FAILED =
  "REJECT_RESTAURANT_ADMIN_BOOKING_FAILED";
export const ACCEPT_RESTAURANT_ADMIN_BOOKING_START =
  "ACCEPT_RESTAURANT_ADMIN_BOOKING_START";
export const ACCEPT_RESTAURANT_ADMIN_BOOKING_SUCCESS =
  "ACCEPT_RESTAURANT_ADMIN_BOOKING_SUCCESS";
export const ACCEPT_RESTAURANT_ADMIN_BOOKING_FAILED =
  "ACCEPT_RESTAURANT_ADMIN_BOOKING_FAILED";
export const CONFIRM_RESTAURANT_ADMIN_BOOKING_START =
  "CONFIRM_RESTAURANT_ADMIN_BOOKING_START";
export const CONFIRM_RESTAURANT_ADMIN_BOOKING_SUCCESS =
  "CONFIRM_RESTAURANT_ADMIN_BOOKING_SUCCESS";
export const CONFIRM_RESTAURANT_ADMIN_BOOKING_FAILED =
  "CONFIRM_RESTAURANT_ADMIN_BOOKING_FAILED";
export const FETCH_RESTAURANT_COIN_BUNDLE_START =
  "FETCH_RESTAURANT_COIN_BUNDLE_START";
export const FETCH_RESTAURANT_COIN_BUNDLE_SUCCESS =
  "FETCH_RESTAURANT_COIN_BUNDLE_SUCCESS";
export const FETCH_RESTAURANT_COIN_BUNDLE_FAILED =
  "FETCH_RESTAURANT_COIN_BUNDLE_FAILED";
export const FETCH_RESTAURANT_COIN_REWARDS_SETTING_START =
  "FETCH_RESTAURANT_COIN_REWARDS_SETTING_START";
export const FETCH_RESTAURANT_COIN_REWARDS_SETTING_SUCCESS =
  "FETCH_RESTAURANT_COIN_REWARDS_SETTING_SUCCESS";
export const FETCH_RESTAURANT_COIN_REWARDS_SETTING_FAILED =
  "FETCH_RESTAURANT_COIN_REWARDS_SETTING_FAILED";
export const FETCH_PAGE_LEVEL_START = "FETCH_PAGE_LEVEL_START";
export const FETCH_PAGE_LEVEL_SUCCESS = "FETCH_PAGE_LEVEL_SUCCESS";
export const FETCH_PAGE_LEVEL_FAILED = "FETCH_PAGE_LEVEL_FAILED";

//Inbox for restaurant admin
//Moderation
export const FETCH_MODERATION_LIST_START = "FETCH_MODERATION_LIST_START";
export const FETCH_MODERATION_LIST_SUCCESS = "FETCH_MODERATION_LIST_SUCCESS";
export const FETCH_MODERATION_LIST_FAILED = "FETCH_MODERATION_LIST_FAILED";
export const UPDATE_MODERATION_STATUS_START = "UPDATE_MODERATION_STATUS_START";
export const UPDATE_MODERATION_STATUS_SUCCESS =
  "UPDATE_MODERATION_STATUS_SUCCESS";
export const UPDATE_MODERATION_STATUS_FAILED =
  "UPDATE_MODERATION_STATUS_FAILED";

//News
export const FETCH_NEWS_LIST_START = "FETCH_NEWS_LIST_START";
export const FETCH_NEWS_LIST_SUCCESS = "FETCH_NEWS_LIST_SUCCESS";
export const FETCH_NEWS_LIST_FAILED = "FETCH_NEWS_LIST_FAILED";
export const UPDATE_NEWS_STATUS_START = "UPDATE_NEWS_STATUS_START";
export const UPDATE_NEWS_STATUS_SUCCESS = "UPDATE_NEWS_STATUS_SUCCESS";
export const UPDATE_NEWS_STATUS_FAILED = "UPDATE_NEWS_STATUS_FAILED";

//Notification
export const FETCH_NOTIFICATION_START = "FETCH_NOTIFICATION_START";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAILED = "FETCH_NOTIFICATION_FAILED";
export const UPDATE_READ_NOTIFICATION_START = "UPDATE_READ_NOTIFICATION_START";
export const UPDATE_READ_NOTIFICATION_SUCCESS =
  "UPDATE_READ_NOTIFICATION_SUCCESS";
export const UPDATE_READ_NOTIFICATION_FAILED =
  "UPDATE_READ_NOTIFICATION_FAILED";

//Inbox
export const FETCH_INBOX_COUNT_START = "FETCH_INBOX_COUNT_START";
export const FETCH_INBOX_COUNT_SUCCESS = "FETCH_INBOX_COUNT_SUCCESS";
export const FETCH_INBOX_COUNT_FAILED = "FETCH_INBOX_COUNT_FAILED";

//Account
export const FETCH_ADMIN_USERS = "FETCH_ADMIN_USERS";
export const FETCH_ADMIN_USERS_SUCCESS = "FETCH_ADMIN_USERS_SUCCESS";
export const FETCH_ADMIN_USERS_FAILED = "FETCH_ADMIN_USERS_FAILED";
export const UPDATE_USER_ACCESS_TYPE = "UPDATE_USER_ACCESS_TYPE";
export const UPDATE_USER_ACCESS_TYPE_SUCCESS =
  "UPDATE_USER_ACCESS_TYPE_SUCCESS";
export const UPDATE_USER_ACCESS_TYPE_FAILED = "UPDATE_USER_ACCESS_TYPE_FAILED";
export const REMOVE_RESTAURANT_USER = "REMOVE_RESTAURANT_USER";
export const REMOVE_RESTAURANT_USER_SUCCESS = "REMOVE_RESTAURANT_USER_SUCCESS";
export const REMOVE_RESTAURANT_USER_FAILED = "REMOVE_RESTAURANT_USER_FAILED";

//Coins setting for admin
export const FETCH_RESTAURANT_COINS_SETTING_START =
  "FETCH_RESTAURANT_COINS_SETTING_START";
export const FETCH_RESTAURANT_COINS_SETTING_SUCCESS =
  "FETCH_RESTAURANT_COINS_SETTING_SUCCESS";
export const FETCH_RESTAURANT_COINS_SETTING_FAILED =
  "FETCH_RESTAURANT_COINS_SETTING_FAILED";

//Payments
export const FETCH_PAYMENT_METHODS_START = "FETCH_PAYMENT_METHODS_START";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILED = "FETCH_PAYMENT_METHODS_FAILED";

//invoice
export const FETCH_INVOICE_START = "FETCH_INVOICE_START";
export const FETCH_INVOICE_SUCCESS = "FETCH_INVOICE_SUCCESS";
export const FETCH_INVOICE_FAILED = "FETCH_INVOICE_FAILED";

//Feeds
export const FETCH_FEEDS_START = "FETCH_FEEDS_START";
export const FETCH_FEEDS_SUCCESS = "FETCH_FEEDS_SUCCESS";
export const FETCH_FEEDS_FAILED = "FETCH_FEEDS_FAILED";
export const RESET_FEED_CURRENT_PAGE = "RESET_FEED_CURRENT_PAGE";
export const UPDATE_FEED_START = "UPDATE_FEED_START";
export const UPDATE_FEED_SUCCESS = "UPDATE_FEED_SUCCESS";
export const UPDATE_FEED_FAILED = "UPDATE_FEED_FAILED";
export const DELETE_FEED_START = "DELETE_FEED_START";
export const DELETE_FEED_SUCCESS = "DELETE_FEED_SUCCESS";
export const DELETE_FEED_FAILED = "DELETE_FEED_FAILED";
export const FETCH_FEEDS_COUNT_START = "FETCH_FEEDS_COUNT_START";
export const FETCH_FEEDS_COUNT_SUCCESS = "FETCH_FEEDS_COUNT_SUCCESS";
export const FETCH_FEEDS_COUNT_FAILED = "FETCH_FEEDS_COUNT_FAILED";

//Buzz screens
export const SHOW_BUZZ_SCREEN = "SHOW_BUZZ_SCREEN";
export const HIDE_BUZZ_SCREEN = "HIDE_BUZZ_SCREEN";

//Contact
export const ADD_CONTACT_START = "ADD_CONTACT_START";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILED = "ADD_CONTACT_FAILED";
export const ADD_ENQUIRY_START = "ADD_ENQUIRY_START";
export const ADD_ENQUIRY_SUCCESS = "ADD_ENQUIRY_SUCCESS";
export const ADD_ENQUIRY_FAILED = "ADD_ENQUIRY_FAILED";

//Dashboard
export const FETCH_DASHBOARD_START = "FETCH_DASHBOARD_START";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILED = "FETCH_DASHBOARD_FAILED";
//Achievements
export const FETCH_ACHIEVEMENTS_START = "FETCH_ACHIEVEMENTS_START";
export const FETCH_ACHIEVEMENTS_SUCCESS = "FETCH_ACHIEVEMENTS_SUCCESS";
export const FETCH_ACHIEVEMENTS_FAILED = "FETCH_ACHIEVEMENTS_FAILED";

//Top 3 banner restaurants
export const FETCH_TOP3_BANNER_RESTAURANTS = "FETCH_TOP3_BANNER_RESTAURANTS";
export const FETCH_TOP3_BANNER_RESTAURANTS_SUCCESS =
  "FETCH_TOP3_BANNER_RESTAURANTS_SUCCESS";
export const FETCH_TOP3_BANNER_RESTAURANTS_FAILED =
  "FETCH_TOP3_BANNER_RESTAURANTS_FAILED";

//Top banner cuisines
export const FETCH_TOP_BANNER_CUISINES = "FETCH_TOP_BANNER_CUISINES";
export const FETCH_TOP_BANNER_CUISINES_SUCCESS =
  "FETCH_TOP_BANNER_CUISINES_SUCCESS";
export const FETCH_TOP_BANNER_CUISINES_FAILED =
  "FETCH_TOP_BANNER_CUISINES_FAILED";

//Inspiration Tags
export const FETCH_INSPIRATION_TAGS = "FETCH_INSPIRATION_TAGS";
export const FETCH_INSPIRATION_TAGS_SUCCESS = "FETCH_INSPIRATION_TAGS_SUCCESS";
export const FETCH_INSPIRATION_TAGS_FAILED = "FETCH_INSPIRATION_TAGS_FAILED";

//Ads Tags
export const CREATE_AD = "CREATE_AD";
export const CREATE_AD_SUCCESS = "CREATE_AD_SUCCESS";
export const CREATE_AD_FAILURE = "CREATE_AD_FAILURE";
export const FETCH_ADS = "FETCH_ADS";
export const FETCH_ADS_SUCCESS = "FETCH_ADS_SUCCESS";
export const FETCH_ADS_FAILURE = "FETCH_ADS_FAILURE";
export const GET_EST_PRICE = "GET_EST_PRICE";
export const GET_EST_PRICE_SUCCESS = "GET_EST_PRICE_SUCCESS";
export const GET_EST_FAILURE = "GET_EST_FAILURE";

// Shared List
export const FETCH_SHARED_TOKEN_START = "FETCH_SHARED_TOKEN_START";
export const FETCH_SHARED_TOKEN_SUCCESS = "FETCH_SHARED_TOKEN_SUCCESS";
export const FETCH_SHARED_TOKEN_FAILURE = "FETCH_SHARED_TOKEN_FAILURE";
export const FETCH_SHAREDLIST_START = "FETCH_SHAREDLIST_START";
export const FETCH_SHAREDLIST_SUCCESS = "FETCH_SHAREDLIST_SUCCESS";
export const FETCH_SHAREDLIST_FAILURE = "FETCH_SHAREDLIST_FAILURE";
