export const breadcrumbs = [
  {
    category: "home",
    label: "Home",
    link: "/home",
    pages: [
      { name: "register", label: "Register" },
      { name: "mobile-register", label: "Register" },
      { name: "signin", label: "Sign in" },
      { name: "mobile-sign-in", label: "Sign in" },
      { name: "change-password", label: "Change password" },
      { name: "mobile-reset-password", label: "Reset password" },
      { name: "contact-us", label: "Contact" },
      { name: "guest-search-result", label: "Search" },
      { name: "guides", label: "Guides" },
      { name: "benefits", label: "Benefits" },
      { name: "restaurant-closed", label: "Restaurant closed" },
      { name: "registration-benefits", label: "Registration benefits" },
      { name: "list-a-restaurant", label: "List a restaurant" },
      { name: "terms-conditions", label: "Terms & Conditions" },
      { name: "privacy-policy", label: "Privacy Policy" },
      { name: "sitemap", label: "Sitemap" },
    ]
  },
  {
    category: "register",
    label: "Register",
    link: "/register",
    pages: [
    ]
  },
  {
    category: "dashboard",
    label: "Account",
    link: "/dashboard/my-dashboard",
    pages: [
      { name: "my-dashboard", label: "Dashboard" },
      { name: "my-bookings", label: "Bookings" },
      { name: "my-offers", label: "Offers" },
      { name: "my-lists", label: "Lists" },
      { name: "my-coins", label: "Coins" },
      { name: "my-achievements", label: "Achievements" },
      { name: "my-pages", label: "Pages" },
      { name: "my-profile", label: "Profile" },
      { name: "my-help", label: "Help" },
    ]
  },
  {
    category: "restaurant-admin",
    label: "Account",
    link: "/dashboard/my-dashboard",
    pages: [
      {
        name: "page", label: "Page",
      },
      { name: "bookings", label: "Bookings" },
      { name: "coins", label: "Coins" },
      { name: "inbox", label: "Inbox" },
      { name: "account", label: "Account" }
    ]
  },
  {
    category: "restaurant-details",
    label: ":id",
    link: "",
    pages: [
      { name: "guest-search-result", label: "Search" },
      { name: "guides", label: "Guides" },
      { name: "my-lists", label: "My Lists" },
    ]
  },
  {
    category: "restaurant-edit-details",
    label: "Edit",
    link: "",
    pages: []
  },
  {
    category: "restaurant-menu",
    label: "Menu",
    link: "",
    pages: [

    ]
  },
];


