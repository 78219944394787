import {applyMiddleware, createStore} from "redux";
import reducers from "./reducers";
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import * as actionCreators from "./actions/index";

const middlewares = [thunkMiddleware];
const composeEnhancers = composeWithDevTools({
  actionCreators,
  trace: true,
  traceLimit: 25,
});

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
