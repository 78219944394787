import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

//images and icons
import currencyIcon from "../../assets/images/icon_currency.png";
import currencyIconretina from "../../assets/images/icon_currency@2x.png";

import userIcon from "../../assets/images/guest-user-avatar.png";
import userIconTrans from "../../assets/images/guest-user-transparent.jpg";
import authUserIcon from "../../assets/images/icon_user_avtar.png";

//custom components
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
//constants
import {
  updateWindowWidth,
  tryLogin,
  trySocialLogin,
  tryLogout,
  checkAuth,
  tryResetPasswordOtp,
  forgotPasswordOtpClean,
  saveInteractionLog,
  fetchLists,
} from "../../store/actions";
import {
  CONTACT_US,
  MY_DASHBOARD,
  RESET_PASSWORD_CONFIRM,
  MY_COINS,
} from "../../constants/routes";
import { createLoggerData } from "../../utils/loggerData";
import { sectionName, logActions } from "../../constants/loggerConstants";
import {
  IMAGE_PROFILE_SIZE,
  IMAGE_URL, getImageBase, USER_FOLER
} from "../../constants/imageConstants";
import OutsideAlerter from "../../components/OutsideAlerter";

class HeaderAuth extends React.Component {
  state = {
    showForgetPassword: false,
    guestIcon: userIcon,
    savedCredential: "",
    showDropdown: false,
  };

  componentDidMount() {
    if (this.props.authUser && !this.props.authLoading) {
      this.props.fetchLists(false);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.otpEmail &&
      this.state.showForgetPassword &&
      this.props.location.pathname !== RESET_PASSWORD_CONFIRM
    ) {
      this.setState({ showForgetPassword: false });
      this.props.history.push(RESET_PASSWORD_CONFIRM);
    }
    if (
      this.props.authUser !== prevProps.authUser &&
      this.props.authUser &&
      this.state.savedCredential
    ) {
      this.props.saveInteractionLog(
        createLoggerData(
          sectionName.SECTION_LOGIN_MODAL,
          logActions.logUserNameInput(this.state.savedCredential),
          this.props.location.pathname
        )
      );
      this.setState({ savedCredential: "" });
      if (this.props.listsData && this.props.listsData.length === 0) {
        this.props.fetchLists(false);
      }
    }
    if (this.props.authUser !== prevProps.authUser && !this.props.authUser) {
      this.props.saveInteractionLog(
        createLoggerData(
          sectionName.SECTION_LOGIN_MODAL,
          logActions.LOG_SIGN_OUT,
          this.props.location.pathname,
          prevProps.userData
        )
      );
    }
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.state.showDropdown
    ) {
      this.setState({ showDropdown: false });
    }
    if (
      this.props.parentHeaderVisible &&
      this.props.parentHeaderVisible !== prevProps.parentHeaderVisible
    ) {
      this.setState({ showDropdown: true });
    }
  }

  handleLogin = ({ emailOrMobile, password }) => {
    this.setState({ savedCredential: emailOrMobile }, () => {
      this.props.tryLogin(emailOrMobile, password);
    });
  };

  handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        this.statusChangeCallback(response);
      },
      { scope: "public_profile,email" }
    );
  };

  statusChangeCallback = (response) => {
    const { authResponse, status } = response;
    if (status === "connected") {
      this.props.onSetLoginType("facebook");
      this.props.trySocialLogin(authResponse.accessToken, "facebook");
    }
  };

  handleForgetPasswordClick = () => {
    this.setState({ showForgetPassword: true });
    this.props.saveInteractionLog(
      createLoggerData(
        sectionName.SECTION_LOGIN_MODAL,
        logActions.LOG_FORGOTTEN_PASSWORD,
        this.props.location.pathname
      )
    );
  };

  handleForgetPasswordBackClick = () => {
    this.setState({ showForgetPassword: false });
  };

  handleResetSubmit = ({ emailOrMobile }) => {
    localStorage.setItem('rememberMe', emailOrMobile)
    this.props.tryResetPasswordOtp(emailOrMobile);
  };

  onHeaderImageClicked = (type) => {
    if (type === "guest") {
      this.setState({ guestIcon: userIconTrans });
    }
    this.setState({ showDropdown: true });
  };

  handleClickOutSideDropdown = (type) => {
    this.props.onCloseDropdown();
  };

  handleGoogleLogin = (id) => {
    this.props.onSetLoginType("google");
    this.props.trySocialLogin(id, "google");
  };

  render() {
    const {
      authUser,
      authLoading,
      tryLogout,
      forgotPasswordLoading,
      headerClasses,
      userData,
    } = this.props;
    const { showForgetPassword, guestIcon, showDropdown } = this.state;

    return (
      <div className="top-header-right">
        {authUser ? (
          <div className="d-flex-align">
            <div className="top-header-cart d-flex-align">
              <img
                src={currencyIcon}
                srcset={`${currencyIconretina} 2x`}
                alt="currency"
                className="top-header-currency"
              />
              <span
                className="top-header-price"
                style={{ paddingLeft: 8, paddingRight: 20 }}
              >
                {Math.floor(userData.coins) || 0}
              </span>
            </div>
            <div className="top-user ovaluser_loop po-rel">
              <span className="grey_menubar_icon">
                <img src="/images/homepage/v1_menu_close.png" />
              </span>
              <div
                className={`top-user-picture top-dropdown-outer po-rel`}
                onClick={this.onHeaderImageClicked}
              >
                {userData &&
                userData.profilePicture &&
                userData.profilePictureDetails ? (
                  <img
                    // src={`${IMAGE_URL_ROOT}/users/${IMAGE_PROFILE_SIZE}/${userData.profilePictureDetails.fileName}`}
                    src={`${IMAGE_URL}/${getImageBase(userData.profilePictureDetails.fileName, USER_FOLER, IMAGE_PROFILE_SIZE.width, IMAGE_PROFILE_SIZE.height)}`}
                    alt="header"
                    onError={(event) => {
                      event.target.src = authUserIcon;
                    }}
                    className="user_avtar"
                  />
                ) : (
                  <img
                    src="/images/homepage/acnt_user.png"
                    className="user_avtar"
                    alt="avatar"
                  />
                )}
                <OutsideAlerter
                  className={`top-dropdown`}
                  onClickOutside={this.handleClickOutSideDropdown}
                >
                  <ul className="top-dropdown-menu">
                    <li>
                      <Link to={MY_DASHBOARD}>My account</Link>
                    </li>
                    <li>
                      <Link to={MY_COINS}>My coins</Link>
                    </li>
                    <li className="mt-15">
                      <Link to={CONTACT_US}>Contact us</Link>
                    </li>
                    <li>
                      <a
                        onClick={tryLogout}
                        style={{ color: "white !important" }}
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </OutsideAlerter>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex-align">
            <div className="top-header-cart d-flex-align">
              <img src="/images/homepage/coin_grey_22.png" alt="currency" />
              <span className="grey_coin_amount">0</span>
            </div>
            <div className="top-user ovaluser_loop po-rel">
              <span className="grey_menubar_icon">
                <img src="/images/homepage/v1_menu_close.png" />
              </span>
              <div
                className={`top-user-picture top-dropdown-outer po-rel`}
                onClick={() => this.onHeaderImageClicked("guest")}
              >
                <img
                  src="/images/homepage/acnt_user.png"
                  className="user_avtar"
                  alt="avatar"
                />
                {showDropdown && (
                  <OutsideAlerter
                    className={`top-dropdown`}
                    onClickOutside={() =>
                      this.handleClickOutSideDropdown("guest")
                    }
                  >
                    <div className="sign_dek">
                      {showForgetPassword ? (
                        <ResetPassword
                          onBackClick={this.handleForgetPasswordBackClick}
                          onResetPasswordSubmit={this.handleResetSubmit}
                          isLoading={forgotPasswordLoading}
                        />
                      ) : (
                        <SignIn
                          authLoading={authLoading}
                          onClickLogin={this.handleLogin}
                          onFBLogin={this.handleFacebookLogin}
                          onGoogleLogin={this.handleGoogleLogin}
                          onForgetPasswordClick={this.handleForgetPasswordClick}
                        />
                      )}
                    </div>
                  </OutsideAlerter>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, list }) => {
  return {
    authLoading: auth.isLoading,
    authUser: !!auth.payload,
    forgotPasswordLoading: auth.forgetPasswordOtpLoading,
    otpEmail: auth.forgetPasswordOtpPayload,
    userData: auth.payload,
    listsData: list.listsPayload,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateWindowWidth,
    tryLogin,
    trySocialLogin,
    tryLogout,
    checkAuth,
    tryResetPasswordOtp,
    forgotPasswordOtpClean,
    saveInteractionLog,
    fetchLists,
  })(HeaderAuth)
);
