import { dashboardService } from "../../service/dashboard-service";
import {
  FETCH_DASHBOARD_START,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILED
} from "../../constants/actionTypes";

export const fetchDashboardData = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchDashboardDataStart());
    dashboardService.getDashboardData()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchDashboardDataSuccess(response));
        } else {

          dispatch(fetchDashboardDataFailed(response));
        }
      })
      .catch(() => {
        dispatch(fetchDashboardDataFailed(failureMessage));
      });
  }
};

const fetchDashboardDataStart = () => {
  return {
    type: FETCH_DASHBOARD_START
  }
};

const fetchDashboardDataSuccess = data => {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    payload: data
  }
};

const fetchDashboardDataFailed = data => {
  return {
    type: FETCH_DASHBOARD_FAILED,
    payload: data
  }
};