import {
  FETCH_LISTS_START,
  FETCH_LISTS_SUCCESS,
  FETCH_LISTS_FAILED,
  MANIPULATE_LISTS_START,
  MANIPULATE_LISTS_SUCCESS,
  MANIPULATE_LISTS_FAILED,
  FETCH_LISTS_RESTAURANTS_START,
  FETCH_LISTS_RESTAURANTS_SUCCESS,
  FETCH_LISTS_RESTAURANTS_FAILED,
  FETCH_SHAREDLIST_START,
  FETCH_SHAREDLIST_SUCCESS,
  FETCH_SHAREDLIST_FAILURE,
  FETCH_SHARED_TOKEN_START,
  FETCH_SHARED_TOKEN_SUCCESS,
  FETCH_SHARED_TOKEN_FAILURE, 
} from '../../constants/actionTypes';
import { listService } from "../../service/list-service";

export const fetchLists = (isRestaurant, isLoadMore = false, isRefresh = false) => {

  const failureMessage = 'Something wrong happened';

  return (dispatch, getState) => {
    const { currentPage, totalPage, controller } = getState().list;
    const nextPage = currentPage + 1;

    if ((nextPage !== 1 && (nextPage > totalPage)) && !isRefresh) return;

    controller.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
    };

    dispatch(fetchListsStart(newController));
    const signal = newController.signal;
    listService.fetchLists(params, signal, isRestaurant)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {

        if (response.status) {
          dispatch(fetchListsSuccess(response, isRestaurant, isLoadMore));
        } else {
          dispatch(fetchListsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchListsFailed(failureMessage));
      });
  }
};

export const fetchSharedToken = (id) => {

  const failureMessage = 'Something wrong happened';

  return (dispatch, getState) => {

    dispatch(fetchSharedTokenStart());
    listService.fetchSharedToken(id) 
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {

        if (response.status) {
          dispatch(fetchSharedTokenSuccess(response));
        } else {
          dispatch(fetchSharedTokenFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchSharedTokenFailed(failureMessage));
      });
  }
};

export const fetchSharedLists = (tokenId) => {

  const failureMessage = 'Something wrong happened';

  return (dispatch, getState) => {

    dispatch(fetchSharedListsStart());
    listService.fetchSharedLists(tokenId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {

        if (response.status) {
          dispatch(fetchSharedListsSuccess(response));
        } else {
          dispatch(fetchSharedListsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchSharedListsFailed(failureMessage));
      });
  }
};

export const addList = (data) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(manipulateListsStart());
    listService.addList(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(manipulateListsSuccess(response));
        } else {
          dispatch(manipulateListsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(manipulateListsFailed(failureMessage));
      });
  }
};

export const editList = (data, listId) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(manipulateListsStart());
    listService.updateList(data, listId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(manipulateListsSuccess(response));
        } else {
          dispatch(manipulateListsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(manipulateListsFailed(failureMessage));
      });
  }
};

export const removeList = listId => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(manipulateListsStart());
    listService.deleteList(listId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(manipulateListsSuccess(response));
        } else {
          dispatch(manipulateListsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(manipulateListsFailed(failureMessage));
      });
  }
};

export const deleteRestaurantsByList = (data, listId) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(manipulateListsStart());
    listService.deleteRestaurantsByList(data, listId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(manipulateListsSuccess(response));
        } else {
          dispatch(manipulateListsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(manipulateListsFailed(failureMessage));
      });
  }
};

export const addListsRestaruant = (data, restaruantId) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(manipulateListsStart());
    listService.addListsRestaruant(data, restaruantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(manipulateListsSuccess(response));
        } else {
          dispatch(manipulateListsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(manipulateListsFailed(failureMessage));
      });
  }
}

export const getRestaurantsByListId = (listId) => {

  const failureMessage = 'Something wrong happened';

  return (dispatch, getState) => {

    const { currentPageRestaurant, totalPageRestaurant, controllerRestaurant } = getState().list;

    const nextPage = currentPageRestaurant + 1;
    // if (nextPage > totalPageRestaurant) return;

    // controllerRestaurant.abort();
    const newController = new AbortController();

    const params = {
      currentPage: nextPage,
      pageSize: 10
    };

    dispatch(fetchListsRestaurantsStart(newController));
    const signal = newController.signal;

    listService.getRestaurantsByListId(listId, params, signal)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {

        const dataReturn = {
          data: response.data,
          currentPageRestaurant: response.currentPage,
          pageSizeRestaurant: response.pageSize,
          totalItemRestaurant: response.totalItem,
          totalPageRestaurant: response.totalPage
        }
        if (response.status) {
          dispatch(fetchListsRestaurantsSuccess(dataReturn));
        } else {
          dispatch(fetchListsRestaurantsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchListsRestaurantsFailed(failureMessage));
      });
  }
}

const fetchListsStart = (controller) => {
  return {
    type: FETCH_LISTS_START,
    controller
  }
};

const fetchListsSuccess = (data, isRestaurant, isLoadMore) => {
  return {
    type: FETCH_LISTS_SUCCESS,
    payload: data,
    isRestaurant: isRestaurant,
    isLoadMore: isLoadMore
  }
};

const fetchListsFailed = data => {
  return {
    type: FETCH_LISTS_FAILED,
    payload: data
  }
};

const fetchSharedListsStart = () => {
  return {
    type: FETCH_SHAREDLIST_START,
  }
};

const fetchSharedListsSuccess = (data) => {
  return {
    type: FETCH_SHAREDLIST_SUCCESS,
    payload: data,
  }
};

const fetchSharedListsFailed = data => {
  return {
    type: FETCH_SHAREDLIST_FAILURE,
    payload: data
  }
};

const fetchSharedTokenStart = () => {
  return {
    type: FETCH_SHARED_TOKEN_START,
  }
};

const fetchSharedTokenSuccess = (data) => {
  return {
    type: FETCH_SHARED_TOKEN_SUCCESS,
    payload: data,
  }
};

const fetchSharedTokenFailed = data => {
  return {
    type: FETCH_SHARED_TOKEN_FAILURE,
    payload: data
  }
};

const manipulateListsStart = () => {
  return {
    type: MANIPULATE_LISTS_START
  }
};

const manipulateListsSuccess = data => {
  return {
    type: MANIPULATE_LISTS_SUCCESS,
    payload: data
  }
};

const manipulateListsFailed = data => {
  return {
    type: MANIPULATE_LISTS_FAILED,
    payload: data
  }
};

const fetchListsRestaurantsStart = (controller) => {
  return {
    type: FETCH_LISTS_RESTAURANTS_START,
    controller
  }
};

const fetchListsRestaurantsSuccess = data => {
  return {
    type: FETCH_LISTS_RESTAURANTS_SUCCESS,
    payload: data
  }
};

const fetchListsRestaurantsFailed = data => {
  return {
    type: FETCH_LISTS_RESTAURANTS_FAILED,
    payload: data
  }
};