export const achievementsData = [
  {
    "title": "Dish 'em up!",
    "imageFile": "Single-dish.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Plant a flag",
    "imageFile": "Single-plant _flag.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Qualified gastronaut",
    "imageFile": "Single-qualified_gastronaut.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Bold explorer",
    "imageFile": "Single-bold_explorer.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Send your coordinates",
    "imageFile": "Single-send_coordinates.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Discover 'appiness",
    "imageFile": "",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Identify new worlds",
    "imageFile": "Single- new_restaurant_opening.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Made a friend!",
    "imageFile": "Single-made_friend.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Clinched the deal!",
    "imageFile": "Single-clinched_deal.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Local hero!",
    "imageFile": "Single-local_hero_scrn.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "All-star profile",
    "imageFile": "Single-star_profile.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Capture your trip",
    "imageFile": "Single-capture_trip.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Recruit gastronauts",
    "imageFile": "Single-recruit_gastronauts.png",
    "pendingDescription": "",
    "completedDescription": ""
  },
  {
    "title": "Look to the stars",
    "imageFile": "Multi-look_stars.png",
    "pendingDescription": "Run replacetext searches",
    "completedDescription": "Total replacetext searches"
  },
  {
    "title": "Culinary explorer",
    "imageFile": "Multi-culinary_explorer.png",
    "pendingDescription": "View replacetext Restaurants",
    "completedDescription": "Viewed replacetext Restaurants"
  },
  {
    "title": "Gastronauts log",
    "imageFile": "Multi-gastronauts_log.png",
    "pendingDescription": "Create replacetext lists",
    "completedDescription": "Created replacetext lists"
  },
  {
    "title": "Transmit a log",
    "imageFile": "Multi-report_findings.png",
    "pendingDescription": "Share replacetext lists",
    "completedDescription": "Shared replacetext lists"
  },
  {
    "title": "Trip booked",
    "imageFile": "Multi-Trip_booked.png",
    "pendingDescription": "Make replacetext bookings",
    "completedDescription": "Made replacetext bookings"
  },
  {
    "title": "Make your mark",
    "imageFile": "Multi-make_mark.png",
    "pendingDescription": "replacetext successful page edits",
    "completedDescription": "replacetext successful page edits"
  },
  {
    "title": "Report to control",
    "imageFile": "Multi-report_tocontrol.png",
    "pendingDescription": "replacetext successful reports",
    "completedDescription": "replacetext successful reports"
  },
  {
    "title": "Send a transmission",
    "imageFile": "MultiTransmission.png",
    "pendingDescription": "Share replacetext restaurants",
    "completedDescription": "Shared replacetext restaurants"
  }
];

export const achievementsDefaultImg = 'Generic Achievement.png';