// export const IMAGE_URL = 'https://d3f9rs4u3sa3el.cloudfront.net/restaurants';
// export const IMAGE_URL_ROOT = 'https://d3f9rs4u3sa3el.cloudfront.net';
// export const IMAGE_CARD_SIZE = '345x220';
// export const IMAGE_BANNER_SIZE = '528x400';
// export const IMAGE_GALLERY_SIZE = '346x241';
// export const IMAGE_REVIEW_SIZE = '350x230';
// export const IMAGE_GALLERY_FULL_SIZE = '1180x537';
// export const IMAGE_BOOKING_REVIEW_SIZE = '302x158';
// export const IMAGE_GUIDE_HOME = '212x180';
// export const IMAGE_PROFILE_SIZE = '84x84';
// export const IMAGE_THUMB_SIZE = '40x40';
// export const IMAGE_RESTAURANT_THUMB_SIZE = '73x53'

export const IMAGE_URL = 'https://d2oocqy6gyxr82.cloudfront.net'
// export const IMAGE_URL_ROOT = 'https://d3f9rs4u3sa3el.cloudfront.net';
export const IMAGE_CARD_SIZE = {width: 345, height: 220} // '345x220';
export const IMAGE_BANNER_SIZE = {width: 528, height: 400} // '528x400';
export const IMAGE_GALLERY_SIZE = {width: 346, height: 241} //'346x241';
export const IMAGE_REVIEW_SIZE = {width: 350, height: 230} //'350x230';
export const IMAGE_GALLERY_FULL_SIZE = {width: 1180, height: 537} //'1180x537';
export const IMAGE_BOOKING_REVIEW_SIZE = {width: 302, height: 158} //'302x158';
export const IMAGE_GUIDE_HOME = {width: 212, height: 180} //'212x180';
export const IMAGE_PROFILE_SIZE = {width: 84, height: 84} //'84x84';
export const IMAGE_THUMB_SIZE = {width: 40, height: 40} //'40x40';
export const IMAGE_RESTAURANT_THUMB_SIZE = {width: 73, height: 53} //'73x53'
export const RESTAURANT_FOLDER = 'restaurants'
export const USER_FOLER = 'users'

export const getImageBase = (fileName, folderName, width, height) => {
    const imageRequest = JSON.stringify({
        bucket: "tablefy-images",
        key: `${folderName}/${fileName}`,
        edits: { "resize": { "width": width, "height": height, "fit": "cover", "withoutEnlargement": true } }
    });
    return btoa(imageRequest);
}
