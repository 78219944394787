import React, { useState } from "react";
import { useFormik } from "formik";
import { Link } from 'react-router-dom';

//constants
import { completeSignUp } from "../../validationSchema/completeSignUp";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "../../constants/routes";

//custom components
import SpinnerWhite from "../../components/Spinner/SpinnerWhite";
import InputWrapper from "../../components/UI/InputWrapper";

const CompleteDetailsForm = ({ onSubmit: onFromSubmit, loading, initialData }) => {
  const { firstName, lastName, email, phone } = initialData;

  const formik = useFormik({
    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      mobile: phone || '',
    },
    validationSchema: completeSignUp,
    onSubmit: values => {
      onFromSubmit({
        fullName: `${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.mobile === '' ? null : values.mobile,
        email: values.email,
      })
    },
  });

  return (
    <form className="registartion_modal register-guest" onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-12">
          <InputWrapper formik={formik} fieldName='email' icon='mail' noTouch>
            <input
              type="email"
              placeholder="Enter email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </InputWrapper>
        </div>
        <div className="col-6" style={{ paddingRight: 5 }}>
          <InputWrapper formik={formik} fieldName="firstName" icon="person">
            <input
              type="text"
              placeholder="*First name"
              id="firstName"
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
          </InputWrapper>
        </div>
        <div className="col-6" style={{ paddingLeft: 5 }}>
          <InputWrapper formik={formik} fieldName="lastName" icon="person">
            <input
              type="text"
              placeholder="*Last name"
              id="lastName"
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
          </InputWrapper>
        </div>
        <div className="col-12">
          <InputWrapper formik={formik} fieldName="mobile" icon="call">
            <input
              type="text"
              placeholder="*Mobile"
              id="mobile"
              name="mobile"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobile}
            />
          </InputWrapper>
        </div>
        <div className="col-12">
          <button className="login_btn_div btn-red" type="submit" disabled={loading}>
            {loading ? <SpinnerWhite/> : 'Submit Details'}
          </button>
        </div>
      </div>
    </form>
  )
}

export default CompleteDetailsForm;
