import { toast } from "react-toastify";

import { restaurantAdminService } from "../../service/restaurant-admin-service";
import {
  FETCH_ADMIN_USERS,
  FETCH_ADMIN_USERS_SUCCESS,
  FETCH_ADMIN_USERS_FAILED,
  UPDATE_USER_ACCESS_TYPE,
  UPDATE_USER_ACCESS_TYPE_SUCCESS,
  UPDATE_USER_ACCESS_TYPE_FAILED,
  REMOVE_RESTAURANT_USER,
  REMOVE_RESTAURANT_USER_SUCCESS,
  REMOVE_RESTAURANT_USER_FAILED
} from "../../constants/actionTypes";

export const fetchRestaurantAdminUsers = (restaurantId) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(restaurantAdminUsersStart());
    restaurantAdminService.getRestaurantAdminUsers(restaurantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(restaurantAdminUsersSuccess(response));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(restaurantAdminUsersFailed(errorMessage));
        }
      })
      .catch(() => {
        toast.error(failureMessage);
        dispatch(restaurantAdminUsersFailed(failureMessage));
      });
  }
};

const restaurantAdminUsersStart = () => {
  return {
    type: FETCH_ADMIN_USERS
  };
}


const restaurantAdminUsersSuccess = response => {
  return {
    type: FETCH_ADMIN_USERS_SUCCESS,
    payload: response.data
  }
};

const restaurantAdminUsersFailed = error => {
  return {
    type: FETCH_ADMIN_USERS_FAILED,
    payload: error
  }
};

export const modifyUserAccessType = (id, data) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(modifyUserAccessTypeStart(id));
    restaurantAdminService.updateUserAccessType(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(modifyUserAccessTypeSuccess(response));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(modifyUserAccessTypeFailed(errorMessage));
        }
      })
      .catch(() => {
        toast.error(failureMessage);
        dispatch(modifyUserAccessTypeFailed(failureMessage));
      });
  }
};

const modifyUserAccessTypeStart = (id) => {
  return {
    type: UPDATE_USER_ACCESS_TYPE,
    payload: id
  };
}


const modifyUserAccessTypeSuccess = response => {
  return {
    type: UPDATE_USER_ACCESS_TYPE_SUCCESS,
    payload: response.data
  }
};

const modifyUserAccessTypeFailed = error => {
  return {
    type: UPDATE_USER_ACCESS_TYPE_FAILED,
    payload: error
  }
};

export const removeRestaurantUser = (data) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    const { userId, restaurantId, itemId } = data;
    dispatch(removeRestaurantUserStart(itemId));
    restaurantAdminService.deleteRestaurantUser(userId, restaurantId)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(removeRestaurantUserSuccess(itemId));
        } else {
          const errorMessage = Array.isArray(response.message) ? response.message[0] : response.message
          toast.error(errorMessage);
          dispatch(removeRestaurantUserFailed(errorMessage));
        }
      })
      .catch(() => {
        toast.error(failureMessage);
        dispatch(removeRestaurantUserFailed(failureMessage));
      });
  }
};

const removeRestaurantUserStart = (id) => {
  return {
    type: REMOVE_RESTAURANT_USER,
    payload: id
  };
}


const removeRestaurantUserSuccess = response => {
  return {
    type: REMOVE_RESTAURANT_USER_SUCCESS,
    payload: response
  }
};

const removeRestaurantUserFailed = error => {
  return {
    type: REMOVE_RESTAURANT_USER_FAILED,
    payload: error
  }
};