import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {

  return (
    <section class="bg_gray for_userdashboard pb-0-fortablet">

      <div class="nav-bar-desktop-two-fields">

        <div class="error-code">
          <div class="top-error-cls">
            <div class="error-code-404">
              Error code:404
            </div>
            <div class="message">
              <div class="bubble">
                <span class="looks-like-youre-a"> Looks like you're a bit lost... </span>
              </div>
            </div>
            <div class="henry-normal-henry-unsure">
              <img src="images/unsure-removebg-preview.png" srcset="images/unsure-removebg-preview@2x.png 2x" />
            </div>
          </div>
          <div class="bottom-line-error-cls">
            <div class="links">
              <div class="heres-some-links-yo">
                Here's some links you might find helpful:
              </div>
              <div class="links_list">
                <ul>
                  <li className="text-center">
                    <Link
                      className="link_red"
                      to={{
                        pathname: `/home`
                      }}
                    >Home</Link>
                  </li>
                  <li className="text-center">
                    <Link
                      className="link_red"
                      to={{
                        pathname: `/home`
                      }}
                    >Discover restaurants</Link>
                  </li>
                  <li className="text-center">
                    <Link
                      className="link_red"
                      to={{
                        pathname: `/home`
                      }}
                    >Restaurants nearby</Link>
                  </li>
                  <li className="text-center">
                    <Link
                      className="link_red"
                      to={{
                        pathname: `/dashboard`
                      }}
                    >Get reward coins</Link>
                  </li>
                  <li className="text-center">
                    <Link
                      className="link_red"
                      to={{
                        pathname: `/home`
                      }}
                    >Add a restaurant</Link>
                  </li>
                  <li className="text-center">
                    <Link
                      className="link_red"
                      to={{
                        pathname: `/contact-us`
                      }}
                    >Contact us</Link>
                  </li>
                </ul>

              </div>
            </div>
          </div>

        </div>

      </div>

    </section>
  );
};

export default Error404;