import {
  FETCH_MENU_EDIT,
  FETCH_MENU_EDIT_SUCCESS,
  FETCH_MENU_EDIT_FAILED,
  FETCH_MENU_TYPE,
  FETCH_MENU_TYPE_SUCCESS,
  FETCH_MENU_TYPE_FAILED,
  FETCH_ARCHIVE_MENU,
  FETCH_ARCHIVE_MENU_SUCCESS,
  FETCH_ARCHIVE_MENU_FAILED,
  FETCH_ARCHIVE_MENU_CLEAR
} from '../../constants/actionTypes';
import { editMenuService } from '../../service/menu-edit-service';
import { restaurantService } from "../../service/restaurant-service";
import { fetchRestaurantDetailsSuccess, fetchRestaurantDetailsFailed } from './Restaurant';
import { sortMenus } from "../../utils/sortItems";

export const editMenu = (id, data, type) => {
  const failureMessage = 'Something wrong happened';
  let callFunction = editMenuService.editMenu;
  if (type === 'add') {
    callFunction = editMenuService.addMenu
  }
  if (type === 'remove') {
    callFunction = editMenuService.toggleRemoveMenu
  }
  if (type === 'change-order') {
    callFunction = editMenuService.changeMenuOrder
  }

  return dispatch => {
    dispatch(editMenuStart());
    callFunction(id, data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          restaurantService.getRestaurantDetails(data.restaurant)
            .then(rawData => {
              return rawData.json();
            })
            .then(response => {
              if (response.status) {
                let newMenuList = [];
                response.data.menuList.forEach(menuData => {
                  if (menuData.menus && menuData.menus.length) {
                    menuData.menus.forEach(menuItem => {
                      const newMenuItem = {...menuItem, menuTypeID: menuData.menuType._id, menuTypeTitle: menuData.menuType.title};
                      newMenuList.push(newMenuItem);
                    });
                  }
                });
                const sortedMenuList = sortMenus(newMenuList);
                dispatch(fetchRestaurantDetailsSuccess(response.data, sortedMenuList));
                dispatch(editMenuSuccess(response));
              } else {
                dispatch(fetchRestaurantDetailsFailed(failureMessage));
                dispatch(editMenuFailed(failureMessage));
              }
            })
            .catch(() => {
              dispatch(fetchRestaurantDetailsFailed(failureMessage));
              dispatch(editMenuFailed(failureMessage));
            });
        } else {
          dispatch(editMenuFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(editMenuFailed(failureMessage));
      });
  }
};

export const fetchMenuTypes = (search) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(fetchMenuTypeStart());
    editMenuService.fetchMenuType(search)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchMenuTypeSuccess(response));
        } else {
          dispatch(fetchMenuTypeFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchMenuTypeFailed(failureMessage));
      });
  }
};

const editMenuStart = () => {
  return {
    type: FETCH_MENU_EDIT
  }
};

const editMenuSuccess = data => {
  return {
    type: FETCH_MENU_EDIT_SUCCESS,
    payload: data
  }
};

const editMenuFailed = data => {
  return {
    type: FETCH_MENU_EDIT_FAILED,
    payload: data
  }
};

const fetchMenuTypeStart = () => {
  return {
    type: FETCH_MENU_TYPE
  }
};

const fetchMenuTypeSuccess = data => {
  return {
    type: FETCH_MENU_TYPE_SUCCESS,
    payload: data
  }
};

const fetchMenuTypeFailed = data => {
  return {
    type: FETCH_MENU_TYPE_FAILED,
    payload: data
  }
};

export const fetchArchivedMenuList = id => {
  const failureMessage = 'Something wrong happened';
  return (dispatch, getState) => {
    const {currentPage, totalPage} = getState().menu;
    const nextPage = parseInt(currentPage) + 1;
    if (nextPage !== 1 && (nextPage > totalPage)) return;

    const params = {
      currentPage: nextPage,
      restaurantId: id
    };
    dispatch(fetchArchivedMenusStart());
    editMenuService.getMenuList(params)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          let newMenuList = [];
          response.data.forEach(menuData => {
            if (menuData.menus && menuData.menus.length) {
              menuData.menus.forEach(menuItem => {
                const newMenuItem = {...menuItem, menuTypeID: menuData.menuType._id, menuTypeTitle: menuData.menuType.title};
                newMenuList.push(newMenuItem);
              });
            }
          });
          const sortedMenuList = sortMenus(newMenuList);
          dispatch(fetchArchivedMenusSuccess({...response, data: sortedMenuList}));
        } else {
          dispatch(fetchArchivedMenuFailed(failureMessage));
        }
      })
      .catch(err => {
        console.log(err)
        dispatch(fetchArchivedMenuFailed(failureMessage));
      });
  }
};

const fetchArchivedMenusStart = () => {
  return {
    type: FETCH_ARCHIVE_MENU
  }
};

const fetchArchivedMenusSuccess = data => {
  return {
    type: FETCH_ARCHIVE_MENU_SUCCESS,
    payload: data
  }
};

const fetchArchivedMenuFailed = data => {
  return {
    type: FETCH_ARCHIVE_MENU_FAILED,
    payload: data
  }
};

export const fetchArchivedMenuClear = () => {
  return {
    type: FETCH_ARCHIVE_MENU_CLEAR,
  }
};
