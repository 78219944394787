import { combineReducers } from "redux";
import Settings from "./Settings";
import Auth from './Auth';
import Restaurant from './Restaurant';
import User from './User';
import Cuisine from './Cuisine';
import Tag from './Tag';
import TopLocation from './TopLocation';
import Menu from './Menu';
import Booking from './Booking';
import List from './List';
import Logger from './Logger';
import UserSuggestion from './UserSuggestion';
import Rewards from './Rewards';
import Review from './Review';
import Guide from './Guide';
import ReportIssue from './ReportIssue';
import RestaurantAdmin from './RestaurantAdmin';
import Offers from './Offers';
import Payment from './Payment';
import Feed from './Feed';
import RestaurantAccount from './RestaurantAccount';
import BuzzScreens from './BuzzScreens';
import Contact from './Contact';
import Dashboard from './Dashboard';
import Achievements from './Achievements';
import Banner from './Banner';
import Ads from './Ads'

const reducers = combineReducers({
  settings: Settings,
  auth: Auth,
  restaurant: Restaurant,
  user: User,
  cuisine: Cuisine,
  tag: Tag,
  topLocation: TopLocation,
  menu: Menu,
  booking: Booking,
  list: List,
  logger: Logger,
  userSuggestion: UserSuggestion,
  rewards: Rewards,
  review: Review,
  guide: Guide,
  reportIssue: ReportIssue,
  restaurantAdmin: RestaurantAdmin,
  offers: Offers,
  payment: Payment,
  feed: Feed,
  restaurantAccount: RestaurantAccount,
  buzzScreens: BuzzScreens,
  contact: Contact,
  dashboard: Dashboard,
  achievements: Achievements,
  banner: Banner,
  Ads: Ads
});

export default reducers;
