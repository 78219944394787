import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const topLocationService = {
  getTopLocations,
  getTopRatedRestaurants
};

function getTopLocations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.TOP_LOCATIONS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getTopRatedRestaurants({currentPage}) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.TOP_LOCATIONS_RESTAURANTS}?pageSize=23&currentPage=${currentPage}&type=listed`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
