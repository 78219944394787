export const convertData = data => {
  const details = {...data};

  let formBody = [];
  const detailKeys = Object.keys(details);
  detailKeys.forEach(property => {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  });
  formBody = formBody.join("&");

  return formBody;
}