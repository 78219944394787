import React from "react";
import { useBackdrop } from "./customHooks";

const Donate = ({ onClose, details }) => {
  useBackdrop();
  const { donatedCoins } = details;

  return (
    <section className="buzz_modal">
      <div className="buzz_donate">
        <button type="button" className="buzz_close_modal" onClick={onClose}>
          <img src="/images/buzz/buzz_close_white.png" srcset="/images/buzz/buzz_close_white@2x.png 2x"/>
        </button>
        <h3 className="buzz_center_heading">Thank you for your donation</h3>
        <div className="buzz_donate_txt">
          <div className="buzz_donate_amnt">
            Open Kitchens has raised<b>£253,593</b>resulting in 254,323 meals for those in need!
          </div>
        </div>
        <div className="buzz_bonus_coins">
          <b>You donated</b>
          <span className="buzz_earnedcoins">
            <img src="/images/buzz/buzz_coin.png" srcset="/images/buzz/buzz_coin@2x.png 2x" />{donatedCoins} Coins!
          </span>
        </div>
        <button type="button" onClick={() => {window.open('https://openkitchens.co.uk/', "_blank")}} className="btn_buzz_get_coins">Learn more about Open Kitchens</button>
      </div>
    </section>

  )
}

export default Donate;