import React from 'react';
import { withRouter } from 'react-router-dom';

import { TERMS_CONDITIONS } from "../../constants/routes";

const TCButton = ({ history }) => {
  return (
    <div className="bg_gray pb-50">
      <div className="wrapper bg_gray d-flex justify-content-end">
        <button
          className="btn btn-white terms-shadow"
          style={{ maxWidth: 188 }}
          onClick={() => history.push(TERMS_CONDITIONS)}
        >
          <img src="/images/info-icon.png" className="btn-image" alt="info"/> Terms, privacy & more
        </button>
      </div>
    </div>
  )
}

export default withRouter(TCButton); 