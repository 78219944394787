import {
  FETCH_CUISINES_START,
  FETCH_CUISINES_SUCCESS,
  FETCH_CUISINES_FAILED,
  FETCH_FILTERED_CUISINES_SUCCESS,
} from '../../constants/actionTypes';
import {cuisineService} from '../../service/cuisine-service';

export const fetchCuisines = (search, isFiltered) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    const newSearch = search || '';
    dispatch(fetchCuisinesStart());
    cuisineService.getCuisines(newSearch)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          if (isFiltered) {
            dispatch(fetchFilteredCuisinesSuccess(response));
          } else {
            dispatch(fetchCuisinesSuccess(response));
          }
        } else {
          dispatch(fetchCuisinesFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchCuisinesFailed(failureMessage));
      });
  }
};

const fetchCuisinesStart = () => {
  return {
    type: FETCH_CUISINES_START
  }
};

const fetchCuisinesSuccess = data => {
  return {
    type: FETCH_CUISINES_SUCCESS,
    payload: data
  }
};

const fetchFilteredCuisinesSuccess = data => {
  return {
    type: FETCH_FILTERED_CUISINES_SUCCESS,
    payload: data
  }
};

const fetchCuisinesFailed = data => {
  return {
    type: FETCH_CUISINES_FAILED,
    payload: data
  }
};
