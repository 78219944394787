import {
  ADD_SUGGESTION_START,
  ADD_SUGGESTION_SUCCESS,
  ADD_SUGGESTION_FAILED,
  REMOVE_SUGGESTION_STATUS,
  SET_SUGGESTION_STATUS,
  FETCH_PENDING_SUGGESTIONS_START,
  FETCH_PENDING_SUGGESTIONS_SUCCESS,
  FETCH_PENDING_SUGGESTIONS_FAILED
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  restaurantUpdated: false,
  pendingSuggestions: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUGGESTION_START: {
      return { ...state, error: null, isLoading: true };
    }
    case ADD_SUGGESTION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        isLoading: false,
        restaurantUpdated: true
      };
    }
    case ADD_SUGGESTION_FAILED: {
      return { ...state, isLoading: false, error: action.payload, restaurantUpdated: true };
    }
    case REMOVE_SUGGESTION_STATUS: {
      return { ...state, restaurantUpdated: false };
    }
    case SET_SUGGESTION_STATUS: {
      return { ...state, restaurantUpdated: true };
    }
    case FETCH_PENDING_SUGGESTIONS_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_PENDING_SUGGESTIONS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        error: null,
        pendingSuggestions: data,
        isLoading: false
      };
    }
    case FETCH_PENDING_SUGGESTIONS_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
