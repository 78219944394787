export let FRONT_BASE_URL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  FRONT_BASE_URL = "http://localhost:3000";
} else {
  FRONT_BASE_URL = "http://ec2-3-10-219-65.eu-west-2.compute.amazonaws.com";
}

export const HOME = '/home';
export const MOBILE_SIGN_IN = '/mobile-sign-in';
export const MOBILE_REGISTER = '/mobile-register';
export const DESKTOP_REGISTER = '/register';
export const RESTAURANT_MENU = '/restaurant-menu';
export const RESTAURANT_MENU_EDIT = '/restaurant-menu-edit';
export const GUEST_SEARCH_RESULT = '/guest-search-result';
export const USER_SEARCH_RESULT = '/user-search-result';
export const LOADING_SEARCH_RESULTS = '/loading-search-results';
export const RESTAURANT_DETAILS = '/restaurant-details';
export const RESTAURANT_EDIT_DETAILS = '/restaurant-edit-details';
export const RESTAURANT_EDIT_USER = '/user-restaurant-suggestion';
export const RESTAURANT_ADMIN_EDIT_DETAILS = `/restaurant-admin-edit`;
export const REGISTRATION_BENEFITS = '/registration-benefits';
export const RESET_PASSWORD_CONFIRM = '/reset-password-confirm';
export const RESET_PASSWORD_MOBILE = '/mobile-reset-password';
export const TERMS_CONDITIONS = '/terms-conditions';
export const PRIVACY_POLICY = '/privacy-policy';
export const SITEMAP = '/sitemap';
export const LIST_A_RESTAURANT = '/list-a-restaurant';
export const CONTACT_US = '/contact-us';
export const BENEFITS = '/benefits';
export const GUIDES = '/guides';
export const CHANGE_PASSWORD = '/change-password';
export const RESTAURANT_EMBED = '/restaurant-embed';

export const TABLE_BOOKING = '/table-booking';
export const SUBMIT_BOOKING = `${TABLE_BOOKING}/submit-booking`;
export const BOOKING_LOADING = `${TABLE_BOOKING}/loading`;
export const BOOKING_CONFIRM = `${TABLE_BOOKING}/confirm-booking`;

export const DASHBOARD = '/dashboard';
export const MY_DASHBOARD = `${DASHBOARD}/my-dashboard`;
export const MY_BOOKINGS = `${DASHBOARD}/my-bookings`;
export const MY_OFFERS = `${DASHBOARD}/my-offers`;
export const MY_LISTS = `${DASHBOARD}/my-lists`;
export const MY_COINS = `${DASHBOARD}/my-coins`;
export const MY_PROFILE = `${DASHBOARD}/my-profile`;
export const MY_PAGES = `${DASHBOARD}/my-pages`;
export const MY_HELP = `${DASHBOARD}/my-help`;
export const MY_ACHIEVEMENTS = `${DASHBOARD}/my-achievements`;

export const RESTAURANT_ADMIN = '/restaurant-admin';
