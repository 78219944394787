import React from "react";
import { Helmet } from "react-helmet";
const SITE_URL = 'https://dev.tablefy.com/';

class MetaTags extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isDashboard: this.props.isDashboard
    };
  }

  render() {

    const { isDashboard } = this.state;

    let title = '';
    let restaurantSlug = '';
    let image = 'http://tablefy-images.s3-website.eu-west-2.amazonaws.com/restaurants/345x220/511d1120-2d7f-5e42-90d4-6e2576b8002c.jpg';

    if(this.props.tablefy && isDashboard){
      title = this.props.tablefy
    }

    if (!isDashboard) {
      title = this.props.data.title ? this.props.data.title : this.props.data[0].toUpperCase() + this.props.data.substring(1);
      restaurantSlug = this.props.data.slug;
      if (typeof this.props.data.imageReviews !== "undefined" && this.props.data.imageReviews.length !== 0) {
        image = 'http://tablefy-images.s3-website.eu-west-2.amazonaws.com/restaurants/345x220/' + this.props.data.imageReviews[0].photoDetails[0].fileName;
      }
    }

    return (
      <div className="application">
        {
          this.props.tablefy && <Helmet>
            <title>{title}</title>
          </Helmet>
        }
        {isDashboard && <Helmet>
          {/* Facebook meta tags */}
          <meta property="og:title" content={"Take a look a my restaurant list from Tablefy"} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`${SITE_URL}benefits`} />
          <meta property="og:image" content={`${image}`} />
          {/* Twitter meta tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={`${SITE_URL}benefits`} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content="Take a look a my restaurant list from Tablefy" />
          <meta name="twitter:image" content={`${image}`} />
        </Helmet>
        }
        {!isDashboard && <Helmet>
          <title>{title}</title>
          {/* Facebook meta tags */}
          <meta property="og:title" content={"Take a look at this restaurant I found on Tablefy"} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`${SITE_URL}restaurant-details/${restaurantSlug}`} />
          <meta property="og:image" content={`${image}`} />

          {/* Twitter meta tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={`${SITE_URL}benefits`} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content="Take a look at this restaurant I found on Tablefy" />
          <meta name="twitter:image" content={`${image}`} />
        </Helmet>}

      </div>
    );
  }
};

export default MetaTags;