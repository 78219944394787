import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';

export const listService = {
  fetchLists,
  addList,
  updateList,
  deleteList,
  addListsRestaruant,
  getRestaurantsByListId,
  deleteRestaurantsByList,
  fetchSharedLists,
  fetchSharedToken
};

function fetchLists({ currentPage, searchQuery, orderBy, pageSize }, signal, isRestaurant) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    signal
  };

  if (!isRestaurant) {
    pageSize = 100;
  } else {
    pageSize = 10 * currentPage;
  }

  return secureFetch(`${apiURL.MY_LISTS}?pageSize=${pageSize}&currentPage=1&orderBy=fullName&search=&restaurant=${isRestaurant}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function fetchSharedLists(tokenId) {

  const requestOptions = {
    method: 'GET',
  };

  // if (!isRestaurant) {
  //   pageSize = 100;
  // } else {
  //   pageSize = 10 * currentPage;
  // }

  return secureFetch(`${apiURL.MY_LISTS}/list-details/${tokenId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function fetchSharedToken(id) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.MY_LISTS}/${id}/access-token`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function addList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ ...data })
  };

  return secureFetch(`${apiURL.MY_LISTS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateList(data, listId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ ...data })
  };

  return secureFetch(`${apiURL.MY_LISTS}/${listId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function deleteList(listId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return secureFetch(`${apiURL.MY_LISTS}/${listId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function addListsRestaruant(data, restaruantId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ ...data })
  };

  return secureFetch(`${apiURL.MY_LISTS}/${restaruantId}/add-restaurant`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getRestaurantsByListId(listId, { currentPage, searchQuery, orderBy, pageSize }, signal) {

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    signal
  };

  pageSize = 10 * currentPage;

  // console.log(`${apiURL.MY_LISTS}/${listId}/restaurants?pageSize=${pageSize}&currentPage=1`);

  return secureFetch(`${apiURL.MY_LISTS}/${listId}/restaurants?pageSize=${pageSize}&currentPage=1`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });

}

function deleteRestaurantsByList(data, listId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ ...data })
  };

  return secureFetch(`${apiURL.MY_LISTS}/${listId}/remove-restaurant`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
