import { authHeader } from "./auth-header";
import * as apiURL from "./Apiconfig";
import { secureFetch } from "./secureFetch";
import { OFFERS, CHEST } from "./Apiconfig";

export const offersService = {
  getRestaurantDetailOffers,
  getRestaurantDetailExceptionOffers,
  getRestaurantDetailOtherOffers,
  getChestOffer,
  getDailyChestOffer,
  addDailyChestOffer,
  addOffer,
  updateOffer,
  deleteOffer,
  getOffer,
  getOfferUserMayLike,
};

//get the  offers data
function getRestaurantDetailOffers(restaurantId, category) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(
    `${apiURL.OFFERS}?pageSize=10&currentPage=1&restaurant=${restaurantId}&offerCategory=${category}&orderBy=fullName`,
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

//get all the data of exception offers on date range filter
function getRestaurantDetailExceptionOffers(restaurantId, dataObj) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(
    `${apiURL.OFFERS}?startDate=${dataObj.startDate}&endDate=${dataObj.endDate}&restaurant=${restaurantId}&offerCategory=${dataObj.category}&orderBy=fullName`,
    requestOptions
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

//get the data of other offers data
function getRestaurantDetailOtherOffers(restaurantId, dataObj) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(
    `${apiURL.OFFERS}?currentPage=1&restaurant=${restaurantId}&offerCategory=${dataObj.category}`
  )
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getChestOffer() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.CHEST}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getDailyChestOffer() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.CHEST}/daily-chest`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function addDailyChestOffer(chest) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(chest),
  };

  return secureFetch(`${apiURL.CHEST}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}
function addOffer(params) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(params),
  };

  return secureFetch(`${apiURL.OFFERS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function updateOffer(Id, params) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(params),
  };

  return secureFetch(`${apiURL.OFFERS}/${Id}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function deleteOffer(Id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.OFFERS}/${Id}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getOffer(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.OFFERS}/${id}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

function getOfferUserMayLike() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  //console.log("header token ==", authHeader())
  return secureFetch(`${apiURL.USER_OFFERS}`, requestOptions)
    .then((user) => {
      return user;
    })
    .catch((err) => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
