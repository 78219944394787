import React from 'react';
import { connect } from 'react-redux';

import {
  fetchRestaurantDetails,
  fetchRestaurantDetailOffers
} from '../../store/actions';

import BookTableNew from "../BookTableNew";
import { SUBMIT_BOOKING } from "../../constants/routes";

class RestaurantEmbed extends React.Component {
  state = {
    mobileSidebarOpen: false
  }

  componentDidMount() {
    this.props.fetchRestaurantDetails(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(this.props.restaurantDetails) !== JSON.stringify(prevProps.restaurantDetails)) {
      this.props.fetchRestaurantDetailOffers(this.props.restaurantDetails._id, "weeklyOffer");
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
  }

  handleSidebarToggleClicked = value => {
    if (value) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    this.setState({ mobileSidebarOpen: value })
  }

  handleBookingSubmit = () => {
    this.props.history.push(`${SUBMIT_BOOKING}/${this.props.restaurantDetails.slug}`);
  }

  render() {
    const { mobileSidebarOpen } = this.state;
    const { offersDetails, restaurantDetails } = this.props;

    return (
      <div>
        <section>
          <div className="bg_gray">
            <div className="booking_web_embed">
              <div className="d-flex">
                <div className="web_embed_body" style={{ position: 'relative' }}>
                  <iframe
                    src={`${restaurantDetails ? restaurantDetails.website : ''}`}
                    title="weblink"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div className={`booking_embed_body ${mobileSidebarOpen ? 'show' : ''}`}>
                  <BookTableNew
                    isEmbed
                    visible={mobileSidebarOpen}
                    onClose={() => this.handleSidebarToggleClicked(false)}
                    onSubmitClicked={this.handleBookingSubmit}
                    isFixed={false}
                    offerDetails={offersDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {
          mobileSidebarOpen
            ? null
            : (
              <div className="book_table_btn_area new">
                <button type="button" className="dwnld_bookings" onClick={() => this.handleSidebarToggleClicked(true)}>
                  Book your table
                </button>
                <span className="people new">
                  <img src="/images/sidebar-people.png" alt="sidebar" />
                  <span className="roundlive_red" />
                </span>
              </div>
            )
        }
      </div>

    )
  }
}

const mapStateToProps = ({ restaurant }) => {
  return {
    restaurantDetails: restaurant.detailsPayload,
    offersDetails: restaurant.offersPayload,
  }
}

export default connect(mapStateToProps, {
  fetchRestaurantDetails,
  fetchRestaurantDetailOffers
})(RestaurantEmbed);
