import React from "react";

const InputWrapper = ({ formik, children, icon, fieldName, noTouch, ...props }) => {
  return (
    <div
      className={`form-group input-style ${formik.touched[fieldName] && formik.errors[fieldName] ? 'error' : ''}`}
    >
      {children}
      {
        noTouch

          ? (<span>
            <img src={
              !formik.errors[fieldName]
                ? `/images/booking/${icon}-red.png`
                : `/images/booking/${icon}.png`
            } alt="" style={{ maxHeight: 16 }} />
          </span>)
          : (<span>
            <img src={
              // formik.touched.email && !formik.errors.email
              formik.touched[fieldName] && formik.errors[fieldName]
                ? `/images/booking/${icon}-red.png`
                : formik.values[fieldName].trim().length > 0 ? (`/images/booking/${icon}-red.png`) : `/images/booking/${icon}.png`
            } alt="" style={{ maxHeight: 16 }} />
          </span>)

      }
      {
        formik.touched[fieldName] && formik.errors[fieldName]
          ? (<React.Fragment>
            <span className="error-symbol"><img src="/images/booking/icon_info.png" /></span>
            <div className="invalid-feedback" style={{ display: "block" }}>{formik.errors[fieldName]}</div>
          </React.Fragment>)
          : null
      }
    </div>
  )
}

export default InputWrapper;