import { toast } from 'react-toastify';
import {
  ADD_SUGGESTION_START,
  ADD_SUGGESTION_SUCCESS,
  ADD_SUGGESTION_FAILED,
  REMOVE_SUGGESTION_STATUS,
  SET_SUGGESTION_STATUS,
  FETCH_PENDING_SUGGESTIONS_START,
  FETCH_PENDING_SUGGESTIONS_SUCCESS,
  FETCH_PENDING_SUGGESTIONS_FAILED
} from '../../constants/actionTypes';
import { suggestionService } from "../../service/suggestion-service";
import { updateRestaurantData } from './Restaurant';

export const addSuggestions = (data, updateData, onError) => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(addSuggestionsStart());
    suggestionService.postSuggestion(data)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(addSuggestionsSuccess(response));
          if (updateData) {
            dispatch(updateRestaurantData(updateData));
          }
        } else {
          if (response.message && response.message.length) {
            //console.log("response.message[0]",response.message)
            if(response.message[0].includes("pending")){
              onError("Pending")
            }else{
              toast.error(response.message[0]);
            }
          }
          dispatch(addSuggestionsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(addSuggestionsFailed(failureMessage));
      });
  }
};

export const fetchPendingSuggestions = () => {
  const failureMessage = 'Something wrong happened';
  return dispatch => {
    dispatch(getPendingSuggestionsStart());
    suggestionService.getPendingSuggestions()
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(getPendingSuggestionsSuccess(response));
        } else {
          if (response.message && response.message.length) {
            //console.log("response.message[0]",response.message)
            toast.error(response.message);
          }
          dispatch(getPendingSuggestionsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(getPendingSuggestionsFailed(failureMessage));
      });
  }
};

const addSuggestionsStart = () => {
  return {
    type: ADD_SUGGESTION_START
  }
};

const addSuggestionsSuccess = data => {
  return {
    type: ADD_SUGGESTION_SUCCESS,
    payload: data
  }
};

const addSuggestionsFailed = data => {
  return {
    type: ADD_SUGGESTION_FAILED,
    payload: data
  }
};

const getPendingSuggestionsStart = () => {
  return {
    type: FETCH_PENDING_SUGGESTIONS_START
  }
};

const getPendingSuggestionsSuccess = data => {
  return {
    type: FETCH_PENDING_SUGGESTIONS_SUCCESS,
    payload: data
  }
};

const getPendingSuggestionsFailed = data => {
  return {
    type: FETCH_PENDING_SUGGESTIONS_FAILED,
    payload: data
  }
};

export const setSuggestionStatus = () => {
  return {
    type: SET_SUGGESTION_STATUS,
  }
}

export const removeSuggestionStatus = () => {
  return {
    type: REMOVE_SUGGESTION_STATUS,
  }
};
