import {
  CLAIM_RESTAURANT_START,
  CLAIM_RESTAURANT_SUCCESS,
  CLAIM_RESTAURANT_FAILED,
  FETCH_LISTS_RESTAURANTS_AFFILIATED_START,
  FETCH_LISTS_RESTAURANTS_AFFILIATED_SUCCESS,
  FETCH_LISTS_RESTAURANTS_AFFILIATED_FAILED,
  CHECK_RESTAURANT_ADMIN_START,
  CHECK_RESTAURANT_ADMIN_SUCCESS,
  CHECK_RESTAURANT_ADMIN_FAILED,
  UPDATE_RESTAURANT_ADMIN_START,
  UPDATE_RESTAURANT_ADMIN_SUCCESS,
  UPDATE_RESTAURANT_ADMIN_FAILED,
  SET_UPDATE_RESTAURANT_ADMIN_STATUS,
  REMOVE_UPDATE_RESTAURANT_ADMIN_STATUS,
  LIST_RESTAURANT_ADMIN_BOOKING_START,
  LIST_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  LIST_RESTAURANT_ADMIN_BOOKING_FAILED,
  LIST_RESTAURANT_ADMIN_ALL_BOOKING_START,
  LIST_RESTAURANT_ADMIN_ALL_BOOKING_SUCCESS,
  LIST_RESTAURANT_ADMIN_ALL_BOOKING_FAILED,
  REJECT_RESTAURANT_ADMIN_BOOKING_START,
  REJECT_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  REJECT_RESTAURANT_ADMIN_BOOKING_FAILED,
  ACCEPT_RESTAURANT_ADMIN_BOOKING_START,
  ACCEPT_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  ACCEPT_RESTAURANT_ADMIN_BOOKING_FAILED,
  CONFIRM_RESTAURANT_ADMIN_BOOKING_START,
  CONFIRM_RESTAURANT_ADMIN_BOOKING_SUCCESS,
  CONFIRM_RESTAURANT_ADMIN_BOOKING_FAILED,
  FETCH_RESTAURANT_COIN_BUNDLE_START,
  FETCH_RESTAURANT_COIN_BUNDLE_SUCCESS,
  FETCH_RESTAURANT_COIN_BUNDLE_FAILED,
  FETCH_RESTAURANT_COIN_REWARDS_SETTING_START,
  FETCH_RESTAURANT_COIN_REWARDS_SETTING_SUCCESS,
  FETCH_RESTAURANT_COIN_REWARDS_SETTING_FAILED,
  FETCH_MODERATION_LIST_START,
  FETCH_MODERATION_LIST_SUCCESS,
  FETCH_MODERATION_LIST_FAILED,
  UPDATE_MODERATION_STATUS_START,
  UPDATE_MODERATION_STATUS_SUCCESS,
  UPDATE_MODERATION_STATUS_FAILED,
  FETCH_PAGE_LEVEL_START,
  FETCH_PAGE_LEVEL_SUCCESS,
  FETCH_PAGE_LEVEL_FAILED,
  FETCH_NEWS_LIST_START,
  FETCH_NEWS_LIST_SUCCESS,
  FETCH_NEWS_LIST_FAILED,
  UPDATE_NEWS_STATUS_START,
  UPDATE_NEWS_STATUS_SUCCESS,
  UPDATE_NEWS_STATUS_FAILED,
  FETCH_NOTIFICATION_START,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILED,
  UPDATE_READ_NOTIFICATION_START,
  UPDATE_READ_NOTIFICATION_SUCCESS,
  UPDATE_READ_NOTIFICATION_FAILED,
  FETCH_INBOX_COUNT_START,
  FETCH_INBOX_COUNT_SUCCESS,
  FETCH_INBOX_COUNT_FAILED
} from "../../constants/actionTypes";
import { cloneDeep } from 'lodash';

const initialState = {
  controller: new AbortController(),
  error: null,
  isLoading: false,
  payload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
  filters: {},
  modifyPayload: null,
  modifyLoading: false,
  newPayload: [],
  restauranAdminUpdated: false,
  bookingPayload: [],
  bookingLoading: false,
  bookingAllPayload: [],
  bookingAllLoading: false,
  coinBundles: [],
  coinBundleLoading: false,
  rewardsLoading: false,
  coinsRewards: [],
  moderationPayload: [],
  moderationLoading: false,
  updateLoading: false,
  detailsPayload: [],
  pageLevelDetails: null,
  pageLevelDetailsLoading: false,
  newsLoading: false,
  newsPayload: [],
  newsCurrentPage: 0,
  newsPageSize: null,
  newsTotalItem: null,
  newsTotalPage: null,
  newsError: null,
  updateNewsLoading: false,
  notificationLoading: false,
  notificationPayload: [],
  notificationCurrentPage: 0,
  notificationPageSize: null,
  notificationTotalItem: null,
  notificationTotalPage: null,
  inboxError: null,
  inboxPayload: [],
  inboxIsLoading: false,
  newsUpdateData: [],
  notificationUpdateData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLAIM_RESTAURANT_START: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case CLAIM_RESTAURANT_SUCCESS: {
      return { ...state, error: null, isLoading: true };
    }
    case CLAIM_RESTAURANT_FAILED: {
      return { ...state, isLoading: false, error: null, payload: action.payload };
    }
    case FETCH_LISTS_RESTAURANTS_AFFILIATED_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_LISTS_RESTAURANTS_AFFILIATED_SUCCESS: {
      return {
        ...state,
        error: null,
        newPayload: action.payload,
        isLoading: false
      };

    }
    case FETCH_LISTS_RESTAURANTS_AFFILIATED_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    case CHECK_RESTAURANT_ADMIN_START: {
      return { ...state, isLoading: true, error: null };
    }
    case CHECK_RESTAURANT_ADMIN_SUCCESS: {
      return { ...state, error: null, isLoading: false, payload: cloneDeep(action.payload) };
    }
    case CHECK_RESTAURANT_ADMIN_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case UPDATE_RESTAURANT_ADMIN_START: {
      return { ...state, error: null, updateLoading: true };
    }
    case UPDATE_RESTAURANT_ADMIN_SUCCESS: {
      return { ...state, updateLoading: false, error: null, detailsPayload: action.payload };
    }
    case UPDATE_RESTAURANT_ADMIN_FAILED: {
      return { ...state, updateLoading: false, error: action.payload };
    }
    case SET_UPDATE_RESTAURANT_ADMIN_STATUS: {
      return { ...state, restauranAdminUpdated: true }
    }
    case REMOVE_UPDATE_RESTAURANT_ADMIN_STATUS: {
      return { ...state, restauranAdminUpdated: false }
    }
    case LIST_RESTAURANT_ADMIN_BOOKING_START: {
      return { ...state, error: null, bookingLoading: true };
    }
    case LIST_RESTAURANT_ADMIN_BOOKING_SUCCESS: {
      const { totalPage, currentPage } = action.data;
      return {
        ...state,
        error: null,
        bookingPayload: action.payload,
        totalPage,
        currentPage,
        bookingLoading: false
      };

    }
    case LIST_RESTAURANT_ADMIN_BOOKING_FAILED: {
      return { ...state, bookingPayload: null, bookingLoading: false, error: action.payload };
    }
    case LIST_RESTAURANT_ADMIN_ALL_BOOKING_START: {
      return { ...state, error: null, bookingAllLoading: true };
    }
    case LIST_RESTAURANT_ADMIN_ALL_BOOKING_SUCCESS: {
      return {
        ...state,
        error: null,
        bookingAllPayload: action.payload,
        bookingAllLoading: false
      };

    }
    case LIST_RESTAURANT_ADMIN_ALL_BOOKING_FAILED: {
      return { ...state, bookingAllPayload: null, bookingAllLoading: false, error: action.payload };
    }
    case REJECT_RESTAURANT_ADMIN_BOOKING_START: {
      return { ...state, error: null, modifyLoading: true };
    }
    case REJECT_RESTAURANT_ADMIN_BOOKING_SUCCESS: {
      const bookingPayload = cloneDeep(state.bookingPayload);
      const foundIndex = bookingPayload.findIndex(booking => booking._id === action.payload._id)
      if (foundIndex !== -1) {
        bookingPayload[foundIndex].bookingStage = action.payload.bookingStage;
      }
      return { ...state, modifyLoading: false, error: null, modifyPayload: action.payload, bookingPayload };
    }
    case REJECT_RESTAURANT_ADMIN_BOOKING_FAILED: {
      return { ...state, modifyLoading: false, error: action.payload };
    }
    case ACCEPT_RESTAURANT_ADMIN_BOOKING_START: {
      return { ...state, error: null, modifyLoading: true };
    }
    case ACCEPT_RESTAURANT_ADMIN_BOOKING_SUCCESS: {
      const bookingPayload = cloneDeep(state.bookingPayload);
      const foundIndex = bookingPayload.findIndex(booking => booking._id === action.payload._id)
      if (foundIndex !== -1) {
        bookingPayload[foundIndex].bookingStage = action.payload.bookingStage;
      }
      return { ...state, modifyLoading: false, modifyPayload: action.payload, bookingPayload };
    }
    case ACCEPT_RESTAURANT_ADMIN_BOOKING_FAILED: {
      return { ...state, modifyLoading: false, error: action.payload };
    }
    case CONFIRM_RESTAURANT_ADMIN_BOOKING_START: {
      return { ...state, error: null, modifyLoading: true };
    }
    case CONFIRM_RESTAURANT_ADMIN_BOOKING_SUCCESS: {
      return { ...state, modifyLoading: false, error: null, modifyPayload: action.payload };
    }
    case CONFIRM_RESTAURANT_ADMIN_BOOKING_FAILED: {
      return { ...state, modifyLoading: false, error: action.payload }
    }
    case FETCH_RESTAURANT_COIN_BUNDLE_START: {
      return { ...state, coinBundleLoading: true, error: null };
    }
    case FETCH_RESTAURANT_COIN_BUNDLE_SUCCESS: {
      return { ...state, coinBundleLoading: false, error: null, coinBundles: action.payload };
    }
    case FETCH_RESTAURANT_COIN_BUNDLE_FAILED: {
      return { ...state, coinBundleLoading: false, error: action.payload };
    }
    case FETCH_RESTAURANT_COIN_REWARDS_SETTING_START: {
      return { ...state, rewardsLoading: true, error: null };
    }
    case FETCH_RESTAURANT_COIN_REWARDS_SETTING_SUCCESS: {
      return { ...state, rewardsLoading: false, error: null, coinsRewards: action.payload };
    }
    case FETCH_RESTAURANT_COIN_REWARDS_SETTING_FAILED: {
      return { ...state, rewardsLoading: false, error: action.payload };
    }
    case FETCH_MODERATION_LIST_START: {
      return { ...state, moderationLoading: true, error: null, controller: action.controller };
    }
    case FETCH_MODERATION_LIST_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;

      if (currentPage !== state.currentPage) {

        return {
          ...state,
          moderationLoading: false,
          moderationPayload: state.moderationPayload.concat(data),
          currentPage,
          pageSize,
          totalItem,
          totalPage,
          error: null
        };
      } else {
        return { ...state, moderationLoading: false };
      }
    }
    case FETCH_MODERATION_LIST_FAILED: {
      return { ...state, moderationLoading: false, error: action.payload };
    }
    case UPDATE_MODERATION_STATUS_START: {
      return { ...state, error: null, updateLoading: true };
    }
    case UPDATE_MODERATION_STATUS_SUCCESS: {
      return {
        ...state, updateLoading: false, error: null
      };
    }
    case UPDATE_MODERATION_STATUS_FAILED: {
      return { ...state, updateLoading: false, error: action.payload };
    }
    case FETCH_PAGE_LEVEL_START: {
      return { ...state, error: null, pageLevelDetailsLoading: true };
    }
    case FETCH_PAGE_LEVEL_SUCCESS: {
      return { ...state, pageLevelDetailsLoading: false, pageLevelDetails: action.payload, error: null };
    }
    case FETCH_PAGE_LEVEL_FAILED: {
      return { ...state, pageLevelDetailsLoading: false, error: action.payload };
    }
    case FETCH_NEWS_LIST_START: {
      return { ...state, newsLoading: true, error: null, controller: action.controller };
    }
    case FETCH_NEWS_LIST_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;
      if (currentPage !== state.newsCurrentPage) {
        return {
          ...state,
          newsLoading: true,
          newsPayload: state.newsPayload.concat(data),
          newsCurrentPage: currentPage,
          newsPageSize: pageSize,
          newsTotalItem: totalItem,
          newsTotalPage: totalPage,
          newsError: null
        };
      } else {
        return { ...state, newsLoading: false };
      }
    }
    case FETCH_NEWS_LIST_FAILED: {
      return { ...state, newsLoading: false, newsError: action.payload };
    }
    case UPDATE_NEWS_STATUS_START: {
      return { ...state, error: null, updateNewsLoading: true };
    }
    case UPDATE_NEWS_STATUS_SUCCESS: {
      const data = action.payload.data;
      const newsPayload = state.newsPayload.map(news => {
        if (news._id === data.news) {
          news['readStatus'] = data.readStatus;
          news['deleteStatus'] = data.deleteStatus;
          return news;
        } else {
          return news;
        }
      });
      return {
        ...state, updateNewsLoading: false, error: null, newsPayload: newsPayload
      };
    }
    case UPDATE_NEWS_STATUS_FAILED: {
      return { ...state, updateLoading: false, error: action.payload };
    }
    case UPDATE_READ_NOTIFICATION_START: {
      return { ...state, error: null, updateNotificationLoading: true };
    }
    case UPDATE_READ_NOTIFICATION_SUCCESS: {

      const data = action.payload.data;
      const newData = state.notificationPayload.map((notification) => {
        if (notification._id === data._id) {
          return data;
        } else {
          return notification;
        }
      });
      return {
        ...state, updateNotificationLoading: false, error: null, notificationPayload: newData
      };
    }
    case UPDATE_READ_NOTIFICATION_FAILED: {
      return { ...state, updateNotificationLoading: false, error: action.payload };
    }
    case FETCH_NOTIFICATION_START: {
      return { ...state, notificationLoading: true, notificationError: null, controller: action.controller };
    }
    case FETCH_NOTIFICATION_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;

      if (currentPage !== state.notificationCurrentPage) {
        return {
          ...state,
          notificationLoading: true,
          notificationPayload: state.notificationPayload.concat(data),
          notificationCurrentPage: currentPage,
          notificationPageSize: pageSize,
          notificationTotalItem: totalItem,
          notificationTotalPage: totalPage,
          notificationError: null
        };
      } else {
        return { ...state, notificationLoading: false };
      }
    }
    case FETCH_NOTIFICATION_FAILED: {
      return { ...state, notificationLoading: false, error: action.payload };
    }
    case FETCH_INBOX_COUNT_START: {
      return { ...state, inboxError: null, inboxIsLoading: true };
    }
    case FETCH_INBOX_COUNT_SUCCESS: {
      return {
        ...state,
        inboxError: null,
        inboxPayload: action.payload,
        inboxIsLoading: false
      };

    }
    case FETCH_INBOX_COUNT_FAILED: {
      return { ...state, inboxPayload: null, inboxIsLoading: false, inboxError: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
