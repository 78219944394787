import { urlEncodedAuthHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import {secureFetch} from './secureFetch';
import { convertData } from "./convertToUrlEncoded";

export const reviewService = {
  postReview,
  updateReview
};

function postReview(data) {
  const formBody = convertData(data);

  const requestOptions = {
    method: 'POST',
    headers: urlEncodedAuthHeader(),
    body: formBody
  };

  return secureFetch(`${apiURL.REVIEWS}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateReview(data, reviewId) {
  const formBody = convertData(data);

  const requestOptions = {
    method: 'PUT',
    headers: urlEncodedAuthHeader(),
    body: formBody
  };

  return secureFetch(`${apiURL.REVIEWS}/${reviewId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
