import { cloneDeep } from 'lodash';
import {
  FETCH_ALL_RESTAURANTS_START,
  FETCH_ALL_RESTAURANTS_SUCCESS,
  FETCH_ALL_RESTAURANTS_FAILED,
  FETCH_RESTAURANT_DETAILS_START,
  FETCH_RESTAURANT_DETAILS_SUCCESS,
  FETCH_RESTAURANT_DETAILS_FAILED,
  UPDATE_RESTAURANT_DETAILS_START,
  UPDATE_RESTAURANT_DETAILS_SUCCESS,
  UPDATE_RESTAURANT_DETAILS_FAILED,
  UPDATE_RESTAURANT_HYGIENE_START,
  UPDATE_RESTAURANT_HYGIENE_SUCCESS,
  UPDATE_RESTAURANT_HYGIENE_FAILED,
  SET_QUERY_STRING,
  REMOVE_QUERY_STRING,
  FETCH_RESTAURANT_LOCATIONS_START,
  FETCH_RESTAURANT_LOCATIONS_SUCCESS,
  FETCH_RESTAURANT_LOCATIONS_FAILED,
  SET_TOP_RESTAURANT_MODE,
  REMOVE_TOP_RESTAURANT_MODE,
  SET_UPDATE_RESTAURANT_STATUS,
  REMOVE_UPDATE_RESTAURANT_STATUS,
  UPDATE_RESTAURANT_DATA,
  FETCH_RESTAURANT_DETAIL_OFFERS_START,
  FETCH_RESTAURANT_DETAIL_OFFERS_SUCCESS,
  FETCH_RESTAURANT_DETAIL_OFFERS_FAILED,
  FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_START,
  FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_SUCCESS,
  FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_FAILED,
  FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_START,
  FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_SUCCESS,
  FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_FAILED,
  UPDATE_RESTAURANT_COINS,
  FETCH_BOOKING_OFFERS_EXCEPTION,
  FETCH_BOOKING_OFFERS_EXCEPTION_SUCCESS,
  FETCH_BOOKING_OFFERS_EXCEPTION_FAILED
} from "../../constants/actionTypes";

const initialState = {
  controller: new AbortController(),
  error: null,
  isLoading: false,
  payload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null,
  filters: {},
  detailsLoading: false,
  detailsPayload: null,
  restaurantLocations: null,
  locationsLoading: false,
  topRestaurantMode: false,
  menuList: null,
  updateLoading: false,
  hygieneLoading: false,
  restaurantUpdated: false,
  offersPayload: [],
  offersOtherPayload: [],
  offersExceptionPayload: [],
  offersLoading: false,
  otherLoading: false,
  exceptionLoading: false,
  bookingOffersLoading: false,
  bookingOffers: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_RESTAURANTS_START: {
      return { ...state, error: null, isLoading: true, controller: action.controller };
    }
    case FETCH_ALL_RESTAURANTS_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;
      if (currentPage !== state.currentPage) {
        return {
          ...state,
          error: null,
          payload: state.payload.concat(data),
          currentPage,
          pageSize,
          totalItem,
          totalPage,
          isLoading: false
        };
      } else {
        return state;
      }
    }
    case FETCH_ALL_RESTAURANTS_FAILED: {
      return { ...state, payload: null, isLoading: false, error: action.payload };
    }
    case SET_QUERY_STRING: {
      return { ...initialState, filters: action.payload }
    }
    case REMOVE_QUERY_STRING: {
      return { ...initialState, payload: [], filters: {} }
    }
    case FETCH_RESTAURANT_DETAILS_START: {
      return { ...state, error: null, detailsLoading: true };
    }
    case FETCH_RESTAURANT_DETAILS_SUCCESS: {
      return { ...state, detailsPayload: action.payload, detailsLoading: false, error: null, menuList: action.menuList };
    }
    case FETCH_RESTAURANT_DETAILS_FAILED: {
      return { ...state, payload: null, detailsLoading: false, error: action.payload, menuList: null };
    }
    case UPDATE_RESTAURANT_DETAILS_START: {
      return { ...state, error: null, updateLoading: true };
    }
    case UPDATE_RESTAURANT_DETAILS_SUCCESS: {
      return { ...state, updateLoading: false, error: null, detailsPayload: action.payload };
    }
    case UPDATE_RESTAURANT_DETAILS_FAILED: {
      return { ...state, updateLoading: false, error: action.payload };
    }
    case UPDATE_RESTAURANT_HYGIENE_START: {
      return { ...state, error: null, hygieneLoading: true };
    }
    case UPDATE_RESTAURANT_HYGIENE_SUCCESS: {
      return { ...state, hygieneLoading: false, error: null, detailsPayload: action.payload };
    }
    case UPDATE_RESTAURANT_HYGIENE_FAILED: {
      return { ...state, hygieneLoading: false, error: action.payload };
    }
    case FETCH_RESTAURANT_LOCATIONS_START: {
      return { ...state, error: null, locationsLoading: true };
    }
    case FETCH_RESTAURANT_LOCATIONS_SUCCESS: {
      return { ...state, restaurantLocations: action.payload, locationsLoading: false, error: null };
    }
    case FETCH_RESTAURANT_LOCATIONS_FAILED: {
      return { ...state, restaurantLocations: null, locationsLoading: false, error: action.payload };
    }
    case SET_TOP_RESTAURANT_MODE: {
      return { ...state, topRestaurantMode: true }
    }
    case REMOVE_TOP_RESTAURANT_MODE: {
      return { ...state, topRestaurantMode: false }
    }
    case SET_UPDATE_RESTAURANT_STATUS: {
      return { ...state, restaurantUpdated: true }
    }
    case REMOVE_UPDATE_RESTAURANT_STATUS: {
      return { ...state, restaurantUpdated: false }
    }
    case UPDATE_RESTAURANT_DATA: {
      return {
        ...state,
        detailsPayload: {
          ...cloneDeep(state.detailsPayload),
          ...cloneDeep(action.payload)
        }
      }
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_START: {
      return { ...state, error: null, offerLoading: true };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_SUCCESS: {
      return { ...state, offersPayload: action.payload, offerLoading: false, error: null };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_FAILED: {
      return { ...state, offersPayload: null, offerLoading: false, error: action.payload };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_START: {
      return { ...state, error: null, controller: action.controller, exceptionLoading: true };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_SUCCESS: {
      return { ...state, offersExceptionPayload: action.payload, error: null, exceptionLoading: false };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_EXCEPTION_FAILED: {
      return { ...state, offersExceptionPayload: null, error: action.payload, exceptionLoading: false };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_START: {
      return { ...state, error: null, otherLoading: true, controller: action.controller };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_SUCCESS: {
      return { ...state, offersOtherPayload: action.payload, otherLoading: false, error: null };
    }
    case FETCH_RESTAURANT_DETAIL_OFFERS_OTHER_FAILED: {
      return { ...state, offersOtherPayload: null, otherLoading: false, error: action.payload };
    }
    case UPDATE_RESTAURANT_COINS: {
      if (state.detailsPayload) {
        return { ...state, detailsPayload: { ...cloneDeep(state.detailsPayload), coins: action.payload }};
      } else {
        return state;
      }
    }
    case FETCH_BOOKING_OFFERS_EXCEPTION: {
      return { ...state, error: null, bookingOffersLoading: true };
    }
    case FETCH_BOOKING_OFFERS_EXCEPTION_SUCCESS: {
      return { ...state, bookingOffersLoading: false, bookingOffers: action.payload }
    }
    case FETCH_BOOKING_OFFERS_EXCEPTION_FAILED: {
      return { ...state, bookingOffersLoading: false, error: action.payload }
    }
    default:
      return state
  }
};

export default reducer;
