import {
  FETCH_DASHBOARD_START,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILED
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  payload: [],
  currentPage: 0,
  pageSize: null,
  totalItem: null,
  totalPage: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_START: {
      return { ...state, error: null, isLoading: true };
    }
    case FETCH_DASHBOARD_SUCCESS: {
      const { data, currentPage, pageSize, totalItem, totalPage } = action.payload;
      return {
        ...state,
        error: null,
        payload: data,
        currentPage,
        pageSize,
        totalItem,
        totalPage,
        isLoading: false
      };
    }
    case FETCH_DASHBOARD_FAILED: {
      return { ...state, isLoading: false, error: action.payload };
    }
    default:
      return state
  }
};

export default reducer;
