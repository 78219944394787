import store from '../store';
import { cloneDeep } from 'lodash';

export const createLoggerData = (primaryAction, secondaryAction, url, user) => {
  const state = store.getState();
  const data = {
    locationUrl: url,
    primaryFunction: primaryAction,
    secondaryFunction: secondaryAction,
    actionBy: user ? user._id : state.auth.payload ? state.auth.payload._id : '',
    actionDate: (new Date()).toISOString(),
    logSource: 'Frontend'
  }
  return cloneDeep(data);
}
