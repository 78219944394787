import {
  FETCH_ACHIEVEMENTS_START,
  FETCH_ACHIEVEMENTS_SUCCESS,
  FETCH_ACHIEVEMENTS_FAILED
} from '../../constants/actionTypes';
import { achievementsService } from '../../service/achievements-service';

export const fetchAchievements = (status) => {

  const failureMessage = 'Something wrong happened';

  return (dispatch, getState) => {

    dispatch(fetchAchievementsStart());
    achievementsService.fetchAchievements(status)
      .then(rawData => {
        return rawData.json();
      })
      .then(response => {
        if (response.status) {
          dispatch(fetchAchievementsSuccess(response, status));
        } else {
          dispatch(fetchAchievementsFailed(failureMessage));
        }
      })
      .catch(() => {
        dispatch(fetchAchievementsFailed(failureMessage));
      });
  }
};

const fetchAchievementsStart = (controller) => {
  return {
    type: FETCH_ACHIEVEMENTS_START,
    controller
  }
};

const fetchAchievementsSuccess = (data, status) => {
  return {
    type: FETCH_ACHIEVEMENTS_SUCCESS,
    payload: data,
    status
  }
};

const fetchAchievementsFailed = data => {
  return {
    type: FETCH_ACHIEVEMENTS_FAILED,
    payload: data
  }
};