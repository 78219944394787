import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { partition } from 'lodash';

//constants
import { RESTAURANT_ADMIN } from '../../constants/routes';
import { fetchInboxCount, fetchRestaurantDetails } from '../../store/actions';

class RestaurantAdminHeader extends React.Component {

  state = {
    initialLoad: true,
    restaurantId: null
  };

  componentDidMount() {
    const { location } = this.props;
    const id = location.pathname.split('/')[2];
    this.props.fetchRestaurantDetails(id);
  }

  componentDidUpdate() {
    if (this.state.initialLoad && this.props.restaurantDetails) {
      this.setState({ restaurantId: this.props.restaurantDetails._id, initialLoad: false });
      if (typeof this.props.restaurantDetails._id !== "undefined") {
        this.props.fetchInboxCount(this.props.restaurantDetails._id);
      }
    }
  }

  render() {
    const { location, inboxPayload, roleHeaders } = this.props;
    const id = location.pathname.split('/')[2];
    const { news, notification, moderation } = inboxPayload;
    let countNew = 0;
    if (news) {
      countNew = countNew + news["new"];
    }
    if (notification) {
      countNew = countNew + notification["new"];
    }
    if (moderation) {
      countNew = countNew + moderation["new"];
    }

    const modifiedHeaders = partition(roleHeaders, header => header.name !== 'Account' && header.name !== 'Help')

    return (
      <div className="user_search_nav bg-blue fixed_top_nav_for_index_tablet">
        <div className="wrapper p_36 user_search_nav_inner">
          <ul className="d-flex-align user_search_nav top_blue_nav1">
            {
              modifiedHeaders[0].map(headerData => {
                const {path, name} = headerData;
                if (name === 'Inbox') {
                  return <li key={path}>
                    <NavLink activeClassName="active" to={`${RESTAURANT_ADMIN}/${id}/${path}`}>{name} <span className="no_in_round">{countNew}</span></NavLink>
                  </li>
                } else {
                  return <li key={path}><NavLink activeClassName="active" to={`${RESTAURANT_ADMIN}/${id}/${path}`}>{name}</NavLink></li>
                }
              })
            }
          </ul>
          <ul className="d-flex-align user_search_nav top_blue_nav1">
            <li><a onClick={e => e.preventDefault()} className="left_line" style={{ padding: 0 }} /></li>
            {
              modifiedHeaders[1].map(headerData => {
                const {path, name} = headerData;
                return <li key={path}><NavLink activeClassName="active" to={`${RESTAURANT_ADMIN}/${id}/${path}`}>{name}</NavLink></li>
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}


const mapStateToProps = ({ restaurantAdmin, restaurant }) => {
  return {
    loading: restaurantAdmin.isLoading,
    inboxPayload: restaurantAdmin.inboxPayload,
    restaurantDetails: restaurant.detailsPayload,
  }
};

export default withRouter(connect(mapStateToProps,
  {
    fetchInboxCount,
    fetchRestaurantDetails
  })(RestaurantAdminHeader));