import {
  WINDOW_WIDTH,
  TOGGLE_COLLAPSED_NAV,
  HIDE_FOOTER,
  SHOW_FOOTER,
  CHANGE_MAP_VIEW,
  SHOW_TOAST_SETTINGS,
  HIDE_TOAST_SETTINGS
} from "../../constants/actionTypes";

export const toggleCollapsedSideNav = navCollapsed => {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
};

export const updateWindowWidth = width => {
  return { type: WINDOW_WIDTH, width };
};

export const showFooter = () => {
  return { type: SHOW_FOOTER };
};

export const hideFooter = () => {
  return { type: HIDE_FOOTER };
};

export const changeMapView = status => {
  return {
    type: CHANGE_MAP_VIEW,
    payload: status
  }
};

export const showToastSettings = () => {
  return { type: SHOW_TOAST_SETTINGS };
}

export const hideToastSettings = () => {
  return { type: HIDE_TOAST_SETTINGS };
}
