import { authHeader } from './auth-header';
import * as apiURL from './Apiconfig';
import { secureFetch } from './secureFetch';
import { RESTAURANTS } from "./Apiconfig";

export const restaurantService = {
  updateViewCount,
  getAllRestaurants,
  getRestaurantDetails,
  updateRestaurantDetails,
  updateRestaurantDetailsHygiene,
  getRestaurantLocations,
  updateCallCount
};

function getAllRestaurants({
  currentPage,
  searchQuery,
  location,
  cuisine,
  tag,
  menu,
  menuType,
  orderBy,
  pageSize,
  mapRadius,
  placeId,
  name,
  saveRecent
}, signal) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    signal
  };

  const newPageSize = pageSize ? pageSize : 22;

  return secureFetch(`${apiURL.RESTAURANTS}?pageSize=${newPageSize}&currentPage=${currentPage}&search=${searchQuery}&mapCenter=${location}&orderDir=desc&orderBy=${orderBy || 'rating'}&cuisine=${cuisine}&type=listed&menu=${menu}&menuType=${menuType}&tag=${tag}&mapRadius=${mapRadius}&placeId=${placeId}&name=${name}&saveRecent=${saveRecent}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateViewCount(restaurantId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANTS}/${restaurantId}/view-count`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function getRestaurantDetails(restaurantId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANTS}/${restaurantId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateRestaurantDetails(restaurantId, details) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(details)
  };

  return secureFetch(`${apiURL.RESTAURANTS}/${restaurantId}`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateRestaurantDetailsHygiene(restaurantId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };

  return fetch(`${RESTAURANTS}/${restaurantId}/update-hygiene-rating`, requestOptions)
    .then(details => {
      return details
    })
    .catch(err => {
      return err
    });
}

function getRestaurantLocations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return secureFetch(`${apiURL.RESTAURANT_LOCATIONS}/?pageSize=10`, requestOptions)
    .then(user => {
      return user;
    }).catch(err => {
      return err;
    });
}

function updateCallCount(restaurantId) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
  };

  return fetch(`${RESTAURANTS}/${restaurantId}/update-callcount`, requestOptions)
    .then(details => {
      return details
    })
    .catch(err => {
      return err
    });
}

/*function handleResponse(response) {
  return response.text().then(text => {
    return text && JSON.parse(text);
  });
}*/
