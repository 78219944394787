import {
  FETCH_ACHIEVEMENTS_START,
  FETCH_ACHIEVEMENTS_SUCCESS,
  FETCH_ACHIEVEMENTS_FAILED
} from "../../constants/actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  pendingAchievements: [],
  completedAchievements: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACHIEVEMENTS_START: {
      return { ...state, error: null, isLoading: true, controller: action.controller };
    }
    case FETCH_ACHIEVEMENTS_SUCCESS: {

      const { data } = action.payload;
      if (action.status === "pending") {
        return {
          ...state,
          error: null,
          // pendingAchievements: state.pendingAchievements.concat(data),
          pendingAchievements: data,
          isLoading: false
        };
      } else {
        return {
          ...state,
          error: null,
          completedAchievements: data,
          isLoading: false
        };
      }

    }
    case FETCH_ACHIEVEMENTS_FAILED: {
      return { ...state, isLoading: false, error: action.pendingPayload };
    }
    default:
      return state
  }
};

export default reducer;