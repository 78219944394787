import React, { useState, useEffect, useRef } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

import "./Info.css";

const InfoModal = (props) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const pendingInfo = useRef(null)

  const handleInfoClicked = () => {
    if (props.type === "bookingTable") {
      setTooltipVisible(!tooltipVisible);
    } else if (props.type === "currentOffers") {
      setTooltipVisible(true);
    } else if (props.type === "detailsPage") {
      setTooltipVisible(true);
    } else if (props.type === "pendingBooking") {
      setTooltipVisible(true);
    } else if (props.type === "ads") {
      setTooltipVisible(true);
    }
  };

  const handleCloseTooltip = () => {
    if (!tooltipVisible) {
      pendingInfo.current.handleShow()
    } else {
      pendingInfo.current.handleHide()
    }
    setTooltipVisible(false);
  };

  const popover = (
    <Popover id="popover-basic" placement="left" className={props.divWidth} >
      <Popover.Content>
        <div className="tooltip-offers-body">
          {props.type === "bookingTable" && (
            <div className="tooltip-description booking-table">
              <h5 className="description-header">How does booking work?</h5>
              <div className="description-txt">
                Select your time,party size and any special requests and we'll
                contact the restaurant to reserve your table.
              </div>
              <h5 className="description-header">Discounts</h5>
              <div className="description-txt">
                Any discounts based on your coin balance,time slot or offers
                selected will be applied to your booking.
              </div>
              <h5 className="description-header">Coin rewards</h5>
              <div className="description-txt">
                Rewards applied to your account based on your booking and review
                activity.Some bookings will need some of your coin balance to
                secure.
              </div>
            </div>
          )}

          {props.type === "currentOffers" && (
            <>
              <div className="tooltip-description current-offers">
                Click the blocks on the grid to edit offer <br />
                details and times. 50 coins is the default.
              </div>
              <div className="tooltip-close">
                <img
                  src="/images/icon-delete.png"
                  style={{
                    width: 15,
                    height: 15,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={handleCloseTooltip}
                />
              </div>
            </>
          )}
          {props.type === "detailsPage" && (
            <>
              <div className="tooltip-description current-offers p-10">
                <h5 className="description-header">How does booking work?</h5>
                Rewards applied to your account based on your booking and review
                activity.Some bookings will need some of your coin balance to
                secure.
              </div>
              <div className="tooltip-close">
                <img
                  src="/images/icon-delete.png"
                  style={{
                    width: 15,
                    height: 15,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={handleCloseTooltip}
                />
              </div>
              {/* <div className="tooltip-description booking-table">
                <div className="description-txt">
                  Rewards applied to your account based on your booking and
                  review activity.Some bookings will need some of your coin
                  balance to secure.
                </div>
              </div> */}
            </>
          )}

          {props.type === "pendingBooking" && (
            <>
              <div className="tooltip-description current-offers p-10">
                The booking has been passed to the restaurant and as soon as they are able to check their reservations, you will receive confirmation on the details you provided.
              </div>
              <div className="tooltip-close">
                <img
                  src="/images/icon-delete.png"
                  style={{
                    width: 12,
                    height: 12,
                    marginLeft: 10,
                    marginTop: -5,
                    cursor: "pointer",
                  }}
                  onClick={handleCloseTooltip}
                />
              </div>
            </>
          )}
          {props.type === "ads" && (
            <>
              <div className="tooltip-description ads-tooltip ">
                <div className="d-flex width-280">
                  <div className="tooltip-close">
                    <img
                      src="/images/icon-delete.png"
                      style={{
                        width: 12,
                        height: 12,
                        marginLeft: 10,
                        marginTop: -5,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseTooltip}
                    />
                  </div>
                </div>
                <h5 className="description-header">What are tablefy ads?</h5>
                <div className="d-flex flex-col">
                  <div className="d-flex">
                    <div className="description-txt">
                      Boost your bookings by promoting your restaurant to a whole range of audiences. Your listing will appear in special promoted spots around the Tablefy site, specifically to the audience you have targeted.
                   </div>
                  </div>
                  <img className="restro-img" src="/images/ads/ads-restaurant.png" alt='dummy-img' />
                </div>


              </div>
            </>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      ref={pendingInfo}
      trigger="click"
      placement={props.position}
      overlay={popover}
      rootClose={true}
      show={tooltipVisible}
      divWidth={props.width}
    // onClick={handleClick}
    >
      <img
        src="/images/info.png"
        srcset="/images/info@2x.png 2x"
        style={{
          width: props.width,
          height: props.height,
          marginLeft: 10,
          cursor: "pointer",
        }}
        onClick={handleInfoClicked}
      />
    </OverlayTrigger>
  );
};

export default InfoModal;
